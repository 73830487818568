import React, { useEffect, useState } from "react"
import ReactPaginate from "react-paginate"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { useDispatch } from "react-redux"
import { questionEmpty } from "../../redux/slices/questions"
import Switch from "react-switch"
import EventName from "../../components/popUp/EventName"
import { MdQrCodeScanner } from "react-icons/md"
import { BsLink45Deg, BsX } from "react-icons/bs"
import QRCode from "../../components/qr-code"
import { FiMoreVertical, FiTrash2 } from "react-icons/fi"
import { AiOutlineMail, AiOutlineSend } from "react-icons/ai"
import Mailsend from "../../components/popUp/Mailsend"
import Popup from "../../components/popUp/Popup"

const Table = ({ items, events }) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [id, setId] = useState("")
    const [popUpBox, setPopUpBox] = useState(false)
    const [actions, setActions] = useState("")
    const [eventName, setEventName] = useState("")
    const [deleteEvent, setDeleteEvent] = useState("")
    const [actionStatus, setActionStatus] = useState(true)
    const [name, setName] = useState("")
    const [qrcode, setQr] = useState(null)
    const [qrPopup, setQrPopup] = useState(false)
    const [sideOption, setSideOption] = useState(false)
    const [show, setShow] = useState(false)
    const [shortID, setShortID] = useState("")
    const [mail, setMail] = useState(false)
    const { mood } = useSelector((state) => state.mood)
    const [itemsPerPage, setItemPerPage] = useState(10)
    const [itemOffset, setItemOffset] = useState(0)
    const endOffset = itemOffset + itemsPerPage
    const currentItems = items.slice(itemOffset, endOffset)
    const pageCount = Math.ceil(items.length / itemsPerPage)
    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % items.length

        setItemOffset(newOffset)
    }

    const report = (id) => {
        navigate(`/report/${id}`)
    }

    const editQuestion = (e, name) => {
        dispatch(questionEmpty())
        navigate(`/addQuestions?id=${e}&name=${name}`)
    }

    useEffect(() => {
        return events(deleteEvent)
    }, [deleteEvent])

    document.body.addEventListener("click", () => {
        setQrPopup(false)
    })

    document.body.addEventListener("click", () => {
        setSideOption(false)
    })

    function Items({ currentItems }) {
        return (
            <>
                {currentItems &&
                    currentItems.map((i, index) => {
                        return (
                            <>
                                <tr
                                    className=""
                                    key={index}
                                >
                                    <td className={`react_table_body_content`}>
                                        <div
                                            className={` react_table_body_content_text ${
                                                mood === "light" ? "text-[#18181B]" : "text-gray"
                                            }`}
                                        >
                                            {i.eventName}
                                        </div>
                                    </td>

                                    <td className=" react_table_body_content">
                                        <div
                                            className={`react_table_body_content_text1 ${
                                                mood === "light" ? "text-[#71717A]" : "text-gray"
                                            }`}
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                strokeWidth={1.5}
                                                stroke="currentColor"
                                                className="w-4 h-4 mr-2"
                                            >
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5"
                                                />
                                            </svg>
                                            {new Date(i.createdAt).toLocaleDateString()}
                                        </div>
                                    </td>

                                    <td className=" react_table_body_content">
                                        <div
                                            className={`react_table_body_content_text1 ${
                                                mood === "light" ? "text-[#71717A]" : "text-gray"
                                            }`}
                                        >
                                            {i.no_of_question < 9 ? "0" + i.no_of_question : i.no_of_question}
                                        </div>
                                    </td>

                                    <td className=" react_table_body_content">
                                        <div
                                            className={`react_table_body_content_text1 ${
                                                mood === "light" ? "text-[#71717A]" : "text-gray"
                                            }`}
                                        >
                                            {i.feedback_count < 9 ? "0" + i.feedback_count : i.feedback_count}
                                        </div>
                                    </td>

                                    <td className=" react_table_body_content">
                                        <div className="react_table_body_content_text1 cursor-pointer">
                                            <MdQrCodeScanner
                                                onMouseEnter={() => {
                                                    setName(i.eventName)
                                                    setQr(`${window.location.origin}/${i.shortID}`)
                                                }}
                                                onClick={(e) => {
                                                    e.stopPropagation()
                                                    setQrPopup(!qrPopup)
                                                }}
                                                className="cursor-pointer w-6 h-6"
                                                size={25}
                                            />
                                        </div>
                                    </td>

                                    <td className=" react_table_body_content">
                                        <div className="react_table_body_content_text1 cursor-pointer">
                                            <Switch
                                                checked={i.action === "yes" ? true : false}
                                                className="react-switch"
                                                onColor="#4F46E5"
                                                onHandleColor="#fff"
                                                handleDiameter={20}
                                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                                onChange={() => {
                                                    setId(i.id)
                                                    setActions(i.action === "yes" ? "deactive" : "active")
                                                    setEventName(i.eventName)
                                                    setPopUpBox(!popUpBox)
                                                }}
                                            />
                                        </div>
                                    </td>

                                    <td className=" react_table_body_content">
                                        <div className="react_table_body_content_text1">
                                            <button
                                                onClick={() => editQuestion(i.shortID, i.eventName)}
                                                className="primaryButton !px-5"
                                            >
                                                Edit
                                            </button>
                                        </div>
                                    </td>

                                    <td className=" react_table_body_content">
                                        <div className="react_table_body_content_text1">
                                            <button className="primaryButton !px-5">
                                                <a
                                                    target="__blank"
                                                    // className="w-full flex items-center justify-center hover:text-blue"
                                                    href={`/${i.shortID}`}
                                                >
                                                    View
                                                </a>
                                            </button>
                                        </div>
                                    </td>

                                    <td className=" react_table_body_content">
                                        <div className="react_table_body_content_text1">
                                            <button
                                                onClick={() => report(i.shortID)}
                                                className="primaryButton !px-5"
                                            >
                                                View
                                            </button>
                                        </div>
                                    </td>

                                    <td className=" react_table_body_content_1 z-10">
                                        <FiMoreVertical
                                            onClick={(e) => {
                                                e.stopPropagation()
                                                setSideOption(!sideOption)
                                                setId(i.id)
                                            }}
                                            size={25}
                                            className="cursor-pointer hover:text-blue"
                                        />

                                        {sideOption && id === i.id && (
                                            <>
                                                <div
                                                    className={`p-2 block w-40 rounded absolute z-30 top-[-50%] right-[75%]  transition-all duration-500 font-inter bg-white text-black shadow-[0px_0px_0px_102vw_rgba(0,0,0,0.10)]`}
                                                >
                                                    {/* <div
                                                        className={`w-[18px] h-[18px] rounded ${
                                                            mood === "light" ? "bg-white" : "bg-[#161A25]"
                                                        }  absolute right-[4px] -top-1.5 -z-10`}
                                                        style={{
                                                            transform: "rotate(45deg)"
                                                        }}
                                                    ></div> */}

                                                    <div className="inside_content group">
                                                        <p
                                                            className="w-full text-start flex items-center"
                                                            onClick={() => {
                                                                setMail(!mail)
                                                                setShortID(i.shortID)
                                                            }}
                                                        >
                                                            <AiOutlineMail
                                                                size={25}
                                                                className="cursor-pointer text-[#4F46E5]"
                                                            />
                                                            <span className="ml-3 text-[#71717A] group-hover:!text-[#4F46E5]">
                                                                Send Mail
                                                            </span>
                                                        </p>
                                                    </div>

                                                    <div className=" inside_content group rounded !py-2">
                                                        <a
                                                            target="__blank"
                                                            className="w-full text-start flex items-center"
                                                            href={`/${i.shortID}`}
                                                        >
                                                            <BsLink45Deg
                                                                size={25}
                                                                className="cursor-pointer text-[#4F46E5]"
                                                            />
                                                            <span className="ml-3 text-[#71717A] group-hover:!text-[#4F46E5]">
                                                                Open Link
                                                            </span>
                                                        </a>
                                                    </div>
                                                    <div className="inside_content group rounded !py-2">
                                                        <a
                                                            href=" "
                                                            className="w-full text-start flex items-center"
                                                            onClick={(e) => {
                                                                setSideOption(false)
                                                                e.preventDefault()
                                                                e.stopPropagation()
                                                                setShow(true)
                                                                setShortID(i.shortID)
                                                            }}
                                                        >
                                                            <AiOutlineSend
                                                                size={20}
                                                                className="cursor-pointer text-[#4F46E5]"
                                                            />
                                                            <span className="ml-4 text-[#71717A] group-hover:!text-[#4F46E5]">
                                                                Share
                                                            </span>
                                                        </a>
                                                    </div>
                                                    <p
                                                        className=" inside_content group rounded !py-2"
                                                        onClick={() => {
                                                            setPopUpBox(!popUpBox)
                                                            setId(i.id)
                                                            setEventName(i.eventName)
                                                            setActions("delete")
                                                        }}
                                                    >
                                                        <div className="w-full text-start flex items-center">
                                                            <FiTrash2
                                                                size={20}
                                                                className="cursor-pointer text-[#4F46E5]"
                                                            />
                                                            <span className="ml-4 text-[#71717A] group-hover:!text-[#4F46E5]">
                                                                Delete
                                                            </span>
                                                        </div>
                                                    </p>
                                                </div>
                                            </>
                                        )}
                                    </td>
                                </tr>
                            </>
                        )
                    })}
            </>
        )
    }

    return (
        <div className=" mt-5">
            <div
                className="report_table bg-white rounded-xl dropdownBackground"
                
            >
                <table className="report_table_head">
                    <thead className="reports_table_head_content">
                        <tr>
                            <th className="table_head_title">Survey name</th>

                            <th className="table_head_title">Created date</th>

                            <th className="table_head_title">Questions</th>

                            <th className="table_head_title">Feedback count</th>

                            <th className="table_head_title">QR CODE</th>

                            <th className="table_head_title">STATUS</th>

                            <th className="table_head_title">DETAILS</th>

                            <th className="table_head_title">Preview</th>

                            <th className="table_head_title">USER FEEDBACK</th>

                            <th className="table_head_title">Actions</th>
                        </tr>
                    </thead>

                    <tbody className="react_table_body">
                        <Items currentItems={currentItems} />
                    </tbody>
                </table>
            </div>

            <div className="flex w-full justify-end mt-4 relative z-[0]">
                <ReactPaginate
                    breakLabel="..."
                    nextLabel=">"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={5}
                    pageCount={pageCount}
                    previousLabel="<"
                    renderOnZeroPageCount={null}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                    containerClassName={"pagination"}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    activeClassName={"active"}
                />
            </div>

            {popUpBox && (
                <EventName
                    show={popUpBox}
                    hide={setPopUpBox}
                    status={actions}
                    id={id}
                    eventNames={eventName}
                    changeId={setDeleteEvent}
                    setActionStatus={setActionStatus}
                    toogle={actionStatus}
                    trigger={setDeleteEvent}
                />
            )}

            {qrPopup && (
                <div className="fixed w-full h-full z-[70]  top-0 left-0 flex justify-center items-center bg-[#00000023]">
                    <div
                        onClick={(e) => {
                            e.stopPropagation()
                            setQrPopup(!qrPopup)
                        }}
                        style={{ boxShadow: "2px 2px 10px #ddd" }}
                        className="relative flex flex-col items-center py-8 px-8 bg-white rounded-lg"
                    >
                        <button
                            type="button"
                            className="absolute -top-2 -right-2 w-8 h-8 text-white bg-[#4f46e5] rounded-full text-xl p-1 ml-auto inline-flex items-center justify-center  transition-all duration-300"
                            data-modal-toggle="popup-modal"
                            onClick={(e) => {
                                e.stopPropagation()
                                setQrPopup(!qrPopup)
                            }}
                        >
                            <BsX size={30} />
                        </button>
                        <p className="text-lg font-bold text-center font-inter">{name === "" ? "QR Code" : name}</p>

                        <QRCode
                            value={qrcode}
                            name={name}
                        />
                    </div>
                </div>
            )}
            {mail && (
                <Mailsend
                    hide={setMail}
                    id={shortID}
                />
            )}
            {show && (
                <Popup
                    show={true}
                    hide={setShow}
                    id={shortID}
                />
            )}
        </div>
    )
}

export default Table
