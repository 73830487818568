import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  companyLogo: null,
  welcomeBg: null,
  buttonColor: "#4F46E5",
  textColor: "#000000",
  welcomeTextColor: "#000000",
};

const globalDefine = createSlice({
  name: "globalDefine",
  initialState,
  reducers: {
    addCompanyLogo(state, action) {
      state.companyLogo = action.payload;
    },
    removeCompanyLogo(state, action) {
      state.companyLogo = null;
    },
    addWelcomeBg(state, action) {
      state.welcomeBg = action.payload;
    },
    removeWelcomeBg(state, action) {
      state.welcomeBg = null;
    },
    addButtonColor(state, action) {
      state.buttonColor = action.payload;
    },

    addTextColor(state, action) {
      state.textColor = action.payload;
    },

    addWelcomeTextColor(state, action) {
      state.welcomeTextColor = action.payload;
    },
  },
});

export const {
  addCompanyLogo,
  removeCompanyLogo,
  addWelcomeBg,
  removeWelcomeBg,
  addButtonColor,
  addTextColor,
  addWelcomeTextColor,
} = globalDefine.actions;
export default globalDefine.reducer;
