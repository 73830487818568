import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  links: null
};

const linkSlice = createSlice({
  name: "links",
  initialState,
  reducers: {
    setLinkValue(state, action) {
      state.links = action.payload;
    },
  },
});

export const { setLinkValue } = linkSlice.actions;
export default linkSlice.reducer;
