import React from "react"

const Clippy = (props) => {
    return (
        <svg
            width="30"
            height="30"
            viewBox="0 0 16 16"
            fill="none"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            {...props}
        >
            <path d="M5.75 4.75H10.25V1.75H5.75V4.75Z" />
            <path d="M3.25 2.88379C2.9511 3.05669 2.75 3.37987 2.75 3.75001V13.25C2.75 13.8023 3.19772 14.25 3.75 14.25H12.25C12.8023 14.25 13.25 13.8023 13.25 13.25V3.75001C13.25 3.37987 13.0489 3.05669 12.75 2.88379" />
        </svg>
    )
}

const Check = (props) => {
    return (
        <svg
            width="30"
            height="30"
            viewBox="0 0 16 16"
            fill="none"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            {...props}
        >
            <path d="M13.25 4.75L6 12L2.75 8.75" />
        </svg>
    )
}

const CopyToClipboard = ({ linkToCopy }) => {
    const [copied, setCopied] = React.useState(false)

    React.useEffect(() => {
        const timeout = setTimeout(() => {
            if (copied) setCopied(false)
        }, 1000)

        return () => clearTimeout(timeout)
    }, [copied])

    const click = () => {
        navigator.clipboard.writeText(linkToCopy)
        setCopied(true)
    }

    return (
        <div
            onClick={click}
            className="flex flex-col justify-center items-center gap-2 p-4 rounded-lg border border-blue cursor-pointer hover:bg-blue/10"
        >
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                }}
            >
                <button
                    style={{
                        appearance: "none",
                        padding: 8,
                        border: 0,
                        outline: 0,
                        cursor: "pointer"
                    }}
                >
                    <div
                        style={{
                            position: "relative",
                            height: 30,
                            width: 30
                        }}
                    >
                        <Clippy
                            style={{
                                color: "#4F46E5",
                                position: "absolute",
                                top: 0,
                                left: 0,
                                strokeDasharray: 50,
                                strokeDashoffset: copied ? -50 : 0,
                                transition: "all 300ms ease-in-out"
                            }}
                        />
                        <Check
                            isVisible={copied}
                            style={{
                                color: "green",
                                position: "absolute",
                                top: 0,
                                left: 0,
                                strokeDasharray: 50,
                                strokeDashoffset: copied ? 0 : -50,
                                transition: "all 300ms ease-in-out"
                            }}
                        />
                    </div>
                </button>
            </div>
            <p className=" text-base font-medium">{ copied ? "Copied!" : "Copy Link"}</p>
        </div>
    )
}

export default CopyToClipboard
