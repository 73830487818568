import React, { useState } from "react"

import bgImage from "../../assets/images/viewSamplebg.png"
import ViewSamplePopup from "./ViewSamplePopup"

import { HiOutlineMail } from "react-icons/hi"
import { BiPhone } from "react-icons/bi"

const ViewSample = () => {
    const [templateModalOpen, setTemplateModalOpen] = useState(false)

    return (
        <div className="flex h-32 bg-white rounded-2xl overflow-hidden mb-8 shadow-lg">
            <div className=" w-20 h-full overflow-hidden">
                <img
                    src={bgImage}
                    alt="sample image"
                    className=" w-full h-full object-cover"
                />
            </div>
            <div className=" ml-10 py-6 flex-1">
                <h4 className=" font-bold text-xl">Enhance Feedback Collection through Design</h4>
                <p className=" text-base font-normal mt-2">
                    Find designs that make users want to share their thoughts. Good designs get you good feedback. If
                    you've got a different idea, tell us. We can get it done for you!
                </p>
            </div>
            <div className="flex px-8 py-10 gap-4 items-center">
                <a
                    href="mailto:Info@feedbacksync.com"
                    target="_blank"
                    className=" relative h-10 w-10 cursor-pointer bg-white outline outline-2 outline-blue/80 rounded grid place-items-center group"
                >
                    <span className=" absolute w-fit whitespace-nowrap opacity-0 group-hover:opacity-100 group-hover:translate-y-[-120%] translate-y-[-100%] top-0 left-[-40%] bg-blue/20 px-4 py-2 rounded-md text-black pointer-events-none text-xs transition-all duration-300">
                        Mail us
                    </span>
                    <HiOutlineMail
                        size={22}
                        className=" text-blue"
                    />
                </a>
                <a
                    href="tel:9994172111"
                    target="_blank"
                    className="relative h-10 w-10 cursor-pointer bg-white outline outline-2 outline-blue/80 rounded grid place-items-center group"
                >
                    <span className=" absolute w-fit whitespace-nowrap opacity-0 group-hover:opacity-100 group-hover:translate-y-[-120%] translate-y-[-100%] top-0 left-[-60%] bg-blue/20 px-4 py-2 rounded-md text-black pointer-events-none text-xs transition-all duration-300">
                        Contact us
                    </span>
                    <BiPhone
                        size={22}
                        className=" text-blue"
                    />
                </a>
                <button
                    onClick={() => setTemplateModalOpen(true)}
                    className="primaryButton"
                >
                    View Designs
                </button>
            </div>

            {templateModalOpen ? <ViewSamplePopup setModalOpen={setTemplateModalOpen} /> : null}
        </div>
    )
}

export default ViewSample
