import React, { useState } from "react";
import { useEffect } from "react";
import Upload from "../UploadImage/UploadImage";
import { useSelector } from "react-redux";
import axios from "../../axios";
import { setEmailTemplateValue } from "../../redux/slices/emailTemplate";
import { useDispatch } from "react-redux";
import { toast } from "react-hot-toast";
import CustomDotLoader from "../CustomDotLoader";
import Switch from "react-switch";

const EmailText = ({
  content,
  onPressMenu,
  rerender,
  setrerender,
  show,
  close,
  move,
}) => {
  const dispatch = useDispatch();
  const { emailTemplate } = useSelector((state) => state.emailTemplate);
  const { user } = useSelector((state) => state.userAuth);
  const [emailLogoUpload, setEmailLogoUpload] = useState(false);
  const [loader, setLoader] = useState(false);
  const [upload, setupload] = useState(false);
  const [statusChange, setstatusChange] = useState(false);
  const [preview, setPreview] = useState(null);
  const [templateInfo, setTemplateInfo] = useState({
    subject: "",
    message: "",
  });
  const [email, setEmail] = useState(false);
  const [textChange, setTextChange] = useState(false);

  useEffect(() => {
    const API = async () => {
      if (user !== null) {
        const { data } = await axios.post("/get-particular-template", {
          id: user.id,
        });

        if (data.success) {
          dispatch(setEmailTemplateValue(data.data));
          return;
        }
      }
    };
    API();
  }, [user]);

  useEffect(() => {
    const API = async () => {
      if (rerender) {
        if (user !== null) {
          const { data } = await axios.post("/add-email-content", {
            id: user.id,
            subject: templateInfo.subject,
            message: templateInfo.message,
            bottomColor: emailTemplate?.[0]?.bottomColor,
          });
          if (data.success) {
            const datas = await axios.post("/get-particular-template", {
              id: user.id,
            });
            if (datas.data.success) {
              dispatch(setEmailTemplateValue(datas.data.data));
              setrerender(false);
              move(onPressMenu);
              close(false);
              toast.success("Uploaded Successfully");
              setLoader(false);
              return;
            }
          }
        }
      }
    };
    API();
  }, [user, rerender]);

  const handelChange = (e) => {
    const { name, value } = e.target;

    setTemplateInfo((pre) => {
      return {
        ...pre,
        [name]: value,
      };
    });
  };

  const handelChangeEmail = async (e) => {
    if (user !== null) {
      if (e === "Email") {
        const { data } = await axios.post("/Email-notification", {
          id: user.id,
        });
        if (data.success) {
          const datas = await axios.post("/toggle-response", {
            id: user.id,
          });
          setEmail(datas.data.data[0].email_response);
          if (email) {
            return toast.error(`Email ${data.data}`);
          } else {
            return toast.success(`Email ${data.data}`);
          }
        }
      }
    }
  };

  useEffect(() => {
    const API = async () => {
      if (user !== null) {
        const { data } = await axios.post("/toggle-response", {
          id: user.id,
        });
        setEmail(data.data[0].email_response);
      }
    };
    API();
  }, [user]);

  useEffect(() => {
    const API = async () => {
      if (preview !== null) {
        if (user) {
          try {
            const fd = new FormData();
            fd.append("id", user.id);
            fd.append("image", preview);
            const { data } = await axios.post("/add-email-logo", fd);

            if (data.success) {
              const datas = await axios.post("/get-particular-template", {
                id: user.id,
              });
              if (datas.data.success) {
                setstatusChange(true);
                setEmailLogoUpload(false);
                setPreview(null);
                setLoader(false);
                dispatch(setEmailTemplateValue(datas.data.data));
                toast.success("Uploaded Successfully");
                setLoader(false);
              }
            }
          } catch (error) {
            if (error.message === "Network Error") {
              setLoader(false);
              toast.error("File size too large");
            } else {
              setLoader(false);
              toast.error("Something went wrong");
            }
          }
        }
      }
    };

    API();
  }, [upload]);

  useEffect(() => {
    setTemplateInfo({
      subject:
        templateInfo.subject === undefined || templateInfo.subject.length === 0
          ? emailTemplate?.[0]?.subject
          : templateInfo.subject,

      message:
        templateInfo.message === undefined || templateInfo.message.length === 0
          ? emailTemplate?.[0]?.message
          : templateInfo.message,
    });
  }, [emailTemplate]);

  useEffect(() => {
    content({
      subject: templateInfo.subject,
      message: templateInfo.message,
      logo: emailTemplate?.[0]?.logo,
    });
  }, [templateInfo, content, emailTemplate]);

  const updateContent = async () => {
    setLoader(true);
    const { data } = await axios.post("/add-email-content", {
      id: user.id,
      subject: templateInfo.subject,
      message: templateInfo.message,
      bottomColor: emailTemplate?.[0]?.bottomColor,
    });
    if (data.success) {
      const datas = await axios.post("/get-particular-template", {
        id: user.id,
      });
      if (datas.data.success) {
        dispatch(setEmailTemplateValue(datas.data.data));
        toast.success("Uploaded Successfully");
        setLoader(false);
        return;
      }
    }
  };

  const removeLogo = async () => {
    setTextChange(true);
    const { data } = await axios.post("/remove-email-logo", {
      id: user.id,
    });

    if (data.success) {
      setTemplateInfo(false);
      const datas = await axios.post("/get-particular-template", {
        id: user.id,
      });
      if (datas.data.success) {
        dispatch(setEmailTemplateValue(datas.data.data));

        return toast.success("Logo removed");
      }
    }
  };

  return (
    <div className="email_main">
      <div className="flex items-center justify-between">
        <div>
          <h1 className="email_head">Email Notification</h1>
          <p className="email_sub_title">
            Enter the email template details below .
          </p>
        </div>
        <div>
          <Switch
            checked={email}
            className="react-switch"
            onColor="#4F46E5"
            onHandleColor="#fff"
            handleDiameter={20}
            onChange={() => handelChangeEmail("Email")}
            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
          />
        </div>
      </div>

      {/* logo */}
      <div className="email_brand_container">
        <div className="email_input_tag">
          <div className="email_input_tag_head">
            <p>Logo</p>
          </div>
          <div className="email_qr_logo_upload">
            <div className="email_qr_logo_img">
              {emailTemplate?.length !== 0 &&
                emailTemplate?.[0]?.logo?.length !== 0 && (
                  <img
                    src={
                      emailTemplate?.[0]?.logo?.length !== 0 &&
                      emailTemplate?.[0]?.logo
                    }
                    alt="logo"
                  />
                )}
            </div>
            <div className="email_qr_logo_btn">
              <p
                className="email_qr_logo_text"
                onClick={() => {
                  setTextChange(false);
                  setEmailLogoUpload(!emailLogoUpload);
                }}
              >
                Upload
              </p>
              {emailTemplate?.[0]?.logo?.length !== 0 && (
                <p className="email_qr_logo_remove" onClick={removeLogo}>
                  {textChange ? "Removing..." : "Remove"}
                </p>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* subject */}

      <div className="email_input_tag">
        <div className="email_input_tag_head">
          <p>Subject</p>
        </div>
        <div className="email_input_tag_input">
          {/* <p className=" absolute right-2">{lengthInfo.title}/40</p> */}
          <input
            value={templateInfo?.subject}
            name="subject"
            className="email_input"
            placeholder="Subject"
            onChange={handelChange}
          />
        </div>
      </div>

      {/* message */}
      <div className="email_input_tag">
        <div className="email_input_tag_head">
          <p>Message to your customers</p>
        </div>
        <div className="email_input_tag_input relative">
          {/* <p className=" absolute right-2">{lengthInfo.title}/40</p> */}
          <textarea
            value={templateInfo.message}
            name="message"
            rows="8"
            className="email_input"
            placeholder="Message"
            onChange={handelChange}
            style={{
              resize: "none",
            }}
          />
        </div>
      </div>

      <div className="flex items-center justify-end">
        {!loader ? (
          <button
            className="primaryButton !px-8 min-w-[25%]"
            onClick={updateContent}
          >
            Update
          </button>
        ) : (
          <button className=" primaryButton !px-8 min-w-[25%]">
            <CustomDotLoader
                      isWhite={true}
                          dotSize={50}
                          className="mx-auto text-center"
                      />
          </button>
        )}
      </div>
      {emailLogoUpload && (
        <Upload
          show={emailLogoUpload}
          close={setEmailLogoUpload}
          pre={setPreview}
          uploads={setupload}
          uploadStatus={upload}
          statusChange={statusChange}
          change={setstatusChange}
        />
      )}
    </div>
  );
};

export default EmailText;
