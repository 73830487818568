/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useLayoutEffect } from "react";
import { FileText, PlayCircle } from "react-feather";
// import { MdFormatAlignRight } from "react-icons/md";
import {
  BsStarHalf,
  BsEmojiHeartEyes,
  BsFillCheckSquareFill,
  BsSpeedometer2,
  // BsList,
} from "react-icons/bs";
import { GrRadialSelected } from "react-icons/gr";
// import { FaAddressCard } from "react-icons/fa";
import { useSelector } from "react-redux";

const typeOfQuestions = ({ addValue, close, driverObj }) => {
  const [typeOfQuestion, setTypeOfQuestion] = useState(null);
  const { bgColor } = useSelector((state) => state.bg);
  const { questionValues } = useSelector((state) => state.questions);
  const { user } = useSelector((state) => state.userAuth);

  let filter = questionValues.filter((i) => i.type === "contact");

  useLayoutEffect(() => {
    if (user?.initialLogin) {
      driverObj?.current?.highlight({
        element: "#addQuestion_typeOfQuestions",
        popover: {
          title: "Questions",
          description:
            "Select from the list of various questions to add in your Survey survey",
          side: "right",
          align: "start",
        },
      });
    }
    // driverObj?.current?.highlight({
    //   element: "#addQuestion_typeOfQuestions",
    // });
  }, [user]);

  const selectQuestion = (type) => {
    addValue(type);
    close(false);
    driverObj?.current.destroy();
  };

  return (
      <div className="w-full absolute top-[-21rem] left-0 l3:left-[-2rem] xxl:left-[-3rem] flex flex-col items-center justify-center">
          <div
              id="addQuestion_typeOfQuestions"
              className="w-full flex items-center max-w-md l3:max-w-lg mt-2 h-80 bg-white  border border-gray rounded-md shadow-[0px_0px_0px_102vw_rgba(0,0,0,0.25)]"
          >
              <div className="  h-full w-full flex flex-col items-start overflow-auto mx-2 py-4">
                  <p
                      data-tut="four-step"
                      onMouseEnter={() => {
                          setTypeOfQuestion("welcome")
                      }}
                      onClick={() => {
                          selectQuestion("welcome")
                      }}
                      className="hover:!bg-[#EDF4FC] flex items-center my-1 cursor-pointer px-3 hover:bg-slate-100 w-full rounded font-base text-base py-2"
                  >
                      <PlayCircle
                          size={20}
                          className="mx-3"
                      />{" "}
                      Welcome screen
                  </p>
                  <p
                      onMouseEnter={() => {
                          setTypeOfQuestion("radioButton")
                      }}
                      onClick={() => {
                          selectQuestion("radioButton")
                      }}
                      className="hover:!bg-[#EDF4FC] flex items-center my-1 cursor-pointer px-3 hover:bg-slate-100 w-full rounded font-base text-base py-2"
                  >
                      <GrRadialSelected
                          size={16}
                          className="mx-3"
                      />{" "}
                      Single answer selection
                  </p>
                  <p
                      onMouseEnter={() => {
                          setTypeOfQuestion("multiSelect")
                      }}
                      onClick={() => {
                          selectQuestion("multiSelect")
                      }}
                      className="hover:!bg-[#EDF4FC] flex items-center my-1 cursor-pointer px-3 hover:bg-slate-100 w-full rounded font-base text-base py-2"
                  >
                      <BsFillCheckSquareFill
                          size={20}
                          className={`mx-3 ${bgColor === null && `text-blue`}`}
                          style={{ color: `${bgColor !== null && bgColor}` }}
                      />{" "}
                      Multiple answer select
                  </p>
                  <p
                      onMouseEnter={() => {
                          setTypeOfQuestion("starRating")
                      }}
                      onClick={() => {
                          selectQuestion("starRating")
                      }}
                      className="hover:!bg-[#EDF4FC] flex items-center my-1 cursor-pointer px-3 hover:bg-slate-100 w-full rounded font-base text-base py-2"
                  >
                      <BsStarHalf
                          size={20}
                          className="mx-3 text-yellow"
                      />{" "}
                      Start rating
                  </p>
                  <p
                      onMouseEnter={() => {
                          setTypeOfQuestion("smilyScale")
                      }}
                      onClick={() => {
                          selectQuestion("smileyScale")
                      }}
                      className="hover:!bg-[#EDF4FC] flex items-center my-1 cursor-pointer px-3 hover:bg-slate-100 w-full rounded font-base text-base py-2"
                  >
                      <BsEmojiHeartEyes
                          size={20}
                          className="mx-3 text-green"
                      />{" "}
                      Smiley scale
                  </p>
                  <p
                      onMouseEnter={() => {
                          setTypeOfQuestion("Nsp")
                      }}
                      onClick={() => {
                          selectQuestion("Nsp")
                      }}
                      className="hover:!bg-[#EDF4FC] flex items-center my-1 cursor-pointer px-3 hover:bg-slate-100 w-full rounded font-base text-base py-2"
                  >
                      <BsSpeedometer2
                          size={20}
                          className="mx-3 text-pink"
                      />{" "}
                      NPS
                  </p>

                  {filter.length === 0 && (
                      <p
                          onMouseEnter={() => {
                              setTypeOfQuestion("contact")
                          }}
                          onClick={() => {
                              selectQuestion("contact")
                          }}
                          className="hover:!bg-[#EDF4FC] flex items-center my-1 cursor-pointer px-3 hover:bg-slate-100 w-full rounded font-base text-base py-2"
                      >
                          <FileText
                              size={20}
                              className="mx-3"
                          />{" "}
                          Contact Form
                      </p>
                  )}

                  {/* <p
            onMouseEnter={() => {
              setTypeOfQuestion("dropDown");
            }}
            onClick={() => {
              addValue('dropDown);
        dropDown  
            }}
            className=" flex items-center my-1 cursor-pointer px-3 hover:bg-slate-100 w-full rounded font-base text-base py-2"
          >
            <BsList
              size={20}
              className={`mx-3 ${bgColor === null && `text-blue`}`}
              style={{ color: `${bgColor !== null && bgColor}` }}
            />{" "}
            dropdown list
          </p>
          <p
            onMouseEnter={() => {
              setTypeOfQuestion("formContent");
            }}
            onClick={() => {
              addValue("formContent");
       formContent  
            }}
            className=" flex items-center my-1 cursor-pointer px-3 hover:bg-slate-100 w-full rounded font-base text-base py-2"
          >
            <FaAddressCard size={20} className="mx-3 text-brown" /> form content
          </p>
          <p
            onMouseEnter={() => {
              setTypeOfQuestion("textAnswer");
            }}
            onClick={() => {
              addValue("textAnswer");
        textAnswer  
            }}
            className=" flex items-center my-1 cursor-pointer px-3 hover:bg-slate-100 w-full rounded font-base text-base py-2"
          >
            <MdFormatAlignRight
              size={20}
              className={`mx-3 ${bgColor === null && `text-blue`} `}
              style={{ color: `${bgColor !== null && bgColor}` }}
            />{" "}
            text answer
          </p> */}
              </div>
              <div className="bg-[#EFEEEE] h-full w-full px-4 overflow-y-auto">
                  {typeOfQuestion === "radioButton" && (
                      <>
                          <p className=" text-base font-bold my-4">Single Answer Selection</p>
                          <p className=" text-grayDark font-semibold text-xs capitalize mb-3 ">
                              Give your users easy choices. They pick one answer from options you provide.
                          </p>
                          <img
                              src="https://feedbackuploadimage.s3.ap-south-1.amazonaws.com/radiobutton.webp"
                              alt="radioButton"
                              className="flex items-center justify-center "
                          />
                      </>
                  )}
                  {typeOfQuestion === "starRating" && (
                      <>
                          <p className=" text-base font-bold my-4">Star Rating</p>
                          <p className=" text-grayDark font-semibold text-xs capitalize mb-3 ">
                              Let users express their feelings using stars. It's like a visual thumbs-up!
                          </p>

                          <img
                              src="https://feedbackuploadimage.s3.ap-south-1.amazonaws.com/startrating.gif"
                              alt="radioButton"
                              className="flex items-center justify-center"
                          />
                      </>
                  )}
                  {typeOfQuestion === "smilyScale" && (
                      <>
                          <p className=" text-base font-bold  my-4">Smiley Scale</p>
                          <p className=" text-grayDark font-semibold text-xs capitalize mb-3">
                              Users pick a smiley that shows how they feel. Happy? Neutral? Not so happy?
                          </p>
                          <img
                              src="https://feedbackuploadimage.s3.ap-south-1.amazonaws.com/Smily.gif"
                              alt="radioButton"
                              className="flex items-center justify-center "
                          />
                      </>
                  )}
                  {typeOfQuestion === "multiSelect" && (
                      <>
                          <p className=" text-base font-bold  my-4">Multiple Answer Selection</p>
                          <p className=" text-grayDark font-semibold text-xs capitalize mb-3">
                              Give users the freedom to pick more than one answer. They can choose all the options that
                              match their thoughts.
                          </p>
                          <img
                              src="https://feedbackuploadimage.s3.ap-south-1.amazonaws.com/Multiselect.gif"
                              alt="radioButton"
                              className="flex items-center justify-center "
                          />
                      </>
                  )}
                  {typeOfQuestion === "Nsp" && (
                      <>
                          <p className=" text-base font-bold  my-4">NPS (Net Promoter Score)</p>
                          <p className=" text-grayDark font-semibold text-xs capitalize mb-3">
                              Find out how much users like you. They rate you from 1 to 10.
                          </p>
                          <img
                              src="https://feedbackuploadimage.s3.ap-south-1.amazonaws.com/Nsp.webp"
                              alt="radioButton"
                              className="flex items-center justify-center"
                          />
                      </>
                  )}
                  {typeOfQuestion === "dropDown" && (
                      <>
                          <p className=" text-base font-bold  my-4">Dropdown list</p>
                          <p className=" text-grayDark font-semibold text-xs capitalize mb-3">
                              Let people pick one answer from a dropdown list of choices. Great for space-saving
                              reasons.
                          </p>
                          <img
                              src="https://feedbackuploadimage.s3.ap-south-1.amazonaws.com/Dropdown.gif"
                              alt="radioButton"
                              className="flex items-center justify-center "
                          />
                      </>
                  )}

                  {typeOfQuestion === "formContent" && (
                      <>
                          <p className=" text-base font-bold  my-4">Contact Form</p>
                          <p className=" text-grayDark font-semibold text-xs capitalize mb-3">
                              Get in touch! Users share info with you, and you can respond.
                          </p>
                          <img
                              src="https://feedbackuploadimage.s3.ap-south-1.amazonaws.com/COntact.png"
                              alt="radioButton"
                              className="flex items-center justify-center "
                          />
                      </>
                  )}
                  {typeOfQuestion === "textAnswer" && (
                      <>
                          <p className=" text-base font-bold  my-4">Text answer</p>
                          <p className=" text-grayDark font-semibold text-xs capitalize mb-3">
                              Provide a text box so people can share written, open-ended feedback.
                          </p>
                      </>
                  )}
                  {typeOfQuestion === "welcome" && (
                      <>
                          <p className=" text-base font-bold  my-4">Welcome Screen</p>
                          <p className=" text-grayDark font-semibold text-xs capitalize mb-3">
                              Start your Survey with a warm welcome. Let users know their thoughts matter.
                          </p>
                      </>
                  )}
                  {typeOfQuestion === "contact" && (
                      <>
                          <p className=" text-base font-bold  my-4">Contact From</p>
                          <p className=" text-grayDark font-semibold text-xs capitalize mb-3">
                              Collect contact information such as name, email, mobile number etc.
                          </p>

                          <img
                              src="https://feedbackuploadimage.s3.ap-south-1.amazonaws.com/contact.gif"
                              alt="radioButton"
                              className="flex items-center justify-center "
                          />
                      </>
                  )}
              </div>
          </div>
      </div>
  )
};

export default typeOfQuestions;
