import React, { useEffect, useState } from "react"
import { RiArrowDropDownLine } from "react-icons/ri"
import { CSVLink } from "react-csv"
import Table from "./Table"
import { useSelector } from "react-redux"
import axios from "../../../axios"

const Billing = () => {
    const { user } = useSelector((state) => state.userAuth)

    const [filterPop, setfilterPop] = useState(false)
    const [filterPop1, setfilterPop1] = useState(false)
    const [selectedFilter, setselectedFilter] = useState("Newest")
    const [info, setInfo] = useState([])
    const [billingDetails, setBillingDetails] = useState([])

    const handelSelectFilter = (e) => {
        setselectedFilter(e)
        setfilterPop(false)
    }

    const csvExport = [
        { label: "Survey NAME", key: "eventName" },
        { label: "CREATED DATE", key: "createdAt" },
        { label: "QUESTIONS", key: "no_of_question" },
        { label: "FEEDBACK COUNTS", key: "feedback_count" }
    ]

    useEffect(() => {
        const API = async () => {
            const { data } = await axios.get(`/getuser-payment-details?id=${user.id}`)
            if (data.success) {
                setBillingDetails(data.data)
            }
        }

        API()
    }, [user])

    return (
        <>
            <div className="w-full max-w-7xl p-8 mx-auto ">
                <div className="flex justify-between items-end mb-8">
                    <div className="">
                        <h1 className="reportTitle">Billing History</h1>
                        <p className={`mt-1 text-sm font-medium  text-[#71717A]`}>
                            Easily review your payment history.
                        </p>
                    </div>
                    <div className="report_subcontent">
                        <div
                            className="report_dropdown relative select-none"
                            onClick={() => setfilterPop(!filterPop)}
                        >
                            <div className="report_selected_element">
                                <p>{selectedFilter}</p>
                                <RiArrowDropDownLine
                                    size={28}
                                    className=" absolute right-0"
                                />
                            </div>
                            {filterPop && (
                                <div className="dropdown_content shadow-lg">
                                    <p
                                        onClick={() => handelSelectFilter("Popular")}
                                        className="dropdown_content_text"
                                    >
                                        Popular
                                    </p>
                                    <p
                                        onClick={() => handelSelectFilter("Old")}
                                        className="dropdown_content_text"
                                    >
                                        Old
                                    </p>
                                    <p
                                        onClick={() => handelSelectFilter("Newest")}
                                        className="dropdown_content_text"
                                    >
                                        Newest
                                    </p>
                                </div>
                            )}
                        </div>
                        <div
                            className="report_dropdown relative select-none"
                            onClick={() => setfilterPop1(!filterPop1)}
                        >
                            <div className="report_selected_element">
                                <p>Export</p>
                                <RiArrowDropDownLine
                                    size={28}
                                    className=" absolute right-0"
                                />
                            </div>
                            {filterPop1 && (
                                <div className="dropdown_content shadow-lg">
                                    <CSVLink
                                        data={info.map((i) => i)}
                                        headers={csvExport}
                                        filename="csvDownload"
                                        target="_blank"
                                    >
                                        <p className="dropdown_content_text">CSV</p>
                                    </CSVLink>
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                {/* <div className=" w-full  overflow-x-scroll"> */}
                <Table
                    items={billingDetails}
                    // events={setDeleteEvent}
                />
                {/* </div> */}
            </div>
        </>
    )
}

export default Billing
