import React, { useState } from "react";
import Navbar from "../../components/Navbar";
import InsertText from "../../components/QrcodeTemplate/InsertText";
import Template from "../../components/QrcodeTemplate/Template";
import { BiHomeAlt } from "react-icons/bi";
import { AiOutlinePlayCircle } from "react-icons/ai";
import { HiOutlineChartSquareBar, HiOutlineCreditCard } from "react-icons/hi";
import settingIcon from "../../assets/svg/sidebar/settings.svg";
import settingWhiteIcon from "../../assets/svg/sidebar/settingsWhite.svg";
import EventName from "../../components/popUp/EventName";
import GlobalBranding from "../../components/GlobalBranding/GlobalBranding";
import EmailText from "../../components/EmailTemplate/EmailText";
import EmailPreview from "../../components/EmailTemplate/EmailPreview";
import GeneralSettings from "../../components/General Settings/GeneralSettings";
import ViewSample from "../../components/QrcodeTemplate/ViewSample";
import { useSelector } from "react-redux";
import SmallScreenOverlay from "../SmallScreenOverlay";

const GlobalSettings = () => {
  const getSavedTemplate = JSON.parse(localStorage.getItem("qrcodeTemplate"));
  const [selectedMenu, setselectedMenu] = useState("Email Template");
  const { emailTemplate } = useSelector((state) => state.emailTemplate);

  const [templateContent, setTemplateContent] = useState({
    weblink:
      getSavedTemplate !== null
        ? getSavedTemplate.weblink
        : "www.feedbackSync.com",
    title: getSavedTemplate !== null ? getSavedTemplate.title : "",
    description: getSavedTemplate !== null ? getSavedTemplate.description : "",
    qrcode: "",
    weblinkStatus:
      getSavedTemplate !== null ? getSavedTemplate.weblinkStatus : true,
    brandStatus:
      getSavedTemplate !== null ? getSavedTemplate.brandStatus : true,
    brandColor:
      getSavedTemplate !== null ? getSavedTemplate.brandColor : "#4F46E5",
    brandTextColor:
      getSavedTemplate !== null ? getSavedTemplate.brandTextColor : "#ffffff",
    qrID: "",
  });

  const [emailTemplates, setEmailTemplates] = useState({
    subject: "",
    message: "",
    logo: "",
  });

  const [popUp, setpopUp] = useState(false);
  const [popUp1, setpopUp1] = useState(false);
  const [rerender, setrerender] = useState(false);

  const [menu, setMenu] = useState(false);
  const [onPressMenu, setonPressMenu] = useState("");


  const handelChange = async (e) => {
    if (
      emailTemplates.subject !== emailTemplate[0].subject ||
      emailTemplates.message !== emailTemplate[0].message
    ) {
      setonPressMenu(e);
      setpopUp1(!popUp1);
    } else if (getSavedTemplate !== null) {
      if (
        templateContent.weblink !== getSavedTemplate.weblink ||
        templateContent.title !== getSavedTemplate.title ||
        templateContent.description !== getSavedTemplate.description ||
        templateContent.brandColor !== getSavedTemplate.brandColor ||
        templateContent.brandTextColor !== getSavedTemplate.brandTextColor ||
        templateContent.brandStatus !== getSavedTemplate.brandStatus ||
        templateContent.weblinkStatus !== getSavedTemplate.weblinkStatus
      ) {
        setonPressMenu(e);
        setpopUp(!popUp);
      } else {
        setselectedMenu(e);
      }
    } else if (
      templateContent.weblink !== "www.feedbackSync.com" ||
      templateContent.title.length > 0 ||
      templateContent.description.length > 0 || // Add missing > 0
      templateContent.qrcode.length > 0 ||
      templateContent.brandColor !== "#4F46E5" ||
      templateContent.brandTextColor !== "#ffffff" ||
      templateContent.brandStatus !== true ||
      templateContent.weblinkStatus !== true
    ) {
      setonPressMenu(e);
      setpopUp(!popUp);
    } else {
      setselectedMenu(e);
    }
  };

  const clearState = () => {
    setTemplateContent({
      weblink: "",
      title: "",
      description: "",
      qrcode: "",
      weblinkStatus: true,
      brandStatus: true,
      brandColor: "",
      brandTextColor: "",
    });
  };

  return (
    <>
    <div className="max-lg:hidden">
      <Navbar menu={setMenu} />

      <div className="global_main relative">
        <div
          className={`global_sidemenu ${
            !menu
              ? "min-w-[250px] t4:min-w-[280px] l1:min-w-[250px] l2:min-w-[280px]"
              : " w-[95px] l1:w-[92px] l3:w-[280px]"
          }   `}
        >
          <div
            className={`global_sidemenu_item ${
              selectedMenu === "Email Template"
                ? "bg-blue text-white hover:bg-blue"
                : "blueHover"
            }`}
            onClick={() => {
              handelChange("Email Template");
            }}
          >
            <div className=" min-w-max flex items-center gap-x-4">
              <BiHomeAlt size={24} className="global_sidemenu_item_icon" />
              <p className="global_sidemenu_item_text">Notification Email</p>
            </div>
          </div>

          <div
            className={`global_sidemenu_item ${
              selectedMenu === "QR Code Template"
                ? "bg-blue text-white rounded-full"
                : "blueHover"
            }`}
            onClick={() => {
              handelChange("QR Code Template");
            }}
          >
            <div className=" min-w-max flex items-center gap-x-4">
              <AiOutlinePlayCircle
                size={24}
                className="global_sidemenu_item_icon"
              />
              <p className="global_sidemenu_item_text">QR Code Template</p>
            </div>
          </div>
          {/* 
          <div
            className={`global_sidemenu_item ${
              selectedMenu === "Global Branding" &&
              "bg-blue text-white rounded-full"
            }`}
            onClick={() => handelChange("Global Branding")}
          >
            <HiOutlineChartSquareBar
              size={24}
              className="global_sidemenu_item_icon"
            />
            <p className="global_sidemenu_item_text">Global Branding</p>
          </div> */}
          {/* 
          <div
            className={`global_sidemenu_item ${
              selectedMenu === "Integration" &&
              "bg-blue text-white rounded-full"
            }`}
            onClick={() => handelChange("Integration")}
          >
            <HiOutlineCreditCard
              size={24}
              className="global_sidemenu_item_icon"
            />
            <p className="global_sidemenu_item_text">Integration</p>
          </div> */}

          {/* <div
            className={`global_sidemenu_item ${
              selectedMenu === "Language" && "bg-blue text-white rounded-full"
            }`}
            onClick={() => handelChange("Language")}
          >
            <MdPersonOutline size={24} className="global_sidemenu_item_icon" />
            <p className="global_sidemenu_item_text">Language</p>
          </div> */}

          <div
            className={`global_sidemenu_item ${
              selectedMenu === "General Settings"
                ? "bg-blue text-white rounded-full"
                : "blueHover"
            }`}
            onClick={() => {
              handelChange("General Settings");
            }}
          >
            <div className=" min-w-max flex items-center gap-x-4">
              {selectedMenu === "General Settings" ? (
                <img
                  width={22}
                  src={settingWhiteIcon}
                  alt="general settings"
                  className=""
                />
              ) : (
                <img width={22} src={settingIcon} alt="general settings" />
              )}
              <p className="global_sidemenu_item_text">General Settings</p>
            </div>
          </div>
        </div>

        <div className="global_template">
          {selectedMenu === "QR Code Template" ? <ViewSample /> : null}

          <div className="qr_Code">
            {selectedMenu === "Email Template" && (
              <div className="template1">
                <EmailText
                  content={setEmailTemplates}
                  onPressMenu={onPressMenu}
                  rerender={rerender}
                  setrerender={setrerender}
                  show={popUp1}
                  close={setpopUp1}
                  move={setselectedMenu}
                />
              </div>
            )}
            {selectedMenu === "Email Template" && (
              <div className="email_template2 flex">
                <EmailPreview
                  subject={emailTemplates.subject}
                  message={emailTemplates.message}
                  logo={emailTemplates.logo}
                />
              </div>
            )}
          </div>

          <div className="qr_Code">
            {selectedMenu === "QR Code Template" && (
              <div className="template1">
                <InsertText content={setTemplateContent} qrTemplate={templateContent} />
              </div>
            )}
            {selectedMenu === "QR Code Template" && (
              <div className="template2">
                <Template
                  link={templateContent.weblinkStatus}
                  brand={templateContent.brandStatus}
                  webtext={templateContent.weblink}
                  title={templateContent.title}
                  qrCode={templateContent.qrcode}
                  description={templateContent.description}
                  brandColor={templateContent.brandColor}
                  brandTextColor={templateContent.brandTextColor}
                  qrID={templateContent.qrID}
                />
              </div>
            )}
          </div>

          <div className="">
            {selectedMenu === "Global Branding" && <GlobalBranding />}
          </div>

          <div className="">
            {selectedMenu === "General Settings" && (
              <div className="template1">
                <GeneralSettings />
              </div>
            )}
          </div>
        </div>
      </div>

      {popUp && (
        <EventName
          show={popUp}
          hide={setpopUp}
          status="clearQrcodeTemplate"
          move={setselectedMenu}
          onPressMenu={onPressMenu}
          clearStatement={clearState}
          qrTemplate={templateContent}
          setrerender={setrerender}

        />
      )}

      {popUp1 && (
        <EventName
          show={popUp1}
          hide={setpopUp1}
          status="clearEmailTemplate"
          move={setselectedMenu}
          onPressMenu={onPressMenu}
          clearStatement={clearState}
          setrerender={setrerender}
        />
      )}
    </div>
    <SmallScreenOverlay />
    </>
  );
};

export default GlobalSettings;
