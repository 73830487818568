/* eslint-disable react-hooks/rules-of-hooks */
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  logoDetails,
  bgImageDetails,
  setButtonColor,
  setTextColor,
  setWelcomeScreenTextColor,
} from "../../redux/slices/questions";
import axios from "../../axios";
import toast, { Toaster } from "react-hot-toast";
import Upload from "../UploadImage/UploadImage";
import { useSearchParams } from "react-router-dom";
import { TbDownload } from "react-icons/tb";
import { BlockPicker } from "react-color";
import CustomDotLoader from "../CustomDotLoader";

const index = () => {
  const { mood } = useSelector((state) => state.mood);
  const [buttonColors, setbuttonColors] = useState(null);
  const [textColors, setTextColors] = useState(null);
  const [welcomeScreenColor, setWelcomeScreenColor] = useState(null);
  const { questions } = useSelector((state) => state);
  const dispatch = useDispatch();
  const logoInfo = questions.logoDetails;
  const bgInfo = questions.bgImageDetails;
  const [searchParams] = useSearchParams();
  const ID = searchParams.get("id");
  const [refresh, setRefresh] = useState(false);
  const [uploadCategoryImage, setuploadCategoryImage] = useState("");
  const [upload, setupload] = useState(false);
  const [statusChange, setstatusChange] = useState(false);
  const [uploadImage, setUploadImage] = useState(false);
  const [preview, setPreview] = useState(null);
  const [loader, setLoader] = useState(false);
  const [base64String, setbase64String] = useState(null);
  const [colorCode, setColorCode] = useState(false);
  const [colorCode1, setColorCode1] = useState(false);
  const [colorCode2, setColorCode2] = useState(false);

  // const { buttonColor } = useSelector((state) => state.globalDefine);
  // const { textColor } = useSelector((state) => state.globalDefine);
  // const { welcomeTextColor } = useSelector((state) => state.globalDefine);
  // const { companyLogo } = useSelector((state) => state.globalDefine);
  // const { welcomeBg } = useSelector((state) => state.globalDefine);

  localStorage.setItem("BGColor", buttonColors);
  localStorage.setItem("TextColor", textColors);

  useEffect(() => {
    const API = async () => {
      const render = localStorage.getItem("brandRender");
      if (ID !== null && render === null) {
        localStorage.setItem("brandRender", false);
        const { data } = await axios.post("/get-logo", {
          eventId: ID,
        });

        const datas = await axios.post("/getParticularEvent", {
          shortID: ID,
        });

        if (buttonColors === null) {
          setbuttonColors(datas?.data?.[0]?.buttonColor);
        }
        if (textColors === null) {
          setTextColors(datas?.data?.[0]?.textColor);
        }
        if (welcomeScreenColor === null) {
          setWelcomeScreenColor(datas?.data?.[0]?.welcomeTextColor);
        }
        let bg = data.data.filter((i) => i.status === "bgImage")[0];

        dispatch(bgImageDetails([{ ...bg, status: "bgImage" }]));
        let logo = data.data.filter((i) => i.status === "logoImage")[0];
        console.log("logo", logo);

        dispatch(logoDetails([{ ...logo, status: "logoImage" }]));
      }
    };

    API();
  }, [ID, refresh]);

  const deleteImage = async () => {
    if (logoInfo.length !== 0) {
      setLoader(true);
      const { data } = await axios.post("/deleteLogo", {
        id: logoInfo[0].id,
        logo: logoInfo[0].logoId,
      });
      if (data.success) {
        setLoader(false);
        toast.success(`${data.message}`);
        localStorage.removeItem("brandRender");
        setRefresh(!refresh);
        dispatch(logoDetails([]));
      } else {
        toast.error(`${data.message}`);
        setLoader(false);
        return;
      }
    } else {
      setLoader(false);
      toast.error("Logo not available");
      return;
    }
  };

  const deleteBgImage = async () => {
    if (bgInfo.length !== 0) {
      setLoader(true);
      const { data } = await axios.post("/deleteLogo", {
        id: bgInfo[0].id,
        logo: bgInfo[0].logoId,
      });

      if (data.success) {
        setLoader(false);
        toast.success(`${data.message}`);
        localStorage.removeItem("brandRender");
        setRefresh(!refresh);

        dispatch(bgImageDetails([]));
      } else {
        toast.error(`${data.message}`);
        setLoader(false);
        return;
      }
    } else {
      setLoader(false);
      toast.error("BG image not available");
      return;
    }
  };

  const handelUpdateColor = async () => {
    dispatch(
      setButtonColor({
        buttonColor:
          buttonColors === null ? questions.buttonColor : buttonColors,
      })
    );
    dispatch(
      setTextColor({
        textColor: textColors === null ? questions.textColor : textColors,
      })
    );
    dispatch(
      setWelcomeScreenTextColor({
        welcomeScreenTextColor:
          welcomeScreenColor === null
            ? questions.welcomeScreenTextColor
            : welcomeScreenColor,
      })
    );
  };

  useEffect(() => {
    const render = localStorage.getItem("brandRender");

    if (
      buttonColors === null &&
      textColors === null &&
      welcomeScreenColor === null &&
      render === null
    ) {
      localStorage.setItem("brandRender", false);
      setbuttonColors("#4F46E5");
      dispatch(setButtonColor({ buttonColor: "#4F46E5" }));
      setTextColors("#000000");
      dispatch(setTextColor({ textColor: "#000000" }));
      setWelcomeScreenColor("#000000");
      dispatch(setWelcomeScreenTextColor({welcomeScreenTextColor: "#000000"}));
    }
  }, []);

  const defaultColor = async () => {
    setbuttonColors("#4F46E5");
    dispatch(setButtonColor({ buttonColor: "#4F46E5" }));
    setTextColors("#000000");
    dispatch(setTextColor({ textColor: "#000000" }));
    setWelcomeScreenColor("#000000");
    dispatch(setWelcomeScreenTextColor({welcomeScreenTextColor: "#000000"}));
  };

  useEffect(() => {
    const API = async () => {
      if (preview !== null) {
        if (uploadCategoryImage === "welcomeBG") {
          if (ID !== null) {
            try {
              setLoader(true);
              setbase64String({ ...base64String?.[0], status: "bgImage" });
              const fd = new FormData();
              fd.append("image", preview);
              fd.append("eventId", ID);
              fd.append("status", "bgImage");
              const { data } = await axios.post("/logoUpload", fd);

              if (data) {
                setPreview(null);
                setstatusChange(true);
                setUploadImage(false);
                localStorage.removeItem("brandRender");
                setLoader(false);
                setupload(false);
                let bg = [{ ...data?.[0] }];

                dispatch(bgImageDetails(bg));
                return toast.success("Successfully Uploaded");
              }
            } catch (error) {
              if (error.message === "Network Error") {
                setstatusChange(true);
                setLoader(false);
                return toast.error("File size too large");
              } else {
                setstatusChange(true);
                setLoader(false);
                return toast.error("Image not supported");
              }
            }
          } else {
            try {
              setbase64String({ ...base64String?.[0], status: "bgImage" });
              const fd = new FormData();
              fd.append("image", preview);
              const { data } = await axios.post("/logoUpload", fd);
              if (data) {
                setPreview(null);
                setstatusChange(true);
                setUploadImage(false);
                setLoader(false);
                setupload(false);

                let bg = [{ ...data?.[0], status: "bgImage" }];
                console.log("bg", bg);
                dispatch(bgImageDetails(bg));
                return toast.success("Successfully Uploaded");
              }
            } catch (error) {
              if (error.message === "Network Error") {
                setstatusChange(true);
                setLoader(false);
                toast.error("File size too large");
              } else {
                setstatusChange(true);
                setLoader(false);
                return toast.error("Image not supported");
              }
            }
          }
        }
        if (uploadCategoryImage === "logo") {
          if (ID !== null) {
            try {
              setLoader(true);
              setbase64String({ ...base64String?.[0], status: "logoImage" });
              const fd = new FormData();
              fd.append("image", preview);
              fd.append("eventId", ID);
              fd.append("status", "logoImage");
              const { data } = await axios.post("/logoUpload", fd);

              if (data) {
                setPreview(null);
                setstatusChange(true);
                setUploadImage(false);
                setLoader(false);
                setupload(false);
                localStorage.removeItem("brandRender");
                let logo = [{ ...data?.[0] }];

                dispatch(logoDetails(logo));
                return toast.success("Successfully Uploaded");
              }
            } catch (error) {
              if (error.message === "Network Error") {
                setstatusChange(true);
                setLoader(false);
                return toast.error("File size too large");
              } else {
                setstatusChange(true);
                setLoader(false);
                return toast.error("Image not supported");
              }
            }
          } else {
            try {
              setLoader(true);
              setbase64String({ ...base64String?.[0], status: "logoImage" });
              const fd = new FormData();
              fd.append("image", preview);
              const { data } = await axios.post("/logoUpload", fd);
              if (data) {
                setPreview(null);
                setstatusChange(true);
                setUploadImage(false);
                setLoader(false);
                setupload(false);
                let logo = [{ ...data?.[0], status: "logoImage" }];
                dispatch(logoDetails(logo));
                return toast.success("Successfully Uploaded");
              }
            } catch (error) {
              if (error.message === "Network Error") {
                setstatusChange(true);
                setLoader(false);
                return toast.error("File size too large");
              } else {
                setstatusChange(true);
                setLoader(false);
                return toast.error("Image not supported");
              }
            }
          }
        }
      }
    };

    API();
  }, [upload]);

  return (
    <div
      className={`w-[40%] h-[calc(100vh-110px)] relative flex overflow-y-auto justify-center lg:border-r border-[#E4E4E7] ${
        mood === "light" ? "bg-[#f4f4f4]" : "bg-[#232734]"
      }`}
    >
      {loader && (
        <div className="fixed top-0 left-0 w-full h-full justify-center items-center flex !z-50 bg-[#2620473a]">
          <CustomDotLoader dotSize={150} />
        </div>
      )}
      <div className="border-gray w-[100%]  h-fit py-8  gap-y-8  rounded capitalize flex flex-col items-center">
        <div className="branding_img_container">
          <div>
            <div>
              <span className=" text-lg font-semibold">Company Logo</span>
            </div>

            <div className=" flex gap-8 items-center mt-6">
              {ID === null ? (
                <>
                  <div className="border-2 border-gray w-[110px] h-[80px] overflow-hidden p-1  rounded-md flex items-center justify-center">
                    {logoInfo.length !== undefined &&
                      logoInfo.length !== 0 &&
                      logoInfo?.[0]?.logoLocation !== undefined && (
                        <img
                          className="w-[80%] h-[80%] object-contain"
                          src={logoInfo?.[0]?.logoLocation}
                          alt="logo"
                        />
                      )}
                  </div>
                </>
              ) : (
                <>
                  <div className="border-2 border-gray w-[110px] h-[80px] overflow-hidden p-1 my-2 rounded-md flex items-center justify-center">
                    {logoInfo.length !== undefined &&
                      logoInfo.length !== 0 &&
                      logoInfo?.[0]?.logoLocation !== undefined && (
                        <img
                          className="w-[80%] h-[80%] object-contain"
                          src={logoInfo?.[0]?.logoLocation}
                          alt="logo"
                        />
                      )}
                  </div>
                </>
              )}
              <div>
                <span
                  className="text-blue cursor-pointer md:text-sm"
                  onClick={() => {
                    setUploadImage(!uploadImage);
                    setuploadCategoryImage("logo");
                  }}
                >
                  {logoInfo?.length !== 0 ? "Upload" : "Update"}
                </span>
                {logoInfo?.length !== 0 && logoInfo?.[0]?.id && (
                  <span
                    className="text-red  mx-4 cursor-pointer md:text-sm"
                    onClick={deleteImage}
                  >
                    remove
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="branding_img_container">
          <div>
            <span className="text-lg font-semibold">
              Welcome Screen BG image
            </span>
          </div>
          <div className="flex gap-8 items-center mt-6">
            {ID === null ? (
              <div className="border-2 border-gray w-[110px] h-[80px] overflow-hidden p-1 my-2 rounded-md flex items-center justify-center">
                {bgInfo !== undefined &&
                  bgInfo.length !== 0 &&
                  bgInfo?.[0]?.logoLocation !== undefined && (
                    <img
                      className="w-[80%] h-[80%] object-contain"
                      src={bgInfo?.[0]?.logoLocation}
                      alt="logo"
                    />
                  )}
              </div>
            ) : (
              <div className="border-2 border-gray w-[110px] h-[80px] overflow-hidden p-1 my-2 rounded-md flex items-center justify-center">
                {bgInfo.length !== undefined &&
                  bgInfo.length !== 0 &&
                  bgInfo?.[0]?.logoLocation !== undefined && (
                    <img
                      className="w-[80%] h-[80%] object-contain"
                      src={bgInfo?.[0]?.logoLocation}
                      alt="logo"
                    />
                  )}
              </div>
            )}

            <div>
              <span
                className="text-blue cursor-pointer md:text-sm"
                onClick={() => {
                  setuploadCategoryImage("welcomeBG");
                  setUploadImage(!uploadImage);
                }}
              >
                {bgInfo.length !== 0 ? "Upload" : "Update"}
              </span>
              {bgInfo.length !== undefined && bgInfo?.[0]?.id && (
                <span
                  className="text-red  mx-4 cursor-pointer md:text-sm"
                  onClick={deleteBgImage}
                >
                  remove
                </span>
              )}
            </div>
          </div>
        </div>
        <div className="branding_img_container">
          <div>
            <span className="text-lg font-semibold">Brand Colors</span>
          </div>
          <div className="brand_colors mt-4">
            <div className="brand_color_content relative">
              <p
                style={{
                  background:
                    buttonColors === null
                      ? questions.buttonColor
                      : buttonColors,
                  boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.15)",
                }}
                className="brand_input_color"
                onClick={() => {
                  setColorCode(!colorCode);
                  setColorCode1(false);
                  setColorCode2(false);
                }}
              ></p>

              {colorCode && (
                <div className="absolute top-[70%] ">
                  <BlockPicker
                    color={
                      buttonColors === null
                        ? questions.buttonColor
                        : buttonColors
                    }
                    onChange={(e) => setbuttonColors(e.hex)}
                  />
                  <div className=" w-[175px] flex items-center justify-between mb-6">
                    <button
                      onClick={() => {
                        setbuttonColors(
                          buttonColors === null
                            ? questions.buttonColor
                            : buttonColors
                        );
                        setColorCode(false);
                      }}
                      className="bg-white text-black border-2 border-black w-[48%] rounded-lg"
                    >
                      Cancel
                    </button>
                    <button
                      onClick={() => {
                        setbuttonColors(
                          buttonColors === null
                            ? questions.buttonColor
                            : buttonColors
                        );
                        setColorCode(false);
                      }}
                      className="bg-blue text-white w-[48%] rounded-lg"
                    >
                      Save
                    </button>
                  </div>
                </div>
              )}

              <div className="brand_input_tag_head">
                <p>Button</p>
              </div>
            </div>

            <div className="brand_color_content relative">
              <p
                style={{
                  background:
                    textColors === null ? questions.textColor : textColors,
                  boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.15)",
                }}
                className="brand_input_color"
                onClick={() => {
                  setColorCode1(!colorCode1);
                  setColorCode(false);
                  setColorCode2(false);
                }}
              ></p>

              {colorCode1 && (
                <div className="absolute top-[70%]  ">
                  <BlockPicker
                    color={
                      textColors === null ? questions.textColor : textColors
                    }
                    onChange={(e) => setTextColors(e.hex)}
                  />
                  <div className=" w-[175px] flex items-center justify-between mb-6">
                    <button
                      onClick={() => {
                        setTextColors(
                          textColors === null ? questions.textColor : textColors
                        );
                        setColorCode1(false);
                      }}
                      className="bg-white text-black border-2 border-black w-[48%] rounded-lg"
                    >
                      Cancel
                    </button>
                    <button
                      onClick={() => {
                        setTextColors(
                          textColors === null ? questions.textColor : textColors
                        );
                        setColorCode1(false);
                      }}
                      className="bg-blue text-white w-[48%] rounded-lg"
                    >
                      Save
                    </button>
                  </div>
                </div>
              )}

              <div className="brand_input_tag_head">
                <p>Question</p>
              </div>
            </div>

            <div className="brand_color_content relative">
              <p
                style={{
                  background:
                    welcomeScreenColor === null
                      ? questions.welcomeScreenTextColor
                      : welcomeScreenColor,
                  boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.15)",
                }}
                className="brand_input_color"
                onClick={() => {
                  setColorCode2(!colorCode2);
                  setColorCode(false);
                  setColorCode1(false);
                }}
              ></p>

              {colorCode2 && (
                <div className="absolute top-[70%]">
                  <BlockPicker
                    color={
                      welcomeScreenColor === null
                        ? questions.welcomeScreenTextColor
                        : welcomeScreenColor
                    }
                    onChange={(e) => setWelcomeScreenColor(e.hex)}
                  />
                  <div className=" w-[175px] flex items-center justify-between mb-6">
                    <button
                      onClick={() => {
                        setWelcomeScreenColor(
                          welcomeScreenColor === null
                            ? questions.welcomeScreenTextColor
                            : welcomeScreenColor
                        );
                        setColorCode2(false);
                      }}
                      className="bg-white text-black border-2 border-black w-[48%] rounded-lg"
                    >
                      Cancel
                    </button>
                    <button
                      onClick={() => {
                        setWelcomeScreenColor(
                          welcomeScreenColor === null
                            ? questions.welcomeScreenTextColor
                            : welcomeScreenColor
                        );
                        setColorCode2(false);
                      }}
                      className="bg-blue text-white w-[48%] rounded-lg"
                    >
                      Save
                    </button>
                  </div>
                </div>
              )}

              <div className="brand_input_tag_head">
                <p>Welcome screen</p>
              </div>
            </div>
          </div>
          <div className="flex w-full  mt-6 gap-4">
            <button
              className="secondaryButton flex-1 text-sm"
              onClick={defaultColor}
            >
              {/* <TbDownload
                                size={20}
                                className="mr-3"
                            /> */}
              Default
            </button>
            <button
              className="primaryButton flex-1 text-sm"
              onClick={handelUpdateColor}
            >
              {/* <TbDownload
                                size={20}
                                className="mr-3"
                            /> */}
              Update
            </button>
          </div>
        </div>
      </div>

      {uploadImage && (
        <Upload
          show={uploadImage}
          close={() => setUploadImage()}
          pre={setPreview}
          uploads={setupload}
          statusChange={statusChange}
          change={setstatusChange}
          setbase64String={setbase64String}
          load={setLoader}
        />
      )}
      <Toaster position="top-right" reverseOrder={false} />
    </div>
  );
};

export default index;
