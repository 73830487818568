/* eslint-disable react-hooks/rules-of-hooks */
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import axios from "../../axios";
import Radio from "../../components/shareReview/radioButton";
import MultiSelect from "../../components/shareReview/multiSelect/MultiSelect";
import SmileyScale from "../../components/shareReview/emoji/Emoji";
import DropDown from "../../components/shareReview/dropDown/dropDown";
import Nsp from "../../components/shareReview/nsp/Nsp";
// import formContent from '../../components/reviews/'
// import textAnswerfrom  '../../components/reviews/'
import StarRating from "../../components/shareReview/starRating/StarRating";
import { toast, Toaster } from "react-hot-toast";
import Loader from "react-js-loader";
// import { useSelector } from "react-redux";
import EventName from "../../components/popUp/EventName";
import CustomDotLoader from "./../../components/CustomDotLoader/index";
import Welcome from "../../components/shareReview/welcome";
import Connect from "../../components/shareReview/connect";
import { useDispatch } from "react-redux";
import { setFeedbackValue } from "../../redux/slices/feedbackValue";
import { setTextColor } from "../../redux/slices/questions";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import LanguagePopup from "../../components/Languages/LanguagePopup";
import { RiArrowDropDownLine } from "react-icons/ri";
import Axios from "axios";

const index = () => {
  const getSelectedLanguage = localStorage.getItem("selectedLanguage");
  const defaultLanguage = {
    name: "English",
    language: "en",
    id: "1",
    content: "suggest",
  };
  const { eventId } = useParams();
  const [list, setList] = useState([]);
  const [currentId, setCurrentId] = useState(0);
  const navigate = useNavigate();
  const [bg, setBg] = useState("white");
  const [color, setColor] = useState(null);
  const [imgUrl, setImageUrl] = useState("");
  const [bgImgUrl, setBgImageUrl] = useState("");
  const [status, setStatus] = useState(false);
  const [bgStatus, setBgStatus] = useState(false);
  const dispatch = useDispatch();
  const [textColors, setTextColors] = useState("");
  const [welcometextColors, setWelcomeTextColors] = useState("");
  const [popupStatus, setPopupStatus] = useState(false);
  const [types, setType] = useState("");
  const [multi, setMulti] = useState("");
  const [single, setSingle] = useState('')
  const [starRating, setStarRating] = useState(0);
  const [emoji, setEmoji] = useState(0);
  const [nsp, setNsp] = useState(0);
  const [gender, setGenderValue] = useState("");
  localStorage.setItem("location", eventId);
  const [searchParams] = useSearchParams();
  const Open = searchParams.get("open");
  const [eventDeleted, setEventDeleted] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(
    getSelectedLanguage ? JSON.parse(getSelectedLanguage) : defaultLanguage
  );
  const [languagePop, setlanguagePop] = useState(false);
  const [openCount, setopenCount] = useState(true);
  const [staticText, setStaticText] = useState("");
  const [initialData, setInitialData] = useState([]);


  useEffect(() => {
    if (getSelectedLanguage === null) {
      localStorage.setItem("selectedLanguage", JSON.stringify(defaultLanguage));
    } else {
      localStorage.setItem(
        "selectedLanguage",
        JSON.stringify(selectedLanguage)
      );
    }
  }, [selectedLanguage]);

  const onClick = () => {
    setCurrentId(currentId + 1);
  };


  const getPage = (item) => {
    const types = {
      welcome: (
        <Welcome
          inputQuestion={item?.question}
          id={item?.id}
          questionType={item?.type}
          answer={item?.answer}
          eventId={eventId}
          bg={bg}
          color={color}
          textColor={welcometextColors}
          setType={setType}
          subHeading={item?.subHeading}
        />
      ),
      radioButton: (
        <Radio
          inputQuestion={item?.question}
          id={item?.id}
          questionType={item?.type}
          answer={item?.answer}
          eventId={eventId}
          bg={bg}
          textColor={textColors}
          color={color}
          setType={setType}

        />
      ),
      contact: (
        <Connect
          inputQuestion={item?.question}
          id={item?.id}
          questionType={item?.type}
          answer={item?.answer}
          eventId={eventId}
          bg={bg}
          color={color}
          textColor={textColors}
          setType={setType}
          setGenderValue={setGenderValue}
        />
      ),
      multiSelect: (
        <MultiSelect
          inputQuestion={item?.question}
          id={item?.id}
          questionType={item?.type}
          answer={item?.answer}
          eventId={eventId}
          bg={bg}
          color={color}
          textColor={textColors}
          setType={setType}
          setMulti={setMulti}
          statictext={staticText}
        />
      ),
      smileyScale: (
        <SmileyScale
          inputQuestion={item?.question}
          id={item?.id}
          questionType={item?.type}
          answer={item?.answer}
          eventId={eventId}
          bg={bg}
          color={color}
          textColor={textColors}
          setType={setType}
          setEmoji={setEmoji}
          current={onClick}
          length={currentId}
          last={list.length}
          handleSubmit={submit}

        />
      ),
      starRating: (
        <StarRating
          inputQuestion={item?.question}
          id={item?.id}
          questionType={item?.type}
          answer={item?.answer}
          eventId={eventId}
          bg={bg}
          color={color}
          textColor={textColors}
          setStarRating={setStarRating}
          setType={setType}
          current={onClick}
          length={currentId}
          last={list.length}
          handleSubmit={submit}

        />
      ),
      dropDown: (
        <DropDown
          inputQuestion={item?.question}
          id={item?.id}
          questionType={item?.type}
          answer={item?.answer}
          eventId={eventId}
          bg={bg}
          color={color}
          textColor={textColors}
        />
      ),
      Nsp: (
        <Nsp
          inputQuestion={item?.question}
          id={item?.id}
          questionType={item?.type}
          answer={item?.answer}
          eventId={eventId}
          bg={bg}
          color={color}
          textColor={textColors}
          setType={setType}
          setNsp={setNsp}
          current={onClick}
          length={currentId}
          last={list.length}
          handleSubmit={submit}
        />
      ),
    };
    return types[item?.type];
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await axios.post("/getParticularEvent", {
          shortID: eventId,
        });


        setInitialData(data?.[0]?.list_of_items);
        if (data.length === 0) {
          setEventDeleted(true);
        } else if (data[0].action === "yes") {
          const { textColor, backgroundColor, buttonColor, welcomeTextColor } =
            data[0];
          dispatch(setTextColor(textColor)); // Assuming you have a Redux action for setting text color

          setBg(backgroundColor);
          setColor(buttonColor);
          setStatus(true);
          setTextColors(textColor);
          setWelcomeTextColors(
            welcomeTextColor.length !== 0 ? welcomeTextColor : textColor
          );
          if (data?.[0]?.list_of_items?.length !== 0) {
            setList([]);
            setCurrentId(1);

            const translateData = await Axios.post(
              "https://feedback-translator.up.railway.app/translate-survey",
              {
                targetLanguage: selectedLanguage.language,
                surveyData: data?.[0]?.list_of_items,
              }
            );

            // const translateData = await Axios.post("/tr-api/translate-survey", {
            //   targetLanguage: selectedLanguage.language,
            //   surveyData: data?.[0]?.list_of_items,
            // });

            //   const translateData = await Axios.post(
            //   "http://3.111.15.6/translate-survey",
            //   {
            //     targetLanguage: selectedLanguage.language,
            //     surveyData: data?.[0]?.list_of_items,
            //   },
            //   { withCredentials: true }
            // );

            setList(translateData.data.data);

            if (openCount) {
              const response = await axios.post("/openFeedback", {
                id: eventId,
              });

              if (response.data.success) {
                setopenCount(false);
              }
            }

            const targetText = await Axios.post(
              "https://feedback-translator.up.railway.app/translate-to-target",
              {
                targetLanguage: selectedLanguage.language,
                randomText: "You can select multiple answer",
              }
            );

            setStaticText(targetText.data.data.data);
          }
        } else {
          setPopupStatus(true);
        }
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.success === false
        ) {
          setEventDeleted(true);
          //  return window.location.replace('https://www.feedbacksync.com');
        }
      }
    };

    fetchData();
  }, [eventId, selectedLanguage]);

  useEffect(() => {
    if (initialData?.length === list?.length) {
      for (let i = 0; i < initialData.length; i++) {
        list[i].uuid = initialData[i].uuid;
      }
    } else {
      console.log("Arrays have different lengths");
    }
  }, [list]);

  useEffect(() => {
    if (list?.length !== 0) {
      dispatch(
        setFeedbackValue({
          eventId: eventId,
          list_of_items: list,
        })
      );
    }
  }, [list]);

  useEffect(() => {
    axios
      .post("/get-logo", {
        eventId,
      })
      .then((data) => {
        const bgImages = data?.data?.data.filter((i) => i.status === "bgImage");
        const logoImages = data?.data?.data.filter(
          (i) => i.status === "logoImage"
        );

        if (bgImages.length > 0 && bgImages[0].logoLocation) {
          setBgImageUrl(bgImages[0].logoLocation);
        }

        if (logoImages.length > 0 && logoImages[0].logoLocation) {
          setImageUrl(logoImages[0].logoLocation);
        }
      });
  }, [eventId]);

  const onMinus = () => {
    setType("");
    setCurrentId(currentId - 1);
  };

  const submit = () => {
    navigate(
      `/success/${color.substring(1)}/${bg.substring(1)}/${eventId}?language=${
        selectedLanguage.language
      }`
    );
  };

  useEffect(() => {
    setTimeout(() => {
      onClick();
    }, 2000);
  }, []);

  useEffect(() => {
    if (list) {
      if (list[currentId - 1]?.type === "welcome") {
        setBgStatus(true);
      } else {
        setBgStatus(false);
      }
    }
  }, [currentId, list]);

  const handleSubmit = () => {
    if (currentId < list?.length) {
      if (!multi && types === "multiSelect") {
        toast.error("Kindly select the choices");
      } else if (emoji === 0 && types === "smileyScale") {
        toast.error("Kindly select the choices");
      } else if (gender === "" && types === "select") {
        toast.error("Select your gender");
      } else if (starRating === 0 && types === "starRating") {
        toast.error("Pick your preferences");
      } else if (nsp === 0 && types === "Nsp") {
        toast.error("Kindly select the choices");
      } else {
        setMulti("");
        setEmoji(0);
        setStarRating(0);
        setNsp(0);
        setGenderValue("");
        onClick();
      }
    } else {
      if (!multi && types === "multiSelect") {
        toast.error("Kindly select the choices");
      } else if (emoji === 0 && types === "smileyScale") {
        toast.error("Kindly select the choices");
      } else if (gender === "" && types === "select") {
        toast.error("Select your gender");
      } else if (starRating === 0 && types === "starRating") {
        toast.error("Pick your preferences");
      } else if (nsp === 0 && types === "Nsp") {
        toast.error("Kindly select the choices");
      } else {
        setMulti("");
        setEmoji(0);
        setStarRating(0);
        setNsp(0);
        setGenderValue("");
        submit();
      }
    }
  };

  return (
      <div
          style={{ background: `${bg}`, borderBottom: `15px solid ${color}` }}
          className={`flex flex-col items-center overflow-hidden max-sm:!border-none  w-full h-screen `}
      >
          {status ? (
              <>
                  <>
                      {list.length === 0 && (
                          <div className="w-[100%] h-[100%] bg-white absolute flex items-center justify-center">
                              <div className="flex flex-col justify-center">
                                  {imgUrl !== "" && (
                                      <div className=" w-[100%] h-[150px] mx-auto  flex items-center justify-center">
                                          <img
                                              src={imgUrl}
                                              className="w-[80%] h-[80%]  object-contain mx-auto flex items-center justify-start"
                                              alt=""
                                          />
                                      </div>
                                  )}
                                  <Loader
                                      type="rectangular-ping"
                                      bgColor={`${color}`}
                                      color={`${color}`}
                                      size={180}
                                  />
                              </div>
                          </div>
                      )}
                      {currentId > 0 && (
                          <form
                              onSubmit={(e) => {
                                e.preventDefault();
                                handleSubmit()
                              }}
                              action=""
                          >
                              <div
                                  style={{
                                      backgroundImage: `url(${bgStatus ? bgImgUrl : ""})`,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "cover"
                                  }}
                                  className="h-screen w-screen flex overflow-hidden  flex-col p-6"
                              >
                                  <div className="w-full max-w-[105rem] mx-auto !px-6">
                                      <div className="grid grid-cols-2 lg:grid-cols-4 gap-y-4 w-full justify-between bg-transparent z-[999]">
                                          <div
                                              className={` max-sm:hidden flex lg:col-start-2 col-span-2 items-center gap-2  ${
                                                  list?.length > 14 ? "justify-start" : "justify-center"
                                              }  w-full max-w-4xl  `}
                                          >
                                              {list?.map((i, index) => (
                                                  <p
                                                      key={index}
                                                      style={{
                                                          background: currentId - 1 === index ? `${color}` : "gray"
                                                      }}
                                                      className={`${window.innerWidth && "w-[8%]"} h-[10px] rounded-lg`}
                                                  ></p>
                                              ))}
                                          </div>
                                          {list?.length !== 0 && (
                                              <div className=" max-sm:absolute top-4 right-4 flex col-span-2 justify-center lg:col-span-1 lg:justify-end items-center gap-x-4">
                                                  <p className=" max-sm:hidden">Languages:</p>
                                                  <p
                                                      style={{
                                                          color: `${color !== null ? color : "#4F46E5"}`
                                                      }}
                                                      className="flex items-center cursor-pointer"
                                                      onClick={() => setlanguagePop(!languagePop)}
                                                  >
                                                      {selectedLanguage.name}
                                                      <RiArrowDropDownLine size={26} />
                                                  </p>
                                              </div>
                                          )}
                                      </div>
                                  </div>

                                  <div className=" absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-full h-fit max-h-[80vh] md:mt-8 overflow-y-scroll flex flex-col px-6 md:p-6">
                                      {imgUrl !== "" && list.length > 0 && (
                                          <div className=" w-[100%] h-[150px] mx-auto  flex items-center justify-center">
                                              <img
                                                  src={imgUrl}
                                                  className="w-[80%] h-[80%]  object-contain mx-auto flex items-center justify-start"
                                                  alt=""
                                              />
                                          </div>
                                      )}
                                      <div className="mt-8 mb-4">
                                          {list?.length !== 0 ? getPage(list[currentId - 1]) : ""}
                                      </div>

                                      {/* Buttons for larger screens  */}
                                      <div className=" max-sm:hidden">
                                          {currentId < list?.length ? (
                                              <div className="flex gap-5 mx-auto w-full justify-center">
                                                  {currentId > 1 && (
                                                      <button
                                                          type="button"
                                                          style={{ background: `${color}`, color: `${bg}` }}
                                                          className={` rounded-full p-3 transition-all duration-200 text-lg font-semibold text-center block capitalize`}
                                                          onClick={onMinus}
                                                      >
                                                          <IoIosArrowBack size={40} />
                                                      </button>
                                                  )}
                                                  <button
                                                      style={{ background: `${color}`, color: `${bg}` }}
                                                      className={` rounded-full p-3 transition-all duration-200 text-lg font-semibold text-center block capitalize`}
                                                      type="submit"
                                                  >
                                                      {/* TODO: change */}
                                                      <IoIosArrowForward size={40} />
                                                  </button>
                                              </div>
                                          ) : (
                                              <div className="flex gap-5 mx-auto w-full justify-center ">
                                                  {currentId > 1 && (
                                                      <button
                                                          type="button"
                                                          style={{ background: `${color}`, color: `${bg}` }}
                                                          className={` rounded-full p-3  transition-all duration-200 text-lg font-semibold text-center block capitalize`}
                                                          onClick={onMinus}
                                                      >
                                                          <IoIosArrowBack size={40} />
                                                      </button>
                                                  )}
                                                  {list?.length > 0 && (
                                                      <button
                                                          style={{ background: `${color}`, color: `${bg}` }}
                                                          className={` rounded-full p-3  transition-all duration-200 text-lg font-semibold text-center block capitalize`}
                                                          type="submit"
                                                      >
                                                          <IoIosArrowForward size={40} />
                                                      </button>
                                                  )}
                                              </div>
                                          )}
                                      </div>
                                  </div>
                              </div>

                              {/* buttons for mobile screens */}
                              <div
                                  style={{ borderBottom: `15px solid ${color}` }}
                                  className=" md:hidden fixed bottom-0 left-0 w-full bg-white"
                              >
                                  {currentId < list?.length ? (
                                      <div className=" flex gap-5 mx-auto w-full justify-center py-2">
                                          {currentId > 1 && (
                                              <button
                                                  type="button"
                                                  style={{ background: `${color}`, color: `${bg}` }}
                                                  className={` rounded-full p-3 transition-all duration-200 text-lg font-semibold text-center block capitalize`}
                                                  onClick={onMinus}
                                              >
                                                  <IoIosArrowBack size={35} />
                                              </button>
                                          )}
                                          <button
                                              style={{ background: `${color}`, color: `${bg}` }}
                                              className={` rounded-full p-3 transition-all duration-200 text-lg font-semibold text-center block capitalize`}
                                              type="submit"
                                          >
                                              {/* TODO: change */}
                                              <IoIosArrowForward size={35} />
                                          </button>
                                      </div>
                                  ) : (
                                      <div className="  flex gap-5 mx-auto w-full justify-center py-2 ">
                                          {currentId > 1 && (
                                              <button
                                                  type="button"
                                                  style={{ background: `${color}`, color: `${bg}` }}
                                                  className={` rounded-full p-3  transition-all duration-200 text-lg font-semibold text-center block capitalize`}
                                                  onClick={onMinus}
                                              >
                                                  <IoIosArrowBack size={35} />
                                              </button>
                                          )}
                                          {list?.length > 0 && (
                                              <button
                                                  style={{ background: `${color}`, color: `${bg}` }}
                                                  className={` rounded-full p-3  transition-all duration-200 text-lg font-semibold text-center block capitalize`}
                                                  type="submit"
                                              >
                                                  <IoIosArrowForward size={35} />
                                              </button>
                                          )}
                                      </div>
                                  )}
                              </div>
                          </form>
                      )}
                  </>
              </>
          ) : (
              <>
                  <CustomDotLoader
                      dotSize={120}
                      className=" absolute top-1/2 -translate-1/2"
                  />
                  <EventName
                      list="list"
                      show={popupStatus}
                      condition={Open}
                  />
              </>
          )}

          {eventDeleted && (
              <EventName
                  status="Deleted"
                  show={eventDeleted}
                  hide={setEventDeleted}
              />
          )}

          {languagePop && (
              <LanguagePopup
                  clicked={selectedLanguage}
                  select={setSelectedLanguage}
                  close={setlanguagePop}
                  color={color}
              />
          )}

          <Toaster
              position="top-right"
              reverseOrder={false}
          />
      </div>
  )
};

export default index;
