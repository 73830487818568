// ** Reducers Imports

import questions from "./slices/questions";
import bg from "./slices/bgColor";
import mood from './slices/mood'
import eventName from "./slices/eventName";
import feedbackValue from "./slices/feedbackValue";
import userAuth from './slices/auth'
import pdfExport from './slices/pdfExport'
import links from './slices/link'
import qrLogo from './slices/qrCodeLogo'
import globalDefine from './slices/globalDefine'
import emailTemplate from "./slices/emailTemplate";

const rootReducer = {
  questions,
  bg,
  mood,
  eventName,
  feedbackValue,
  userAuth,
  pdfExport,
  links,
  qrLogo,
  globalDefine,
  emailTemplate
};

export default rootReducer;
