import React from 'react'

const Tooltip = ({children, tooltipTxt}) => {
  return (
      <div className="relative group">
          <span className=" absolute w-fit whitespace-nowrap opacity-0 group-hover:opacity-100 group-hover:translate-y-[-110%] translate-y-[-100%] top-0 left-[0%] bg-blue/20 !text-black px-4 !py-2 rounded-md pointer-events-none !text-xs transition-all duration-300">
              {tooltipTxt}
          </span>
          {children}
      </div>
  )
}

export default Tooltip