import React, { useState } from "react";
import ReceiptModal from "../../../components/popUp/ReceiptModal";
import dayjs from "dayjs";
const Table = ({ items }) => {
  const [receiptModalOpen, setReceiptModalOpen] = useState(false);

  function Items({ currentItems }) {
    return (
      <>
        {currentItems &&
          currentItems.map((i, index) => {
            return (
              <>
                <tr className="" key={i.id}>
                  <td className={`react_table_body_content`}>
                    <div className=" react_table_body_content_text text-[#18181B]">
                      {i.payment_status}
                    </div>
                  </td>
                  <td className={`react_table_body_content`}>
                    <div className=" react_table_body_content_text text-[#18181B]">
                      {dayjs(i.plan_activate_date).format("DD MMM YY")}
                    </div>
                  </td>
                  <td className={`react_table_body_content`}>
                    <div className=" react_table_body_content_text text-[#18181B]">
                      ₹
                      {new Intl.NumberFormat("en-IN", {
                        currency: "INR",
                      }).format(i?.amount)}
                    </div>
                  </td>
                  <td className={`react_table_body_content`}>
                    <button
                      onClick={() => {
                        localStorage.setItem(
                          "billingDetails",
                          JSON.stringify(i)
                        );
                        setReceiptModalOpen(true);
                      }}
                      className=" secondaryButton"
                    >
                      View Receipt
                    </button>
                  </td>
                </tr>
              </>
            );
          })}
      </>
    );
  }

  return (
    <div className=" mt-5">
      <div
        className="report_table bg-white rounded-xl"
        style={{
          boxShadow: "0px 2.37037px 8px rgba(0, 0, 0, 0.06)",
        }}
      >
        <table className="report_table_head">
          <thead className="reports_table_head_content">
            <tr>
              <th className="table_head_title">Status</th>

              <th className="table_head_title">Date</th>

              <th className="table_head_title">Amount</th>

              <th className="table_head_title">Actions</th>
            </tr>
          </thead>

          <tbody className="react_table_body">
            <Items currentItems={items} />
          </tbody>
        </table>
      </div>

      {/* <div className="flex w-full justify-end mt-4 relative z-[-1]">
                <ReactPaginate
                    breakLabel="..."
                    nextLabel=">"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={5}
                    pageCount={pageCount}
                    previousLabel="<"
                    renderOnZeroPageCount={null}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                    containerClassName={"pagination"}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    activeClassName={"active"}
                />
            </div> */}

      {receiptModalOpen && <ReceiptModal setOpen={setReceiptModalOpen} />}
    </div>
  );
};

export default Table;
