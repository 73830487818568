import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  feedbackValue: [],
};

const merge = (arr1, arr2, p) =>
  arr1.filter((aa) => !arr2.find((bb) => aa[p] === bb[p])).concat(arr2);

const feedbackSlice = createSlice({
  name: "feedbackValue",
  initialState,
  reducers: {
    setFeedbackValue(state, action) {
      const isExisit =
        state.feedbackValue.findIndex(
          (item) => item.email_address === action.payload.email_address
        ) !== -1;
      if (isExisit) {
        const newArr = state.feedbackValue.map((item) => {
          const { list_of_items } = item;

          let newList = merge(
            list_of_items,
            action.payload.list_of_items,
            "answerId"
          );
          return {
            ...item,
            list_of_items: newList.sort((a, b) => a.answerId - b.answerId),
          };
        });
        state.feedbackValue = newArr;
      } else {
        state.feedbackValue.push(action.payload);
      }
    },
    remove(state) {
      state.feedbackValue = [];
    },
  },
});

export const { setFeedbackValue, remove } = feedbackSlice.actions;
export default feedbackSlice.reducer;
