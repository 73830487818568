import React, { useState } from "react"
import { AiOutlineClose } from "react-icons/ai"
import axios from "../../axios"
import toast, { Toaster } from "react-hot-toast"
import { BsCheckCircle, BsLink45Deg } from "react-icons/bs"
import ModalCloseBtn from "../ModalCloseBtn"
import CustomDotLoader from './../CustomDotLoader/index'

import { BiArrowBack } from "react-icons/bi"

const Mailsend = ({ hide, id, innerFormOnly = false, eventName }) => {
    const [chips, setChips] = useState([])
    const [discription, setDiscription] = useState("")
    const [subject, setSubject] = useState("")
    const [errStatus, setErrStatus] = useState(false)
    const [errMessage, setErrMessage] = useState("")
    const [singleEmail, setSingleEmail] = useState("")
    const [loader, setLoader] = useState(false)
    const [isMailSent, setIsMailSent] = useState(false)

    const addChip = (value) => {
        setChips((prevChips) => [...prevChips, value])
    }

    const removeChip = (index) => {
        setChips((prevChips) => prevChips.filter((_, i) => i !== index))
    }

    console.log("singleEmail", singleEmail)
    const link = `${id}?open=open`

    const handelSubmit = async () => {
        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
        setLoader(true)
        if (singleEmail === "") {
            setErrMessage("Email is required")
            setLoader(false)
            return setErrStatus(true)
        }

        if (!emailRegex.test(singleEmail)) {
            setErrStatus(true)
            setErrMessage("Invalid email format")
            setLoader(false)
            return
        }
        if (chips.length > 0) {
            const { data } = await axios.post("/campings-email", {
                email: chips,
                subject: subject,
                message: discription,
                link: link
            })
            if (!data?.success) {
                toast.error(`${data.message}`)
                setLoader(false)
                return
            }

            setLoader(false)
            // toast.success(`${data.message}`)
            setChips([])
            setDiscription("")
            setSubject("")
            
            if (innerFormOnly) {
                setIsMailSent(true)
                return;
            }
            hide(false)

            return
        } else {
            const datas = await axios.post("/campings-email", {
                email: [singleEmail],
                subject: subject,
                message: discription,
                link: link
            })
            if (!datas?.data?.success) {
                toast.error(`${datas.data.message}`)
                setLoader(false)
                return
            }
            // toast.success(`${datas.data.message}`)
            setLoader(false)
            setSingleEmail("")
            setChips([])
            setDiscription("")
            setSubject("")
            
            if (innerFormOnly) {
                setIsMailSent(true)
                return
            }

            hide(false)
            return
        }
    }

    const RenderSuccessContent = () => (
        <div className=" h-full flex flex-col justify-between">
            <div>
            <BsCheckCircle
                size={160}
                color="#4F46E5"
                />
            <h1 className="font-bold text-xl font-inter mt-4">Email sent successfully !</h1>
            <h3 className="text-xl font-semibold text-blue mt-6">“{eventName}”</h3>
            <p className="text-base font-normal mt-4">
                Your users will receive Survey links to give feedback, aiding your business growth.
            </p>
            </div>
            <button
                onClick={() => {setIsMailSent(false)}}
                className={`primaryButton w-full`}
            >
                Go, Back
            </button>
        </div>
    )

    const renderMainContent = () => (
        <div className={` ${innerFormOnly ? " h-full" : ""} text-center flex items-center flex-col`}>
            {isMailSent ? (
                <RenderSuccessContent />
            ) : (
                <>
                    {innerFormOnly ? (
                        <div className="flex items-center w-full">
                            <button onClick={() => hide(false)}>
                                <BiArrowBack
                                    size={30}
                                    className="text-blue cursor-pointer"
                                />
                            </button>
                            <p className="font-bold text-xl flex-1 text-center -ml-8 pointer-events-none">Send Email</p>
                        </div>
                    ) : (
                        <p className="font-bold text-xl mx-1">Send Email</p>
                    )}
                    <div
                        className={` ${
                            innerFormOnly ? " max-h-full overflow-y-auto relative " : ""
                        } mt-4 flex  flex-col w-full  rounded px-3`}
                    >
                        <div className="flex flex-col items-start ">
                            <label className="mx-1 my-1">Email</label>
                            <div className="flex items-center flex-wrap bg-white w-full rounded-lg border-[1px] border-black">
                                {chips.map((i, index) => (
                                    <div className="flex items-center flex-row mx-2 bg-[#e9e9f1] rounded-full px-3 py-1 my-2">
                                        <p>{i}</p>
                                        <AiOutlineClose
                                            size={18}
                                            className="text-blue ml-2 cursor-pointer"
                                            onClick={() => removeChip(index)}
                                        />
                                    </div>
                                ))}
                                <input
                                    className="border-0 outline-none rounded-lg px-2 py-1 w-[300px] my-2 "
                                    placeholder="Enter email address & (Press Enter)"
                                    type="email"
                                    onChange={(e) => setSingleEmail(e.target.value)}
                                    onKeyPress={(event) => {
                                        if (event.key === "Enter") {
                                            const inputValue = event.target.value
                                            const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i

                                            if (emailRegex.test(inputValue)) {
                                                addChip(inputValue)
                                                event.target.value = ""
                                                setErrStatus(false)
                                                setErrMessage("")
                                            } else {
                                                setErrStatus(true)
                                                setErrMessage("Invalid email format")
                                            }
                                        }
                                    }}
                                />
                            </div>
                            {errStatus && chips.length === 0 && <p className="text-red">{errMessage}</p>}
                        </div>
                        <div className="flex items-start flex-col mt-2">
                            <label className="my-2">Subject</label>
                            <input
                                value={subject}
                                placeholder="Enter subject"
                                className="w-full min-h-[50px] text-start outline-none border-[1px] rounded-lg border-black px-2 py-1"
                                onChange={(e) => setSubject(e.target.value)}
                            />
                        </div>
                        <div className="flex items-start flex-col my-3">
                            <label className="my-2">Message</label>
                            <textarea
                                value={discription}
                                placeholder="Description"
                                className="w-full min-h-[130px] text-start outline-none border-[1px] rounded-lg border-black px-2 py-1"
                                onChange={(e) => setDiscription(e.target.value)}
                                style={{
                                    resize: "none"
                                }}
                            ></textarea>
                        </div>

                        <div className="flex items-center mt-2 w-full  text-start outline-none border-[1px] rounded-lg border-black px-2">
                            <BsLink45Deg
                                size={30}
                                className=""
                            />
                            <input
                                placeholder="Enter subject"
                                className="w-[95%] min-h-[50px] text-start outline-none ml-2"
                                onChange={(e) => setSubject(e.target.value)}
                                disabled
                                value={link}
                            />
                        </div>

                        <div
                            className={` ${
                                innerFormOnly && "sticky bottom-0 w-full bg-white"
                            } flex items-center justify-center gap-4 mt-4`}
                        >
                            {!innerFormOnly ? (
                                <button
                                    onClick={() => hide(false)}
                                    className=" secondaryButton flex-1"
                                >
                                    Cancel
                                </button>
                            ) : null}

                            {!loader ? (
                                <button
                                    onClick={handelSubmit}
                                    className={`primaryButton flex-1`}
                                >
                                    Send
                                </button>
                            ) : (
                                <button
                                    disabled
                                    className={`primaryButton flex-1`}
                                >
                                    <CustomDotLoader
                                        dotSize={50}
                                        isWhite
                                        className="mx-auto text-center"
                                    />
                                </button>
                            )}
                        </div>
                    </div>
                </>
            )}
        </div>
    )

    return (
        <>
            {innerFormOnly ? (
                renderMainContent()
            ) : (
                <div
                    id="popup-modal"
                    tabIndex="-1"
                    className=" fixed flex items-center top-0 left-0 justify-center z-[70]  right-auto w-[100vw] h-[100vh] !bg-[#67696d67]"
                    aria-hidden="true"
                >
                    <div
                        className="relative w-[33%] lg:w-[33%] xl:w-[40%] sm:w-[50%]
md:w-[50%] max-w-xl md:h-auto "
                    >
                        <div className="relative bg-white p-4 rounded-lg ">
                            <ModalCloseBtn onClick={() => hide(false)} />
                            {renderMainContent()}
                        </div>
                    </div>
                    <Toaster
                        position="top-right"
                        reverseOrder={false}
                    />
                </div>
            )}
        </>
    )
}

export default Mailsend
