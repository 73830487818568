import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: null,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserValue(state, action) {
      state.user = action.payload;
    },
    logOut(state, action) {
      state.user = null;
      localStorage.removeItem('Token')

    },
  },
});

export const { setUserValue, logOut } = userSlice.actions;
export default userSlice.reducer;
