/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect } from "react"
import { useDispatch } from "react-redux"
import { setFeedbackValue } from "../../redux/slices/feedbackValue"
import α from "color-alpha"
import { useState } from "react"
import { RiCheckFill } from "react-icons/ri"
import { Check } from "react-feather"
import { useParams } from "react-router-dom"

const Welcome = ({
    inputQuestion,
    id,
    questionType,
    answer,
    nextQuz,
    next,
    eventID,
    bg,
    color,
    textColor,
    setType,
    subHeading
}) => {
    const dispatch = useDispatch()
    const [selected, setSelected] = useState(0)

    const { eventId } = useParams()
    localStorage.setItem("location", eventId)

    useEffect(() => {
        setType("welcome")
    }, [])

    const getValue = (answer) => {
        if (
            inputQuestion?.length !== 0 &&
            answer?.length !== 0 &&
            id?.length !== 0 &&
            questionType?.length !== 0 &&
            eventID?.length
        ) {
            return dispatch(
                setFeedbackValue({
                    eventId: eventID,
                    list_of_items: [
                        {
                            question: inputQuestion,
                            answer: "",
                            answerId: id,
                            type: questionType
                        }
                    ]
                })
            )
        }
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks

    //bg-[url('https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg')]
    return (
        <div className=" h-full w-full flex flex-col  justify-center items-center  bg-no-repeat bg-cover bg-center bg-fixed">
            <h3
                style={{ color: `${textColor}`, wordBreak: "break-word" }}
                className="text-center h-fit w-full max-w-3xl  break-words mx-10  text-3xl font-semibold"
            >
                {inputQuestion}
            </h3>

            <h6
                style={{ color: `${textColor}`, wordBreak: "break-word" }}
                className="text-center h-fit w-full max-w-3xl  break-words mx-10 text-[18px] font-medium"
            >
                {subHeading}
            </h6>
        </div>
    )
}

export default Welcome
