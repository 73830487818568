import * as Yup from "yup";

export const signUpSchema = Yup.object({
  Name: Yup.string()
    .required("Name is required")
    // .matches(/^[a-zA-Z_ ]*$/, "No special characters allowed.")
    .min(2, "Name must be between 2 and 16 characters.")
    .max(25, "Name must be between 2 and 16 characters."),
  CompanyName: Yup.string().required("CompanyName is required"),
  // .matches(/^[a-zA-Z_ ]*$/, "No special characters allowed."),

  Email: Yup.string()
    .required("Email address is required.")
    .email("Invalid email address."),
  Password: Yup.string()
    .required("Password is required.")
    .matches(
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
      "Password must contain atleast 8 characters, 1 uppercase, 1 lowercase, 1 number and 1 special character."
    ),
});

export const signInSchema = Yup.object({
  Email: Yup.string()
    .required("Email address is required.")
    .email("Invalid email address."),
  Password: Yup.string().required("Password is required."),
});

export const magicLinkSchema = Yup.object({
  Email: Yup.string()
    .required("Email address is required.")
    .email("Invalid email address."),
});

export const passwordSchema = Yup.object({
  Password: Yup.string()
    .required("Password is required.")
    .matches(
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
      "Password must contain atleast 8 characters, 1 uppercase, 1 lowercase, 1 number and 1 special character."
    ),
  ConfirmPassword: Yup.string()
    .required("Confirm Password is required.")
    .matches(
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
      "Password must contain atleast 8 characters, 1 uppercase, 1 lowercase, 1 number and 1 special character."
    ),
});

export const reportSchema = Yup.object({
  Describe: Yup.string()
    .required("Describe is required")
    .min(2, "Name must be between 2 and 16 characters.")
    .max(200, "Name must be between 2 and 16 characters."),
  URL: Yup.string().required("URL is required"),
  Email: Yup.string()
    .required("Email address is required.")
    .email("Invalid email address."),
});
