/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from "react";
import RadioButton from "../../components/reviews/radioButton";
import MultiSelect from "../../components/reviews/multiSelect/MultiSelect";
import Emoji from "../../components/reviews/emoji/Emoji";
import Start from "../../components/reviews/starRating/StarRating";
import DropDown from "../../components/reviews/dropDown/dropDown";
import { useSelector } from "react-redux";
import Nsp from "../../components/reviews/nsp/Nsp";
import Welcome from "../../components/reviews/welcome";
import Connect from "../../components/reviews/connect";
import EventImg from "../../assets/svg/noEvent.svg";
import { useDispatch } from "react-redux";
import { setButtonColor, setTextColor } from "../../redux/slices/questions";

const preview = ({ typeOfPreview, id, colName, changeId, viewStatus }) => {
  // const { welcomeBg } = useSelector((state) => state.globalDefine);
  const { questionValues } = useSelector((state) => state.questions);
  const { mood } = useSelector((state) => state.mood);
  const { questions } = useSelector((state) => state);
  const bgInfo = questions.bgImageDetails;
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState([]);
  const [subHeading, setSubHeading] = useState("");
  const buttonColors = questions.buttonColor;
  // const { buttonColor } = useSelector((state) => state.globalDefine);

  useEffect(() => {
    const filterAnswer = questionValues.filter((item) => item.id == id);
    const isExisit =
      filterAnswer.findIndex((item) => item.type === typeOfPreview) !== -1;
    if (isExisit) {
      return setAnswer(filterAnswer[0].choice);
    }
  }, [typeOfPreview, id, questionValues]);

  useEffect(() => {
    const questionFilter = questionValues.filter((item) => item.id == id);

    setQuestion(questionFilter?.[0]?.question);
    setSubHeading(questionFilter?.[0]?.subHeading)
    return 
  }, [questionValues, typeOfPreview, id]);

  return (
    <div
      style={{
        backgroundColor:
          questionValues?.[0]?.backgroundColor?.length !== 0 &&
          questionValues?.[0]?.backgroundColor,
      }}
      className={`${
        mood === "light" ? `!bg-[#FAFAFA]` : "!bg-[#232734]"
      } w-[60%] h-[calc(100vh-105px)] !overflow-hidden padding-[50px] flex items-center justify-center flex-col`}
    >
      {questionValues.length === 0 && (
        <>
          <img src={EventImg} alt="noEvent" />
          <p
            className={`ml-10 text-2xl font-bold mt-6 ${
              mood === "dark" ? "text-white" : "text-black"
            }`}
          >
            Nothing to see here!
          </p>
          <p className="ml-10 text-2xl mt-2 text-grayDark">
            Add one question first
          </p>
        </>
      )}

      {questionValues.length !== 0 && (
        <>
          {typeOfPreview === "welcome" && (
            <div
              style={{
                backgroundImage: `url(${
                  (bgInfo?.[0]?.logoLocation !== undefined) &
                  (bgInfo?.[0]?.logoLocation !== null)
                    ? bgInfo?.[0]?.logoLocation
                    : null
                  // ? welcomeBg
                  // : bgInfo?.[0]?.logoLocation
                })`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
              }}
              className={`${
                viewStatus === "computer"
                  ? "w-[90%] h-[70vh] max-h-[600px]"
                  : "w-[40%] h-[75vh] max-h-[800px]"
              } border-[25px] border-[black] rounded-lg m-auto overflow-hidden !pb-12 !pt-5 mt-[30px] flex flex-col !justify-center relative`}
            >
              <div
                style={{
                  borderBottom: `15px solid ${
                    (buttonColors !== null) & (buttonColors !== undefined)
                      ? buttonColors
                      : "#4F46E5"
                    //  buttonColor : buttonColors
                  }`,
                }}
                className={` !z-10 absolute bottom-[0] w-full left-0`}
              ></div>
              <Welcome
                inputQuestion={question}
                id={id}
                questionType={typeOfPreview}
                answer={answer}
                nextQuz={colName}
                next={changeId}
                subHeading={subHeading}
              />
            </div>
          )}
          {typeOfPreview === "radioButton" && (
            <div
              className={`${
                viewStatus === "computer"
                  ? "w-[90%] h-[70vh] max-h-[600px]"
                  : "w-[40%] h-[75vh] max-h-[800px]"
              } border-[25px] border-[black] m-auto rounded-lg overflow-hidden !pb-12 !pt-5 mt-[30px] flex flex-col !justify-center relative`}
            >
              <div
                style={{
                  borderBottom: `15px solid ${
                    (buttonColors !== null) & (buttonColors !== undefined)
                      ? buttonColors
                      : "#4F46E5"
                    //  buttonColor : buttonColors
                  }`,
                }}
                className={` !z-10 absolute bottom-[0] w-full left-0`}
              ></div>
              <RadioButton
                inputQuestion={question}
                id={id}
                questionType={typeOfPreview}
                answer={answer}
                nextQuz={colName}
                next={changeId}
                viewStatus={viewStatus}
              />
            </div>
          )}
          {typeOfPreview === "contact" && (
            <div
              className={`${
                viewStatus === "computer"
                  ? "w-[90%] h-[70vh] max-h-[600px]"
                  : "w-[40%] h-[75vh] max-h-[800px]"
              } border-[25px] border-[black] m-auto rounded-lg overflow-hidden !pb-12 !pt-5 mt-[30px] flex flex-col !justify-center relative`}
            >
              <div
                style={{
                  borderBottom: `15px solid ${
                    (buttonColors !== null) & (buttonColors !== undefined)
                      ? buttonColors
                      : "#4F46E5"
                    //  buttonColor : buttonColors
                  }`,
                }}
                className={` !z-10 absolute bottom-[0] w-full left-0`}
              ></div>
              <Connect
                inputQuestion={question}
                id={id}
                questionType={typeOfPreview}
                answer={answer}
                nextQuz={colName}
                next={changeId}
                viewStatus={viewStatus}
              />
            </div>
          )}
          {typeOfPreview === "multiSelect" && (
            <div
              className={`${
                viewStatus === "computer"
                  ? "w-[90%] h-[70vh] max-h-[600px]"
                  : "w-[40%] h-[75vh] max-h-[800px]"
              } border-[25px] border-[black] rounded-lg m-auto  overflow-hidden !pb-12 !pt-5 mt-[30px] flex flex-col !justify-center relative`}
            >
              <div
                style={{
                  borderBottom: `15px solid ${
                    (buttonColors !== null) & (buttonColors !== undefined)
                      ? buttonColors
                      : "#4F46E5"
                    //  buttonColor : buttonColors
                  }`,
                }}
                className={` !z-10 absolute bottom-[0] w-full left-0`}
              ></div>
              <MultiSelect
                inputQuestion={question}
                id={id}
                questionType={typeOfPreview}
                answer={answer}
                nextQuz={colName}
                next={changeId}
                viewStatus={viewStatus}
              />
            </div>
          )}
          {typeOfPreview === "starRating" && (
            <div
              className={`${
                viewStatus === "computer"
                  ? "w-[90%] h-[70vh] max-h-[600px]"
                  : "w-[40%] h-[75vh] max-h-[800px]"
              } border-[25px] border-[black] rounded-lg m-auto  overflow-hidden !pb-12 !pt-5 mt-[30px] flex flex-col !justify-center relative`}
            >
              <div
                s
                style={{
                  borderBottom: `15px solid ${
                    (buttonColors !== null) & (buttonColors !== undefined)
                      ? buttonColors
                      : "#4F46E5"
                    //  buttonColor : buttonColors
                  }`,
                }}
                className={` !z-10 absolute bottom-[0] w-full left-0`}
              ></div>
              <Start
                inputQuestion={question}
                id={id}
                questionType={typeOfPreview}
                answer={answer}
                nextQuz={colName}
                next={changeId}
                color={buttonColors}
              />
            </div>
          )}
          {typeOfPreview === "smileyScale" && (
            <div
              className={`${
                viewStatus === "computer"
                  ? "w-[90%] h-[70vh] max-h-[600px]"
                  : "w-[40%] h-[75vh] max-h-[800px]"
              } border-[25px] border-[black] rounded-lg m-auto  overflow-hidden !pb-12  mt-[10px] flex flex-col !justify-center relative`}
            >
              <div
                style={{
                  borderBottom: `15px solid ${
                    (buttonColors !== null) & (buttonColors !== undefined)
                      ? buttonColors
                      : "#4F46E5"
                    //  buttonColor : buttonColors
                  }`,
                }}
                className={` !z-10 absolute bottom-[0] w-full left-0`}
              ></div>
              <Emoji
                emojiSize={viewStatus === "computer" ? "50px" : "10px"}
                inputQuestion={question}
                id={id}
                questionType={typeOfPreview}
                answer={answer}
                nextQuz={colName}
                next={changeId}
                viewStatus={viewStatus}
                color={buttonColors}
              />
            </div>
          )}
          {typeOfPreview === "dropDown" && (
            <DropDown
              inputQuestion={question}
              id={id}
              questionType={typeOfPreview}
              answer={answer}
              nextQuz={colName}
              next={changeId}
            />
          )}
          {typeOfPreview === "Nsp" && (
            <div
              className={`${
                viewStatus === "computer"
                  ? "w-[90%] h-[70vh] max-h-[600px]"
                  : "w-[40%] h-[75vh] max-h-[800px]"
              } border-[25px] border-[black] rounded-lg m-auto  overflow-hidden !pb-12 !pt-5 mt-[30px] flex flex-col !justify-center relative`}
            >
              <div
                style={{
                  borderBottom: `15px solid ${
                    (buttonColors !== null) & (buttonColors !== undefined)
                      ? buttonColors
                      : "#4F46E5"
                    //  buttonColor : buttonColors
                  }`,
                }}
                className={` !z-10 absolute bottom-[0] w-full left-0`}
              ></div>
              <Nsp
                inputQuestion={question}
                answer={answer}
                nextQuz={colName}
                id={id}
                questionType={typeOfPreview}
                next={changeId}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default preview;
