import { useState } from "react"
import { useSelector } from "react-redux"
import Navbar from "../../components/Navbar"

// local components
import Password from "./password"
import Profile from "./Profile"
import Billing from "./Billing"
import MyPlan from "./MyPlan"

// assets
import { BiUserCircle, BiBarChartSquare } from "react-icons/bi"
import { RiLockPasswordLine } from "react-icons/ri"
import { BsCreditCard } from "react-icons/bs"
import { TbMessageReport } from "react-icons/tb"
import ReportIssue from "./ReportIssue"
import SmallScreenOverlay from "../SmallScreenOverlay"

const navItems = [
    {
        name: "profile",
        icon: (
            <BiUserCircle
                size={24}
                className="global_sidemenu_item_icon"
            />
        )
    },
    {
        name: "password",
        icon: (
            <RiLockPasswordLine
                size={24}
                className="global_sidemenu_item_icon"
            />
        )
    },
    {
        name: "my plan",
        icon: (
            <BiBarChartSquare
                size={24}
                className="global_sidemenu_item_icon"
            />
        )
    },
    {
        name: "billing History",
        icon: (
            <BsCreditCard
                size={24}
                className="global_sidemenu_item_icon"
            />
        )
    },
    {
        name: "report an issue",
        icon: (
            <TbMessageReport
                size={24}
                className="global_sidemenu_item_icon"
            />
        )
    }
]

const Account = () => {
    const [currentPage, setCurrentPage] = useState("profile")
    const { mood } = useSelector((state) => state.mood)
    const { user } = useSelector((state) => state.userAuth)
    const [menu, setMenu] = useState(false)

    return (
        <>
        <main className={`${mood === "light" ? ` border-[#E4E4E7]` : "bg-[#161A25] border-gray"} max-lg:hidden`}>
            <Navbar menu={setMenu} />
            <div className="global_main relative">
                <div
                    className={`global_sidemenu ${
                        !menu
                            ? "min-w-[250px] t4:min-w-[260px] l1:min-w-[260px]"
                            : " w-[95px] l1:w-[108px] l3:w-[260px]"
                    }   `}
                >
                    {navItems.map((obj, idx) => (
                        <div
                            className={`global_sidemenu_item ${
                                currentPage === obj.name ? "bg-blue text-white " : "blueHover"
                            }`}
                            onClick={() => setCurrentPage(obj.name)}
                        >
                            <div className=" min-w-max flex items-center gap-x-4">
                                {obj.icon}
                                <p className="global_sidemenu_item_text capitalize">{obj.name}</p>
                            </div>
                        </div>
                    ))}
                </div>
                <div className=" xxl:w-screen xxl:ml-[-700px]  flex-1  p-10">
                    <>
                        {currentPage === "profile" && <Profile />}
                        {currentPage === "password" && <Password />}
                        {currentPage === "my plan" && <MyPlan />}
                        {currentPage === "billing History" && <Billing />}
                        {currentPage === "report an issue" && <ReportIssue />}
                    </>
                </div>
            </div>
        </main>
        <SmallScreenOverlay />
        </>
    )
}

export default Account
