/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Calendar, Eye, FilePlus, FileText } from "react-feather";
import {
  BsCheckCircle,
  BsChevronDown,
  BsEmojiSmile,
  BsOctagon,
  BsRecordCircle,
  BsSpeedometer2,
  BsStar,
} from "react-icons/bs";
import Navbar from "../../components/Navbar";
import img1 from "../../assets/ratting/1.png";
import img2 from "../../assets/ratting/2.png";
import img3 from "../../assets/ratting/4.png";
import img4 from "../../assets/ratting/3.png";
import img5 from "../../assets/ratting/5.png";
import icon from "../../assets/ratting/Icon.svg";
import star from "../../assets/ratting/Active.svg";
import { useParams, Link } from "react-router-dom";
import axios from "../../axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useSelector } from "react-redux";
import logoDark from "../../assets/svg/logo.svg";
import ReportPateForExport from "./ReportPageForExport";
import Table from "./table";
import ContactTableExport from "./ContactTableExport";
import { CSVLink } from "react-csv";
import { AiOutlineUser } from "react-icons/ai";
import DateFilterMenu from "./../../components/DateFilterMenu";
import { svg1, svg2, svg3, svg4, svg5 } from "../../assets/ratting/1";
import CustomDotLoader from './../../components/CustomDotLoader/index'
import NoEvent from "../../assets/svg/eventnotfound.svg";
import SmallScreenOverlay from "../SmallScreenOverlay";

export const exportToCSV = (data, name) => {
  const csvData = data?.map((row) =>
    row?.map((i) => ({
      type: i?.type,
      value: i?.value,
    }))
  );
  const uniqueTypes = [
    ...new Set(csvData.flatMap((item) => item.map((entry) => entry.type))),
  ];

  const csvHeaders = uniqueTypes.map((type) => ({
    label: type === "text" ? "name" : type,
    key: type,
  }));

  const transformedData = csvData.map((item) => {
    const entryObject = {};
    item.forEach((entry) => {
      entryObject[entry.type] = entry.value;
    });
    return entryObject;
  });

  return (
    <button className="font-inter text-[#71717A] px-2 rounded-md border border-[#E4E4E7]">
      <FilePlus size={20} className="mr-2" />

      <CSVLink
        data={transformedData}
        headers={csvHeaders}
        filename={`${name}.csv`}
      >
        Export to CSV
      </CSVLink>
    </button>
  );
};

const Report = () => {
  const { id } = useParams();
  const [eventDetails, setEventDetails] = useState([]);
  const [listOfQuestion, setListOfQuestion] = useState([]);
  const [popupStatus, setPopup] = useState(false);
  const [active, setActive] = useState(100);
  const [activeDate, setDateManual] = useState(null);
  const [mainActiveDate, setMainActiveDate] = useState(null);
  const [date, setDate] = useState(new Date());
  const [startDate, setStartDate] = useState(new Date());
  const [dateStatus, setDateStatus] = useState(false);
  const [mainDate, setMainDate] = useState(null);
  const [items, setItems] = useState([]);
  const [multiSelectValue, setMultiSelectValue] = useState([]);
  const { mood } = useSelector((state) => state.mood);
  const [buttonClick, setButtonClick] = useState(null);
  const [buttonClick1, setButtonClick1] = useState(null);
  const [localStorageValue, setLocalStorageValue] = useState(
    localStorage.getItem("eventResponse") || ""
  );
  const [filterOpen, setFilterOpen] = useState(false);
  const [dateType, setDateType] = useState("All");
  const [getData, setgetData] = useState(false);
  const [dateRange, setDateRange] = useState("");
  const [reports, setReports] = useState([]);
  const [spinner, setSpinner] = useState(true);

  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.key === "eventResponse") {
        setLocalStorageValue(event.newValue || "");
      }
    };
    // Listen for changes in localStorage
    window.addEventListener("storage", handleStorageChange);
    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  const handelExport = (event) => {
    if (buttonClick == null) {
      setButtonClick(true);
    } else if (buttonClick == false) {
      setButtonClick(true);
    } else {
      setButtonClick(false);
    }
  };

  const radioButtonValues = reports?.filter(
    (obj) => obj.type === "radioButton"
  );
  const smileyScaleValues = reports?.filter(
    (obj) => obj.type === "smileyScale"
  );
  const starRatingValues = reports?.filter((obj) => obj.type === "starRating");
  const Nps = reports?.filter((obj) => obj.type === "Nsp");

  useEffect(() => {
    const Api = async () => {
      if ((dateRange !== "") & (dateRange !== undefined)) {
        const { data } = await axios.post("/report", {
          label: dateType,
          id: id,
          fromDate: dateRange[0],
          toDate: dateRange[1],
        });

        if (data.success) {
          setReports(data.feedbackCount);
          setItems(data.contactForm);
          setMultiSelectValue(data.multiSelect);
          setgetData(false);
          setSpinner(false);
        }
      } else {
        const { data } = await axios.post("/report", {
          label: dateType,
          id: id,
        });

        if (data.success) {
          setReports(data.feedbackCount);
          setItems(data.contactForm);
          setMultiSelectValue(data.multiSelect);
          setgetData(false);
          setDateRange("");
          setSpinner(false);
        }
      }
    };
    Api();
  }, [id, localStorageValue, getData, dateType, dateRange]);

  useEffect(() => {
    const Api = async () => {
      const { data } = await axios.post("/getParticularEvent", {
        shortID: id,
      });

      console.log("data", data);
      setListOfQuestion(data?.[0]?.list_of_items);
      return setEventDetails(data);
    };
    Api();
  }, [id, localStorageValue]);

  document.body.addEventListener("click", () => {
    setPopup(false);
    setDateStatus(false);
  });

  const data = [
    "Today",
    "Yesterday",
    "Last Week",
    "This Week",
    "Last Month",
    "This Month",
    "This Year",
    "Last Year",
    "Life Time",
  ];

  const RadioButton = (con, id) => {
    const totalCount = radioButtonValues?.reduce((accumulator, item) => {
      if (item?.uuid === con && item?.answer === id) {
        accumulator += item?.count;
      }
      return accumulator;
    }, 0);

    return totalCount;
  };

  const StarRating = (con, id) => {
    const totalCount = starRatingValues?.reduce((accumulator, item) => {
      if (item?.uuid === con && item?.answer === id) {
        accumulator += item?.count;
      }
      return accumulator;
    }, 0);

    return totalCount;
  };

  const MultiPle = (con, id) => {
    const totalCount = multiSelectValue?.reduce((accumulator, item) => {
      if (item?.uuid === con && item?.answer == id.toString()) {
        accumulator += item?.count;
      }
      return accumulator;
    }, 0);

    return totalCount;
  };

  const Nsp = (con, id) => {
    const totalCount = Nps?.reduce((accumulator, item) => {
      if (item?.uuid === con && item?.answer === id) {
        accumulator += item?.count;
      }
      return accumulator;
    }, 0);

    return totalCount;
  };

  const SmilyScale = (con, id) => {
    const totalCount = smileyScaleValues?.reduce((accumulator, item) => {
      if (item.uuid === con && item.answer === id) {
        accumulator += item?.count;
      }
      return accumulator;
    }, 0);

    return totalCount;
  };

  return (
      <>
          <div className=" max-lg:hidden">
              <Navbar />
              <div
                  className={`w-[100vw] ${
                      mood === "light" ? `bg-[#FAFAFA]` : "bg-[#161A25]"
                  } overflow-hidden min-h-screen max-w-[105rem] mx-auto`}
              >
                  <div
                      className={`hidden logoBar py-3 border-b ${
                          mood === "light" ? `bg-black border-[#E4E4E7]` : "bg-[#161A25] border-gray"
                      } `}
                  >
                      <div className={`px-4 mx-auto w-[95%] 3xl:max-w-7xl sm:px-2 py-1`}>
                          <div className="flex items-center w-full justify-center xs:flex-col sm:flex-col ms:flex-col lg:flex-row xl:flex-row">
                              <div className="my-2">
                                  <>
                                      <img
                                          className="block w-auto h-8 lg:hidden"
                                          src={logoDark}
                                          alt=""
                                      />
                                      <img
                                          className="hidden w-auto h-8 lg:block"
                                          src={logoDark}
                                          alt=""
                                      />
                                  </>
                              </div>
                          </div>
                      </div>
                  </div>
                  {spinner ? (
                      <>
                          <div
                              className={`w-full h-[100vh]  flex items-center justify-center ${
                                  mood === "light" ? "bg-[#fafafa]" : "bg-[#161A25]"
                              } `}
                          >
                              <CustomDotLoader dotSize={120} />
                          </div>
                      </>
                  ) : (
                      <>
                          <div className="py-10">
                              <div
                                  className="w-[90%] flex items-center justify-between mx-auto bg-[white] border-2 border-[#E4E4E7] rounded-xl
                     py-7 px-5"
                              >
                                  <div className="flex">
                                      <div className="text-start">
                                          <p className="uppercase text-[#71717A] text-[14px]">Event Name</p>
                                          <p className="capitalize mt-1 font-inter font-[600] text-[14px] text-black">
                                              {eventDetails?.[0]?.eventName}
                                          </p>
                                      </div>
                                      <div className="ml-12 text-center">
                                          <p className="uppercase text-[#71717A] text-[14px]">Question</p>
                                          <p className="capitalize mt-1 font-inter font-[600] text-[14px] text-black">
                                              {eventDetails?.[0]?.no_of_question}
                                          </p>
                                      </div>
                                      <div className="ml-12 text-center">
                                          <p className="uppercase text-[#71717A] text-[14px]">Feedback Count</p>
                                          <p className="capitalize mt-1 font-inter font-[600] text-[14px] text-black">
                                              {eventDetails?.[0]?.feedback_count}
                                          </p>
                                      </div>
                                  </div>
                                  <div className="flex gap-x-5">
                                      <div
                                          onClick={(e) => {
                                              e.stopPropagation()
                                              setPopup(!popupStatus)
                                          }}
                                          className="hidden relative"
                                      >
                                          <div className="cursor-pointer flex w-[275px] justify-between relative !outline-none placeholder:text-[#71717A] px-2 py-2 rounded-md text-[#71717A] border border-[#E4E4E7]">
                                              <p>
                                                  {mainActiveDate === null
                                                      ? `${
                                                            mainDate == null
                                                                ? "18/06/2022"
                                                                : `${startDate.getDate()}/${
                                                                      startDate.getMonth() + 1
                                                                  }/${startDate.getFullYear()}`
                                                        } - ${date.getDate()}/${
                                                            date.getMonth() + 1
                                                        }/${date.getFullYear()}`
                                                      : mainActiveDate}
                                              </p>
                                              <Calendar
                                                  size={20}
                                                  className=""
                                              />
                                          </div>
                                          {popupStatus && (
                                              <div
                                                  onClick={(e) => {
                                                      e.stopPropagation()
                                                      setPopup(true)
                                                  }}
                                                  style={{
                                                      boxShadow:
                                                          "0px 6px 14px -6px rgba(24, 39, 75, 0.12), 0px 10px 32px -4px rgba(24, 39, 75, 0.1)"
                                                  }}
                                                  className="w-[400px] px-[20px] py-[20px] border border-[#D8D6DE] absolute bg-white rounded-md top-[120%]"
                                              >
                                                  <p className="font-inter text-[#6E6B7B] font-[500] text-[14px]">
                                                      Time Period
                                                  </p>
                                                  <div className="flex justify-between relative !outline-none placeholder:text-[#71717A] px-2 py-2 rounded-md text-[#71717A] border border-[#E4E4E7]">
                                                      <p>
                                                          {activeDate === null
                                                              ? `${startDate.getDate()}/${
                                                                    startDate.getMonth() + 1
                                                                }/${startDate.getFullYear()} - ${date.getDate()}/${
                                                                    date.getMonth() + 1
                                                                }/${date.getFullYear()}`
                                                              : activeDate}
                                                      </p>
                                                      <Calendar
                                                          onClick={(e) => {
                                                              e.stopPropagation()
                                                              setDateStatus(!dateStatus)
                                                          }}
                                                          size={20}
                                                          className="cursor-pointer"
                                                      />
                                                  </div>
                                                  <DatePicker
                                                      className="hidden top-5 absolute right-0"
                                                      open={dateStatus}
                                                      selected={startDate}
                                                      onChange={(date) => {
                                                          setDateStatus(false)
                                                          setStartDate(date)
                                                      }}
                                                  />
                                                  <div className="flex flex-wrap gap-x-2 gap-y-3">
                                                      {data?.map((data, i) => {
                                                          return (
                                                              <div
                                                                  onClick={() => {
                                                                      setDateManual(data)
                                                                      setActive(i)
                                                                  }}
                                                                  className={`${
                                                                      active === i &&
                                                                      "bg-[#4e46e52c] !border-transparent text-[#4F46E5]"
                                                                  } py-[2px] cursor-pointer focus:border-transparent px-4 rounded-xl text-[14px] border border-[#D8D6DE]`}
                                                              >
                                                                  {data}
                                                              </div>
                                                          )
                                                      })}
                                                  </div>
                                                  <div className="flex flex-wrap mt-6 justify-between">
                                                      <button
                                                          onClick={() => {
                                                              setMainActiveDate(null)
                                                              setActive(100)
                                                              setDateManual(null)
                                                          }}
                                                          className="w-[45%] py-2.5 border border-[#BABFC7] rounded-lg text-[#333333]"
                                                      >
                                                          Clear
                                                      </button>
                                                      <button
                                                          onClick={(e) => {
                                                              e.stopPropagation()
                                                              if (activeDate !== null) {
                                                                  setMainActiveDate(activeDate)
                                                              } else {
                                                                  setMainDate(startDate)
                                                              }
                                                              setPopup(false)
                                                          }}
                                                          className="w-[45%] py-2.5 text-white bg-[#4F46E5] rounded-lg"
                                                      >
                                                          Apply
                                                      </button>
                                                  </div>
                                              </div>
                                          )}
                                      </div>
                                      <div className="relative inline-block text-left">
                                          <div>
                                              <button
                                                  onClick={() => {
                                                      setFilterOpen((prev) => !prev)
                                                  }}
                                                  type="button"
                                                  className="inline-flex w-full justify-center items-center gap-x-10 rounded-md bg-white py-2.5 px-4 text-left text-grayDark text-sm font-semibold shadow-sm ring-1 ring-inset ring-[#E4E4E7] hover:bg-gray-50"
                                              >
                                                  {dateType}
                                                  <BsChevronDown size={16} />
                                              </button>
                                          </div>

                                          <DateFilterMenu
                                              filterOpen={filterOpen}
                                              close={setFilterOpen}
                                              setDateTypes={setDateType}
                                              dateRanges={setDateRange}
                                              getData={setgetData}
                                              all="all"
                                          />
                                      </div>
                                      <div>
                                          <button
                                              className="font-inter text-[#71717A] py-2 px-5 rounded-md border border-[#E4E4E7]"
                                              onClick={handelExport}
                                          >
                                              <FilePlus
                                                  size={20}
                                                  className="mr-2"
                                              />{" "}
                                              Export to PDF
                                          </button>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </>
                  )}

                  {(reports?.length === 0) & !spinner ? (
                      <>
                          <div
                              className={`flex items-center justify-center flex-col ${
                                  mood === "dark" ? "bg-[#161A25]" : "bg-white"
                              } h-[100vh]`}
                          >
                              <img
                                  src={NoEvent}
                                  alt="noevent"
                              />
                              <p
                                  className={`ml-10 text-2xl font-bold mt-6 ${
                                      mood === "dark" ? "text-white" : "text-black"
                                  }`}
                              >
                                  No feedback for {dateType === "All" ? "this campaing" : dateType}
                              </p>
                              <p className={`ml-10 text-2xl mt-2 ${mood === "dark" ? "text-white" : "text-black"}`}>
                                  {dateType === "All" && "Please add one feedback and check"}
                              </p>
                          </div>
                      </>
                  ) : (
                      <>
                          {listOfQuestion?.map((i, index) => (
                              <>
                                  {i?.type === "smileyScale" && (
                                      <div className="py-10">
                                          <div className="w-[90%] flex flex-col mx-auto bg-[white] border-2 border-[#E4E4E7] rounded-xl py-7 px-7">
                                              <div className="flex items-center">
                                                  {/* <p>{index?.length > 9 ? index : "0" + index}</p> */}
                                                  <BsEmojiSmile
                                                      size={18}
                                                      color="#4F46E5"
                                                      className="ml-2"
                                                  />
                                                  <p className="font-inter text-[#1F1F1F] ml-2">Smiley scale</p>
                                              </div>
                                              <p className="font-inter text-[#71717A] my-2">{i?.question}</p>
                                              <div className="flex mt-5 gap-x-8">
                                                  <div className="flex flex-col items-center">
                                                      <span>{svg1({ size: "100px", color: "#4F46E5" })}</span>
                                                      <button className="flex items-center bg-[#4F46E5] py-1 px-3 rounded-md text-white font-inter mt-2 gap-2">
                                                          <AiOutlineUser
                                                              size={18}
                                                              color="white"
                                                          />
                                                          {SmilyScale(i?.uuid, "1")}
                                                      </button>
                                                  </div>
                                                  <div className="flex flex-col items-center">
                                                      <span>{svg2({ size: "100px", color: "#4F46E5" })}</span>

                                                      <button className="flex items-center bg-[#4F46E5] py-1 px-3 rounded-md text-white font-inter mt-2 gap-2">
                                                          <AiOutlineUser
                                                              size={18}
                                                              color="white"
                                                          />
                                                          {SmilyScale(i?.uuid, "2")}
                                                      </button>
                                                  </div>
                                                  <div className="flex flex-col items-center">
                                                      <span>{svg3({ size: "100px", color: "#4F46E5" })}</span>
                                                      <button className="flex items-center bg-[#4F46E5] py-1 px-3 rounded-md text-white font-inter mt-2 gap-2">
                                                          <AiOutlineUser
                                                              size={18}
                                                              color="white"
                                                          />
                                                          {SmilyScale(i?.uuid, "3")}
                                                      </button>
                                                  </div>
                                                  <div className="flex flex-col items-center">
                                                      <span>{svg4({ size: "100px", color: "#4F46E5" })}</span>

                                                      <button className="flex items-center bg-[#4F46E5] py-1 px-3 rounded-md text-white font-inter mt-2 gap-2">
                                                          <AiOutlineUser
                                                              size={18}
                                                              color="white"
                                                          />
                                                          {SmilyScale(i?.uuid, "4")}
                                                      </button>
                                                  </div>
                                                  <div className="flex flex-col items-center">
                                                      <span>{svg5({ size: "100px", color: "#4F46E5" })}</span>
                                                      <button className="flex items-center bg-[#4F46E5] py-1 px-3 rounded-md text-white font-inter mt-2 gap-2">
                                                          <AiOutlineUser
                                                              size={18}
                                                              color="white"
                                                          />
                                                          {SmilyScale(i?.uuid, "5")}
                                                      </button>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  )}

                                  {i?.type === "starRating" && (
                                      <div className="py-10">
                                          <div className="w-[90%] flex flex-col mx-auto bg-[white] border-2 border-[#E4E4E7] rounded-xl py-7 px-7">
                                              <div className="flex items-center">
                                                  {/* <p>{index?.length > 9 ? index : "0" + index}</p> */}

                                                  <BsStar
                                                      size={18}
                                                      color="#4F46E5"
                                                      className="ml-2"
                                                  />
                                                  <p className="font-inter text-[#1F1F1F] ml-2">Rating scale</p>
                                              </div>
                                              <p className="font-inter text-[#71717A] my-2">{i?.question}</p>
                                              <div className="flex mt-5 gap-x-8">
                                                  {i?.answer?.map((ans) => (
                                                      <div
                                                          className="flex flex-col items-center"
                                                          key={ans?.id}
                                                      >
                                                          <img
                                                              src={star}
                                                              alt=""
                                                          />
                                                          <p className="font-inter text-[#71717A] my-1">{ans?.value}</p>
                                                          <button className="icon_alignment">
                                                              <AiOutlineUser size={18} />
                                                              {StarRating(i?.uuid, ans?.id)}
                                                          </button>
                                                      </div>
                                                  ))}
                                              </div>
                                          </div>
                                      </div>
                                  )}

                                  {i.type === "radioButton" && (
                                      <div className="py-10">
                                          <div className="w-[90%] flex flex-col mx-auto bg-[white] border-2 border-[#E4E4E7] rounded-xl py-7 px-7">
                                              <div className="flex items-center">
                                                  {/* <p>{index?.length > 9 ? index : "0" + index}</p> */}

                                                  <BsRecordCircle
                                                      size={18}
                                                      color="#4F46E5"
                                                      className="ml-2"
                                                  />
                                                  <p className="font-inter text-[#1F1F1F] ml-2">
                                                      Single answer selection
                                                  </p>
                                              </div>
                                              <p className="font-inter text-[#71717A] my-2">{i?.question}</p>
                                              {i?.answer?.map((ans) => (
                                                  <div
                                                      className="flex flex-col gap-y-3 mt-5 gap-x-8"
                                                      key={ans?.id}
                                                  >
                                                      <div className="flex items-center">
                                                          <button
                                                              className="text-[#1F1F1F] font-inter py-3 px-3 border border-[#E4E4E7] rounded-md bg-[#FAFAFA] w-[400px] 
                text-start break-words"
                                                          >
                                                              {ans?.value}
                                                          </button>
                                                          <button className="icon_alignment ml-4">
                                                              <AiOutlineUser size={18} />
                                                              {RadioButton(i?.uuid, ans?.id)}
                                                          </button>
                                                      </div>
                                                  </div>
                                              ))}
                                          </div>
                                      </div>
                                  )}
                                  {i?.type === "multiSelect" && (
                                      <div className="py-10">
                                          <div className="w-[90%] flex flex-col mx-auto bg-[white] border-2 border-[#E4E4E7] rounded-xl py-7 px-7">
                                              <div className="flex items-center">
                                                  {/* <p>{index?.length > 9 ? index : "0" + index}</p> */}

                                                  <BsCheckCircle
                                                      size={18}
                                                      color="#4F46E5"
                                                      className="ml-2"
                                                  />
                                                  <p className="font-inter text-[#1F1F1F] ml-2">
                                                      Multiple answer selection
                                                  </p>
                                              </div>

                                              <p className="font-inter text-[#71717A] my-2">{i?.question}</p>
                                              {i?.answer?.map((ans, idx) => (
                                                  <div className="flex flex-col gap-y-3 mt-5 gap-x-8">
                                                      <div className="flex items-center">
                                                          <button
                                                              className="text-[#1F1F1F] font-inter py-3 px-3 border border-[#E4E4E7] rounded-md bg-[#FAFAFA] w-[400px] 
                  text-start break-words"
                                                          >
                                                              {ans?.value}
                                                          </button>
                                                          <button className="icon_alignment ml-4">
                                                              <AiOutlineUser size={18} />
                                                              {MultiPle(i?.uuid, idx + 1)}
                                                          </button>
                                                      </div>
                                                  </div>
                                              ))}
                                          </div>
                                      </div>
                                  )}
                                  {i?.type === "Nsp" && (
                                      <div className="py-10">
                                          <div className="w-[90%] flex flex-col mx-auto bg-[white] border-2 border-[#E4E4E7] rounded-xl py-7 px-7">
                                              <div className="flex items-center">
                                                  {/* <p>{index?.length > 9 ? index : "0" + index}</p> */}

                                                  <BsSpeedometer2
                                                      size={18}
                                                      color="#4F46E5"
                                                      className="ml-2"
                                                  />
                                                  <p className="font-inter text-[#1F1F1F] ml-2">NSP</p>
                                              </div>
                                              <p className="font-inter text-[#71717A] my-2">{i?.question}</p>
                                              <div className="flex mt-5 gap-x-8">
                                                  <div className="flex flex-col items-center">
                                                      <p className="font-inter text-[#3d3d52] my-1 p-2 px-4 mb-2 rounded-md bg-[#F7F7F7]">
                                                          1
                                                      </p>
                                                      <button className="icon_alignment">
                                                          <AiOutlineUser size={18} />
                                                          {Nsp(i?.uuid, "1")}
                                                      </button>
                                                  </div>
                                                  <div className="flex flex-col items-center">
                                                      <p className="font-inter text-[#71717A] my-1 p-2 px-4 mb-2 rounded-md bg-[#F7F7F7]">
                                                          2
                                                      </p>
                                                      <button className="icon_alignment">
                                                          <AiOutlineUser size={18} />
                                                          {Nsp(i?.uuid, "2")}
                                                      </button>
                                                  </div>
                                                  <div className="flex flex-col items-center">
                                                      <p className="font-inter text-[#71717A] my-1 p-2 px-4 mb-2 rounded-md bg-[#F7F7F7]">
                                                          3
                                                      </p>
                                                      <button className="icon_alignment">
                                                          <AiOutlineUser size={18} />
                                                          {Nsp(i?.uuid, "3")}
                                                      </button>
                                                  </div>
                                                  <div className="flex flex-col items-center">
                                                      <p className="font-inter text-[#71717A] my-1 p-2 px-4 mb-2 rounded-md bg-[#F7F7F7]">
                                                          4
                                                      </p>
                                                      <button className="icon_alignment">
                                                          <AiOutlineUser size={18} />
                                                          {Nsp(i?.uuid, "4")}
                                                      </button>
                                                  </div>
                                                  <div className="flex flex-col items-center">
                                                      <p className="font-inter text-[#71717A] my-1 p-2 px-4 mb-2 rounded-md bg-[#F7F7F7]">
                                                          5
                                                      </p>
                                                      <button className="icon_alignment">
                                                          <AiOutlineUser size={18} />
                                                          {Nsp(i?.uuid, "5")}
                                                      </button>
                                                  </div>
                                              </div>
                                              <div className="flex mt-5 gap-x-8">
                                                  <div className="flex flex-col items-center">
                                                      <p className="font-inter text-[#71717A] my-1 p-2 px-4 mb-2 rounded-md bg-[#F7F7F7]">
                                                          6
                                                      </p>
                                                      <button className="icon_alignment">
                                                          <AiOutlineUser size={18} />
                                                          {Nsp(i?.uuid, "6")}
                                                      </button>
                                                  </div>
                                                  <div className="flex flex-col items-center">
                                                      <p className="font-inter text-[#71717A] my-1 p-2 px-4 mb-2 rounded-md bg-[#F7F7F7]">
                                                          7
                                                      </p>
                                                      <button className="icon_alignment">
                                                          <AiOutlineUser size={18} />
                                                          {Nsp(i?.uuid, "7")}
                                                      </button>
                                                  </div>
                                                  <div className="flex flex-col items-center">
                                                      <p className="font-inter text-[#71717A] my-1 p-2 px-4 mb-2 rounded-md bg-[#F7F7F7]">
                                                          8
                                                      </p>
                                                      <button className="icon_alignment">
                                                          <AiOutlineUser size={18} />
                                                          {Nsp(i?.uuid, "8")}
                                                      </button>
                                                  </div>
                                                  <div className="flex flex-col items-center">
                                                      <p className="font-inter text-[#71717A] my-1 p-2 px-4 mb-2 rounded-md bg-[#F7F7F7]">
                                                          9
                                                      </p>
                                                      <button className="icon_alignment">
                                                          <AiOutlineUser size={18} />
                                                          {Nsp(i?.uuid, "9")}
                                                      </button>
                                                  </div>
                                                  <div className="flex flex-col items-center">
                                                      <p className="font-inter text-[#71717A] my-1 p-2 px-4 mb-2 rounded-md bg-[#F7F7F7]">
                                                          10
                                                      </p>
                                                      <button className="icon_alignment">
                                                          <AiOutlineUser size={18} />
                                                          {Nsp(i?.uuid, "10")}
                                                      </button>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  )}
                              </>
                          ))}

                          <>
                              {items.map((item) => {
                                  return (
                                      <div className="py-10">
                                          <div className="w-[90%] flex flex-col overflow-auto mx-auto bg-[white] border-2 border-[#E4E4E7] rounded-xl py-7 px-7">
                                              <div className="flex items-center">
                                                  <FileText
                                                      size={18}
                                                      color="#4F46E5"
                                                      className="ml-2"
                                                  />
                                                  <p className="font-inter text-[#1F1F1F] ml-2">Contact Form</p>
                                              </div>
                                              <div className="flex justify-between my-2">
                                                  <p className="font-inter font-[600] text-lg text-[#71717A]">
                                                      <p className="font-inter text-[#71717A] my-2">Response.</p>
                                                  </p>
                                                  <div className="flex">
                                                      {exportToCSV(item, eventDetails?.[0]?.eventName)}
                                                  </div>
                                              </div>
                                              <Table items={item} />
                                          </div>
                                          <div className="fixed top-0 left-[150%] w-[100vw]">
                                              <ContactTableExport
                                                  item={item}
                                                  buttonClick={buttonClick1}
                                              />
                                          </div>
                                      </div>
                                  )
                              })}
                          </>
                      </>
                  )}
              </div>
              <div className="fixed top-0 left-[150%] w-[100vw]">
                  <ReportPateForExport
                      id={id}
                      buttonClick={buttonClick}
                      DateRange={dateRange}
                      DateType={dateType}
                      GetData={getData}
                  />
              </div>
          </div>
          <SmallScreenOverlay />
      </>
  )
};

export default Report;
