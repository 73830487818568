const subConfig = {
    local: "http://localhost:5000",
    production: process.env.REACT_APP_FETCHING_API,
    development: "https://feedback-v1-sandbox-be.vercel.app"
}
const config = {
    SERVER_URL: subConfig.production,
    Local_URL: subConfig.local

}

Object.freeze(config);

export default config