import React, { useState } from "react";
// import Switch from "react-input-switch"
import Switch from "react-switch";
import Button from "../../components/shared/Button";
import { Link, useNavigate } from "react-router-dom";
import axios from "../../axios";
import { toast } from "react-hot-toast";
import { IoMdArrowBack } from "react-icons/io";
import { useSelector } from "react-redux";
import bgImage from "../../assets/images/plans-bg.png";
import SmallScreenOverlay from "../SmallScreenOverlay";

const Payment1 = () => {
  const [isYearlyPlan, setIsYearlyPlan] = useState(true);
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.userAuth);

  const scrollTo = (id) => {
    if (window.location.pathname === "/") {
      document.getElementById(id).scrollIntoView({ behavior: "smooth" });
    } else {
      navigate("/");
      setTimeout(() => {
        document.getElementById(id).scrollIntoView({ behavior: "smooth" });
      }, 100);
    }
  };

  console.log(user);

  const handelPayment = async (e) => {
    if (user) {
      const { data } = await axios.post("/phonepe", {
        mobileNumber: user.phone_no,
        id: user.id,
        plan: isYearlyPlan ? "year" : "month",
      });
      if (data.success) {
        const url = data.data.instrumentResponse.redirectInfo.url
        if (url) {
          window.location.href = url;
        }
      }
    } else {
      return toast.error("Please login and pay");
    }
  };

  return (
    <>
    <div className="w-full min-h-full overflow-hidden sectionPadding pb-10 max-w-[105rem] pt-8 mx-auto relative max-lg:hidden">    

      <div className="absolute pointer-events-none hidden px-20 pt-20 lg:block top-[15%] l3:top-[10%] z-10 left-0 w-[100%] mx-auto h-full">
        <img
          src={bgImage}
          alt="background illustration"
          className=" w-full h-full object-contain"
        />
      </div>
      <h1 className=" text-2xl l2:text-4xl font-bold text-center">
        All features for one special price.
      </h1>
      

      <div className="ms:flex items-center mx-auto text-center w-full justify-center mt-8">
        <div className="flex justify-center ms:justify-start">
          <h6 className={`!text-lg ${isYearlyPlan && "text-[gray]"}`}>
            Monthly Plan
          </h6>
          {/* switch */}
          {/* <Switch
            // styles={{
            //     track: {
            //         width: "50px",
            //         height: "30px",
            //         borderRadius: "30px"
            //     },
            //     trackChecked: {
            //         backgroundColor: "#5046E5"
            //     },
            //     button: {
            //         width: "25px",
            //         height: "25px",
            //         borderRadius: "100%"
            //     },
            //     buttonChecked: {
            //         left: "22px"
            //     }
            // }}
            checkedIcon={false}
            uncheckedIcon={false}
            className="react-switch ml-4"
            onColor="#4F46E5"
            onHandleColor="#fff"
            handleDiameter={20}
            // className="mx-4"
            checked={isYearlyPlan}
            onChange={() => setIsYearlyPlan((prev) => !prev)}
          /> */}
        </div>

        <div className="flex justify-center ms:justify-start">
          <h6 className={`text-2xl l2:text-4xl font-bold text-center text-blue ml-5 ${!isYearlyPlan && "text-[gray]"}`}>
          Life Time Plan
          </h6>         
        </div>
      </div>

      <div className="w-full mt-10 scale-95 l3:scale-100  flex flex-wrap justify-center mx-0 relative">
        <div
          style={{
            boxShadow:
              "0px 6px 14px -6px rgba(24, 39, 75, 0.12), 0px 10px 32px -4px rgba(24, 39, 75, 0.1)",
          }}
          className=" relative z-0  sm:mt-0 w-[90%] ms:w-[70%] sm:w-[45%] lg:w-[31%] mx-auto lg:mx-0 bg-[#4f46e5] border-2 border-black rounded-lg "
        >
          {/* <span className="ribbon"></span> */}
          <div className=" p-[25px] bg-[#4f46e5] border-b border-black overflow-hidden">
            <h6 className="text-[#fff] text-lg font-semibold l3:text-xl">
              Spark
            </h6>
            <p className="text-[#fff] text-sm l3:text-base mt-2">
              One plan for all the your business needs.
            </p>
          </div>
          <div className=" p-4 l3:p-6">
            <div className="flex items-center">
              <span className="font-[poppins] text-4xl l3:text-[48px] font-[600] text-[#fff]">
                ₹ {!isYearlyPlan ? "3,999" : "39,999"}
              </span>
              <p className="ml-2 text-[14px] font-[poppins] text-[#fff]">
                /{isYearlyPlan ? "oneTime" : "month"}
              </p>
            </div>

            <button
              onClick={() => handelPayment(!isYearlyPlan ? 3999 : 39999)}
              className="secondaryButton hover:!text-blue hover:bg-white hover:ring-2 hover:!ring-white w-full my-6"
            >
              Get Started
            </button>

            <div>
              <span className="text-[#fff] font-[600]">SPARK FEATURES</span>
              <div className="mt-[10px] flex items-center">
                <div>
                  <i class="fa-solid fa-check text-primary text-[9px] bg-white rounded-full p-[4px]"></i>
                </div>
                <p className="ml-2 mb-[-6px] font-[poppins] text-[16px] text-[#fff]">
                  Unlimited responses
                </p>
              </div>
              <div className="mt-[10px] flex items-center">
                <div>
                  <i class="fa-solid fa-check text-primary text-[9px] bg-white rounded-full p-[4px]"></i>
                </div>
                <p className="ml-2 mb-[-6px] font-[poppins] text-[16px] text-[#fff]">
                  Unlimited Surveys
                </p>
              </div>
              <div className="mt-[10px] flex items-center">
                <div>
                  <i class="fa-solid fa-check text-primary text-[9px] bg-white rounded-full p-[4px]"></i>
                </div>
                <p className="ml-2 mb-[-6px] font-[poppins] text-[16px] text-[#fff]">
                  Unlimited questions
                </p>
              </div>
              <div className="mt-[10px] flex items-center">
                <div>
                  <i class="fa-solid fa-check text-primary text-[9px] bg-white rounded-full p-[4px]"></i>
                </div>
                <p className="ml-2 mb-[-6px] font-[poppins] text-[16px] text-[#fff]">
                  Unlimited email
                </p>
              </div>
              {/* <div className="mt-[10px] flex items-center">
                                <div>
                                    <i class="fa-solid fa-check text-primary text-[9px] bg-white rounded-full p-[4px]"></i>
                                </div>
                                <p className="ml-2 mb-[-6px] font-[poppins] text-[16px] text-[#fff]">Unlimited sms</p>
                            </div> */}
              <div className="mt-[10px] flex items-center">
                <div>
                  <i class="fa-solid fa-check text-primary text-[9px] bg-white rounded-full p-[4px]"></i>
                </div>
                <p className="ml-2 mb-[-6px] font-[poppins] text-[16px] text-[#fff]">
                  15 days free trial
                </p>
              </div>
              <div className="mt-[10px] flex items-center">
                <div>
                  <i class="fa-solid fa-check text-primary text-[9px] bg-white rounded-full p-[4px]"></i>
                </div>
                <p className="ml-2 mb-[-6px] font-[poppins] text-[16px] text-[#fff]">
                  No credit card required
                </p>
              </div>
              {/* <div className="mt-[10px] flex items-center">
                                <div>
                                    <i class="fa-solid fa-check text-primary text-[9px] bg-white rounded-full p-[4px]"></i>
                                </div>
                                <p className="ml-2 mb-[-6px] font-[poppins] text-[16px] text-[#fff]">Multiple Integration</p>
                            </div> */}
              <div className="mt-[10px] flex items-center">
                <div>
                  <i class="fa-solid fa-check text-primary text-[9px] bg-white rounded-full p-[4px]"></i>
                </div>
                <p className="ml-2 mb-[-6px] font-[poppins] text-[16px] text-[#fff]">
                  Dashboards & reports
                </p>
              </div>
              <div className="mt-[10px] flex items-center">
                <div>
                  <i class="fa-solid fa-check text-primary text-[9px] bg-white rounded-full p-[4px]"></i>
                </div>
                <p className="ml-2 mb-[-6px] font-[poppins] text-[16px] text-[#fff]">
                  Live Chat support
                </p>
              </div>
            </div>
          </div>
          {/* <div className="absolute top-0 left-[-380px] w-[400px] h-[630px] z-50">
                        <img
                            src={cardImage}
                            alt="man holding phone illustration"
                            className=" w-full h-full object-cover"
                        />
                    </div> */}
        </div>
      </div>
    </div>
    <SmallScreenOverlay />
    </>
  );
};

export default Payment1;
