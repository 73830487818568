/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { useDispatch } from "react-redux";
import { FileUploader } from "react-drag-drop-files";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { useRef } from "react";
import { X } from "react-feather";
import CustomDotLoader from './../CustomDotLoader/index'
import { setLogoValue } from "../../redux/slices/qrCodeLogo";

const BgImage = ({ close, reload, check }) => {
  const getSavedTemplate = JSON.parse(localStorage.getItem("qrcodeTemplate"));
  const [imageCode, setImageCode] = useState(null);
  const [preview, setPreview] = useState(null);
  const [mainPreview, setMainPreview] = useState(null);
  const [btn, setBtn] = useState(true);
  const [url, setImageUrl] = useState("");
  const [isEdit, setEditStatus] = useState(false);
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [qrTemp, setQrTemp] = useState(getSavedTemplate);

  const onClose = () => {
    setPreview(null);
  };


  const upload = async () => {
    try {
      setLoader(true);
      if (getSavedTemplate !== null) {
        const pre = qrTemp;
        pre.logo = preview;

        localStorage.setItem("qrcodeTemplate", JSON.stringify(pre));
      }
      setTimeout(() => {
        setLoader(false);
        dispatch(setLogoValue(preview));
        toast.success("Uploaded Successfully");
        setImageCode(null);
        onClose();
        close(false);
        setBtn(false);
        reload(check === true ? false : true);
      }, 500);
    } catch (error) {
      if (error.message === "Network Error") {
        setLoader(false);
        toast.error("File size too large");
      } else {
        setLoader(false);
        toast.error(error.message);
      }
    }
  };

  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let fileInfo;
      let baseURL = "";
      let reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onload = () => {
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };

  const handleChange = (file) => {
    const array = file.name.split(".");
    if (
      array[array.length - 1] == "png" ||
      array[array.length - 1] == "jpeg" ||
      array[array.length - 1] == "jpg"
    ) {
      setImageUrl(URL.createObjectURL(file));
      getBase64(file).then((result) => {
        setMainPreview(result);
        setPreview(result);
      });
    } else {
      toast.error("Support image format only");
    }
  };

  const cropperRef = useRef(null);
  const onCrop = () => {
    const imageElement = cropperRef?.current;
    const cropper = imageElement?.cropper;
    setPreview(cropper.getCroppedCanvas().toDataURL());
  };

  return (
    <div className=" fixed top-0 left-0 w-screen h-screen flex justify-center items-center z-[70]  bg-[#00000042]">
      <div className="bg-[#fafafa] min-w-[90%] mx-auto sm:min-w-[500px] min-h-[300px] py-2 sm:px-[10px] rounded-lg">
        <div className="relative">
          <button
            type="button"
            className="absolute -top-4 -right-4 w-6 h-6 text-white bg-[#4F46E5] rounded-full text-sm p-1 ml-auto inline-flex items-center justify-center  transition-all duration-300 z-50"
            data-modal-toggle="popup-modal"
            onClick={() => {
              setImageUrl("");
              setPreview(null);
              close(false);
            }}
          >
            <X size={25} />
          </button>
        </div>
        <div className="overflow-hidden upload min-w-full min-h-full flex flex-col items-center relative">
          <div
            className={`flex flex-col items-center absolute top-[50px] ${
              preview === null ? "block" : "hidden"
            }`}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="mb-2 w-10 h-10 mx-auto text-[#4F46E5]"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5m-13.5-9L12 3m0 0l4.5 4.5M12 3v13.5"
              />
            </svg>
            <p className="text-[16px] text-[#6d6d6d] font-semibold">
              Drag and Drop file to upload
            </p>
            <p className="text-[16px] text-[#666666] font-semibold mt-3">Or</p>
          </div>

          {url && (
            <div
              className={`${
                preview === null ? "hidden" : "block"
              } min-h-[300px] relative`}
            >
              {isEdit && (
                <div
                  onClick={() => {
                    setPreview(mainPreview);
                    setEditStatus(false);
                  }}
                  className="absolute top-3 cursor-pointer flex"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M15.75 19.5L8.25 12l7.5-7.5"
                    />
                  </svg>
                </div>
              )}

              <p className="text-[20px] py-2 pb-5 text-center font-semibold">
                {isEdit ? "Crop" : "Upload"} Image
              </p>
              {!isEdit && (
                <div className="min-w-[400px] mx-auto h-[200px] !border-[3px] !border-[#E4E4E7] rounded-xl flex justify-center items-center relative">
                  <img src={url} className="max-w-full max-h-[90%]" alt="" />
                  <svg
                    onClick={() => setEditStatus(true)}
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6 absolute top-2 text-[#4F46E5] cursor-pointer right-3"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                    />
                  </svg>
                </div>
              )}
              {isEdit && (
                <div className="max-w-[400px] mx-auto h-[220px] !border-[3px] !border-[#E4E4E7] rounded-xl flex justify-center items-center relative overflow-hidden">
                  <Cropper
                    src={url}
                    className="max-w-full h-[90%]"
                    initialAspectRatio={16 / 9}
                    crop={onCrop}
                    ref={cropperRef}
                  />
                </div>
              )}

              <div className="mt-10 mb-5 flex gap-4 justify-between min-w-[400px]">
                {!isEdit ? (
                  <button
                    onClick={() => {
                      setImageUrl("");
                      setPreview(null);
                      close(false);
                    }}
                    data-modal-toggle="popup-modal"
                    type="button"
                    className=" secondaryButton flex-1"
                  >
                    Cancel
                  </button>
                ) : (
                  <button
                    onClick={() => {
                      setPreview(mainPreview);
                      cropperRef.current.cropper.reset();
                    }}
                    data-modal-toggle="popup-modal"
                    type="button"
                    className=" secondaryButton flex-1"
                  >
                    Reset
                  </button>
                )}
                {!loader ? (
                  <button
                    data-modal-toggle="popup-modal"
                    type="button"
                    className=" primaryButton flex-1"
                    onClick={upload}
                  >
                    Save
                  </button>
                ) : (
                  <button
                    data-modal-toggle="popup-modal"
                    type="button"
                    className=" primaryButton flex-1"
                  >
                    <CustomDotLoader
                                          dotSize={50}
                                          isWhite
                                          className="mx-auto text-center"
                                      />
                  </button>
                )}
              </div>
            </div>
          )}

          <div
            className={`!z-50 mt-10 relative min-w-full ${
              preview === null ? "block" : "hidden"
            }`}
          >
            <FileUploader
              onDrop={handleChange}
              handleChange={handleChange}
              name="file"
              hoverTitle={"Drag here"}
              label="Browse"
            />
            <p className="absolute bottom-16 min-w-full text-center text-[#8a8a8d]">
              Supported file: JPEG, PNG Up to 4MB.
            </p>
          </div>
        </div>
      </div>
      <Toaster position="top-right" reverseOrder={false} />
    </div>
  );
};

export default BgImage;
