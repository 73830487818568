/* eslint-disable react-hooks/rules-of-hooks */
import React from "react";
import { useDispatch } from "react-redux";
import { setFeedbackValue } from "../../redux/slices/feedbackValue";
import α from "color-alpha";
import { useState } from "react";
import { RiCheckFill } from "react-icons/ri";
import { Check } from "react-feather";
import { useSelector } from "react-redux";
import { useEffect } from "react";
const radioButton = ({
  inputQuestion,
  id,
  questionType,
  answer,
  nextQuz,
  next,
  eventId,
  bg,
  color,
  textColor,
  setType,
  setSingle
}) => {
  const col = α(color, 0.2);
  const dispatch = useDispatch();
  const datas = useSelector((state) => state.feedbackValue.feedbackValue);


  const getValue = (answer) => {
    if (
      inputQuestion.length !== 0 &&
      answer.length !== 0 &&
      id.length !== 0 &&
      questionType.length !== 0 &&
      answer.length !== 0 &&
      eventId.length
    ) {
      let list = [...datas?.[0]?.list_of_items];
      list[id - 1] = {
        ...list[id - 1],
        answer: answer,
      };
      let allItem = { ...datas?.[0], list_of_items: list };
      dispatch(setFeedbackValue(allItem));
      

      return;
    }
  };

  useEffect(() => {
    setType("radio");
  }, [datas, setType]);

  
  // eslint-disable-next-line react-hooks/rules-of-hooks

  //bg-[url('https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg')]
  return (
    <div className=" mb-6 h-full w-full flex flex-col justify-center items-center  bg-no-repeat bg-cover bg-center bg-fixed">
      <h3
        style={{ color: `${textColor}`, wordBreak: "break-word" }}
        className="mb-8 text-center  flex items-center justify-center w-full max-w-3xl  break-words  text-xl sm:text-3xl font-semibold"
      >
        {inputQuestion}
      </h3>

      {answer.map((i) => (
        <div
          key={i.id}
          onClick={() => {
            getValue(i.id);
          }}
          style={{
            border: `3px solid ${
              datas?.[0]?.list_of_items[id - 1]?.answer == i?.id
                ? color
                : "#F7F7F7"
            }`,
            background: `${
              datas?.[0]?.list_of_items[id - 1]?.answer == i?.id
                ? col
                : "#F7F7F7"
            }`,
          }}
          className="relative flex items-center justify-center  opacity-80  rounded border border-gray dark:border-gray-700 w-full mt-4 !min-w-[25%] md:max-w-[25%] cursor-pointer"
        >
          <input
            id={i.id}
            type="radio"
            name="bordered-radio"
            style={{ opacity: "0" }}
            checked={
              datas?.[0]?.list_of_items[id - 1]?.answer == i?.id ? true : false
            }
            required
          />
          <label
            for={i.id}
            style={{
              color: `${
                datas?.[0]?.list_of_items[id - 1]?.answer == i?.id
                  ? "black"
                  : "#494949"
              }`,
              wordBreak: "break-word",
            }}
            className="py-4 w-[80%] sm:w-96 text-base font-medium text-[black] cursor-pointer"
          >
            {i.value}
          </label>
          {datas?.[0]?.list_of_items[id - 1]?.answer == i?.id && (
            <Check
              style={{ color: `${color}` }}
              className="absolute right-3 top-4 "
              size={25}
            />
          )}
        </div>
      ))}
    </div>
  );
};

export default radioButton;
