import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  bgColor: null,
  color: null,
};

const bgSlice = createSlice({
  name: "bg",
  initialState,
  reducers: {
    setBgValue(state, action) {
      state.bgColor = action.payload;
    },
    setColor(state, action) {
      state.color = action.payload;
    },
    setClear(state, action) {
      state.bgColor = null;
      state.color = null;
    },
  },
});

export const { setBgValue, setColor, setClear } = bgSlice.actions;
export default bgSlice.reducer;
