// ** Router imports
import { useRoutes } from "react-router-dom"

// ** Route Components
import PublicRoute from "../security/PublicRoute"
import PrivateRoute from "../security/PrivateRoute"
import Dashboard from "../pages/Dashboard"
import Events from "../pages/Events/Event"
import AddQuestions from "../pages/addQuestions"
import Share from "../pages/share"
import Success from "../pages/success/Success"
import Login from "../pages/auth/signIn"
import SignUp from "../pages/auth/signUp"
import Verify from "../pages/auth/Otp"
import ForgotPassword from "../pages/auth/ForgotPassword"
import Account from "../pages/Account"
import Report from "../pages/Report/index"
import Payment from "../pages/payment/payment"
import PaymentRedirect from "../pages/payment/Payment1"
import Reports from "../pages/reports/Reports"
import GlobalSettings from "../pages/GlobalSettings/GlobalSettings"
import SocialStep from "../pages/auth/SocialLoginStep/SocialLoginStep"
import DownloadInvoice from "../pages/Account/DownloadInvoice"

const Router = () => {
    const route = [
        {
            element: <PrivateRoute />,
            children: [
                { path: "/", element: <Dashboard /> },
                { path: "/events", element: <Events /> },
                { path: "/invoice", element: <DownloadInvoice /> },
                { path: "/addquestions", element: <AddQuestions /> },
                { path: "/account", element: <Account /> },
                { path: "/report/:id", element: <Report /> },
                { path: "/payment", element: <Payment /> },
                { path: "/PaymentRedirect", element: <PaymentRedirect /> },
                { path: "/reports", element: <Reports /> },
                { path: "/global-settings", element: <GlobalSettings /> }
            ]
        },
        {
            element: <PublicRoute />,
            children: [
                { path: "/login", element: <Login /> },
                { path: "/signup", element: <SignUp /> },
                { path: "/forgot-pass", element: <ForgotPassword /> },
                { path: "/verify", element: <Verify /> },
                { path: "/:eventId", element: <Share /> },
                { path: "/success/:cl/:bg/:id", element: <Success /> },
                {
                    path: "/socialStep",
                    element: <SocialStep />
                }
            ]
        }
    ]

    const routes = useRoutes(route)

    return routes
}

export default Router
