/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState, useRef } from "react";
import { MdAdd, MdFormatAlignRight } from "react-icons/md";
import {
  BsEmojiSmile,
  BsFillCheckSquareFill,
  BsList,
  BsSpeedometer2,
  BsStarHalf,
} from "react-icons/bs";
import TypesOfQuestions from "../TypeOfQuestion/typeOfQuestions";
import toast, { Toaster } from "react-hot-toast";
import AnswerBox from "./answerBox";
import { useDispatch, useSelector } from "react-redux";
import {
  newArrayQuestion,
  removeQuestion,
  setQuestionValue,
  setArrayValue,
  logoDetails,
  bgImageDetails,
  editValues,
  setButtonColor,
  setTextColor,
  setWelcomeScreenTextColor,
} from "../../redux/slices/questions";
import { IoMdRadioButtonOn } from "react-icons/io";
import { FaAddressCard } from "react-icons/fa";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import { useSearchParams } from "react-router-dom";
import axios from "../../axios";
import { setEventValue } from "../../redux/slices/eventName";
import CustomDotLoader from "../../components/CustomDotLoader";

import { AiOutlineClose } from "react-icons/ai";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { VscGripper } from "react-icons/vsc";
import { ChevronUp, FileText, PlayCircle } from "react-feather";
import Contact from "./Contact";
import { TbDownload } from "react-icons/tb";

// website tour
import { driver } from "driver.js";
import "driver.js/dist/driver.css";

const addQuestions = ({
  questionTypes,
  getId,
  popup,
  id,
  quType,
  loader,
  create,
  loadDetails,
  Status,
  ViewStatus,
  errorHighlight,
  event,
}) => {
  const [addQuestions, setAddQuestions] = useState([]);
  const [typeOfQuestions, setTypeOfQuestions] = useState(false);
  const [show, setShow] = useState(false);
  const [boxId, setBoxId] = useState(null);
  const [questionType, setQuestionType] = useState(null);
  const { bgColor } = useSelector((state) => state.bg);
  const { questionValues } = useSelector((state) => state.questions);
  const [values, setValues] = useState("");
  const [values1, setValues1] = useState("");
  const { eventName } = useSelector((state) => state.eventName);
  const dispatch = useDispatch();
  const [inputValue, setinputValue] = useState("");
  const [subHeading, setSubHeading] = useState("");
  const [temp, setTemp] = useState({ id: "", type: "" });
  const [temp1, setTemp1] = useState({ id: "", type: "" });

  const [status, setStatus] = useState(false);
  const [deleteBox, setDeleteBox] = useState("");
  const [searchParams] = useSearchParams();
  const ID = searchParams.get("id");
  const EventName = searchParams.get("name");
  const { mood } = useSelector((state) => state.mood);
  const [intputStatus, setInputStatus] = useState(false);
  const { user } = useSelector((state) => state.userAuth);
  const [load, setLoad] = useState(false);
  const addQuestion_addButton = localStorage.getItem("addQuestion_addButton");

  // const { companyLogo } = useSelector((state) => state.globalDefine);
  // const { welcomeBg } = useSelector((state) => state.globalDefine);\

  window.onbeforeunload = function () {
    localStorage.removeItem("reRender");
    localStorage.removeItem("brandRender");
    return "Are you sure you want to refresh the page?";
  };
  // website tour
  const driverObj = useRef(driver({ allowClose: false, showProgress: false }));

  useEffect(() => {
    if (user?.initialLogin && addQuestion_addButton === null) {
      // const tourCompleted = localStorage.getItem("addQuestion_tourCompleted");

      // if (tourCompleted) return;

      driverObj?.current?.highlight({
        element: "#addQuestion_addButton",
        popover: {
          title: "Add Questions",
          description:
            "Click here to add various types of questions to your Survey survey.",
          side: "top",
          align: "center",
        },
      });

      localStorage.setItem("addQuestion_addButton", false);
    }
  }, [user]);

  useEffect(() => {
    setAddQuestions(questionValues);
  }, [questionValues]);

  useEffect(() => {
    const getRerender = localStorage.getItem("reRender");
    if (ID !== null && getRerender === null) {
      dispatch(setEventValue(EventName));
      localStorage.setItem("reRender", false);
      const Api = async () => {
        setLoad(true);
        const { data } = await axios.post("/getParticularEvent", {
          shortID: ID,
        });
        localStorage.setItem("uuid", data[0].id);

        dispatch(
          setButtonColor({
            buttonColor:
              data?.[0]?.buttonColor?.length === 0
                ? null
                : data?.[0]?.buttonColor,
          })
        );
        dispatch(
          setTextColor({
            textColor:
              data?.[0]?.textColor?.length === 0 ? null : data?.[0]?.textColor,
          })
        );

        dispatch(
          setWelcomeScreenTextColor({
            welcomeScreenTextColor:
              data?.[0]?.welcomeTextColor?.length === 0
                ? null
                : data?.[0]?.welcomeTextColor,
          })
        );
        dispatch(editValues(data[0].list_of_items));
        localStorage.setItem(
          "Oldquestion",
          JSON.stringify(data[0].list_of_items)
        );
        await data[0].list_of_items.map((i) => {
          if (i.type === "welcome") {
            return dispatch(
              setQuestionValue({
                id: i.id,
                question: i.question,
                subHeading:
                  i.subHeading !== undefined && i.subHeading !== null
                    ? i.subHeading
                    : "",
                type: i.type,
                choice: i.answer !== undefined && i.answer,
                uuid: i.uuid,
              })
            );
          } else {
            return dispatch(
              setQuestionValue({
                id: i.id,
                question: i.question,
                type: i.type,
                choice: i.answer !== undefined && i.answer,
                uuid: i.uuid,
              })
            );
          }
        });
        const datas = await axios.post("/get-logo", {
          eventId: ID,
        });

        const logo = datas.data.data.filter((i) => i.status === "logoImage");
        const bg = datas.data.data.filter((i) => i.status === "bgImage");

        dispatch(logoDetails(logo));
        dispatch(bgImageDetails(bg));
        setLoad(false);
      };
      Api();
    }
  }, [ID, EventName]);

  useEffect(() => {
    const storeQuestion = (id, typeOfPreview) => {
      const SubHeading = questionValues.filter(
        (i) => i.id == id && i.type === typeOfPreview
      );
      if (id && typeOfPreview) {
        if (typeOfPreview === "welcome") {
          dispatch(
            setQuestionValue({
              id: id,
              question: inputValue,
              type: typeOfPreview,
              subHeading: SubHeading[0].subHeading,
            })
          );
        } else {
          dispatch(
            setQuestionValue({
              id: id,
              question: inputValue,
              type: typeOfPreview,
            })
          );
        }
      }
    };

    storeQuestion(temp.id, temp.type);
  }, [inputValue, temp]);

  useEffect(() => {
    const storeQuestion = (id, typeOfPreview) => {
      const Heading = questionValues.filter(
        (i) => i.id == id && i.type === typeOfPreview
      );
      if (id && typeOfPreview) {
        dispatch(
          setQuestionValue({
            id: id,
            question: Heading[0].question,
            type: typeOfPreview,
            subHeading: subHeading,
          })
        );
      }
    };

    storeQuestion(temp1.id, temp1.type);
  }, [subHeading, temp1]);

  const getReduxValue = (e, id) => {
    const filter = questionValues.filter((i) => i.id == id && i.type === e);

    if (filter?.length === 0) {
      setValues("");
      return;
    }
    setValues(filter[0].question);
    setValues1(filter[0].subHeading);
  };

  const showBox = (i) => {
    setBoxId(i);
    getId(i);
  };

  const remove = (i) => {
    dispatch(removeQuestion({ id: i }));
  };

  const addValue = (i) => {
    if (questionValues?.length > 0) {
      const addValues = questionValues.slice(-1)[0].id;
      let newValue = Number(addValues) + 1;
      setShow(true);
      showBox(newValue.toString());
      questionTypes(i);
      return dispatch(
        i == "radioButton" || i == "multiSelect"
          ? setQuestionValue({
              id: newValue.toString(),
              question: "",
              type: i,
              choice: [
                { id: "1", value: "" },
                { id: "2", value: "" },
              ],
            })
          : i == "contact"
          ? setQuestionValue({
              id: newValue.toString(),
              question: "",
              type: i,
              choice: [
                {
                  uniqueID: "01",
                  id: "1",
                  value: "",
                  placeholder: "Name",
                  type: "text",
                },
              ],
            })
          : i == "welcome"
          ? setQuestionValue({
              id: newValue.toString(),
              question: "",
              type: i,
              subHeading: "",
            })
          : setQuestionValue({
              id: newValue.toString(),
              question: "",
              type: i,
            })
      );
    }
    const addValues = questionValues?.length;
    let newValue = addValues + 1;
    setShow(true);
    showBox(newValue.toString());
    questionTypes(i);

    return dispatch(
      i == "radioButton" || i == "multiSelect"
        ? setQuestionValue({
            id: newValue.toString(),
            question: "",
            type: i,
            choice: [
              { id: "1", value: "" },
              { id: "2", value: "" },
            ],
          })
        : i == "contact"
        ? setQuestionValue({
            id: newValue.toString(),
            question: "",
            type: i,
            choice: [
              {
                uniqueID: "01",
                id: "1",
                value: "",
                placeholder: "Name",
                type: "text",
              },
            ],
          })
        : i == "welcome"
        ? setQuestionValue({
            id: newValue.toString(),
            question: "",
            type: i,
            subHeading: "",
          })
        : setQuestionValue({
            id: newValue.toString(),
            question: "",
            type: i,
          })
    );
  };

  const loop = (id, type) => {
    if (type === "Nsp") {
      let newArray = [];
      for (let i = 0; i < 10; i++) {
        let num = i + 1;
        newArray.push({ id: num.toString(), value: num.toString() });
      }
      return dispatch(
        setArrayValue({
          id: id,
          choice: newArray,
          type: type,
        })
      );
    }
    let newArray = [];
    for (let i = 0; i < 5; i++) {
      let num = i + 1;
      newArray.push({ id: num.toString(), value: num.toString() });
    }
    return dispatch(
      setArrayValue({
        id: id,
        choice: newArray,
        type: type,
      })
    );
    // setRate(newArray);
  };

  const handelChange = (e) => {
    if (!e.destination) return;
    const items = Array.from(addQuestions);
    const [reorderedItem] = items.splice(e.source.index, 1);
    items.splice(e.destination.index, 0, reorderedItem);
    dispatch(newArrayQuestion(items));
  };

  const [addAns, setAns] = useState(null);
  const [addM, setM] = useState(true);
  const addA = () => {
    if (addAns === null) {
      setAns(1 + 1);
    } else {
      setAns(1 + 1);
    }
  };

  useEffect(() => {
    if (ID !== null && !quType && !id) {
      if (questionValues?.length !== 0) {
        questionTypes(questionValues[0].type);
        getId(questionValues[0].id);
      }
    }
  }, [ID, questionValues]);

  const openBox = () => {
    setTypeOfQuestions((prev) => !prev);
  };

  const duplicateQuestion = (i) => {
    const data = {
      ...i,
      id: (addQuestions?.length + 1).toString(),
    };

    console.log("data", data);
    dispatch(setQuestionValue(data));
    setStatus(false);
  };

  // document.body.addEventListener("click", () => {
  //   setStatus(false);
  // });
  return (
    <div
      className={` w-[40%] h-[calc(100vh-105px)] relative  flex flex-col items-center pt-5 lg:border-r border-[#E4E4E7] bg-[#f4f4f4]`}
    >
      {ID !== null && load ? (
        <>
          <div className="w-full h-full flex items-center justify-center absolute">
            <CustomDotLoader dotSize={120} />
          </div>
        </>
      ) : (
        <>
          <div className="w-full flex-1 overflow-y-scroll px-8">
            <DragDropContext onDragEnd={handelChange}>
              <Droppable droppableId="addQuestions">
                {(provided, idx) => (
                  <div
                    key={idx}
                    className="addQuestions"
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    onClick={(e) => {
                      e.stopPropagation();
                      setInputStatus(!intputStatus);
                      setStatus(false);
                    }}
                  >
                    {addQuestions.map((i, index) => (
                      <div className="flex items-center ">
                        <Draggable key={i.id} draggableId={i.id} index={index}>
                          {(provided) => (
                            <div
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              ref={provided.innerRef}
                            >
                              <VscGripper
                                size={30}
                                className="text-gray hover:text-black"
                              />
                            </div>
                          )}
                        </Draggable>

                        <div
                          style={{
                            boxShadow: `${
                              mood === "light"
                                ? "0px 2px 8px rgba(0, 0, 0, 0.15)"
                                : "0px 2px 8px #2f3b5c"
                            }`,
                          }}
                          className={`${
                            errorHighlight !== undefined &&
                            errorHighlight.id == index + 1 &&
                            " border-2 border-red"
                          } flex items-center bg-white w-full  my-2 mr-6 lg:mr-0 rounded-md`}
                        >
                          <p
                            className={
                              show && boxId == i.id
                                ? "hidden"
                                : `flex justify-center items-center px-1`
                            }
                          >
                            {i.type === "radioButton" && (
                              <IoMdRadioButtonOn
                                color={`${
                                  mood === "light" ? "#4F46E5" : "white"
                                }`}
                                size={25}
                                className={`mx-2 my-2.5`}
                              />
                            )}
                            {i.type === "multiSelect" && (
                              <BsFillCheckSquareFill
                                size={25}
                                color={`${
                                  mood === "light" ? "#4F46E5" : "white"
                                }`}
                                className={` mx-2 my-2.5`}
                                style={{
                                  color: `${bgColor !== null && bgColor}`,
                                }}
                              />
                            )}
                            {i.type === "starRating" && (
                              <BsStarHalf
                                size={25}
                                color={`${
                                  mood === "light" ? "#4F46E5" : "white"
                                }`}
                                className={`  mx-2 my-2.5`}
                              />
                            )}
                            {i.type === "smileyScale" && (
                              <BsEmojiSmile
                                size={25}
                                color={`${
                                  mood === "light" ? "#4F46E5" : "white"
                                }`}
                                className={` mx-2 my-2.5`}
                              />
                            )}
                            {i.type === "Nsp" && (
                              <BsSpeedometer2
                                size={25}
                                className={` text-pink mx-2 my-2.5`}
                                color={`${
                                  mood === "light" ? "#4F46E5" : "white"
                                }`}
                              />
                            )}
                            {i.type === "dropDown" && (
                              <BsList
                                size={25}
                                className={` mx-2 my-2.5`}
                                color={`${
                                  mood === "light" ? "#4F46E5" : "white"
                                }`}
                              />
                            )}
                            {i.type === "textAnswer" && (
                              <MdFormatAlignRight
                                size={25}
                                className={` mx-2 my-2.5`}
                                color={`${
                                  mood === "light" ? "#4F46E5" : "white"
                                }`}
                              />
                            )}
                            {i.type === "formContent" && (
                              <FaAddressCard
                                size={25}
                                className={` mx-2 my-2.5`}
                                color={`${
                                  mood === "light" ? "#4F46E5" : "white"
                                }`}
                              />
                            )}
                            {i.type === "welcome" && (
                              <PlayCircle
                                size={25}
                                className={` mx-2 my-2.5`}
                                color={`${
                                  mood === "light" ? "#4F46E5" : "white"
                                }`}
                              />
                            )}
                            {i.type === "contact" && (
                              <FileText
                                size={25}
                                className={` mx-2 my-2.5`}
                                color={`${
                                  mood === "light" ? "#4F46E5" : "white"
                                }`}
                              />
                            )}

                            <span
                              className={`font-inter text-[16px] px-1 ${
                                mood === "light"
                                  ? "text-[#4F46E5]"
                                  : "text-white"
                              }`}
                            >
                              {index + 1 > 9 ? index + 1 : `0${index + 1}`}
                            </span>
                          </p>
                          <div
                            className="flex flex-1 !py-0 outline-0 rounded relative"
                            onClick={() => {
                              questionTypes(i.type);
                              setQuestionType(i.type);
                              getReduxValue(i.type, i.id);
                            }}
                          >
                            <p
                              className={
                                show && boxId == i.id
                                  ? "hidden"
                                  : ` ${
                                      mood === "light"
                                        ? "text-[#18181B]"
                                        : "text-gray pl-4"
                                    } pl-3 font-[500] font-inter capitalize text-[16px] w-full h-full flex justify-between items-center`
                              }
                              onClick={() => {
                                setShow(true);
                                showBox(i.id);
                              }}
                            >
                              <p>
                                {i.type === "radioButton"
                                  ? "SingleSelect"
                                  : i.type === "Nsp"
                                  ? "NPS"
                                  : i.type}
                              </p>

                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="w-5 h-5 mr-2"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                                />
                              </svg>
                            </p>

                            {show && boxId == i.id && (
                              <>
                                <div className="!rounded-xl w-full">
                                  {/* <div className="flex items-center">
                                         <p
                                           className={`${bgColor === null && `text-blue`}
                                               font-bold text-lg capitalize`}
                                         >
                                           {i.type}
                                         </p>
                                       </div> */}
                                  <div className={`flex justify-between`}>
                                    <div className="flex items-center">
                                      <p
                                        className={`flex py-[11px] ml-1 pr-1 items-center font-[500] font-inter capitalize text-[16px] ${
                                          mood === "light"
                                            ? "text-black"
                                            : "text-white"
                                        }`}
                                      >
                                        <span className="mt-[-3px]">
                                          {i.type === "radioButton" && (
                                            <IoMdRadioButtonOn
                                              color={`${
                                                mood === "light"
                                                  ? "#4F46E5"
                                                  : "white"
                                              }`}
                                              size={25}
                                              className={`mx-2 `}
                                            />
                                          )}
                                          {i.type === "multiSelect" && (
                                            <BsFillCheckSquareFill
                                              size={25}
                                              color={`${
                                                mood === "light"
                                                  ? "#4F46E5"
                                                  : "white"
                                              }`}
                                              className={` mx-2 `}
                                              style={{
                                                color: `${
                                                  bgColor !== null && bgColor
                                                }`,
                                              }}
                                            />
                                          )}
                                          {i.type === "contact" && (
                                            <FileText
                                              size={25}
                                              className={` mx-2`}
                                              color={`${
                                                mood === "light"
                                                  ? "#4F46E5"
                                                  : "white"
                                              }`}
                                            />
                                          )}
                                          {i.type === "starRating" && (
                                            <BsStarHalf
                                              size={25}
                                              color={`${
                                                mood === "light"
                                                  ? "#4F46E5"
                                                  : "white"
                                              }`}
                                              className={`  mx-2 `}
                                            />
                                          )}
                                          {i.type === "smileyScale" && (
                                            <BsEmojiSmile
                                              size={25}
                                              color={`${
                                                mood === "light"
                                                  ? "#4F46E5"
                                                  : "white"
                                              }`}
                                              className={` mx-2 `}
                                            />
                                          )}
                                          {i.type === "Nsp" && (
                                            <BsSpeedometer2
                                              size={25}
                                              className={` text-pink mx-2 `}
                                              color={`${
                                                mood === "light"
                                                  ? "#4F46E5"
                                                  : "white"
                                              }`}
                                            />
                                          )}
                                          {i.type === "dropDown" && (
                                            <BsList
                                              size={25}
                                              className={` mx-2`}
                                              color={`${
                                                mood === "light"
                                                  ? "#4F46E5"
                                                  : "white"
                                              }`}
                                            />
                                          )}
                                          {i.type === "textAnswer" && (
                                            <MdFormatAlignRight
                                              size={25}
                                              className={` mx-2 `}
                                              color={`${
                                                mood === "light"
                                                  ? "#4F46E5"
                                                  : "white"
                                              }`}
                                            />
                                          )}
                                          {i.type === "formContent" && (
                                            <FaAddressCard
                                              size={25}
                                              className={` mx-2 `}
                                              color={`${
                                                mood === "light"
                                                  ? "#4F46E5"
                                                  : "white"
                                              }`}
                                            />
                                          )}
                                          {i.type === "welcome" && (
                                            <PlayCircle
                                              size={25}
                                              className={` mx-2 `}
                                              color={`${
                                                mood === "light"
                                                  ? "#4F46E5"
                                                  : "white"
                                              }`}
                                            />
                                          )}
                                        </span>
                                        <span className="px-1 text-[#4F46E5]">
                                          {index + 1 > 9
                                            ? index + 1
                                            : `0${index + 1}`}
                                        </span>
                                      </p>
                                      <p className="capitalize ml-3 text-[#18181B] font-inter font-[500]">
                                        {/* {index + 1 > 10 ? index + 1 : `0${index + 1}`} */}
                                        {i.type === "radioButton"
                                          ? "SingleSelect"
                                          : i.type === "contact"
                                          ? "Contact from"
                                          : i.type === "Nsp"
                                          ? "NPS"
                                          : i.type}
                                      </p>
                                    </div>
                                    <div className="flex items-center h-full mt-2">
                                      <BiDotsHorizontalRounded
                                        size={25}
                                        className={`cursor-pointer mr-1 ${
                                          mood === "light"
                                            ? "text-black hover:text-gray"
                                            : "text-white hover:text-gray"
                                        }`}
                                        onClick={(e) => {
                                          // index + 1 == i.id && setShow(false);
                                          e.stopPropagation();
                                          setStatus(!status);
                                          setDeleteBox(i.id);
                                        }}
                                      />
                                      <ChevronUp
                                        size={23}
                                        className={`mr-1.5 cursor-pointer ${
                                          mood === "light"
                                            ? "text-black hover:text-gray"
                                            : "text-white hover:text-gray"
                                        }`}
                                        onClick={() => {
                                          setShow(!show);
                                        }}
                                      />
                                    </div>
                                  </div>

                                  <div className="px-7 pb-4">
                                    <div className="flex items-center justify-between pt-2">
                                      <div className="flex items-center">
                                        <p
                                          className={`flex items-center font-[300] font-inter capitalize text-[16px] ${
                                            mood === "light"
                                              ? "text-black"
                                              : "text-white"
                                          }`}
                                        >
                                          {i.type === "contact"
                                            ? "Heading"
                                            : i.type === "welcome"
                                            ? "Title"
                                            : "Question"}
                                        </p>
                                      </div>
                                    </div>
                                    {status && deleteBox === i.id && (
                                      <>
                                        {boxId == i.id && (
                                          <div
                                            style={{
                                              boxShadow: `${
                                                mood === "light"
                                                  ? "0px 2px 8px rgba(0, 0, 0, 0.15)"
                                                  : "0px 2px 8px #2f3b5c"
                                              }`,
                                            }}
                                            className={`w-40 py-3 rounded absolute z-30 top-[35px] text-start right-8 -mt-1 transition-all duration-500 px-3 font-inter ${
                                              mood === "light"
                                                ? "bg-white text-black"
                                                : "border border-[#2f3b5c] bg-[#161A25] text-white"
                                            } `}
                                          >
                                            {i.type !== "contact" && (
                                              <p
                                                className="px-3 rounded-sm  blueHover py-1.5 font-[500] capitalize cursor-pointer font-inter !text-start"
                                                onClick={() => {
                                                  duplicateQuestion(i);
                                                }}
                                              >
                                                Duplicate
                                              </p>
                                            )}

                                            <p
                                              className="px-3 rounded-sm blueHover py-1.5 font-[500] capitalize cursor-pointer font-inter !text-start"
                                              onClick={() => {
                                                remove(i.id);
                                                setStatus(false);
                                              }}
                                            >
                                              delete
                                            </p>
                                          </div>
                                        )}
                                      </>
                                    )}
                                    <div
                                      className={`flex items-center py-3 my-3  px-3 outline-0 font-medium w-full bg-white  text-base text-[#000000] border border-[#D4D4D8] rounded-lg focus-within:border-[#4F46E5]`}
                                    >
                                      <input
                                        data-tut="five-step"
                                        type="text"
                                        placeholder={
                                          i.type === "contact"
                                            ? "Enter Heading"
                                            : "Enter your question here."
                                        }
                                        // style={{
                                        //     boxShadow: `${
                                        //         mood === "light"
                                        //             ? "0px 2px 8px rgba(0, 0, 0, 0.20)"
                                        //             : "0px 2px 8px #2f3b5c"
                                        //     }`
                                        // }}
                                        maxLength={300}
                                        className=" border-0 outline-none px-3 w-[95%]"
                                        onChange={(e) => {
                                          (i.id == index + 1 &&
                                            i.type === "radioButton" &&
                                            setinputValue(e.target.value) &
                                              setTemp({
                                                id: i.id,
                                                type: i.type,
                                              })) ||
                                            (i.id == index + 1 &&
                                              i.type === "welcome" &&
                                              setinputValue(e.target.value) &
                                                setTemp({
                                                  id: i.id,
                                                  type: i.type,
                                                })) ||
                                            (i.id == index + 1 &&
                                              i.type === "contact" &&
                                              setinputValue(e.target.value) &
                                                setTemp({
                                                  id: i.id,
                                                  type: i.type,
                                                })) ||
                                            (i.id == index + 1 &&
                                              i.type === "multiSelect" &&
                                              setinputValue(e.target.value) &
                                                setTemp({
                                                  id: i.id,
                                                  type: i.type,
                                                })) ||
                                            (i.id == index + 1 &&
                                              i.type === "smileyScale" &&
                                              setinputValue(e.target.value) &
                                                setTemp({
                                                  id: i.id,
                                                  type: i.type,
                                                }) &
                                                loop(i.id, i.type)) ||
                                            (i.id == index + 1 &&
                                              i.type === "starRating" &&
                                              setinputValue(e.target.value) &
                                                setTemp({
                                                  id: i.id,
                                                  type: i.type,
                                                }) &
                                                loop(i.id, i.type)) ||
                                            (i.id == index + 1 &&
                                              i.type === "dropDown" &&
                                              setinputValue(e.target.value) &
                                                setTemp({
                                                  id: i.id,
                                                  type: i.type,
                                                })) ||
                                            (i.id == index + 1 &&
                                              i.type === "Nsp" &&
                                              setinputValue(e.target.value) &
                                                setTemp({
                                                  id: i.id,
                                                  type: i.type,
                                                }) &
                                                loop(i.id, i.type)) ||
                                            (i.id == index + 1 &&
                                              i.type === "formContent" &&
                                              setinputValue(e.target.value) &
                                                setTemp({
                                                  id: i.id,
                                                  type: i.type,
                                                })) ||
                                            (i.id == index + 1 &&
                                              i.type === "textAnswer" &&
                                              setinputValue(e.target.value));
                                        }}
                                        Value={values}
                                        defaultValue={
                                          i.id == index + 1 && i.question
                                        }
                                      />
                                      <p className=" text-gray">
                                        {i.id == index + 1 &&
                                          i.question?.length}
                                        /300
                                      </p>
                                    </div>

                                    {/* subHeading */}
                                    {i.type === "welcome" && (
                                      <>
                                        <div>
                                          <p
                                            className={`flex items-center font-[300] font-inter capitalize text-[16px] ${
                                              mood === "light"
                                                ? "text-black"
                                                : "text-white"
                                            }`}
                                          >
                                            Subheading
                                          </p>
                                        </div>
                                        <div
                                          className={`flex items-center py-3 my-3  px-3 outline-0 font-medium w-full bg-white  text-base text-[#000000] border border-[#D4D4D8] rounded-lg focus-within:border-[#4F46E5]`}
                                        >
                                          <input
                                            data-tut="five-step"
                                            type="text"
                                            placeholder={
                                              i.type === "welcome"
                                                ? "Enter your subheading"
                                                : "Enter your question here."
                                            }
                                            maxLength={100}
                                            className=" border-0 outline-none px-3 w-[95%]"
                                            onChange={(e) => {
                                              (i.id == index + 1 &&
                                                i.type === "welcome" &&
                                                setSubHeading(e.target.value) &
                                                  setTemp1({
                                                    id: i.id,
                                                    type: i.type,
                                                  })) ||
                                                (i.id == index + 1 &&
                                                  i.type === "textAnswer" &&
                                                  setinputValue(
                                                    e.target.value
                                                  ));
                                            }}
                                            Value={values1}
                                            defaultValue={
                                              i.id == index + 1 && i.subHeading
                                            }
                                          />

                                          <p className=" text-gray">
                                            {i.id == index + 1 &&
                                              i.subHeading?.length}
                                            /100
                                          </p>
                                        </div>
                                      </>
                                    )}

                                    {/* answer */}

                                    {i.type === "multiSelect" && (
                                      <div className="relative">
                                        <p
                                          className={`font-[300] font-inter capitalize text-[16px] flex justify-between ${
                                            mood === "light"
                                              ? "text-black"
                                              : "text-white"
                                          }`}
                                        >
                                          <span>Your Answer</span>
                                        </p>
                                        <AnswerBox
                                          boxId={boxId}
                                          type={questionType}
                                          addAnswer={addAns}
                                        />
                                      </div>
                                    )}
                                    {i.type === "radioButton" && (
                                      <div className="relative">
                                        <p
                                          className={`font-[300] font-inter capitalize text-[16px] flex justify-between mt-2 ${
                                            mood === "light"
                                              ? "text-black"
                                              : "text-white"
                                          }`}
                                        >
                                          <span>Your Answer</span>
                                        </p>
                                        <AnswerBox
                                          boxId={boxId}
                                          type={questionType}
                                          addAnswer={addAns}
                                        />
                                      </div>
                                    )}
                                    {i.type === "contact" && (
                                      <div className="relative">
                                        <p
                                          className={`font-[500] font-inter capitalize text-[16px] flex justify-between ${
                                            mood === "light"
                                              ? "text-black"
                                              : "text-white"
                                          }`}
                                        >
                                          <span>Contact details</span>
                                        </p>
                                        <Contact
                                          intputStatus={intputStatus}
                                          boxId={boxId}
                                          type={questionType}
                                          addAnswer={addAns}
                                        />
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
          <div className=" w-full px-8 relative bg-white py-3">
            <div className="flex gap-4 items-center justify-between space-x-4">
              <button
                id="addQuestion_addButton"
                data-tut="third-step"
                className={` primaryButton flex-1 gap-2 ${
                  eventName === null || eventName === ""
                    ? "bg-[#8a8af0]"
                    : "bg-blue"
                }`}
                onClick={() => {
                  if (eventName === null)
                    return toast.error("Added: new Survey");
                  openBox();
                }}
                disabled={eventName === null ? true : false}
              >
                <MdAdd size={26} />
                Add Question
              </button>

              {!loader ? (
                <button
                  data-tut="six-step"
                  type="button"
                  className={` secondaryButton flex-1 gap-2 ${
                    eventName === null || eventName === ""
                      ? "bg-[#8a8af0]"
                      : "bg-blue"
                  }`}
                  onClick={() => {
                    create(true);
                    loadDetails(questionValues?.length === 0 ? false : true);
                    // setIsOpen(false)
                    Status(ViewStatus === true ? false : true);
                    localStorage.setItem("Published", true);
                  }}
                >
                  <TbDownload size={24} />
                  {ID !== null ? "Update Survey" : "Publish Survey"}
                </button>
              ) : (
                <button
                  type="button"
                  className={` secondaryButton flex-1 gap-2 ${
                    eventName === null || eventName === ""
                      ? "bg-[#8a8af0]"
                      : "bg-blue"
                  }`}
                >
                  <CustomDotLoader
                    dotSize={50}
                    isWhite
                    className="overflow-hidden mx-auto"
                  />
                </button>
              )}
            </div>

            {typeOfQuestions && (
              <>
                <TypesOfQuestions
                  driverObj={driverObj}
                  addValue={addValue}
                  close={setTypeOfQuestions}
                />
              </>
            )}
          </div>
        </>
      )}

      <Toaster position="top-right" reverseOrder={false} />
    </div>
  );
};

export default addQuestions;
