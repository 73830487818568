/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from "react"
import { MdAdd } from "react-icons/md"
import { AiOutlineMinusCircle } from "react-icons/ai"
import { useDispatch, useSelector } from "react-redux"
import { newArrayAnswer, setArrayValue } from "../../redux/slices/questions"
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd"
import { VscGripper } from "react-icons/vsc"
import Select from "react-select"

const Contact = ({ boxId, type, addAnswer, intputStatus }) => {
    const { questionValues } = useSelector((state) => state.questions)
    const [answerChoice, setAnswerChoice] = useState([])
    const [status, setStatus] = useState(false)
    const dispatch = useDispatch()
    // const { bgColor } = useSelector((state) => state.bg);
    const { mood } = useSelector((state) => state.mood)

    const filter = questionValues.filter((i) => i.type === "contact")
    const Name = filter?.[0]?.choice.filter((i) => i.type === "name")
    const Email = filter?.[0]?.choice.filter((i) => i.type === "email")
    const Mobile = filter?.[0]?.choice.filter((i) => i.type === "number")
    const Age = filter?.[0]?.choice.filter((i) => i.type === "age")
    const Gender = filter?.[0]?.choice.filter((i) => i.type === "gender")
    const Description = filter?.[0]?.choice.filter((i) => i.type === "feedback")
    const Default = filter?.[0]?.choice.filter((i) => i.type === "text")

    useEffect(() => {
        const newChanges = () => {
            const answerIndex = questionValues.filter((item) => item.id == boxId)
            setAnswerChoice(answerIndex[0]?.choice)
        }
        newChanges()
    }, [questionValues])

    useEffect(() => {
        setStatus(false)
    }, [intputStatus])

    document.body.addEventListener("click", () => {
        setStatus(false)
    })

    const addAnswerChoice = (uniqueID, inputType, placeholder, status, option) => {
        const arayValueFilter = questionValues.filter((item) => item.id == boxId)
        const choiceOption = arayValueFilter[0].choice
        let lastIndex = arayValueFilter[0].choice.length
        let addValues = () => {
            const value = Number(lastIndex) + 1
            return value.toString()
        }
        let newValue = addValues()
        let addArray = [
            ...choiceOption,
            {
                id: newValue,
                value: "",
                type: inputType,
                placeholder,
                status,
                options: option,
                uniqueID
            }
        ]

        dispatch(setArrayValue({ id: boxId, choice: addArray, type: type }))
    }

    const remove = (i) => {
        const arrayValue = answerChoice.filter((e) => e.id !== i)
        dispatch(setArrayValue({ id: boxId, choice: arrayValue, type: type }))
    }

    const update = (i, value, inputType, placeholder, status, option) => {
        const arayValueFilter = questionValues.filter((item) => item.id == boxId)
        const choiceOption = arayValueFilter[0].choice.filter((items) => items.id !== i)
        const addValues = [
            ...choiceOption,
            {
                id: i,
                value: value,
                type: inputType,
                placeholder,
                status,
                options: option
            }
        ]
        dispatch(setArrayValue({ id: boxId, choice: addValues, type: type }))
    }

    const handelChange = (e) => {
        if (!e.destination) return
        const items = Array.from(answerChoice)
        const [reorderedItem] = items.splice(e.source.index, 1)
        items.splice(e.destination.index, 0, reorderedItem)
        dispatch(newArrayAnswer({ choice: items, id: boxId }))
        setAnswerChoice(items)
    }

    useEffect(() => {
        if (addAnswer !== null && addAnswer !== true && addAnswer !== false) {
            addAnswerChoice()
        }
    }, [addAnswer])

    const gnOption = [
        { label: "Male", value: "Male", id: "1" },
        { label: "Female", value: "Female", id: "2" },
        { label: "Other", value: "Other", id: "3" }
    ]

    //custom styles
    const customStyles = {
        control: (styles) => ({
            ...styles,
            backgroundColor: `${mood === "light" ? "#FAFAFA" : "#303544"}`,
            border: "1px solid #E4E4E7"
        }),
        option: (provided, state) => ({
            ...provided,
            color: state.isSelected ? "white" : "black",
            background: state.isSelected ? "#8a8af0" : "white",
            padding: 10,
            cursor: "pointer",
            borderRadius: "4px"
        }),
        menuList: (base) => ({
            ...base,
            // kill the white space on first and last option
            border: `2px solid #E4E4E7`,
            boxShadow: "0 10px 15px rgba(0,0,0,0.10)",
            borderRadius: "4px",
            padding: 16,
            zIndex: 999
        })
    }

    return (
        <div>
            <div className=" mt-3">
                <DragDropContext onDragEnd={handelChange}>
                    <Droppable droppableId="answerChoice">
                        {(provided) => (
                            <div
                                className="answerChoice"
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                            >
                                {answerChoice.map((item, index) => (
                                    <div>
                                        <Draggable
                                            key={item.id}
                                            draggableId={item.id}
                                            index={index}
                                        >
                                            {(provided) => (
                                                <div
                                                    className="flex items-center contact"
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    ref={provided.innerRef}
                                                >
                                                    <VscGripper
                                                        size={26}
                                                        className="text-gray hover:text-black"
                                                    />
                                                    {item.status === "textarea" ? (
                                                        <textarea
                                                            placeholder={`${item.placeholder}`}
                                                            className={` resize-none w-[78%] h-[100px] py-3 my-1.5 rounded placeholder:text-[black] px-3 outline-0 border font-medium mx-2 ${
                                                                mood === "light"
                                                                    ? "!border-[#E4E4E7] !bg-[#FAFAFA]"
                                                                    : "bg-[#232734] border-[#303544] text-white"
                                                            }`}
                                                            name=""
                                                            id=""
                                                            cols="30"
                                                            rows="10"
                                                            // onChange={(e) => update(item.id, e.target.value, item?.type, item?.placeholder, item?.status, item?.options)}
                                                            defaultValue={item.value}
                                                            disabled
                                                        ></textarea>
                                                    ) : item.status === "select" ? (
                                                        <Select
                                                            /*onChange={(e) => update(item.id, e.value, item?.type, item?.placeholder, item?.status, item?.options)}*/ styles={
                                                                customStyles
                                                            }
                                                            className={`!text-gray w-[78%] mx-2 my-1.5 rounded focus:outline-none`}
                                                            options={item.options}
                                                            placeholder={item.placeholder}
                                                        />
                                                    ) : (
                                                        <input
                                                            type={item.type}
                                                            placeholder={`${item.placeholder}`}
                                                            className={` w-[78%] py-3 my-1.5 rounded px-3 outline-0 placeholder:text-[black] border font-medium mx-2 ${
                                                                mood === "light"
                                                                    ? "!border-[#E4E4E7] !bg-[#FAFAFA]"
                                                                    : "bg-[#232734] border-[#303544] text-white"
                                                            }`}
                                                            // onChange={(e) => update(item.id, e.target.value, item?.type, item?.placeholder, item?.status, item?.options)}
                                                            defaultValue={item.value}
                                                            disabled
                                                        />
                                                    )}
                                                    <AiOutlineMinusCircle
                                                        size={26}
                                                        className={`cursor-pointer text-gray w-[10%] `}
                                                        onClick={() => remove(item.id)}
                                                    />
                                                </div>
                                            )}
                                        </Draggable>
                                    </div>
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </div>
            <p
                className={`inline mt-3 font-[500] font-inter capitalize text-[16px] cursor-pointer text-[#5F58B7] absolute top-[-10px] right-0`}
                onClick={(e) => {
                    e.stopPropagation()
                    setStatus(!status)
                }}
            >
                <MdAdd
                    size={26}
                    className="text-amber-300 font-bold text-lg"
                />
                Add
            </p>
            {status && (
                <div
                    style={{
                        boxShadow: `${mood === "light" ? "0px 2px 8px rgba(0, 0, 0, 0.15)" : "0px 2px 8px #2f3b5c"}`
                    }}
                    className={`z-[999] w-60 py-3 rounded absolute top-[30px] text-start right-0 -mt-1 transition-all duration-500 px-3 font-inter ${
                        mood === "light" ? "bg-white text-black" : "border border-[#2f3b5c] bg-[#161A25] text-white"
                    } `}
                >
                    {Default.length === 0 && Name.length === 0 && (
                        <p
                            className="px-3 rounded-sm blueHover py-1.5 font-[500] capitalize cursor-pointer font-inter !text-start"
                            onClick={() => {
                                addAnswerChoice("01", "name", "Name")
                                setStatus(false)
                            }}
                        >
                            Name
                        </p>
                    )}
                    {Email.length === 0 && (
                        <p
                            className="px-3 rounded-sm blueHover py-1.5 font-[500] capitalize cursor-pointer font-inter !text-start"
                            onClick={() => {
                                addAnswerChoice("02", "email", "Example@gmail.com")
                                setStatus(false)
                            }}
                        >
                            Email
                        </p>
                    )}
                    {Mobile.length === 0 && (
                        <p
                            className="px-3 rounded-sm blueHover py-1.5 font-[500] capitalize cursor-pointer font-inter !text-start"
                            onClick={() => {
                                addAnswerChoice("03", "number", "Mobile Number")
                                setStatus(false)
                            }}
                        >
                            Mobile Number
                        </p>
                    )}
                    {Age.length === 0 && (
                        <p
                            className="px-3 rounded-sm blueHover py-1.5 font-[500] capitalize cursor-pointer font-inter !text-start"
                            onClick={() => {
                                addAnswerChoice("04", "age", "Age")
                                setStatus(false)
                            }}
                        >
                            Age
                        </p>
                    )}
                    {Gender.length === 0 && (
                        <p
                            className="px-3 rounded-sm blueHover py-1.5 font-[500] capitalize cursor-pointer font-inter !text-start"
                            onClick={() => {
                                addAnswerChoice("05", "gender", "Select Gender", "select", gnOption)
                                setStatus(false)
                            }}
                        >
                            Gender
                        </p>
                    )}
                    {Description.length === 0 && (
                        <p
                            className="px-3 rounded-sm blueHover py-1.5 font-[500] capitalize cursor-pointer font-inter !text-start"
                            onClick={() => {
                                addAnswerChoice("06", "feedback", "Description", "textarea")
                                setStatus(false)
                            }}
                        >
                            Description
                        </p>
                    )}
                </div>
            )}
        </div>
    )
}

export default Contact
