/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef } from "react";
import {
  Link,
  useNavigate,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import feedbacklogoDark from "../../assets/svg/logo.svg";
import EventName from "../popUp/EventName";
import {
  AiOutlineSearch,
  AiOutlineHome,
  AiOutlineSetting,
  AiOutlineMenu,
  AiOutlineClose,
} from "react-icons/ai";
import { MdAdd, MdOutlineNightlightRound } from "react-icons/md";
import { BsCalendar, BsSun } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { BiChevronDown } from "react-icons/bi";
import { TbArchive, TbReportAnalytics } from "react-icons/tb";
import { setMoodValue } from "../../redux/slices/mood";
import { logOut, setUserValue } from "../../redux/slices/auth";
import { remove } from "../../redux/slices/feedbackValue";
import {
  bgImageDetails,
  logoDetails,
  questionEmpty,
} from "../../redux/slices/questions";
import { setEventValue } from "../../redux/slices/eventName";
import axios from "../../axios";
import { Toaster, toast } from "react-hot-toast";

// website tour
import { driver } from "driver.js";
import "driver.js/dist/driver.css";

const Navbar = ({ popups, inputValue, setNames, hide, menu }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [popUp, setPopUp] = useState(false);
  const dispatch = useDispatch();
  const { mood } = useSelector((state) => state.mood);
  const { user } = useSelector((state) => state.userAuth);
  const [selected, setSelected] = useState("");
  const { eventName } = useSelector((state) => state.eventName);
  const [pop, setPop] = useState(false);
  const { questionValues } = useSelector((state) => state.questions);
  const { editValues } = useSelector((state) => state.questions);
  const [searchParams] = useSearchParams();
  const ID = searchParams.get("id");
  const [lock, setLock] = useState(hide);
  const [condition, setCondition] = useState(0);
  // const { setIsOpen } = useTour()
  const [logoutPopup, setLogoutPopup] = useState(false);
  const [payment, setpayment] = useState("");
  const [menuClose, setMenuClose] = useState(false);
  const Create_camaping = localStorage.getItem("Create_camaping");
  const getPublished = localStorage.getItem("Published");
  const [closePop, setClosePop] = useState(false);
  const [clearPop, setClearPop] = useState(false);
  const getOldquestion = JSON.parse(localStorage.getItem("Oldquestion"));
  const [navLocation, setNavLocation] = useState("");

  // website tour
  const driverObj = useRef(driver({ allowClose: false }));
  useEffect(() => {
    if (user?.initialLogin && Create_camaping === null) {
      // const tourCompleted = localStorage.getItem("navbar_tourCompleted")

      // if (tourCompleted) return

      const startTour = () => {
        driverObj?.current?.highlight({
          element: "#navbar_createCampaign",
          popover: {
            title: "Create Survey 😃",
            description:
              "Click here to start the process of creating a new Survey.",
            side: "left",
            align: "start",
          },
        });
      };
      startTour();
      localStorage.setItem("Create_camaping", false);

      // Mark the tour as completed
      //   return  localStorage.setItem("navbar_tourCompleted", "true")
    }
  }, [user]);
  useEffect(() => {
    const Api = async () => {
      if (user) {
        const { data } = await axios.post("/getParticularUserEvent", {
          id: user?.id,
        });
        setCondition(data?.length);
        if (user?.initialLogin) {
          // setIsOpen(true)
          await axios.post("/changeStatus", {
            id: user.id,
          });
        }
      }
    };
    Api();
  }, [user]);

  const Pages = localStorage.getItem("clearQuestion");

  const setMood = (e) => {
    dispatch(setMoodValue(e));
  };

  const setIn = (data) => {
    setNames(data);
    dispatch(setEventValue(data));
  };

  useEffect(() => {
    const path = location.pathname;
    const mainPath = path.substring(1);
    if (mainPath.length == 0) {
      setSelected("dashboard");
    } else {
      setSelected(mainPath);
    }
  }, [location.pathname]);

  const addn = location.pathname.split("/");

  const [popupStatus, setPopupStatus] = useState(false);
  document.body.addEventListener("click", () => {
    setPopupStatus(false);
  });

  const handleLinkClick = () => {
    if (location.pathname === "/addQuestions") {
      setPop(true);
      setpayment("account");
    }
  };

  const areArraysMatching = () => {
    if (questionValues.length !== getOldquestion.length) {
      setClosePop(true);
      return;
    }

    for (let i = 0; i < questionValues.length; i++) {
      const obj1 = questionValues[i];
      const obj2 = getOldquestion[i];

      if (
        obj1.id !== obj2.id ||
        obj1.question !== obj2.question ||
        obj1.type !== obj2.type || 
        obj1.subHeading !== obj2.subHeading

      ) {
        setClosePop(true);
        return;
      }

      if (obj1.type === "radioButton" || obj1.type === "starRating") {
        if (obj1.choice.length !== obj2.answer.length) {
          setClosePop(true);
          return;
        }

        for (let j = 0; j < obj1.choice.length; j++) {
          if (
            obj1.choice[j].id !== obj2.answer[j].id ||
            obj1.choice[j].value !== obj2.answer[j].value
          ) {
            setClosePop(true);
            return;
          }
        }
      }
    }

      dispatch(questionEmpty());
      dispatch(setEventValue("New Event"));
      dispatch(logoDetails([]));
      dispatch(bgImageDetails([]));
      localStorage.removeItem("reRender");
      localStorage.removeItem("brandRender");
      navigate(navLocation);
  };


  const handelNavigate = async (e) => {
    if (window.location.pathname !== "/addQuestions") {
      return navigate(e);
    }
  };

  return (
    <header className=" sticky -top-[54px] z-[60]">
      <div
        className={`py-2 border-b ${
          mood === "light"
            ? `bg-black border-[#E4E4E7]`
            : "bg-[#161A25] border-gray"
        } `}
      >
        <div className={` mx-auto  w-full max-w-[105rem] sectionPadding py-1`}>
          <div className="flex items-center w-full m1:flex-col m2:flex-col m3:flex-col m4:flex-col m5:flex-col m6:flex-col m7:flex-col m8:flex-col t1:flex-row t2:flex-row t3:flex-row t4:flex-row t5:flex-row  ">
            <p
              onClick={() => {
                if (window.location.pathname !== "/addQuestions") {
                  handelNavigate("/");
                } else {
                  areArraysMatching();
                }
              }}
              onMouseEnter={() => setNavLocation("/")}
              title=""
              className="flex items-center"
            >
              {mood === "light" ? (
                <>
                  <img
                    className="block w-auto h-7 lg:hidden"
                    src={feedbacklogoDark}
                    alt=""
                  />
                  <img
                    className="hidden w-auto h-7 lg:block"
                    src={feedbacklogoDark}
                    alt=""
                  />
                </>
              ) : (
                <>
                  <img
                    className="block w-auto h-7 lg:hidden"
                    src={feedbacklogoDark}
                    alt=""
                  />
                  <img
                    className="hidden w-auto h-7 lg:block"
                    src={feedbacklogoDark}
                    alt=""
                  />
                </>
              )}
            </p>

            <div className="flex-1">
              <div className="flex items-center justify-end">
                <button
                  type="button"
                  className={`rounded-full `}
                  id="options-menu-button"
                  aria-expanded="false"
                  aria-haspopup="true"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setPopupStatus(!popupStatus);
                  }}
                >
                  <div className="flex items-center justify-between w-full">
                    <div className="flex items-center justify-between min-w-0 space-x-3 relative">
                      <img
                        className="flex-shrink-0 object-cover bg-gray rounded-full w-7 h-7"
                        src={
                          user?.profilePic === ""
                            ? "https://upload.wikimedia.org/wikipedia/commons/thumb/2/2c/Default_pfp.svg/1200px-Default_pfp.svg.png"
                            : user?.profilePic
                        }
                        alt=""
                      />
                      <p
                        className={`text-sm font-medium  
                          ${mood === "light" ? `text-white` : "text-gray"}
                          truncate`}
                      >
                        {user?.name}
                      </p>
                      {popupStatus && (
                        <div
                          style={{
                            boxShadow: `${
                              mood === "light"
                                ? "0px 2px 8px rgba(0, 0, 0, 0.15)"
                                : "0px 2px 8px #2f3b5c"
                            }`,
                          }}
                          className={`absolute z-[999] rounded w-40 py-3 top-[40px]  text-start -right-10 -mt-1 transition-all duration-500 px-3 font-inter bg-white text-black ${
                            mood === "light"
                              ? "bg-white text-black"
                              : "border border-[#2f3b5c] bg-[#161A25] text-white"
                          } `}
                        >
                          <p
                            onClick={() => {
                              if (
                                window.location.pathname !== "/addQuestions"
                              ) {
                                handelNavigate("/account");
                              } else {
                                areArraysMatching();
                              }
                            }}
                            onMouseEnter={() => setNavLocation("/account")}
                          >
                            <p className="px-3 rounded-md blueHover  hover:text-black py-1.5 font-[500] capitalize cursor-pointer font-inter !text-start">
                              My Account
                            </p>
                          </p>

                          <p
                            onClick={() => {
                              if (
                                window.location.pathname !== "/addQuestions"
                              ) {
                                handelNavigate("/payment");
                              } else {
                                areArraysMatching();
                              }
                            }}
                            onMouseEnter={() => setNavLocation("/payment")}
                          >
                            <p className="px-3 rounded-md blueHover py-1.5 font-[500] capitalize cursor-pointer font-inter !text-start">
                              My Plan
                            </p>
                          </p>
                          <p
                            onClick={() => setLogoutPopup(true)}
                            className="px-3 rounded-md blueHover  py-1.5 font-[500] hover:text-black capitalize cursor-pointer font-inter !text-start"
                          >
                            Log out
                          </p>
                        </div>
                      )}
                    </div>
                    <BiChevronDown
                      size={25}
                      className="flex-shrink-0  ml-2 text-gray group-hover:text-gray"
                    />
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {location.pathname !== "/addQuestions" ? (
        <div
          className={`py-2 ${
            mood === "light"
              ? `bg-white border-[#E4E4E7]`
              : "bg-[#161A25] border-gray"
          } border-b lg:block sticky top-0`}
        >
          <div className="mx-auto w-full max-w-[105rem] sectionPadding">
            <div className="flex items-center justify-between">
              {Pages !== null ? (
                <>
                  <div className="flex items-center space-x-4 t1:space-x-1 t2:space-x-1 t3:space-x-1 t4:space-x-1 t5:space-x-4 ">
                    {window.location.pathname === "/global-settings" ||
                    "/account" ? (
                      <div className="   lg:hidden">
                        {menuClose ? (
                          <AiOutlineClose
                            size={20}
                            onClick={() => {
                              setMenuClose(false);
                              menu(false);
                            }}
                          />
                        ) : (
                          <AiOutlineMenu
                            size={20}
                            onClick={() => {
                              setMenuClose(true);
                              menu(true);
                            }}
                          />
                        )}
                      </div>
                    ) : (
                      ""
                    )}
                    <div
                      onClick={() => {
                        if (
                          eventName !== "New Event" &&
                          questionValues !== 0 &&
                          editValues.length !== questionValues.length
                        ) {
                          setPop(true);
                          setpayment("dashboard");
                        } else {
                          navigate("/");
                        }
                      }}
                      className={`cursor-pointer inline-flex items-center py-2 px-3 text-base font-medium transition-all duration-200 bg-transparent rounded-lg font-inter ${
                        mood === "light"
                          ? "blueHover text-[#18181B] "
                          : "hover:text-white text-gray hover:bg-[#303544]"
                      } ${
                        selected === "dashboard" &&
                        `${
                          mood === "light"
                            ? "!text-[#4F46E5] !bg-[#4e46e52d]"
                            : "!text-gray !bg-[#303544]"
                        }`
                      }`}
                      id="navbar_dashboard"
                    >
                      <AiOutlineHome size={20} className=" mr-2" />
                      Dashboard
                    </div>
                    <div
                      onClick={() => {
                        if (
                          eventName !== "New Event" &&
                          questionValues !== 0 &&
                          editValues.length !== questionValues.length
                        ) {
                          setPop(true);
                          setpayment("events");
                        } else {
                          navigate("/events");
                        }
                      }}
                      className={`cursor-pointer inline-flex items-center py-2 px-3 text-base font-medium transition-all duration-200 bg-transparent rounded-lg font-inter ${
                        mood === "light"
                          ? "blueHover text-[#18181B] "
                          : "hover:text-white text-gray hover:bg-[#303544]"
                      } ${
                        selected === "events" &&
                        `${
                          mood === "light"
                            ? "!text-[#4F46E5] !bg-[#4e46e52d]"
                            : "!text-gray !bg-[#303544]"
                        }`
                      }`}
                      id="navbar_campaigns"
                    >
                      <TbArchive size={20} className={`mr-2`} />
                      Surveys 
                    </div>

                    <div
                      onClick={() => {
                        if (
                          eventName !== "New Event" &&
                          questionValues !== 0 &&
                          editValues.length !== questionValues.length
                        ) {
                          setPop(true);
                          setpayment("reports");
                        } else {
                          navigate("/reports");
                        }
                      }}
                      className={`cursor-pointer inline-flex items-center py-2 px-3 text-base font-medium transition-all duration-200 bg-transparent rounded-lg font-inter ${
                        mood === "light"
                          ? "blueHover text-[#18181B] "
                          : "hover:text-white text-gray hover:bg-[#303544]"
                      } ${
                        selected === "reports" &&
                        `${
                          mood === "light"
                            ? "!text-[#4F46E5] !bg-[#4e46e52d]"
                            : "!text-gray !bg-[#303544]"
                        }`
                      }`}
                      id="navbar_reports"
                    >
                      <TbReportAnalytics size={20} className={`mr-2`} />
                      Reports
                    </div>

                    <div
                      onClick={() => {
                        if (
                          eventName !== "New Event" &&
                          questionValues !== 0 &&
                          editValues.length !== questionValues.length
                        ) {
                          setPop(true);
                          setpayment("global-settings");
                        } else {
                          navigate("/global-settings");
                        }
                      }}
                      className={`cursor-pointer inline-flex items-center py-2 px-3 text-base font-medium transition-all duration-200 bg-transparent rounded-lg font-inter ${
                        mood === "light"
                          ? "blueHover text-[#18181B] "
                          : "hover:text-white text-gray hover:bg-[#303544]"
                      } ${
                        selected === "global-settings" &&
                        `${
                          mood === "light"
                            ? "!text-[#4F46E5] !bg-[#4e46e52d]"
                            : "!text-gray !bg-[#303544]"
                        }`
                      }`}
                      id="navbar_globalSettings"
                    >
                      <AiOutlineSetting size={20} className={`mr-2`} />
                      Global Settings
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="flex items-center space-x-4">
                    <Link to={"/"}>
                      <div
                        className={`cursor-pointer inline-flex items-center py-2 px-3 text-base font-medium transition-all duration-200 bg-transparent rounded-lg font-inter ${
                          mood === "light"
                            ? "blueHover text-[#18181B] "
                            : "hover:text-white text-gray hover:bg-[#303544]"
                        } ${
                          selected === "dashboard" &&
                          `${
                            mood === "light"
                              ? "!text-[#4F46E5] !bg-[#4e46e52d]"
                              : "!text-gray !bg-[#303544]"
                          }`
                        }`}
                      >
                        <AiOutlineHome size={20} className={`mr-2`} />
                        Dashboard
                      </div>
                    </Link>

                    <Link to={"/events"}>
                      <div
                        className={`cursor-pointer inline-flex items-center py-2 px-3 text-base font-medium transition-all duration-200 bg-transparent rounded-lg font-inter ${
                          mood === "light"
                            ? "blueHover text-[#18181B] "
                            : "hover:text-white text-gray hover:bg-[#303544]"
                        } ${
                          selected === "events" &&
                          `${
                            mood === "light"
                              ? "!text-[#4F46E5] !bg-[#4e46e52d]"
                              : "!text-gray !bg-[#303544]"
                          }`
                        }`}
                        id="navbar_campaigns"
                      >
                        <TbArchive size={20} className="mr-2" />
                        Surveys
                      </div>
                    </Link>

                    <Link to={"/reports"}>
                      <div
                        className={`cursor-pointer inline-flex items-center py-2 px-3 text-base font-medium transition-all duration-200 bg-transparent rounded-lg font-inter ${
                          mood === "light"
                            ? "blueHover text-[#18181B] "
                            : "hover:text-white text-gray hover:bg-[#303544]"
                        } ${
                          selected === "reports" &&
                          `${
                            mood === "light"
                              ? "!text-[#4F46E5] !bg-[#4e46e52d]"
                              : "!text-gray !bg-[#303544]"
                          }`
                        }`}
                        id="navbar_reports"
                      >
                        <TbReportAnalytics size={20} className="mr-2" />
                        Reports
                      </div>
                    </Link>

                    <Link to={"/global-settings"}>
                      <div
                        className={`cursor-pointer inline-flex items-center py-2 px-3 text-base font-medium transition-all duration-200 bg-transparent rounded-lg font-inter ${
                          mood === "light"
                            ? "blueHover text-[#18181B] "
                            : "hover:text-white text-gray hover:bg-[#303544]"
                        } ${
                          selected === "global-settings" &&
                          `${
                            mood === "light"
                              ? "!text-[#4F46E5] !bg-[#4e46e52d]"
                              : "!text-gray !bg-[#303544]"
                          }`
                        }`}
                      >
                        <AiOutlineSetting size={20} className="mr-2" />
                        Global Settings
                      </div>
                    </Link>
                  </div>
                </>
              )}
              <div className="hidden max-w-lg ml-auto lg:flex items-center">
                {eventName === "New Event" &&
                  ID === null &&
                  addn[1] !== "report" &&
                  location.pathname !== "/addQuestions" && (
                    <div className="relative">
                      {/* {user?.PaymentPaid === "0" && condition === 0 ? (
                      <button
                        data-tut="first-step"
                        onClick={() => {
                          setPopUp(true);
                          localStorage.setItem("reactTourCount", 2);
                        }}
                        className={`${
                          mood === "light"
                            ? "border-[#6B53FD] bg-[#6B53FD] text-white hover:bg-white hover:text-[#6B53FD]"
                            : "bg-[#303544] border-[#303544] text-white hover:bg-[#232734]"
                        }  font-inter flex w-[200px]  border transition-all duration-300  justify-center items-center py-2 rounded-md`}
                      >
                        <MdAdd size={25} className="mx-1" />
                        <span>Create Campaign</span>
                      </button>
                    ) : (
                      user?.PaymentPaid === "0" && (
                        <button
                          data-tut="first-step"
                          onClick={() => {
                            toast.error("Upgrade your account");
                          }}
                          className={`${
                            mood === "light"
                              ? "border-[#6B53FD] bg-[#6B53FD] text-white hover:bg-white hover:text-[#6B53FD]"
                              : "bg-[#303544] border-[#303544] text-white hover:bg-[#232734]"
                          }  font-inter flex w-[200px]  border transition-all duration-300  justify-center items-center py-2 rounded-md`}
                        >
                          <MdAdd size={25} className="mx-1" />
                          <span>Create Campaign</span>
                        </button>
                      )
                    )} */}

                      {/* {user?.PaymentPaid !== "0" && (
                      <button
                        data-tut="first-step"
                        onClick={() => {
                          setPopUp(true);
                        }}
                        className={`${
                          mood === "light"
                            ? "border-[#6B53FD] bg-[#6B53FD] text-white hover:bg-white hover:text-[#6B53FD]"
                            : "bg-[#303544] border-[#303544] text-white hover:bg-[#232734]"
                        }  font-inter flex w-[200px]  border transition-all duration-300  justify-center items-center py-2 rounded-md`}
                      >
                        <MdAdd size={25} className="mx-1" />
                        <span>Create Campaign</span>
                      </button>
                    )} */}

                      <button
                        data-tut="first-step"
                        onClick={() => {
                          driverObj?.current?.destroy();
                          setPopUp(true);
                        }}
                        // className={`${
                        //     mood === "light"
                        //         ? "border-[#6B53FD] bg-[#6B53FD] text-white hover:bg-white hover:text-[#6B53FD]"
                        //         : "bg-[#303544] border-[#303544] text-white hover:bg-[#232734]"
                        // }  font-inter flex w-[200px]  border transition-all duration-300  justify-center items-center py-2 rounded-md`}
                        className="primaryButton px-4 py-2"
                        id="navbar_createCampaign"
                      >
                        <MdAdd size={25} className="mx-1" />
                        <span>Create Survey</span>
                      </button>
                    </div>
                  )}
              </div>
            </div>
          </div>
        </div>
      ) : null}

      {logoutPopup && (
        <EventName status="logout" show={logoutPopup} hide={setLogoutPopup} />
      )}

      {!inputValue && (
        <EventName
          show={popUp}
          status={"addEvent"}
          hide={setPopUp}
          setInput={setIn}
          inputValue={inputValue}
        />
      )}
      <Toaster position="top-right" reverseOrder={false} />

      {closePop && (
        <>
          <EventName
            status="clear"
            show={closePop}
            hide={setClosePop}
            selectName={`${navLocation}`}
          />
        </>
      )}

      {clearPop && (
        <>
          <EventName
            status="close"
            show={clearPop}
            hide={setClearPop}
            selectName={`${navLocation}`}
          />
        </>
      )}
    </header>
  );
};

export default Navbar;
