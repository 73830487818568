import React, { useEffect } from "react"
import { useState } from "react"
import { toast, Toaster } from "react-hot-toast"
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai"
import CustomDotLoader from "../../../components/CustomDotLoader"
import logo from "../../../assets/logo1.svg"
import OtpInput from "react-otp-input"
import { Link, useNavigate } from "react-router-dom"
import axios from "../../../axios"

import Emoji from "react-emojis"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { magicLinkSchema, passwordSchema } from "../../../utils/validation"

// ** import components
import Input from "../../../components/Input/Input"
import FadeInTransition from "../../../components/FadeInTransition"

const ForgotPassword = () => {
    const [visible, setVisible] = useState(false)
    const [visibleCon, setVisibleCon] = useState(false)
    const [loader, setLoader] = useState(false)
    const [step, setStep] = useState(0)
    const [seconds, setSeconds] = useState(180)
    const navigate = useNavigate()
    //otp
    const [otp, setOtp] = useState("")
    const [email, setemail] = useState("")
    const [errStatus, setErrStatus] = useState("")

    // const [resetPass, setPass] = useState({
    //     newPass: "",
    //     confirmPass: ""
    // })

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(step === 0 ? magicLinkSchema : passwordSchema)
    })

    const hadleEmail = async (res) => {
        setLoader(true)
        const { data } = await axios.post("/forgot", { email: res.Email })
        if (data.success) {
            setLoader(false)
            toast.success(`${data.message}`)
            setemail(res.Email)
            reset({
                email: ""
            })
            setErrStatus("")
            setStep(1)
            return
        } else {
            setLoader(false)
            return setErrStatus(data.message)
        }
    }

    useEffect(() => {
        if (seconds > 0) {
            const countdown = setInterval(() => {
                setSeconds((prevSeconds) => prevSeconds - 1)
            }, 1000)

            return async () => clearInterval(countdown)
        } else {
            const API = async () => {
                await axios.post("/remove-otp", {
                    email: email,
                    status: "forgot"
                })
            }
            API()
        }
    }, [seconds])

    //handle otp changes
    const handleChange = (otp) => setOtp(otp)

    const handleOtp = async () => {
        setLoader(true)
        if (otp.length !== 0) {
            setLoader(true)
            const { data } = await axios.post("/verify/otp", {
                action: "forgot",
                otp
            })
            if (data.success) {
                setLoader(false)
                setErrStatus("")
                setStep(2)
                return
            } else {
                setLoader(false)
                toast.error(`${data.message}`)
                return
            }
        } else {
            setLoader(false)
            toast.error("Input the OTP")
        }
    }

    const handlePassword = async (res) => {
        setLoader(true)
        if (res.Password === res.ConfirmPassword) {
            const { data } = await axios.post("/resetPassword", {
                otp: otp,
                password: res.Password,
                email: email
            })
            if (data.success) {
                setLoader(false)
                setErrStatus("")
                setStep(3)
                return toast.success(`${data.message}`)
            } else {
                setLoader(false)
                return setErrStatus(data.message)
            }
        } else {
            setLoader(false)
            return setErrStatus("Password not Match")
        }
    }

    const reSend = async () => {
        if (seconds === 0) {
            if (email !== null) {
                const { data } = await axios.post("/resend-otp", {
                    email: email,
                    status: "forgot"
                })
                if (data.success) {
                    toast.success(`${data.message}`)
                    setSeconds(300)
                    return
                }
            }
        } else {
            return toast.error("OTP Expire time is running")
        }
    }

    const formatTime = (time) => {
        const minutes = Math.floor(time / 60)
        const seconds = time % 60
        return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`
    }

    return (
        <div className="max-h-[100vh] h-screen w-full overflow-hidden py-20">
            <div className="fixed bottom-0 left-0 w-full border-b-[10px] border-[#4F46E5] z-50"></div>
            <div
                style={{ boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.15)" }}
                className="py-5 w-full !z-50 absolute top-0"
            >
                <a href="https://www.feedbacksync.com">
                    <img
                        className="w-[175px] block mx-auto"
                        src={logo}
                        alt=""
                    />
                </a>
            </div>
            <FadeInTransition>
                <section className=" !mt-[80px] w-full">
                    <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
                        <div className="relative max-w-md mx-auto lg:max-w-lg">
                            <div
                                style={{ boxShadow: "0px 0px 61.3082px rgba(0, 0, 0, 0.15)" }}
                                className="bg-[white] relative overflow-hidden rounded-[16px]"
                            >
                                {step === 0 && (
                                    <div className="px-4 py-6 sm:px-8">
                                        <h1 className="font-bold font-pj text-center text-[#18181B] text-[21px]">
                                            Forgot Password
                                        </h1>

                                        <p className="w-[70%] text-center mx-auto mt-3 text-[#52525B] text-[15px]">
                                            Please enter your email address to receive a verification code
                                        </p>

                                        <form
                                            onSubmit={handleSubmit(hadleEmail)}
                                            action=""
                                        >
                                            <div className="space-y-5 mt-[30px]">
                                                <div>
                                                    <Input
                                                        name="Email"
                                                        type="email"
                                                        placeholder="Enter email address"
                                                        register={register}
                                                        error={errors?.Email?.message}
                                                    />
                                                    {step === 0 && errStatus !== "" && (
                                                        <p className="text-red mt-1 text-sm">
                                                            * We could'nt find this email address.
                                                        </p>
                                                    )}
                                                </div>
                                            </div>

                                            {!loader ? (
                                                <button
                                                    type="submit"
                                                    className="flex items-center justify-center w-full px-8 h-[50px] max-h-[50px] mt-5 text-base font-bold text-white transition-all duration-200 bg-[#4F46E5] border border-transparent hover:border-[#4F46E5] hover:!bg-white hover:text-[#4F46E5] rounded-xl focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray  hover:bg-grayDark"
                                                >
                                                    Continue
                                                </button>
                                            ) : (
                                                <button
                                                    type="submit"
                                                    className={`flex items-center justify-center overflow-hidden h-[50px] max-h-[50px] w-full px-8 mt-5 text-base font-bold text-white transition-all duration-200 bg-[#4F46E5] border border-transparent hover:border-[#4F46E5] hover:!bg-[#4e46e5e8] hover:text-[white] rounded-xl focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray`}
                                                >
                                                    <CustomDotLoader
                                                        dotSize={50}
                                                        isWhite
                                                    />
                                                </button>
                                            )}
                                        </form>
                                        <div className="text-center mt-1">
                                            <Link
                                                to="/login"
                                                className="back underline flex items-center justify-center mt-3"
                                            >
                                                Go back
                                            </Link>
                                        </div>
                                    </div>
                                )}

                                {step === 1 && (
                                    <div className="px-4 py-6 sm:px-8">
                                        <h1 className="font-bold font-pj text-center text-[#18181B] text-[21px]">
                                            Verify Code
                                        </h1>

                                        <p className="w-[70%] text-center mx-auto mt-3 text-[#52525B] text-[15px]">
                                            Please enter the 4 digit code sent to {email}
                                        </p>

                                        <div className="space-y-5 mt-[30px] text-center">
                                            <div>
                                                <div className="mb-3 mt-1 flex items-center justify-center relative">
                                                    <OtpInput
                                                        value={otp}
                                                        onChange={handleChange}
                                                        numInputs={4}
                                                        inputStyle={"otpInput"}
                                                    />
                                                </div>
                                            </div>

                                            <p
                                                className="text-[14px] text-[#4F46E5] text-center cursor-pointer underline"
                                                onClick={reSend}
                                            >
                                                Resend code ({formatTime(seconds)})
                                            </p>

                                            <p className="text-[14px] text-[#4F46E5] text-center cursor-pointer underline"></p>
                                        </div>
                                        {!loader ? (
                                            <button
                                                onClick={handleOtp}
                                                type="submit"
                                                className="flex items-center justify-center w-full px-8 h-[50px] max-h-[50px] mt-5 text-base font-bold text-white transition-all duration-200 bg-[#4F46E5] border border-transparent hover:border-[#4F46E5] hover:!bg-white hover:text-[#4F46E5] rounded-xl focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray  hover:bg-grayDark"
                                            >
                                                Continue
                                            </button>
                                        ) : (
                                            <button
                                                type="submit"
                                                className={`flex items-center justify-center overflow-hidden h-[50px] max-h-[50px] w-full px-8 mt-5 text-base font-bold text-white transition-all duration-200 bg-[#4F46E5] border border-transparent hover:border-[#4F46E5] hover:!bg-[#4e46e5e8] hover:text-[white] rounded-xl focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray`}
                                            >
                                                 <CustomDotLoader
                                                    dotSize={50}
                                                    isWhite
                                                />
                                            </button>
                                        )}
                                        <div className=" text-center mt-1">
                                            <p
                                                onClick={() => setStep(0)}
                                                className="back underline flex items-center justify-center mt-3 cursor-pointer"
                                            >
                                                Go back
                                            </p>
                                        </div>
                                    </div>
                                )}

                                {step === 2 && (
                                    <div className="px-4 py-6 sm:px-8">
                                        <h1 className="font-bold font-pj text-center text-[#18181B] text-[21px]">
                                            Reset Password
                                        </h1>

                                        <p className="w-[70%] text-center mx-auto mt-3 text-[#52525B] text-[15px]">
                                            Your new password must be different from previously used password{" "}
                                        </p>

                                        <form
                                            onSubmit={handleSubmit(handlePassword)}
                                            action=""
                                        >
                                            <div className="space-y-5 mt-[30px]">
                                                <div className="mt-1 relative">
                                                    <Input
                                                        name="Password"
                                                        type={visible === false ? "password" : "text"}
                                                        placeholder="Enter password"
                                                        register={register}
                                                        error={errors?.Password?.message}
                                                    />

                                                    <p className=" absolute z-50 right-3 top-11">
                                                        {visible === false ? (
                                                            <>
                                                                <AiFillEyeInvisible
                                                                    size={26}
                                                                    color="gray"
                                                                    className="cursor-pointer"
                                                                    onClick={() => setVisible(true)}
                                                                />
                                                            </>
                                                        ) : (
                                                            <>
                                                                <AiFillEye
                                                                    size={26}
                                                                    color="gray"
                                                                    className="cursor-pointer"
                                                                    onClick={() => setVisible(false)}
                                                                />
                                                            </>
                                                        )}
                                                    </p>
                                                </div>

                                                <div className="mt-1 relative">
                                                    <Input
                                                        name="Confirm Password"
                                                        type={visible === false ? "password" : "text"}
                                                        placeholder="ConfirmPassword"
                                                        register={register}
                                                        error={errors?.ConfirmPassword?.message}
                                                    />
                                                    <p className=" absolute z-50 right-3 top-11 ">
                                                        {visibleCon === false ? (
                                                            <>
                                                                <AiFillEyeInvisible
                                                                    size={26}
                                                                    color="gray"
                                                                    className="cursor-pointer"
                                                                    onClick={() => setVisibleCon(true)}
                                                                />
                                                            </>
                                                        ) : (
                                                            <>
                                                                <AiFillEye
                                                                    size={26}
                                                                    color="gray"
                                                                    className="cursor-pointer"
                                                                    onClick={() => setVisibleCon(false)}
                                                                />
                                                            </>
                                                        )}
                                                    </p>
                                                </div>

                                                {step === 2 && errStatus !== "" && (
                                                    <p className=" text-red">{errStatus}</p>
                                                )}
                                            </div>
                                            {!loader ? (
                                                <button
                                                    type="submit"
                                                    className="flex items-center justify-center w-full px-8 h-[50px] max-h-[50px] mt-5 text-base font-bold text-white transition-all duration-200 bg-[#4F46E5] border border-transparent hover:border-[#4F46E5] hover:!bg-white hover:text-[#4F46E5] rounded-xl focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray  hover:bg-grayDark"
                                                >
                                                    Reset Password
                                                </button>
                                            ) : (
                                                <button
                                                    type="submit"
                                                    className={`flex items-center justify-center overflow-hidden h-[50px] max-h-[50px] w-full px-8 mt-5 text-base font-bold text-white transition-all duration-200 bg-[#4F46E5] border border-transparent hover:border-[#4F46E5] hover:!bg-[#4e46e5e8] hover:text-[white] rounded-xl focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray`}
                                                >
                                                    <CustomDotLoader
                                                        dotSize={50}
                                                        isWhite
                                                        className="mx-auto text-center mr-10"
                                                    />
                                                </button>
                                            )}
                                        </form>
                                    </div>
                                )}
                                {step === 3 && (
                                    <div className="px-4 py-6 sm:px-8 flex flex-col items-center ">
                                        {/* <img
                      src={successImg}
                      className="block mx-auto my-5"
                      alt=""
                    /> */}
                                        <Emoji
                                            emoji="grinning-squinting-face"
                                            size={60}
                                        />
                                        <h1 className="font-bold font-pj text-center text-[#18181B] text-[21px]">
                                            Password Updated
                                        </h1>

                                        <p className="w-[70%] text-center mx-auto mt-3 text-[#52525B] text-[15px]">
                                            Your Password has been updated successfully.
                                        </p>

                                        <button
                                            onClick={(e) => {
                                                e.preventDefault()
                                                navigate("/login")
                                            }}
                                            className="flex items-center justify-center w-full px-8 py-4 mt-5 text-base font-bold text-white transition-all duration-200 bg-[#4F46E5] border border-transparent hover:border-[#4F46E5] hover:!bg-white hover:text-[#4F46E5] rounded-xl focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray  hover:bg-grayDark"
                                        >
                                            Login
                                        </button>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </section>
            </FadeInTransition>

            <Toaster
                position="top-right"
                reverseOrder={false}
            />
        </div>
    )
}

export default ForgotPassword
