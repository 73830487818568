export const svg1 = ({size, color}) => {
    
    console.log('color', color);

    return <svg width={size} height={size} viewBox="0 0 238 238" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M113.543 236.962C61.2927 235.002 14.3227 197.262 3.03267 145.842C-7.23733 99.052 8.90268 54.522 46.5027 24.682C64.776 10.1753 85.136 2.08205 107.583 0.402048C149.733 -2.75795 185.163 12.762 211.683 45.112C234.093 72.442 241.633 108.692 234.923 143.092C229.293 171.962 211.943 197.062 188.683 214.512C167.003 230.782 140.943 237.992 113.543 236.962ZM165.813 122.722C169.803 116.912 174.373 118.022 178.863 122.512C179.108 122.764 179.257 123.094 179.283 123.442C179.443 125.962 179.539 128.485 179.573 131.012C179.826 154.099 180.106 177.039 180.413 199.832C180.416 199.982 180.461 200.129 180.543 200.255C180.625 200.382 180.74 200.483 180.876 200.547C181.012 200.611 181.163 200.637 181.313 200.62C181.462 200.603 181.604 200.545 181.723 200.452C205.263 181.752 220.223 154.682 221.553 124.542C222.283 107.902 220.363 92.322 213.513 77.252C200.043 47.622 174.563 26.572 143.143 18.172C135.273 16.072 126.543 15.802 118.273 15.622C69.3827 14.582 26.0427 52.182 17.0627 99.492C15.5327 107.582 15.6327 116.352 15.9527 124.772C17.1027 154.812 32.2927 181.882 55.7127 200.482C55.8201 200.566 55.949 200.618 56.0847 200.633C56.2203 200.648 56.3574 200.624 56.4802 200.565C56.6031 200.505 56.7068 200.413 56.7797 200.297C56.8526 200.182 56.8918 200.049 56.8927 199.912C56.9827 175.322 57.2327 150.622 57.8627 126.032C58.1227 116.272 72.0527 116.452 72.2927 126.762C72.8727 151.232 72.5627 179.992 73.2527 209.732C73.2648 210.177 73.4001 210.611 73.6436 210.985C73.8871 211.359 74.2293 211.659 74.6327 211.852C102.263 224.932 135.743 225.402 163.353 211.382C163.554 211.278 163.725 211.125 163.847 210.937C163.97 210.749 164.041 210.534 164.053 210.312C165.513 181.442 163.693 152.402 165.513 123.572C165.532 123.271 165.636 122.977 165.813 122.722Z" fill={color} />
        <path d="M49.6023 109.292C47.9123 108.842 44.5424 107.052 44.4924 104.822C44.2224 92.5516 57.4324 84.7115 68.0524 83.0915C80.9623 81.1115 99.1123 88.8415 101.042 102.992C101.542 106.682 98.7424 108.452 95.2524 109.392C94.9457 109.472 94.6623 109.418 94.4023 109.232C91.0023 106.792 87.7323 103.322 84.1523 101.862C78.2423 99.4415 65.2723 98.7716 60.0823 102.312C57.1423 104.325 54.3523 106.488 51.7123 108.802C51.0923 109.342 50.389 109.505 49.6023 109.292Z" fill={color} />
        <path d="M142.213 109.412C140.683 109.072 136.523 107.412 136.533 105.192C136.547 99.4515 138.69 94.6948 142.963 90.9215C156.373 79.1015 176.073 80.5215 188.323 92.9715C191.603 96.3115 193.133 100.385 192.913 105.192C192.893 105.532 192.773 105.828 192.553 106.081C186.373 113.051 180.853 103.231 175.193 101.371C167.943 98.9815 155.523 98.8914 149.363 104.091C146.523 106.491 144.483 108.185 143.243 109.172C143.099 109.284 142.932 109.364 142.754 109.406C142.576 109.447 142.391 109.449 142.213 109.412Z" fill={color} />
        <path d="M118.809 194.881C111.778 194.906 105.024 191.745 100.033 186.095C95.0413 180.445 92.2215 172.769 92.1936 164.754C92.1656 156.739 94.9317 149.043 99.8835 143.358C104.835 137.674 111.567 134.466 118.598 134.442C125.629 134.417 132.383 137.577 137.374 143.227C142.365 148.877 145.185 156.554 145.213 164.569C145.241 172.584 142.475 180.28 137.523 185.964C132.571 191.649 125.84 194.857 118.809 194.881ZM118.637 179.551C120.113 179.554 121.576 179.172 122.941 178.427C124.306 177.682 125.547 176.589 126.593 175.21C127.639 173.831 128.47 172.193 129.038 170.39C129.606 168.587 129.9 166.654 129.903 164.701C129.91 160.757 128.733 156.973 126.63 154.181C124.527 151.388 121.67 149.817 118.689 149.811C117.213 149.809 115.751 150.191 114.386 150.936C113.021 151.681 111.78 152.774 110.734 154.153C109.688 155.532 108.857 157.17 108.289 158.973C107.721 160.776 107.427 162.709 107.423 164.662C107.416 168.606 108.594 172.39 110.697 175.182C112.8 177.974 115.656 179.546 118.637 179.551Z" fill={color} />
    </svg>

}


export const svg2 = ({size, color}) => {
    return <svg width={size} height={size} viewBox="0 0 238 238" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M107.215 236.519C56.9251 231.879 13.415 194.089 2.98501 144.449C-14.145 62.8889 52.685 -8.95105 134.185 0.908951C182.315 6.72895 223.625 43.7689 234.355 91.1489C252.575 171.609 189.255 244.079 107.215 236.519ZM49.805 195.519C94.225 235.909 161.015 229.269 199.225 183.429C213.065 166.829 221.045 146.509 221.805 124.889C222.285 111.249 221.465 98.029 216.795 85.039C204.895 51.909 177.355 26.7589 143.245 18.1789C134.415 15.9489 124.665 15.709 115.315 15.599C109.855 15.539 104.802 15.9956 100.155 16.9689C68.8017 23.5556 44.9017 40.9456 28.455 69.1389C17.305 88.2489 14.915 107.769 16.285 129.569C17.855 154.509 31.575 178.939 49.805 195.519Z" fill={color} />
        <path d="M80.6962 110.619C88.9086 110.619 95.5662 103.961 95.5662 95.7489C95.5662 87.5364 88.9086 80.8789 80.6962 80.8789C72.4837 80.8789 65.8262 87.5364 65.8262 95.7489C65.8262 103.961 72.4837 110.619 80.6962 110.619Z" fill={color} />
        <path d="M157.326 110.659C165.555 110.659 172.226 103.988 172.226 95.7594C172.226 87.5304 165.555 80.8594 157.326 80.8594C149.097 80.8594 142.426 87.5304 142.426 95.7594C142.426 103.988 149.097 110.659 157.326 110.659Z" fill={color} />
        <path d="M103.376 151.088C117.166 148.768 131.786 149.368 144.726 154.328C156.296 158.758 169.266 167.138 175.306 178.538C177.436 182.568 172.506 186.008 169.536 187.498C169.25 187.645 168.943 187.695 168.616 187.648C166.27 187.301 164.56 186.614 163.486 185.588C156.953 179.321 152.43 175.415 149.916 173.868C137.996 166.528 125.04 163.811 111.046 165.718C110.693 165.764 110.526 165.968 110.546 166.328C110.6 167.161 110.7 168.261 110.846 169.628C113.286 192.418 89.2665 209.888 68.3265 200.128C56.5665 194.638 48.5065 182.168 50.6065 169.228C52.0565 160.308 68.6264 129.368 77.7664 127.428C82.5397 126.415 86.5665 127.928 89.8465 131.968C94.6665 137.908 98.9232 144.158 102.616 150.718C102.69 150.85 102.803 150.956 102.938 151.022C103.074 151.088 103.227 151.111 103.376 151.088ZM65.8265 173.568C65.9365 186.468 82.5664 192.148 91.4064 183.038C96.4064 177.888 96.4764 171.668 93.3964 165.388C90.0297 158.528 85.9265 152.045 81.0865 145.938C80.7998 145.578 80.5098 145.578 80.2165 145.938C74.9365 152.408 65.7565 165.228 65.8265 173.568Z" fill={color} />
    </svg>


}


export const svg3 = ({size, color}) => {
    return <svg width={size} height={size} viewBox="0 0 238 238" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M137.481 1.30519C185.261 8.24519 225.291 46.1852 235.161 93.4552C241.091 121.875 237.111 153.005 221.931 178.185C175.631 254.945 68.4411 257.325 19.0711 182.445C-5.47889 145.195 -5.00889 96.6452 16.4811 59.0352C34.4911 27.5252 70.2111 3.36519 106.531 0.335189C114.551 -0.334811 128.521 0.0051899 137.481 1.30519ZM221.721 107.185C218.041 65.2252 185.981 29.9852 146.101 18.7452C137.131 16.2252 127.571 15.6752 118.081 15.5152C85.1311 14.9852 55.2111 32.0052 35.6711 58.1452C20.2111 78.8252 15.0711 100.715 16.2611 125.795C19.0811 185.065 74.4611 228.755 132.261 221.255C174.851 215.735 213.731 180.825 220.741 138.005C222.334 128.252 222.661 117.979 221.721 107.185Z" fill={color} />
        <path d="M80.8712 110.436C89.0672 110.436 95.7112 103.792 95.7112 95.5959C95.7112 87.4 89.0672 80.7559 80.8712 80.7559C72.6753 80.7559 66.0312 87.4 66.0312 95.5959C66.0312 103.792 72.6753 110.436 80.8712 110.436Z" fill={color} />
        <path d="M157.482 110.455C165.673 110.455 172.312 103.815 172.312 95.6249C172.312 87.4345 165.673 80.7949 157.482 80.7949C149.292 80.7949 142.652 87.4345 142.652 95.6249C142.652 103.815 149.292 110.455 157.482 110.455Z" fill={color} />
        <path d="M131.411 161.506C137.401 165.986 143.841 167.896 145.291 176.146C147.761 190.206 126.351 197.936 116.071 198.736C113.691 198.916 111.831 199.406 109.601 198.166C106.441 196.406 108.521 193.106 110.791 191.996C115.791 189.566 128.691 185.316 127.891 177.876C127.191 171.396 117.721 167.866 112.491 165.446C108.381 163.546 106.381 159.626 111.541 157.286C116.728 154.933 120.558 152.94 123.031 151.306C138.801 140.886 114.361 131.636 109.071 128.506C106.421 126.926 108.291 122.966 110.801 122.686C111.141 122.646 112.441 122.746 114.701 122.986C126.771 124.296 155.141 134.936 142.601 151.546C139.881 155.166 135.691 157.636 131.571 159.696C130.545 160.21 130.491 160.813 131.411 161.506Z" fill={color} />
    </svg>


}


export const svg4 = ({size, color}) => {
    return <svg width={size} height={size} viewBox="0 0 239 237" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M115.736 236.913C48.786 234.933 -2.09401 179.073 0.91599 112.643C3.26599 60.7628 40.816 14.6929 91.306 3.06285C97.756 1.57285 103.886 0.212842 110.626 0.0828417C120.453 -0.0971583 127.486 0.0161714 131.726 0.422838C182.786 5.37284 225.756 44.6028 235.666 94.7328C250.576 170.133 192.676 239.183 115.736 236.913ZM19.186 143.723C28.586 181.793 58.496 210.343 96.496 219.173C153.716 232.473 211.916 193.783 221.186 135.923C223.406 122.113 222.776 105.323 218.976 91.4528C210.596 60.8528 191.639 38.5962 162.106 24.6828C143.836 16.0728 121.316 12.9428 101.496 16.7728C65.636 23.7128 37.926 45.5229 23.816 79.0729C15.496 98.8529 14.026 122.833 19.186 143.723Z" fill={color} />
        <path d="M81.0868 110.404C89.2993 110.404 95.9568 103.747 95.9568 95.5341C95.9568 87.3216 89.2993 80.6641 81.0868 80.6641C72.8743 80.6641 66.2168 87.3216 66.2168 95.5341C66.2168 103.747 72.8743 110.404 81.0868 110.404Z" fill={color} />
        <path d="M130.286 106.573C129.746 106.193 129.473 105.676 129.466 105.023C129.206 90.7528 145.376 82.4928 157.676 82.5728C162.883 82.6062 167.803 83.7828 172.436 86.1028C179.356 89.5728 186.996 96.5828 185.706 105.183C185.66 105.501 185.513 105.793 185.286 106.013C183.879 107.399 182.213 108.333 180.286 108.813C180.116 108.854 179.941 108.858 179.772 108.825C179.602 108.792 179.443 108.723 179.306 108.623C174.586 105.223 169.516 101.183 163.996 100.003C157.896 98.6962 151.929 99.2728 146.096 101.733C140.276 104.193 137.266 111.493 130.286 106.573Z" fill={color} />
        <path d="M68.4657 166.103C65.5957 163.253 62.1757 159.003 63.2457 155.203C67.4057 140.403 81.1557 157.773 85.7857 161.103C104.626 174.663 130.246 176.243 150.026 163.263C154.276 160.473 158.516 156.173 162.636 152.543C164.662 150.757 166.316 149.703 167.596 149.383C172.546 148.143 176.596 154.173 175.396 158.673C174.969 160.28 173.786 162.143 171.846 164.263C161.036 176.083 147.516 183.853 131.466 186.263C107.186 189.903 86.0557 183.533 68.4657 166.103Z" fill={color} />
    </svg>


}

export const svg5 = ({size, color}) => {
    return <svg width={size} height={size} viewBox="0 0 238 238" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M32.4656 199.847C6.88563 172.82 -3.57103 140.43 1.09563 102.677C7.31563 52.4069 47.3157 10.5569 97.0657 1.70686C100.126 1.15686 103.176 0.346882 106.226 0.226882C117.852 -0.213118 127.156 -0.0131427 134.136 0.826857C154.209 3.24686 172.282 10.5035 188.356 22.5969C224.756 49.9769 242.276 91.1669 235.876 136.557C228.666 187.657 186.166 229.527 134.946 235.967C95.3457 240.947 59.4456 228.337 32.4656 199.847ZM15.7256 125.547C18.6256 181.447 66.6456 223.317 121.636 221.867C174.576 220.477 221.666 175.577 221.406 121.697C221.376 115.667 221.596 109.117 220.856 103.667C215.296 62.6069 184.296 29.6769 145.016 18.5869C135.496 15.9069 124.996 15.3869 114.996 15.4569C80.1156 15.6869 49.3657 35.6269 30.7457 64.5969C18.3757 83.8369 14.5856 103.387 15.7256 125.547Z" fill={color} />
        <path d="M156.937 110.507C165.166 110.507 171.837 103.836 171.837 95.607C171.837 87.378 165.166 80.707 156.937 80.707C148.708 80.707 142.037 87.378 142.037 95.607C142.037 103.836 148.708 110.507 156.937 110.507Z" fill={color} />
        <path d="M80.3153 110.476C88.5278 110.476 95.1853 103.819 95.1853 95.6063C95.1853 87.3938 88.5278 80.7363 80.3153 80.7363C72.1028 80.7363 65.4453 87.3938 65.4453 95.6063C65.4453 103.819 72.1028 110.476 80.3153 110.476Z" fill={color} />
        <path d="M72.2156 170.507C68.5556 167.317 62.1556 160.957 61.8856 156.757C61.4856 150.557 69.4756 146.907 74.1356 151.357C77.9422 154.997 80.4755 157.364 81.7355 158.457C102.516 176.517 133.796 177.017 154.796 158.927C159.996 154.447 166.226 144.737 173.306 152.427C176.426 155.837 175.286 159.347 172.476 162.667C160.616 176.677 144.626 185.427 126.226 186.837C105.896 188.397 87.8056 184.097 72.2156 170.507Z" fill={color} />
    </svg>


}