import React, { useCallback, useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useSelector } from "react-redux";
import { HiOutlineDownload } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import ReportPateForExport from "./ReportPageForExport";
import axios from "../../axios";
import { CSVLink } from "react-csv";

const exportToCSV = (data, name) => {
  if (data !== undefined) {
    const csvData = data?.map((row) =>
      row?.map((i) => ({
        type: i?.type,
        value: i?.value,
      }))
    );
    const uniqueTypes = [
      ...new Set(csvData.flatMap((item) => item.map((entry) => entry.type))),
    ];

    const csvHeaders = uniqueTypes.map((type) => ({
      label: type === "text" ? "name" : type,
      key: type,
    }));

    const transformedData = csvData.map((item) => {
      const entryObject = {};
      item.forEach((entry) => {
        entryObject[entry.type] = entry.value;
      });
      return entryObject;
    });

    return (
      <CSVLink
        data={transformedData}
        headers={csvHeaders}
        filename={`${name}.csv`}
      >
        <HiOutlineDownload size={24} />
      </CSVLink>
    );
  }
};

const Table = ({ items }) => {
  const navigate = useNavigate();
  const [id, setId] = useState("");
  const [conatactFormDate, setContactFormData] = useState(null);
  const [buttonClick, setButtonClick] = useState(null);
  const { mood } = useSelector((state) => state.mood);
  const [itemsPerPage, setItemPerPage] = useState(10);
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = items.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(items.length / itemsPerPage);
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % items.length;

    setItemOffset(newOffset);
  };

  const report = (id) => {
    navigate(`/report/${id}`);
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        for (const item of items) {
          const { data } = await axios.post("/report", {
            label: "All",
            id: item.shortID,
            fromDate: "",
            toDate: "",
          });

          setContactFormData((prevData) => ({
            ...prevData,
            [item.id]: data.contactForm[data.contactForm.length - 1],
          }));
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [items]);

  const handelExport = (event) => {
    if (buttonClick == null) {
      setButtonClick(true);
    } else if (buttonClick === false) {
      setButtonClick(true);
    } else {
      setButtonClick(false);
    }
  };

  function Items({ currentItems }) {
    return (
      <>
        {currentItems &&
          currentItems?.map((i, index) => {
            return (
              <>
                <tr className="" key={index}>
                  <td className={`react_table_body_content`}>
                    <div
                      className={` react_table_body_content_text ${
                        mood === "light" ? "text-[#18181B]" : "text-gray"
                      }`}
                      // onClick={() => editQuestion(i.id, i.eventName)}
                    >
                      {i?.eventName}
                    </div>
                  </td>

                  <td className=" react_table_body_content">
                    <div
                      className={`react_table_body_content_text1 ${
                        mood === "light" ? "text-[#71717A]" : "text-gray"
                      }`}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-4 h-4 mr-2"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5"
                        />
                      </svg>
                      {new Date(i?.createdAt).toLocaleDateString()}
                    </div>
                  </td>

                  <td className=" react_table_body_content">
                    <div
                      className={`react_table_body_content_text1 ${
                        mood === "light" ? "text-[#71717A]" : "text-gray"
                      }`}
                    >
                      {i.no_of_question < 9
                        ? "0" + i?.no_of_question
                        : i?.no_of_question}
                    </div>
                  </td>

                  <td className=" react_table_body_content">
                    <div
                      className={`react_table_body_content_text1 ${
                        mood === "light" ? "text-[#71717A]" : "text-gray"
                      }`}
                    >
                      {i?.open_count < 9 ? "0" + i?.open_count : i?.open_count}
                    </div>
                  </td>

                  <td className=" react_table_body_content">
                    <div
                      className={`react_table_body_content_text1 ${
                        mood === "light" ? "text-[#71717A]" : "text-gray"
                      }`}
                    >
                      {i.feedback_count < 9
                        ? "0" + i?.feedback_count
                        : i?.feedback_count}
                    </div>
                  </td>
                  <td className=" react_table_body_content">
                    <div
                      className={`react_table_body_content_text1 ${
                        mood === "light" ? "text-[#71717A]" : "text-gray"
                      }`}
                    >
                      {(() => {
                        const v = (i?.feedback_count / i?.open_count) * 100;
                        const p = Math.min(v, 100);
                        const percentage = Math.round(p);

                        return `${
                          isNaN(percentage)
                            ? "00"
                            : percentage < 9
                            ? "0" + percentage
                            : percentage
                        }%`;
                      })()}
                    </div>
                  </td>

                  <td className=" react_table_body_content">
                    <div className="react_table_body_content_text1 cursor-pointer">
                      <HiOutlineDownload
                        size={24}
                        onMouseEnter={() => setId(i?.shortID)}
                        onClick={() => {
                          setId(i?.shortID);
                          handelExport();
                        }}
                      />
                    </div>
                  </td>

                  <td className="react_table_body_content">
                    <div className="react_table_body_content_text1 cursor-pointer">
                      {conatactFormDate !== null ? (
                        exportToCSV(conatactFormDate[i?.id], i?.eventName)
                      ) : (
                        <HiOutlineDownload size={24} />
                      )}
                    </div>
                  </td>

                  <td className=" react_table_body_content">
                    <div className="react_table_body_content_text1">
                      <button
                        onClick={() => report(i.shortID)}
                        className="primaryButton !px-5"
                      >
                        View
                      </button>
                    </div>
                  </td>
                </tr>
              </>
            );
          })}
      </>
    );
  }

  return (
      <div className=" mt-5">
          <div className="report_table bg-white rounded-xl dropdownBackground">
              <table className="report_table_head">
                  <thead className="reports_table_head_content">
                      <tr>
                          <th className="table_head_title">Survey name</th>

                          <th className="table_head_title">Created date</th>

                          <th className="table_head_title">Questions</th>

                          <th className="table_head_title">OPENED</th>

                          <th className="table_head_title">Feedback</th>

                          <th className="table_head_title">COMPLETION RATE</th>

                          <th className="table_head_title">Report</th>

                          <th className="table_head_title">Contact FORM</th>

                          <th className="table_head_title">USER FEEDBACK</th>
                      </tr>
                  </thead>

                  <tbody className="react_table_body">
                      <Items currentItems={currentItems} />
                  </tbody>
              </table>
          </div>

          <div className="flex w-full justify-end mt-4">
              <ReactPaginate
                  breakLabel="..."
                  nextLabel=">"
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={5}
                  pageCount={pageCount}
                  previousLabel="<"
                  renderOnZeroPageCount={null}
                  breakClassName={"page-item"}
                  breakLinkClassName={"page-link"}
                  containerClassName={"pagination"}
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link"}
                  previousClassName={"page-item"}
                  previousLinkClassName={"page-link"}
                  nextClassName={"page-item"}
                  nextLinkClassName={"page-link"}
                  activeClassName={"active"}
              />
          </div>
          <div className="fixed top-0 left-[150%] w-[100vw]">
              <ReportPateForExport
                  id={id}
                  buttonClick={buttonClick}
              />
          </div>
      </div>
  )
};

export default Table;
