import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

const EmailPreview = ({ subject, message, logo }) => {
  const { user } = useSelector((state) => state.userAuth);
  const { emailTemplate } = useSelector((state) => state.emailTemplate);

  const [nameContant, setnameContant] = useState("Hi {Name}");
  const [bestRegards, setbestRegards] = useState("{Company Name}");

  useEffect(() => {
    setbestRegards(user?.company_name);
  }, [user]);


  function convertNewlineToBr(msg) {
    if (msg !== null && msg !== undefined) {
      return msg.replace(/\n/g, "<br/>");
    }
  }


  return (
    <div className=" w-[100%] relative overflow-hidden">
      <div
        style={{
          background: emailTemplate?.[0]?.bottomColor,
        }}
        className={`absolute bottom-0 w-full h-[15px]`}
      ></div>
      <div className="email_pre_container relative">
        <div className="email_logo">
          {logo !== undefined && logo.length !== 0 && (
            <img src={logo} alt="logo" />
          )}
        </div>
        {/* email_content */}
        <div className="email_pre_content">
          <h1>{subject}</h1>
        </div>

        <div className="email_pre_content">
          <p>{nameContant}</p>
        </div>

        {/* 640 */}
        <div className="email_pre_content">
          <p
            dangerouslySetInnerHTML={{ __html: convertNewlineToBr(message) }}
          ></p>
        </div>
       
      </div>
    </div>
  );
};

export default EmailPreview;
