/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setFeedbackValue } from "../../redux/slices/feedbackValue";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import α from "color-alpha";

const radioButton = ({
  inputQuestion,
  id,
  questionType,
  answer,
  nextQuz,
  next,
  eventId,
  viewStatus,
}) => {
  const { questionValues } = useSelector((state) => state.questions);
  const [nextValues, setNextValues] = useState([]);
  const dispatch = useDispatch();
  const { mood } = useSelector((state) => state.mood);
  const { questions } = useSelector((state) => state);
  const buttonColors = questions.buttonColor;
  const textColors = questions.textColor;
  const logoInfo = questions.logoDetails;
  const [preValue, setPreValue] = useState([]);
  const [isChecked, setisChecked] = useState("");


  let prev = Number(id) - 1;
  useEffect(() => {
    const filter = questionValues.filter((e) => Number(e.id) === Number(prev));
    setPreValue(filter);
  }, [questionType, id]);
  // const { buttonColor} = useSelector((state) => state.globalDefine);
  // const { textColor } = useSelector((state) => state.globalDefine);
  const col = α(
    (buttonColors !== null) & (buttonColors !== undefined)
      ? buttonColors
      : "#4F46E5",
    0.2
  );
  // const { companyLogo } = useSelector((state) => state.globalDefine);

  let nextId = Number(id) + 1;

  const getValue = (answer) => {
    if (
      inputQuestion?.length !== 0 &&
      answer?.length !== 0 &&
      id?.length !== 0 &&
      questionType?.length !== 0 &&
      answer?.length !== 0 &&
      eventId?.length
    ) {
      return dispatch(
        setFeedbackValue({
          eventId: eventId,

          list_of_items: [
            {
              question: inputQuestion,
              answer: answer,
              answerId: id,
              type: questionType,
            },
          ],
        })
      );
    }
  };

  useEffect(() => {
    const filter = questionValues.filter(
      (e) => Number(e.id) === Number(nextId)
    );
    setNextValues(filter);
  }, [questionType, id]);

    // eslint-disable-next-line react-hooks/rules-of-hooks

  //bg-[url('https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg')]
  return (
    <div className="w-full flex flex-col items-center mt-[30px] overflow-y-auto overflow-x-hidden">
      {logoInfo?.length !== 0 && (
        <img
          className="mb-[30px] max-w-[125px]"
          src={logoInfo[0]?.logoLocation}
          alt=""
        />
      )}
      {/* {
        logoInfo.length === 0 && companyLogo !== null && 
        <img
        className="mb-[30px] max-w-[125px]"
        src={companyLogo}
        alt=""
      />
      } */}

      <h3
        style={{
          color: `${
            (textColors !== null) & (textColors !== undefined) && textColors
            //  textColor : textColors
          }`,
        }}
        className={`w-[80%] max-h-full z-20 text-center mx-10 text-[18px] font-[500] font-inter ${
          mood === "light" ? "text-black" : "text-white"
        } break-words`}
      >
        <span className="w-full">{inputQuestion}</span>
      </h3>
      {answer !== false &&
        answer?.map((i) => (
          <div
            key={i.id}
            style={{
              border: `3px solid  ${
                (buttonColors !== null) & (buttonColors !== undefined)
                  ? buttonColors
                  : "#4F46E5"
                //  buttonColor : buttonColors
              }`,
              background: `${col}`,
            }}
            className={`${
              viewStatus === "computer" ? "w-[50%]" : "w-[74%]"
            }  break-words flex items-center pl-2 opacity-80 rounded-[5px] py-3 border ${
              mood === "light"
                ? "border-[#E4E4E7] bg-[#f1f1f1]"
                : "bg-[#232734] border-[#303544]"
            } mt-4`}
          >
            <input
              id={i.id}
              type="radio"
              value={i.value} // Replace 'i.value' with the actual value you want to assign to this radio option
              name="bordered-radio"
              style={{
                appearance: "none",
                WebkitAppearance: "none", // Use WebkitAppearance and MozAppearance without the hyphen
                MozAppearance: "none",
                width: "20px",
                height: "20px",
                border: `2px solid ${
                  (buttonColors !== null) & (buttonColors !== undefined)
                    ? buttonColors
                    : "#4F46E5"
                  //  buttonColor : buttonColors
                }`,
                borderRadius: "50%",
                outline: "none",
                backgroundColor:
                  isChecked === i.id
                    ? `${
                        (buttonColors !== null) & (buttonColors !== undefined)
                          ? buttonColors
                          : "#4F46E5"
                        //  buttonColor : buttonColors
                      }`
                    : "transparent",
              }}
              className="rounded cursor-pointer w-4 h-4" // Use 'className' instead of 'class' for React
            />

            <label
              for={i.id}
              className={` ${
                viewStatus === "computer" ? "w-[85%]" : "w-[88%]"
              }  px-0 ml-2 mr-2 text-base font-medium ${
                mood === "light" ? "text-black" : "text-gray"
              } cursor-pointer  break-words`}
              onClick={() => {
                getValue(i.value);
                setisChecked(i?.id);
              }}
            >
              {i.value}
            </label>
          </div>
        ))}

      <div className="flex items-center gap-x-3">
        {preValue?.length !== 0 && (
          <button
            type="button"
            style={{
              background: `${
                buttonColors === null || buttonColors === undefined
                  ? "#4F46E5"
                  : //  buttonColor
                    buttonColors
              }`,
            }}
            className=" rounded-full p-3 my-3 transition-all duration-200 text-lg font-semibold text-center block "
            onClick={() => {
              nextQuz(preValue[0].type);
              next(prev);
            }}
          >
            <IoIosArrowBack size={40} color="white" />
          </button>
        )}
        {nextValues.length !== 0 && (
          <button
            style={{
              background: `${
                buttonColors === null || buttonColors === undefined
                  ? "#4F46E5"
                  : //  buttonColor
                    buttonColors
              }`,
            }}
            className=" rounded-full p-3 my-3 transition-all duration-200 text-lg font-semibold text-center block "
            onClick={() => {
              nextQuz(nextValues[0].type);
              next(nextId);
            }}
          >
            <IoIosArrowForward size={40} color="white" />
          </button>
        )}
      </div>
    </div>
  );
};

export default radioButton;
