import React, { useEffect, useState } from "react";
import Switch from "react-switch";
import QrLogoUpload from "./QrLogoUpload";
import { useSelector } from "react-redux";
import EventName from "../popUp/EventName";
import axios from "../../axios";
import { toast } from "react-hot-toast";
import { RiArrowDropDownLine } from "react-icons/ri";
import { BlockPicker } from "react-color";
import { useDispatch } from "react-redux";
import { setLogoValue } from "../../redux/slices/qrCodeLogo";

const InsertText = ({ content, qrTemplate }) => {
  const getSavedTemplate = JSON.parse(localStorage.getItem("qrcodeTemplate"));
  const { logo } = useSelector((state) => state.qrLogo);
  const { user } = useSelector((state) => state.userAuth);
  const [brandColor, setBrandColor] = useState(
    getSavedTemplate !== null ? getSavedTemplate.brandColor : "#4F46E5"
  );
  const [brandColor1, setBrandColor1] = useState(
    getSavedTemplate !== null ? getSavedTemplate.brandTextColor : "#ffffff"
  );
  const [toggle, settoggle] = useState(
    getSavedTemplate !== null ? getSavedTemplate.weblinkStatus : true
  );
  const [toggle1, settoggle1] = useState(
    getSavedTemplate !== null ? getSavedTemplate.brandStatus : true
  );
  const [uploadLogo, setuploadLogo] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [QRlogo, setQRlogo] = useState(false);
  const [eventList, seteventList] = useState([]);
  const [selectedEvent, setselectedEvent] = useState("Select Survey");
  const [eveListDropDown, seteveListDropDown] = useState(false);
  const [qrid, setQrid] = useState("");
  const [selectedEventurl, setselectedEventurl] = useState("");
  const [colorCode, setColorCode] = useState(false);
  const [colorCode1, setColorCode1] = useState(false);
  const getpreBrandColor = localStorage.getItem("preBrandColor");
  const getpreBrandColor1 = localStorage.getItem("preBrandColor1");
  const dispatch = useDispatch();

  useEffect(() => {
    localStorage.setItem("preBrandColor", brandColor);
    localStorage.setItem("preBrandColor1", brandColor1);
  }, []);

  const [lengthInfo, setlengthInfo] = useState({
    weblink: 0,
    title: 0,
    description: 0,
  });

  const [templateInfo, setTemplateInfo] = useState({
    weblink:
      getSavedTemplate !== null
        ? getSavedTemplate.weblink
        : "www.feedbackSync.com",
    title: getSavedTemplate !== null ? getSavedTemplate.title : "",
    description: getSavedTemplate !== null ? getSavedTemplate.description : "",
  });

  const handelChange = (e) => {
    const { name, value } = e.target;

    setTemplateInfo((pre) => {
      return {
        ...pre,
        [name]: value,
      };
    });
  };

  useEffect(() => {
    setlengthInfo({
      weblink: templateInfo.weblink?.length,
      title: templateInfo.title?.length,
      description: templateInfo.description?.length,
    });
    content({
      weblink: templateInfo.weblink,
      title: templateInfo.title,
      description: templateInfo.description,
      qrcode: selectedEventurl,
      weblinkStatus: toggle,
      brandStatus: toggle1,
      brandColor: brandColor,
      brandTextColor: brandColor1,
      qrID: qrid,
    });
  }, [
    templateInfo,
    toggle,
    toggle1,
    selectedEventurl,
    brandColor,
    brandColor1,
  ]);

  useEffect(() => {
    const Api = async () => {
      if (user) {
        const { data } = await axios.post("/user/event", {
          id: user.id,
        });

        if (data.success) {
          seteventList(data.data);
        }
      }
    };
    Api();
  }, [user]);

  useEffect(() => {
    if (getSavedTemplate) {
      dispatch(setLogoValue(getSavedTemplate.logo));
    }
  }, [getSavedTemplate]);

  const handelRemove = async () => {
    if (!logo) {
      return toast.error("Logo not available");
    } else {
      setQRlogo(!QRlogo);
    }
  };

  const handleColorChange = (newColor) => {
    setBrandColor(newColor.hex);
  };

  const handleColorChange1 = (newColor) => {
    setBrandColor1(newColor.hex);
  };

  return (
    <div className="email_main">
      <h1 className="email_head">Edit QR Code Template</h1>
      <p className="email_sub_title">
        Enter the qr code template details below.{" "}
      </p>

      <div className="input_tag">
        <div className="input_tag_head flex items-center justify-between">
          <p>Your Website Link</p>

          <Switch
            checked={toggle}
            className="react-switch"
            onColor="#4F46E5"
            onHandleColor="#fff"
            handleDiameter={20}
            onChange={() => settoggle(!toggle)}
            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
          />
        </div>
        <div className="email_input_tag_input !flex !items-center relative">
          <p className=" absolute right-2">{lengthInfo.weblink}/40</p>
          <input
            maxLength={40}
            type="text"
            value={templateInfo.weblink}
            name="weblink"
            className="email_input"
            placeholder="Website link"
            onChange={handelChange}
          />
        </div>
      </div>

      <div className="input_tag">
        <div className="input_tag_head">
          <p>Logo</p>
        </div>
        <div className="qr_logo_upload">
          <div className="qr_logo_img">
            {getSavedTemplate !== null && getSavedTemplate.logo !== null ? (
              <img src={getSavedTemplate.logo} alt="logo" />
            ) : (
              <>{logo !== null && <img src={logo} alt="logo" />}</>
            )}
          </div>
          <div className="qr_logo_btn">
            <p
              className="qr_logo_text"
              onClick={() => setuploadLogo(!uploadLogo)}
            >
              Upload
            </p>
            <p className="qr_logo_remove" onClick={handelRemove}>
              Remove
            </p>
          </div>
        </div>
      </div>

      <div className="input_tag">
        <div className="input_tag_head">
          <p>Title</p>
        </div>
        <div className="email_input_tag_input !flex !items-center relative">
          <p className=" absolute right-2">{lengthInfo.title}/40</p>
          <input
            maxLength={40}
            type="text"
            value={templateInfo.title}
            name="title"
            className="email_input"
            placeholder="Title"
            onChange={handelChange}
          />
        </div>
      </div>

      <div className="input_tag">
        <div className="input_tag_head">
          <p>Your Surveys</p>
        </div>
        <div
          className="drop_down relative"
          onClick={() => seteveListDropDown(!eveListDropDown)}
        >
          <div className="drop_down_select">
            <p>{selectedEvent}</p>
            <RiArrowDropDownLine size={28} className="absolute right-0" />
          </div>
          {eveListDropDown && (
            <div className="drop_down_list">
              {eventList.map((i, index) => (
                <p
                  onClick={() => {
                    setselectedEventurl(
                      `${window.location.origin}/${i.shortID}`
                    );
                    setQrid(i.shortID);
                    setselectedEvent(i.eventName);
                    seteveListDropDown(false);
                  }}
                  key={index}
                  className="drop_down_list_text"
                >
                  {i.eventName}
                </p>
              ))}
            </div>
          )}
        </div>
      </div>

      {/* <div className="input_tag">
                <div className="input_tag_head">
                    <p>Campaign link for generating QR code</p>
                </div>
                <div className="email_input_tag_input">
                    <input
                        name="qrCode"
                        className="email_input"
                        placeholder="QR code link"
                        value={selectedEventurl}
                    />
                </div>
            </div> */}

      <div className="input_tag">
        <div className="input_tag_head">
          <p>Description</p>
        </div>
        <div className="email_input_tag_input relative">
          <p className=" absolute right-4 bottom-0">
            {lengthInfo.description}/120
          </p>
          <textarea
            maxLength={120}
            value={templateInfo.description}
            name="description"
            rows="5"
            className="email_input"
            placeholder="Description"
            onChange={handelChange}
            style={{
              resize: "none",
            }}
          />
        </div>
      </div>

      <div className="input_tag ">
        <div className="color">
          <div>
            <div className="input_tag_head">
              <p>Brand Color</p>
            </div>

            <div className="color_content relative">
              <p
                style={{
                  background: brandColor,
                  boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.15)",
                }}
                className="brand_input_color"
                onClick={() => setColorCode(!colorCode)}
              ></p>

              <p
                className="color_content_text"
                onClick={() => setBrandColor("#4F46E5")}
              >
                Default
              </p>

              {colorCode && (
                <div className="absolute top-[110%] -left-6 ">
                  <BlockPicker
                    color={brandColor}
                    onChange={handleColorChange}
                  />
                  <div className=" w-[175px] flex items-center justify-between mb-6">
                    <button
                      onClick={() => {
                        setBrandColor(
                          getpreBrandColor !== null && getpreBrandColor
                        );
                        setColorCode(false);
                      }}
                      className="bg-white text-black border-2 border-black w-[48%] rounded-lg"
                    >
                      Cancel
                    </button>
                    <button
                      onClick={() => {
                        setBrandColor(brandColor);
                        setColorCode(!colorCode);
                      }}
                      className="bg-blue text-white w-[48%] rounded-lg"
                    >
                      Save
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div>
            <div className="input_tag_head">
              <p>Brand Text Color</p>
            </div>

            <div className="color_content relative">
              <p
                style={{
                  background: brandColor1,
                  boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.15)",
                }}
                className="brand_input_color"
                onClick={() => setColorCode1(!colorCode1)}
              ></p>

              <p
                className="color_content_text"
                onClick={() => setBrandColor1("#ffffff")}
              >
                Default
              </p>
              {colorCode1 && (
                <div className="absolute top-[110%] -left-6 ">
                  <BlockPicker
                    color={brandColor1}
                    onChange={handleColorChange1}
                  />
                  <div className=" w-[175px] flex items-center justify-between mb-6">
                    <button
                      onClick={() => {
                        setBrandColor1(
                          getpreBrandColor1 !== null && getpreBrandColor1
                        );
                        setColorCode1(false);
                      }}
                      className="bg-white text-black border-2 border-black w-[48%] rounded-lg"
                    >
                      Cancel
                    </button>
                    <button
                      onClick={() => {
                        setBrandColor1(brandColor1);
                        setColorCode1(!colorCode1);
                      }}
                      className="bg-blue text-white w-[48%] rounded-lg"
                    >
                      Save
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="input_tag !mb-0 mt-2 pt-4 border-t border-gray">
        <div className="input_tag_head">
          <p>Remove Feedback Sync Branding</p>
          <Switch
            checked={toggle1}
            className="react-switch"
            onColor="#4F46E5"
            onHandleColor="#fff"
            handleDiameter={20}
            onChange={() => settoggle1(!toggle1)}
            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
          />
        </div>
      </div>

      {uploadLogo && (
        <QrLogoUpload
          close={setuploadLogo}
          check={refresh}
          reload={setRefresh}
        />
      )}

      {<EventName show={QRlogo} status="deleteQRLogo" hide={setQRlogo} qrTemplate={qrTemplate}/>}
    </div>
  );
};

export default InsertText;
