import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import DashboardComponents from "../../components/DashboardComponents";
import Navbar from "../../components/Navbar";
import { setEventValue } from "../../redux/slices/eventName";
import axios from "../../axios";
import { setUserValue } from "../../redux/slices/auth";
import { toast } from "react-hot-toast";
import {
  bgImageDetails,
  logoDetails,
  questionEmpty,
} from "../../redux/slices/questions";
import { useNavigate, useSearchParams } from "react-router-dom";
import config from "../../constants";
import { Cookies } from "react-cookie";
import {
  addButtonColor,
  addCompanyLogo,
  addTextColor,
  addWelcomeBg,
  addWelcomeTextColor,
} from "../../redux/slices/globalDefine";
import TrialModal from "../../components/DashboardComponents/TrialModal";
import TawkTo from "tawkto-react";

import { useLocation } from "react-router-dom";
import SmallScreenOverlay from './../SmallScreenOverlay/index'

const Dashboard = () => {
  const location = useLocation();

  const { mood } = useSelector((state) => state.mood);
  const dispatch = useDispatch();
  const setNam = (data) => {
    dispatch(setEventValue(data));
  };
  const { user } = useSelector((state) => state.userAuth);
  const [searchParams] = useSearchParams();
  const Token = searchParams.get("token");
  const Admin = searchParams.get("admin");

  const getMethod = localStorage.getItem("Signin Method");
  const getUserID = localStorage.getItem("UserID");
  const initial = localStorage.getItem("initialLogin");

  const navigate = useNavigate();

  useEffect(() => {
    dispatch(logoDetails([]));
    dispatch(bgImageDetails([]));
    dispatch(questionEmpty());
    dispatch(setEventValue("New Event"));
    localStorage.removeItem("reRender");
    localStorage.removeItem("brandRender");
  }, []);

  if (Admin !== null) {
    const cookies = new Cookies();
    const cookieValue = cookies.get("token");
    if (cookieValue !== undefined) {
      localStorage.setItem("Token", cookieValue);
    } else {
      localStorage.setItem("Token", Token);
      // localStorage.setItem("SIGNIN", true);
    }
  }

  if (Token !== null) {
    localStorage.removeItem("Token");
    localStorage.setItem("Token", Token);
  }



  const userToken = localStorage.getItem("Token");

  useEffect(() => {
    dispatch(questionEmpty());
    dispatch(setEventValue("New Event"));
  }, []);

  useEffect(() => {
    const tawk = new TawkTo("64bf636c94cf5d49dc663afc", "1h65poog0");

    if (location.pathname === "/") {
      tawk.onStatusChange((e) => {
        console.log("status", e);
      });

      tawk.showWidget(); // Show the widget when on the homepage

      return () => {
        tawk.hideWidget(); // Hide the widget when unmounting
      };
    } else {
      tawk.hideWidget();
    }
  }, [location.pathname]);

  useEffect(() => {
    const Api = async () => {
      if (userToken !== null) {
        const { data } = await axios.get("/userDetails", {
          headers: {
            Authorization: userToken,
          },
        });
        dispatch(setUserValue(null));

        dispatch(setUserValue(data[0]));
        // dispatch(
        //   addCompanyLogo(
        //     data?.[0]?.company_logo !== undefined &&
        //       data[0].company_logo.length !== 0
        //       ? data[0].company_logo
        //       : null
        //   )
        // );
        // dispatch(
        //   addWelcomeBg(
        //     data[0].welcome_bg_logo !== undefined &&
        //       data[0].welcome_bg_logo.length !== 0
        //       ? data[0].welcome_bg_logo
        //       : null
        //   )
        // );
        // dispatch(
        //   addButtonColor(
        //     data[0].btn_color !== undefined &&
        //       data[0].btn_color.length !== 0
        //       ? data[0].btn_color
        //       : "#4F46E5"
        //   )
        // );
        // dispatch(
        //   addTextColor(
        //     data[0].text_color !== undefined &&
        //       data[0].text_color.length !== 0
        //       ? data[0].text_color
        //       : "#D9D9D9"
        //   )
        // );
        // dispatch(
        //   addWelcomeTextColor(
        //     data[0].welcome_screen_color !== undefined &&
        //       data[0].welcome_screen_color.length !== 0
        //       ? data[0].welcome_screen_color
        //       : "#000000"
        //   )
        // );
        return;
      } else if (initial == false) {
        if (getMethod === "google" || getMethod === "linkedIn") {
          if (getUserID !== null) {
            const { data } = await axios.post("/getParticularUser", {
              id: getUserID,
            });
            dispatch(setUserValue(null));

            if (data.length === 0) {
              navigate("/login");
            } else {
              localStorage.removeItem("initialLogin");
              return dispatch(setUserValue(data[0]));
            }
          }
        }
      } else {
        // const { data } = await axios.get("/success", {
        //   withCredentials: true,
        // });
        const response = await fetch(`${config.SERVER_URL}/success`, {
          credentials: "include",
        });
        const data = await response.json();

        if (data.user.verify) {
          dispatch(setUserValue(null));
          localStorage.setItem("UserID", data.user.id);

          dispatch(setUserValue(data.user));
          //   dispatch(
          //     addCompanyLogo(
          //       data.user.company_logo.length !== 0
          //         ? data.user.company_logo
          //         : null
          //     )
          //   );
          //   dispatch(
          //     addWelcomeBg(
          //       data.user.welcome_bg_logo.length !== 0
          //         ? data.user.welcome_bg_logo
          //         : null
          //     )
          //   );
          //   dispatch(
          //     addButtonColor(
          //       data.user.btn_color.length !== 0 ? data.user.btn_color : "#4F46E5"
          //     )
          //   );
          //   dispatch(
          //     addTextColor(
          //       data.user.text_color.length !== 0
          //         ? data.user.text_color
          //         : "#000000"
          //     )
          //   );
          //   dispatch(
          //     addWelcomeTextColor(
          //       data.user.welcome_screen_color.length !== 0
          //         ? data.user.welcome_screen_color
          //         : "#000000"
          //     )
          //   );
          return;
        } else {
          return toast.error(`Somthing went wrong`);
        }
      }
    };

    Api();
  }, [userToken, getMethod]);

  console.log('user', user);

  return (
    <>
    <div
      className={` max-lg:hidden flex flex-col h-screen pb-4 ${
        mood === "light" ? `bg-[#FAFAFA]` : "bg-[#161A25]"
      }`}
    >
      <Navbar setNames={setNam} />
      <DashboardComponents />
      {user && !user?.payment_activate && <TrialModal />}
    </div>
    <SmallScreenOverlay />
    </>
  );
};

export default Dashboard;
// payment_activate
