import React, { useEffect, useState } from "react"
import { BsX } from "react-icons/bs"
import { BiReceipt } from "react-icons/bi"
import { useNavigate } from "react-router-dom"
import dayjs from "dayjs"
import DownloadInvoice from "../../pages/Account/DownloadInvoice"

const ReceiptModal = ({ setOpen }) => {
    const navigate = useNavigate()
    const [buttonClick, setButtonClick] = useState(null)
    const [datas, setdatas] = useState()

    const getBilingDetail = localStorage.getItem("billingDetails")

    useEffect(() => {
        setdatas(JSON.parse(getBilingDetail))
    }, [getBilingDetail])

    return (
        <div className="fixed w-full h-full top-0 left-0 flex z-[70]  justify-center items-center bg-[#00000023]">
            <div
                style={{ boxShadow: "2px 2px 10px #ddd" }}
                className="relative flex flex-col items-center py-8 px-8 w-[420px] bg-white rounded-lg"
            >
                <button
                    type="button"
                    className="absolute -top-2 -right-2 w-8 h-8 text-white bg-[#4f46e5] rounded-full text-xl p-1 ml-auto inline-flex items-center justify-center  transition-all duration-300"
                    data-modal-toggle="popup-modal"
                    onClick={(e) => {
                        setOpen(false)
                    }}
                >
                    <BsX size={30} />
                </button>

                <BiReceipt
                    size={60}
                    className=" text-blue"
                />
                <p className=" text-xs font-medium text-grayDark mt-8">Invoice paid</p>
                <h1 className=" text-4xl font-semibold text-black mt-2">
                    ₹{" "}
                    {new Intl.NumberFormat("en-IN", {
                        currency: "INR"
                    }).format(datas?.amount)}
                </h1>
                <p className=" text-xs font-medium text-grayDark mt-2">
                    You've made the right choice for your business's future.
                </p>

                <ul className=" mt-8 w-full flex flex-col gap-1 ">
                    <li className=" flex justify-between w-full items-center">
                        <p className=" text-base font-normal text-grayDark">Invoice number</p>
                        <p>{datas?.invoice_no}</p>
                    </li>
                    <li className=" flex justify-between w-full items-center">
                        <p className=" text-base font-normal text-grayDark">Payment Date</p>
                        <p>{dayjs(datas?.plan_activate_date).format("DD MMMM YYYY")}</p>
                    </li>
                    <li className=" flex justify-between w-full items-center">
                        <p className=" text-base font-normal text-grayDark">Payment Method</p>
                        <p></p>
                    </li>
                </ul>

                <div className="flex items-center justify-between w-full mt-8 gap-4">
                    <button
                        onClick={() => navigate("/invoice")}
                        className="secondaryButton w-[50%] h-[35px]"
                    >
                        View invoice
                    </button>
                    <button
                        onClick={() => setButtonClick(!buttonClick)}
                        className="primaryButton w-[50%] h-[40px]"
                    >
                        Download receipt
                    </button>
                </div>
            </div>
            <div className="fixed top-0 left-[150%] w-[100vw]">
                <DownloadInvoice
                    datas={datas}
                    buttonClick={buttonClick}
                />
            </div>
        </div>
    )
}

export default ReceiptModal
