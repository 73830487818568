import React, { useEffect, useState } from "react"
import TrialModal from "./TrialModal"
import { useSelector } from "react-redux"
import dayjs from "dayjs"
import { useNavigate } from "react-router-dom"

const BlueBannerStrip = () => {
    const [showModal, setShowModal] = useState(false)
    const navigate = useNavigate()

    const { user } = useSelector((state) => state.userAuth)

    const currentDate = dayjs()
    const futureDate = dayjs(user.plan_activate_date).add(15, "day")

    const numberOfDays = futureDate.diff(currentDate, "day")

    return (
        <div className="flex bg-blue justify-between p-6 rounded-xl">
            <div className=" text-white">
                <h2 className=" text-xl font-bold">Free 15 Days trial ~ Activated!</h2>
                <sub className=" text-base font-normal">
                    Enjoy a complimentary trial on us. We're offering you the chance to explore, engage, and subscribe
                    with confidence!
                </sub>
            </div>
            <div className="">
                <p className="text-white text-xs text-center">Trial ends in {numberOfDays} days</p>
                <button onClick={() => navigate('/payment')} className=" button bg-white text-blue focus:!ring-0 focus:ring-offset-0 mt-1">
                    Subscribe Now
                </button>
            </div>

            {showModal ? <TrialModal setShowModal={setShowModal} /> : null}
        </div>
    )
}

export default BlueBannerStrip
