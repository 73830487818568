import React, { useEffect, useState } from "react"
// import { HiOutlineUsers } from 'react-icons/hi'
import { VscFeedback } from "react-icons/vsc"
// import { RiWalletLine } from 'react-icons/ri'
import eventImage from "../../assets/svg/Icon.svg"
import ReactCount from "react-countup"
import axios from "../../axios"
import { useSelector } from "react-redux"
import { TbArchive } from "react-icons/tb"

const Box = () => {
    const [totalCount, setTotalCount] = useState("")
    const [totalEvent, setTotalEvent] = useState("")
    const [activatedEvent, setActivatedEvent] = useState("")
    const [todayCount, setTodayCount] = useState("")
    const { user } = useSelector((state) => state.userAuth)

    useEffect(() => {
        const Api = async () => {
            if (user) {

                const { data } = await axios.post("/feedbackCount/total", {
                    id: user.id
                })
                setTotalCount(data.totalCount)

                const eventCount = await axios.post("/feedbackEvent/count", {
                    id: user.id
                })

                setTotalEvent(eventCount.data.totalEvent)

                const activateEvent = await axios.post("/feedbackEvent/activated", {
                    id: user.id
                })
                setActivatedEvent(activateEvent.data.ActivatedEvent)

                const todayCount = await axios.post("/feedbackCount/today", {
                    id: user.id
                })
                setTodayCount(todayCount.data.todayCount)
            }

        }
        Api()
    }, [user])

    return (
        <div>
            <div className="py-12 bg-gray-50 sm:py-16 lg:py-10">
                <div className="mx-auto ">
                    <div className="w-full grid grid-cols-1 gap-10 mx-auto sm:grid-cols-2 lg:grid-cols-4">
                        <div
                            style={{ boxShadow: "0px 0px 24px rgba(79, 70, 229, 0.08)" }}
                            className="card py-3 flex items-center"
                        >
                            <div className="px-5 py-4 flex w-full items-center">
                                <div className=" justify-center items-center bg-lightColor p-2 rounded-full">
                                    <span>
                                        <VscFeedback
                                            className=" text-blue"
                                            size={25}
                                        />
                                    </span>
                                </div>
                                <div className="flex flex-col items-start justify-between ml-4 ">
                                    <p className="text-sm font-medium tracking-wider text-grayDark capitalize">
                                        Total Feedback
                                    </p>
                                    <ReactCount
                                        end={totalCount}
                                        duration={2}
                                        className="text-[16px] font-bold text-blue"
                                    />
                                </div>
                            </div>
                        </div>

                        <div
                            style={{ boxShadow: "0px 0px 24px rgba(79, 70, 229, 0.08)" }}
                            className="card flex items-center"
                        >
                            <div className="px-5 py-4 flex items-center">
                                <div className=" justify-center items-center bg-lightColor p-2 rounded-full">
                                    <span>
                                        <VscFeedback
                                            className=" text-blue"
                                            size={25}
                                        />
                                    </span>
                                </div>
                                <div className="flex flex-col items-start justify-between ml-4 ">
                                    <p className="text-sm font-medium tracking-wider text-grayDark capitalize">
                                        Today Feedback{" "}
                                    </p>
                                    <ReactCount
                                        end={todayCount}
                                        duration={2}
                                        className="text-[16px] font-bold text-blue"
                                    />
                                </div>
                            </div>
                        </div>

                        <div
                            style={{ boxShadow: "0px 0px 24px rgba(79, 70, 229, 0.08)" }}
                            className="card flex items-center"
                        >
                            <div className="px-5 py-4 flex items-center">
                                <div className=" justify-center items-center bg-lightColor p-2 rounded-full">
                                    <span>
                                        <TbArchive
                                            size={25}
                                            className="text-blue"
                                        />
                                    </span>
                                </div>
                                <div className="flex flex-col items-start justify-between ml-4 ">
                                    <p className="text-sm font-medium tracking-wider text-grayDark capitalize">
                                        Total Surveys
                                    </p>
                                    <ReactCount
                                        end={totalEvent}
                                        duration={2}
                                        className="text-[16px] font-bold text-blue"
                                    />
                                </div>
                            </div>
                        </div>

                        <div
                            style={{ boxShadow: "0px 0px 24px rgba(79, 70, 229, 0.08)" }}
                            className="card flex items-center"
                        >
                            <div className="px-5 py-4 flex items-center">
                                <div className=" justify-center items-center bg-lightColor p-2 rounded-full">
                                    <span>
                                        <TbArchive
                                            size={25}
                                            className="text-blue"
                                        />
                                    </span>
                                </div>
                                <div className="flex flex-col items-start justify-between ml-4 ">
                                    <p className="text-sm font-medium tracking-wider text-grayDark capitalize">
                                        Current Surveys
                                    </p>
                                    <ReactCount
                                        end={activatedEvent}
                                        duration={2}
                                        className="text-[16px] font-bold text-blue"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Box
