import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addButtonColor,
  addCompanyLogo,
  addTextColor,
  addWelcomeBg,
  addWelcomeTextColor,
} from "../../redux/slices/globalDefine";
import "../../index.css";
import Upload from "./GlobalBrandingUpload";
import axios from "../../axios";
import { toast } from "react-hot-toast";
import CustomDotLoader from './../CustomDotLoader/index'


const GlobalBranding = () => {
  const dispatch = useDispatch();
  const [uploadLogo, setuploadLogo] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [content, setContent] = useState("");
  const [loader, setLoader] = useState(false);
  const { companyLogo } = useSelector((state) => state.globalDefine);
  const { welcomeBg } = useSelector((state) => state.globalDefine);
  const { buttonColor } = useSelector((state) => state.globalDefine);
  const { textColor } = useSelector((state) => state.globalDefine);
  const { welcomeTextColor } = useSelector((state) => state.globalDefine);
  const { user } = useSelector((state) => state.userAuth);
  const userToken = localStorage.getItem("Token");




  const handelUpload = async (e) => {
    if (e === "Company_logo") {
      setuploadLogo(!uploadLogo);
      setContent(e);
    } else {
      setuploadLogo(!uploadLogo);
      setContent(e);
    }
  };

  const updateColors = async () => {
    setLoader(true);
    const { data } = await axios.post("/global-color", {
      id: user.id,
      btn_color: buttonColor,
      text_color: textColor,
      welcome_text_color: welcomeTextColor,
    });

    if (data.success) {
      setLoader(false);
      return toast.success(`${data.message}`);
    }
  };

  const deleteImage = async (e) => {
    if (e === "Company_Logo") {
      const { data } = await axios.post("/remove-companylogo", {
        id: user.id,
      });
      if (data.success) {
        toast.success(`${data.message}`);
        if (userToken !== null) {
          const user = await axios.get("/userDetails", {
            headers: {
              Authorization: userToken,
            },
          });
          dispatch(
            addCompanyLogo(
              user.data[0].company_logo.length !== 0
                ? user.data[0].company_logo
                : null
            )
          );
          dispatch(
            addWelcomeBg(
              user.data[0].welcome_bg_logo.length !== 0
                ? user.data[0].welcome_bg_logo
                : null
            )
          );
          dispatch(
            addButtonColor(
              user.data[0].btn_color.length !== 0
                ? user.data[0].btn_color
                : "#4F46E5"
            )
          );
          dispatch(
            addTextColor(
              user.data[0].text_color.length !== 0
                ? user.data[0].text_color
                : "#000000"
            )
          );
          dispatch(
            addWelcomeTextColor(
              user.data[0].welcome_screen_color.length !== 0
                ? user.data[0].welcome_screen_color
                : "#000000"
            )
          );
          return;
        }
      }
    } else if (e === "Welcome_bg") {
      const datas = await axios.post("/remove-welcomebg", {
        id: user.id,
      });
      if (datas.data.success) {
        toast.success(`${datas.data.message}`);
        if (userToken !== null) {
          const user = await axios.get("/userDetails", {
            headers: {
              Authorization: userToken,
            },
          });
          dispatch(
            addCompanyLogo(
              user.data[0].company_logo.length !== 0
                ? user.data[0].company_logo
                : null
            )
          );
          dispatch(
            addWelcomeBg(
              user.data[0].welcome_bg_logo.length !== 0
                ? user.data[0].welcome_bg_logo
                : null
            )
          );
          dispatch(
            addButtonColor(
              user.data[0].btn_color.length !== 0
                ? user.data[0].btn_color
                : "#4F46E5"
            )
          );
          dispatch(
            addTextColor(
              user.data[0].text_color.length !== 0
                ? user.data[0].text_color
                : "#D9D9D9"
            )
          );
          dispatch(
            addWelcomeTextColor(
              user.data[0].welcome_screen_color.length !== 0
                ? user.data[0].welcome_screen_color
                : "#000000"
            )
          );
          return;
        }
      }
    }
  };

  return (
    <div className="brand_main">
      <div className="brand_box">
        <h1 className="brand_title">Customize Branding</h1>
        <p className="brand_subTitle">create the branding details below. </p>

        <div className="brand_company_logo">
          <div className="brand_company_logo_title">
            <p>Company Logo</p>
          </div>
          <div className="brand_upload">
            <div className="brand_upload_img">
              {companyLogo !== null && (
                <img src={companyLogo} alt="welcome_bg" />
              )}
            </div>
            <div className="brand_upload_btn">
              <p
                className="brand_upload_text"
                onClick={() => handelUpload("Company_logo")}
              >
                Upload
              </p>
              <p
                className="brand_upload_remove_text"
                onClick={() => deleteImage("Company_Logo")}
              >
                Remove
              </p>
            </div>
          </div>
        </div>

        <div className="brand_welcome_bg">
          <div className="brand_welcome_bg_title">
            <p>Welcome Screen BG image</p>
          </div>
          <div className="brand_upload">
            <div className="brand_upload_img">
              {welcomeBg !== null && <img src={welcomeBg} alt="welcome_bg" />}
            </div>
            <div className="brand_upload_btn">
              <p
                className="brand_upload_text"
                onClick={() => handelUpload("Welcome_bg")}
              >
                Upload
              </p>
              <p
                className="brand_upload_remove_text"
                onClick={() => deleteImage("Welcome_bg")}
              >
                Remove
              </p>
            </div>
          </div>
        </div>

        <hr className="brand_line" />

        <div className="brand_colors">
          <div className="brand_color_content">
            <input
              type="color"
              id="globalchooseColor"
              onChange={(e) => {
                dispatch(addButtonColor(e.target.value.toString()));
              }}
              className="brand_input_color"
              value={buttonColor}
              style={{
                boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.15)",
              }}
            />

            <div className="brand_input_tag_head">
              <p>Button</p>
            </div>
          </div>

          <div className="brand_color_content">
            <input
              type="color"
              id="globalchooseColor"
              onChange={(e) => {
                dispatch(addTextColor(e.target.value.toString()));
              }}
              className="brand_input_color"
              value={textColor}
              style={{
                boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.15)",
              }}
            />

            <div className="brand_input_tag_head">
              <p>Question</p>
            </div>
          </div>

          <div className="brand_color_content">
            <input
              type="color"
              id="globalchooseColor"
              onChange={(e) => {
                dispatch(addWelcomeTextColor(e.target.value.toString()));
              }}
              className="brand_input_color"
              value={welcomeTextColor}
              style={{
                boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.15)",
              }}
            />

            <div className="brand_input_tag_head">
              <p>Welcome screen</p>
            </div>
          </div>
          {!loader ? (
            <button className="brand_update_btn" onClick={updateColors}>
              Update
            </button>
          ) : (
            <button className="brand_update_btn">
               <CustomDotLoader
                              dotSize={50}
                              isWhite
                              className="mx-auto text-center mr-10"
                          />
            </button>
          )}
        </div>
      </div>

      {uploadLogo && (
        <Upload
          close={setuploadLogo}
          check={refresh}
          reload={setRefresh}
          content={content}
        />
      )}
    </div>
  );
};

export default GlobalBranding;
