import React, { useState } from "react"

import bgImg from "../../assets/svg/accountDelete.svg"
import { useSelector } from "react-redux"
import axios from "../../axios"
import { toast } from "react-hot-toast"
import { useNavigate } from "react-router"

const AccountDeleteModal = ({ setShowModal, setShowPopup }) => {
    const { user } = useSelector((state) => state.userAuth)
    const navigate = useNavigate()

    return (
        <div className=" fixed top-0 left-0 w-screen h-screen flex justify-center items-center z-[70]  bg-[#00000042]">
            <div className="bg-[#fafafa] relative flex flex-col w-[60%] mx-auto rounded-lg overflow-hidden">
                <div className="w-full h-80">
                    <img
                        src={bgImg}
                        alt="15 days free trial"
                        className=" w-full h-full object-contain"
                    />
                </div>
                <div className="p-8 text-center w-full">
                    <h1 className=" text-xl font-bold">Account Deletion Confirmation</h1>
                    <p className=" text-base font-normal mt-4 max-w-2xl mx-auto">
                        Deleting your account can't be undone, and you'll lose all your account info. Do you want to go
                        ahead?
                    </p>
                    <div className="grid grid-cols-2 place-items-center w-1/2 mx-auto gap-4 mt-8">
                        <button
                            onClick={() => setShowModal(false)}
                            className=" secondaryButton w-full"
                        >
                            No, go back
                        </button>
                        <button
                            className=" button bg-red text-white w-full"
                            onClick={() => {
                                setShowModal(false)
                                setShowPopup(true)
                            }}
                        >
                            Delete account
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AccountDeleteModal
