/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Rating } from "react-simple-star-rating"
import { setFeedbackValue } from "../../../redux/slices/feedbackValue"
import "./StartRating.module.css"
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io"
import α from "color-alpha"

const StarRating = ({ inputQuestion, id, questionType, answer, nextQuz, next, eventId, color }) => {
    // const [rating, setRating] = useState(0);
    const [review, setReview] = useState(null)
    const { questionValues } = useSelector((state) => state.questions)
    const [nextValues, setNextValues] = useState([])
    let nextId = Number(id) + 1
    const dispatch = useDispatch()
    const { mood } = useSelector((state) => state.mood)
    const { questions } = useSelector((state) => state)
    const logoInfo = questions.logoDetails
    const buttonColors = questions.buttonColor
    const textColors = questions.textColor
    const [preValue, setPreValue] = useState([])
    let prev = Number(id) - 1
    useEffect(() => {
        const filter = questionValues.filter((e) => Number(e.id) === Number(prev))
        setPreValue(filter)
    }, [questionType, id])
    // const { buttonColor} = useSelector((state) => state.globalDefine);
    // const { textColor } = useSelector((state) => state.globalDefine);
    // const col = α(buttonColors !== null || buttonColors !== undefined ? buttonColor : buttonColors, 0.2);
    // const { companyLogo } = useSelector((state) => state.globalDefine);

    const getValue = (answer) => {
        if (
            inputQuestion.length !== 0 &&
            answer.length !== 0 &&
            id.length !== 0 &&
            questionType.length !== 0 &&
            answer.length !== 0 &&
            eventId.length !== 0
        ) {
            return dispatch(
                setFeedbackValue({
                    customerName: "Mohamd",
                    email_address: "mohamedthawfeek96@gmail.com",
                    phone_number: 8248383211,
                    eventId: eventId,
                    age: "26",
                    gender: "male",
                    list_of_items: [
                        {
                            question: inputQuestion,
                            answer: answer.toString(),
                            answerId: id,
                            type: questionType
                        }
                    ]
                })
            )
        }
    }

    useEffect(() => {
        const filter = questionValues.filter((e) => Number(e.id) === Number(nextId))
        setNextValues(filter)
    }, [questionType, id])

    // Catch Rating value
    const handleRating = (rate) => {
        getValue(rate)
    }

    return (
        <div className=" w-full flex flex-col items-center mt-[30px] overflow-y-auto overflow-x-hidden">
            {logoInfo.length !== 0 && (
                <img
                    className="mb-[30px] max-w-[125px]"
                    src={logoInfo[0]?.logoLocation}
                    alt=""
                />
            )}
            {/* {
        logoInfo.length === 0 && companyLogo !== null && 
        <img
        className="mb-[30px] max-w-[125px]"
        src={companyLogo}
        alt=""
      />
      } */}

            <h3
                style={{
                    color: `${(textColors !== null) & (textColors !== undefined) && textColors
                        //  textColor : textColors
                        }`
                }}
                className={`w-[80%] max-h-full z-20 text-center mx-10 text-[18px] font-[500] font-inter ${mood === "light" ? "text-black" : "text-white"
                    } break-words`}
            >
                <span className="w-full">{inputQuestion}</span>
            </h3>
            <div className="flex justify-center items-center my-4">
                <Rating onClick={handleRating} size={60} fillColor={color === null ? '#4F46E5' : color} />
            </div>
            <div className="flex justify-center items-center">
                {review !== null && (
                    <p
                        style={{
                            background: `${(buttonColors !== null) & (buttonColors !== undefined) && buttonColors
                                // buttonColor : buttonColor
                                }`
                        }}
                        className={`text-white ${mood === "light" ? "bg-[#4F46E5]" : "bg-[#4F46E5]"
                            } focus:ring-4 focus:outline-none  font-medium rounded text-sm px-5 py-2.5 text-center inline-flex items-center 5 mr-2 mb-2 mt-4 ml-6`}
                    >
                        {review}
                    </p>
                )}
            </div>
            <div className="flex items-center gap-x-3">
                {preValue.length !== 0 &&
                    <button
                        type="button"
                        style={{
                            background: `${buttonColors === null || buttonColors === undefined
                                ? "#4F46E5"
                                : //  buttonColor
                                buttonColors
                                }`
                        }}
                        className=" rounded-full p-3 my-3 transition-all duration-200 text-lg font-semibold text-center block "
                        onClick={() => {
                            nextQuz(preValue[0].type)
                            next(prev)
                        }}
                    >
                        <IoIosArrowBack size={40} color="white" />
                    </button>

                }
                {
                    nextValues.length !== 0 &&
                    <button
                        style={{
                            background: `${buttonColors === null || buttonColors === undefined
                                ? "#4F46E5"
                                : //  buttonColor
                                buttonColors
                                }`
                        }}
                        className=" rounded-full p-3 my-3 transition-all duration-200 text-lg font-semibold text-center block "
                        onClick={() => {
                            nextQuz(nextValues[0].type)
                            next(nextId)
                        }}
                    >
                        <IoIosArrowForward size={40} color="white" />
                    </button>
                }

            </div>
        </div>
    )
}

export default StarRating
