/* eslint-disable eqeqeq */
import { createSlice, current } from "@reduxjs/toolkit";

const initialState = {
  questionValues: [],
  backgroundColor: "#ffffff",
  buttonColor: "#4F46E5",
  textColor: "#000000",
  welcomeScreenTextColor: "#000000",
  logoDetails: [],
  bgImageDetails: [],
  editValues: [],
};

const arraySlice = createSlice({
  name: "questionValues",
  initialState,
  reducers: {
    setQuestionValue(state, action) {
      const isExisit =
        state.questionValues.findIndex(
          (item) => item.id == action.payload.id
        ) !== -1;

      if (isExisit) {
        state.questionValues = state.questionValues.map((item) => {
          if (item.id === action.payload.id) {
            return {
              ...item,
              question: action.payload.question,
              type: action.payload.type,
              subHeading: action.payload.subHeading
            };
          }
          return item;
        });
      } else {
        state.questionValues.push(action.payload);
      }
    },
    removeQuestion: (state, action) => {
      const newSate = current(state.questionValues);
      const index = newSate.filter((i) => i.id !== action.payload.id);

      const changeStatus = index.map((item, index) => {
        return {
          ...item,
          id: (index + 1).toString(),
        };
      });

      if (index.length >= 0) state.questionValues = changeStatus;
    },
    questionEmpty: (state, action) => {
      state.questionValues = [];
      state.logoDetails = [];
      state.bgImageDetails = [];
      state.buttonColor = null;
      state.welcomeScreenTextColor = null;
      state.textColor = null;
      state.editValues = [];
    },
    newArrayQuestion: (state, action) => {
      state.questionValues = [];
      let newItems = [];
      for (let i = 0; i < action.payload.length; i++) {
        let updatedObj = Object.assign({}, action.payload[i], {
          id: (i + 1).toString(),
        });
        newItems.push(updatedObj);
      }

      state.questionValues.push(...newItems);
    },
    setArrayValue(state, action) {
      const isExisit =
        state.questionValues.findIndex(
          (item) => item.id == action.payload.id
        ) !== -1;

      if (isExisit) {
        state.questionValues = state.questionValues.map((item) => {
          if (item.id === action.payload.id) {
            return {
              ...item,
              choice: action.payload.choice.sort((a, b) => a.id - b.id),
              // type: action.payload.type,
            };
          }

          return item;
        });
      } else {
        state.questionValues.push(action.payload);
      }
    },
    newArrayAnswer: (state, action) => {
      const isExisted =
        state.questionValues.findIndex(
          (item) => item.id == action.payload.id
        ) !== -1;

      if (isExisted) {
        let newItems = [];
        for (let i = 0; i < action.payload.choice.length; i++) {
          let updatedObj = Object.assign({}, action.payload.choice[i], {
            id: (i + 1).toString(),
          });
          newItems.push(updatedObj);
        }
        state.questionValues = state.questionValues.map((item) => {
          if (item.id === action.payload.id) {
            return {
              ...item,
              choice: newItems.map((i, index) => {
                return {
                  ...i,
                  id: (index + 1).toString(),
                };
              }),
            };
          }

          return item;
        });
      }
    },
    setBackgroundColor(state, action) {
      state.backgroundColor = action.payload.backgroundColor;
    },
    setButtonColor(state, action) {
      state.buttonColor = action.payload.buttonColor;
    },
    setTextColor(state, action) {
      state.textColor = action.payload.textColor;
    },
    setWelcomeScreenTextColor(state, action) {
      state.welcomeScreenTextColor = action.payload.welcomeScreenTextColor;
    },
    logoDetails(state, action) {
      state.logoDetails = action.payload;
    },
    bgImageDetails(state, action) {
      state.bgImageDetails = action.payload;
    },
    editValues(state, action) {
      state.editValues = action.payload;
    },
  },
});

export const {
  setQuestionValue,
  removeQuestion,
  questionEmpty,
  newArrayQuestion,
  setArrayValue,
  newArrayAnswer,
  setBackgroundColor,
  setButtonColor,
  setTextColor,
  logoDetails,
  bgImageDetails,
  editValues,
  setWelcomeScreenTextColor,
} = arraySlice.actions;
export default arraySlice.reducer;
