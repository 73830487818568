import React from 'react';

const Star = ({ color, bg }) => {
    return (
        <svg width="160" height="159" viewBox="0 0 160 159" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_2556_12476)">
                <path d="M159.5 0H0.5V159H159.5V0Z" fill="white" fill-opacity="0.01" />
                <path d="M79.9977 13.25L97.3993 25.9436L118.938 25.9026L125.556 46.4005L143.005 59.0278L136.31 79.5L143.005 99.9722L125.556 112.599L118.938 133.097L97.3993 133.056L79.9977 145.75L62.5962 133.056L41.057 133.097L34.4399 112.599L16.9902 99.9722L23.6852 79.5L16.9902 59.0278L34.4399 46.4005L41.057 25.9026L62.5962 25.9436L79.9977 13.25Z" fill={`${color}`} />
                <path d="M79.9977 13.25L97.3993 25.9436L118.938 25.9026L125.556 46.4005L143.005 59.0278L136.31 79.5L143.005 99.9722L125.556 112.599L118.938 133.097L97.3993 133.056L79.9977 145.75L62.5962 133.056L41.057 133.097L34.4399 112.599L16.9902 99.9722L23.6852 79.5L16.9902 59.0278L34.4399 46.4005L41.057 25.9026L62.5962 25.9436L79.9977 13.25Z" fill="black" fill-opacity="0.2" />
                <path d="M79.9977 13.25L97.3993 25.9436L118.938 25.9026L125.556 46.4005L143.005 59.0278L136.31 79.5L143.005 99.9722L125.556 112.599L118.938 133.097L97.3993 133.056L79.9977 145.75L62.5962 133.056L41.057 133.097L34.4399 112.599L16.9902 99.9722L23.6852 79.5L16.9902 59.0278L34.4399 46.4005L41.057 25.9026L62.5962 25.9436L79.9977 13.25Z" stroke={`${color}`} stroke-width="13.25" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M79.9977 13.25L97.3993 25.9436L118.938 25.9026L125.556 46.4005L143.005 59.0278L136.31 79.5L143.005 99.9722L125.556 112.599L118.938 133.097L97.3993 133.056L79.9977 145.75L62.5962 133.056L41.057 133.097L34.4399 112.599L16.9902 99.9722L23.6852 79.5L16.9902 59.0278L34.4399 46.4005L41.057 25.9026L62.5962 25.9436L79.9977 13.25Z" stroke="black" stroke-opacity="0.2" stroke-width="13.25" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M56.8125 79.5L73.375 96.0625L106.5 62.9375" stroke={`${bg}`} stroke-width="10" stroke-linecap="round" stroke-linejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_2556_12476">
                    <rect width="159" height="159" fill="white" transform="translate(0.5)" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default Star;