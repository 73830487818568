import React from 'react'

// ** assets
import logo from "../../assets/Logo.svg"
import illustration from "../../assets/images/laptopSupportIllustration.png"

const SmallScreenOverlay = () => {
  return (
      <section className=" sectionPadding h-screen border-b-2 border-primary bg-white lg:hidden py-10">
          <img
              src={logo}
              alt="feedback sync logo"
          />
          <h2 className=" text-2xl font-semibold text-blue mt-8">Stay Tuned</h2>
          <p className=' text-base font-normal mt-4'>This website is fantastic on desktop, packed with features. Stay tuned for the upcoming mobile version!</p>
          <img src={illustration} alt="illustration of feedback sync webstite in desktop" className=' mt-20 w-full h-auto object-contain max-w-md mx-auto block' />
      </section>
  )
}

export default SmallScreenOverlay