import React, { useState } from "react"
import { Eye } from "react-feather"
import ReactPaginate from "react-paginate"
import "../../index.css"

const Table = ({ items }) => {
    const [itemsPerPage, setItemPerPage] = useState(8)
    const [itemOffset, setItemOffset] = useState(0)

    const endOffset = itemOffset + itemsPerPage
    const currentItems = items.slice(itemOffset, endOffset)
    const pageCount = Math.ceil(items.length / itemsPerPage)

    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % items?.length

        setItemOffset(newOffset)
    }

    function Items({ currentItems }) {
        return (
            <>
                {currentItems &&
                    currentItems?.map((i, idx) => (
                        <tr
                            key={idx}
                            className="bg-grayLigth text-center"
                        >
                            <td className={`px-4 py-4 text-sm align-top lg:align-middle whitespace-nowrap`}>
                                <div className={`flex items-center justify-start font-inter font-medium text-[13px] text-black`}>
                                    {new Date(i?.[0]?.date).toDateString()}
                                </div>
                            </td>
                            <td className="hidden px-4 py-4 text-sm lg:table-cell whitespace-nowrap">
                                <div
                                    className={`flex items-center justify-start font-inter font-medium text-[13px] text-black`}
                                >
                                    {i?.map(
                                        (subItem, idx) => subItem?.type === "name" && <p key={idx}>{subItem?.value}</p>
                                    )}
                                </div>
                            </td>

                            <td className="hidden px-4 py-4 text-center text-sm lg:table-cell whitespace-nowrap">
                                <div
                                    className={`flex items-center justify-start font-inter font-medium text-[13px] text-black`}
                                >

                                    {i?.map(
                                        (subItem, idx) => subItem.type === "email" && <p key={idx}>{subItem.value}</p>
                                    )}
                                </div>
                            </td>

                            <td className="hidden px-4 py-4 text-sm lg:table-cell whitespace-nowrap">
                                <div
                                    className={`flex items-center justify-start  font-inter font-medium text-[13px] text-black`}
                                >
                                    {i?.map(
                                        (subItem, idx) => subItem.type === "number" && <p key={idx}>{subItem.value}</p>
                                    )}
                                </div>
                            </td>

                            <td className="px-4 py-4 text-sm xl:table-cell whitespace-nowrap">
                                <div
                                    className={`flex items-start justify-start font-inter font-medium text-[13px] text-black`}
                                >
                                    {i?.map(
                                        (subItem, idx) => subItem.type === "age" && <p key={idx}>{subItem.value}</p>
                                    )}
                                </div>
                            </td>

                            <td className={`px-4 py-4 text-sm font-medium flex justify-center text-black align-top`}>
                                {i?.map(
                                    (subItem, idx) => subItem.type === "gender" && <p key={idx}>{subItem.value}</p>
                                )}
                            </td>

                            <td className={`text-black relative`}>
                                {i?.map(
                                    (subItem, idx) =>
                                        subItem.type === "feedback" && (
                                            <>
                                                <div
                                                    key={idx}
                                                    className="relative"
                                                >
                                                    <Eye
                                                        size={25}
                                                        color="#6E6B7B"
                                                        className="cursor-pointer"
                                                    />
                                                    <div
                                                        onMouseOver={(e) => {
                                                            e.target.parentElement.nextElementSibling.style.display =
                                                                "block"
                                                        }}
                                                        onMouseOut={(e) => {
                                                            e.target.parentElement.nextElementSibling.style.display =
                                                                "none"
                                                        }}
                                                        className="cursor-pointer absolute w-full h-full top-0 left-0"
                                                    ></div>
                                                </div>
                                                <div className="z-50 absolute left-[0] top-12 p-3 rounded-md bg-white border border-[#E4E4E7] shadow-md mb-5 hidden">
                                                    <p className="min-w-[70px] max-w-[300px]">{subItem.value}</p>
                                                </div>
                                            </>
                                        )
                                )}
                            </td>
                            {/* <td className={`text-[#71717A] hover:text-[#4F46E5]`}>
                    <button
                      type="button"
                      className={`mx-auto block items-center px-4 py-[3px] text-sm font-medium border-2  text-white transition-all
                                   duration-200 bg-blue border-blue rounded-md shadow-sm hover:bg-white hover:text-blue  
                                   focus:outline-none hover:border-lightBlue focus:ring-2 focus:ring-offset-2 focus:ring-lightBlue`}
                    >
                      View
                    </button>
                  </td> */}
                        </tr>
                    ))}
            </>
        )
    }
    return (
        <div>
            <div>
                <table className={`min-w-full lg:divide-gray lg:divide-y bg-white !rounded-lg mb-[50px] mt-[20px]`}>
                    <thead className="hidden lg:table-header-group">
                        <tr>
                            <th className={`py-3.5 px-4 text-start text-[12px] font-[600] text-[#71717A] uppercase`}>
                                Date & Time
                            </th>

                            <th className={`py-3.5 px-4 text-start text-[12px] font-[600] text-[#71717A] uppercase`}>
                                Name
                            </th>

                            <th className={`py-3.5 px-4 text-start text-[12px] font-[600] text-[#71717A] uppercase`}>
                                Email
                            </th>

                            <th className={`py-3.5 px-4 text-start text-[12px] font-[600] text-[#71717A] uppercase`}>
                                Mobile Number
                            </th>

                            <th className={`py-3.5 px-4 text-start text-[12px] font-[600] text-[#71717A] uppercase`}>
                                Age
                            </th>

                            <th className={`py-3.5 px-4 text-center text-[12px] font-[600] text-[#71717A] uppercase`}>
                                Gender
                            </th>

                            <th className={`py-3.5 px-4 text-center text-[12px] font-[600] text-[#71717A] uppercase`}>
                                Description
                            </th>

                            {/* <th
      className={`py-3.5 px-4 text-center text-[12px] font-[600] text-[#71717A] uppercase`}
    >
      USER FEEDBACK
    </th> */}
                        </tr>
                    </thead>

                    <tbody className="text-center divide-gray divide-y">
                        <Items currentItems={currentItems} />
                    </tbody>
                </table>

                <div className="flex w-full justify-end -mt-6">
                    <ReactPaginate
                        breakLabel="..."
                        nextLabel=">"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={5}
                        pageCount={pageCount}
                        previousLabel="<"
                        renderOnZeroPageCount={null}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        containerClassName={"pagination"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        activeClassName={"active"}
                    />
                </div>
            </div>
        </div>
    )
}

export default Table
