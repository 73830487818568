/* eslint-disable react-hooks/rules-of-hooks */
import React from "react"
import { useDispatch } from "react-redux"
import { setFeedbackValue } from "../../redux/slices/feedbackValue"
import α from "color-alpha"
import Select from "react-select"
import { useSelector } from "react-redux"
import { useEffect } from "react"
import PhoneInput from "react-phone-input-2"

const Connect = ({
    inputQuestion,
    id,
    questionType,
    answer,
    nextQuz,
    next,
    eventId,
    bg,
    color,
    textColor,
    setType,
    setGenderValue
}) => {
    const col = α(color, 0.1)
    const dispatch = useDispatch()
    const datas = useSelector((state) => state.feedbackValue.feedbackValue)

    //on change set value in redux
    const getValue = (answer, index, valueID) => {
        let list = [...datas?.[0]?.list_of_items[id - 1]?.arrayAns]
        list[index] = {
            ...list[index],
            value: answer,
            date: new Date().toISOString(),
            valieID: valueID
        }

        let secondList = [...datas?.[0]?.list_of_items]
        secondList[id - 1] = {
            ...secondList[id - 1],
            answer: "",
            arrayAns: list
        }

        let allItem = { ...datas?.[0], list_of_items: secondList }

        dispatch(setFeedbackValue(allItem))
    }

    useEffect(() => {
        if (!datas?.[0]?.list_of_items?.[id - 1]?.arrayAns) {
            let secondList = [...datas?.[0]?.list_of_items]
            secondList[id - 1] = {
                ...secondList[id - 1],
                answer: "",
                arrayAns: answer
            }
            let allItem = { ...datas?.[0], list_of_items: secondList }
            dispatch(setFeedbackValue(allItem))
        }
    }, [])

    //custom styles
    const customStyles = {
        control: (styles) => ({
            ...styles
        }),
        option: (provided, state) => ({
            ...provided,
            color: state.isSelected ? "white" : "black",
            background: state.isSelected ? "#8a8af0" : "white",
            padding: 10,
            cursor: "pointer",
            zIndex: 999
        }),

        singleValue: (provided) => ({
            ...provided,
            color: "black",
            fontWeight: "500"
        }),
        placeholder: (provided) => ({
            ...provided,
            color: "#BEBEBE",
            fontWeight: "500"
        }),
        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
        menuList: (base) => ({
            ...base,
            // kill the white space on first and last option
            padding: 0,
            background: "red",
            zIndex: 9999
        })
    }

    useEffect(() => {
        if (datas?.[0]?.list_of_items?.[id - 1]?.arrayAns) {
            let type = ""
            datas?.[0]?.list_of_items?.[id - 1]?.arrayAns?.map((data) => {
                if (data?.status === "select") {
                    type = "select"
                    if (data?.value) {
                        setGenderValue(data?.value)
                    } else {
                        setGenderValue("")
                    }
                }
            })
            if (type) {
                setType("select")
            } else {
                setType("")
            }
        }
    }, [datas?.[0]?.list_of_items?.[id - 1]])

    return (
        <div className=" mb-6 h-full w-full mx-auto contact flex flex-col justify-center items-center  bg-no-repeat bg-cover bg-center bg-fixed">
            <h3
                style={{ color: `${textColor}`, wordBreak: "break-word" }}
                className="mb-8 text-center  flex items-center justify-center w-full max-w-3xl break-words mx-10  text-xl sm:text-3xl font-semibold"
            >
                {inputQuestion}
            </h3>

            {answer?.map((i, index) => {
                return i?.uniqueID === "06" ? (
                    <textarea
                        required
                        style={{
                            color: "black"
                        }}
                        placeholder={`${i?.placeholder}`}
                        className={`  !z-0 w-full sm:w-96 h-[100px] py-3 my-1.5 rounded px-3 focus:bg-[${col}] focus:border-[${color}] focus:outline-none border font-medium mx-2 placeholder:text-gray `}
                        name=""
                        id=""
                        cols="30"
                        rows="10"
                        // onChange={(e) => update(i.id, e.target.value, i?.type, i?.placeholder, i?.status, item?.options)}
                        defaultValue={
                            datas?.[0]?.list_of_items?.[id - 1]?.arrayAns?.[index]?.value &&
                            datas?.[0]?.list_of_items?.[id - 1]?.arrayAns?.[index]?.value
                        }
                        onChange={(e) => getValue(e.target?.value, index)}
                    ></textarea>
                ) : i?.uniqueID === "05" && i?.options?.length !== 0 ? (
                    datas?.[0]?.list_of_items?.[id - 1]?.arrayAns?.[index]?.value ? (
                        <Select
                            defaultValue={
                                datas?.[0]?.list_of_items[id - 1]?.arrayAns?.[index]?.value && {
                                    value: datas?.[0]?.list_of_items?.[id - 1]?.arrayAns?.[index]?.value,
                                    label: datas?.[0]?.list_of_items?.[id - 1]?.arrayAns?.[index]?.value
                                }
                            }
                            onChange={(e) => {
                                getValue(e.id === "1" ? e.value : e.id === "2" ? e.value : e.value, index, e.id)
                            }}
                            styles={customStyles}
                            className={`!text-black w-full sm:w-96 mx-2 my-1.5 !z-[999999] focus:bg-[${col}] focus:border-[${color}] focus:outline-none `}
                            options={i?.options}
                            placeholder={i?.placeholder}
                            required
                        />
                    ) : (
                        <Select
                            required
                            onChange={(e) => {
                                getValue(e.id === "1" ? e.value : e.id === "2" ? e.value : e.value, index, e.id)
                            }}
                            styles={customStyles}
                            className={`!text-black w-full sm:w-96 mx-2 my-1.5 !z-[999999] border border-black rounded focus:bg-[${col}] focus:border-[${color}] focus:outline-none`}
                            options={i?.options}
                            placeholder={i?.placeholder}
                        />
                    )
                ) : i?.uniqueID === "03" ? (
                    <div className="flex items-center justify-center w-[100%] my-2">
                        <PhoneInput
                            prefix="+"
                            inputProps={{
                                name: "phone",
                                require: true,
                                minLength: 10, // Add minLength prop with a value of 10
                                autoFocus: false
                            }}
                            autoFormat={false}
                            
                            placeholder="Phone number"
                            value={
                                datas?.[0]?.list_of_items?.[id - 1]?.arrayAns?.[index]?.value !== undefined
                                    ? datas?.[0]?.list_of_items[id - 1]?.arrayAns[index]?.value
                                    : ""
                            }
                            country={"in"}
                            className={`contact_no border z-[99999] text-black border-black focus:border-[${color}] focus:outline-none rounded`}
                            style={{
                                color: "black",
                                background: `${col}`,
                                width: "385px"
                            }}
                            name="phone"
                            onChange={(e) => {
                                getValue(e, index)
                            }}
                            defaultCountry={"in"}
                        />
                    </div>
                ) : (
                    <input
                        required
                        onChange={(e) =>
                            getValue(
                                i?.uniqueID === "04"
                                    ? e.target.value.slice(0, 3).replace(/[^0-9]/g, "") // Extract only digits
                                    : i?.uniqueID === "01"
                                    ? e.target.value.replace(/[^a-z]/gi, "")
                                    : e.target.value,
                                index,
                                i?.uniqueID
                            )
                        }
                        style={{
                            color: "black"
                        }}
                        type={
                            i?.uniqueID === "03"
                                ? "tel" // Use "tel" type for mobile numbers
                                : i?.uniqueID === "04"
                                ? "number"
                                : i?.uniqueID === "02"
                                ? "email"
                                : "text"
                        }
                        placeholder={`${i.placeholder}`}
                        className={`w-full sm:w-96 py-3 my-1.5 rounded px-3 outline-0 border font-medium mx-2 placeholder:text-gray !z-10 focus:bg-[${col}] border-black focus:border-[${color}] focus:outline-none`}
                        value={
                            datas?.[0]?.list_of_items?.[id - 1]?.arrayAns?.[index]?.value !== undefined
                                ? datas?.[0]?.list_of_items[id - 1]?.arrayAns[index]?.value
                                : ""
                        }
                        minLength={i?.uniqueID === "03" ? 10 : i?.uniqueID === "04" ? 2 : null}
                        maxLength={i?.uniqueID === "01" && 30}
                    />
                )
            })}
        </div>
    )
}

export default Connect
