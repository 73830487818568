/* eslint-disable react-hooks/exhaustive-deps */
import axios from "../../axios";
import React, { useEffect } from "react";
import { useState } from "react";
import ProfileUpload from "../../components/UploadImage/UploadImage";
import { useDispatch } from "react-redux";
import { setUserValue } from "../../redux/slices/auth";
import toast, { Toaster } from "react-hot-toast";
import { useSelector } from "react-redux";
import { AiFillLinkedin } from "react-icons/ai";
import { FcGoogle } from "react-icons/fc";
import PhoneInput from "react-phone-input-2";
import AccountDeleteModal from "../../components/popUp/AccountDeleteModal";
import EventName from "../../components/popUp/EventName";
import CustomDotLoader from "./../../components/CustomDotLoader/index";

const Profile = () => {
  const [profileDetails, setProfileDetails] = useState({
    name: "",
    company_name: "",
  });
  const [upload, setupload] = useState("");
  const [uploads, setuploads] = useState(false);
  const [popUp, setPopUp] = useState(false);
  const [deleteAccountModal, setDeleteAccountModal] = useState(false);
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.userAuth);
  const { mood } = useSelector((state) => state.mood);
  const [value, setInputValue] = useState("");
  const [loader, setLoader] = useState(false);
  const [textChange, setTextChange] = useState(false);
  const [statusChange, setstatusChange] = useState(false);
  const [preview, setPreview] = useState(null);
  const [base64String, setbase64String] = useState(null);

  const handelChange = (e) => {
    const { name, value } = e.target;
    setProfileDetails((pre) => {
      return { ...pre, [name]: value };
    });
  };


  useEffect(() => {
    const API = async () => {
      if (preview !== null) {
        if (user) {
          const { data } = await axios.post("/profile-Pic", {
            id: user?.id,
            profile: base64String,
          });
          if (data.success) {
            setPopUp(false);
            setLoader(false);
            setPreview(null);
            setbase64String(null);
            

            const datas = await axios.post("/getParticularUser", {
              id: user?.id,
            });
            dispatch(setUserValue(datas.data[0]));
            const createNotification = await axios.post(
              "/add-Notification-sa",
              {
                id: user.id,
                message: "User Updated Profile Picture",
                pic: user?.profilePic,
              }
            );
            if (createNotification.data.success) {
              toast.success(data.message);
              return;
            }
          }
        } else {
          setLoader(false);

          return toast.error("Please upload image");
        }
      }
    };

    API();
  }, [uploads]);

  useEffect(() => {
    const Api = async () => {
      if (user) {
        const datas = await axios.post("/getParticularUser", {
          id: user.id,
        });

        dispatch(setUserValue(datas.data[0]));
        setProfileDetails({
          name: datas.data?.[0]?.name,

          company_name: datas.data?.[0]?.company_name,
        });
        setInputValue(datas.data?.[0]?.phone_no);
      }
    };

    Api();
  }, [upload]);

  const removeLogo = async () => {
    if (user?.pictureId) {
      setTextChange(true);

      const { data } = await axios.post("/delete/profile-pic", {
        profileId: user?.pictureId.toString(),
        id: user?.id,
        img: "",
      });

      if (data.success) {
        setTextChange(false);

        const createNotification = await axios.post("/add-Notification-sa", {
          id: user.id,
          message: "User removed Profile Picture",
          pic: user?.profilePic,
        });
        if (createNotification.data.success) {
          const datas = await axios.post("/getParticularUser", {
            id: user?.id,
          });
          toast.success(`${data.message}`);
          dispatch(setUserValue(datas.data[0]));
          return;
        }
      }
    } else {
      return toast.error("No Profile picture found");
    }
  };

  const update = async () => {
    setLoader(true);
    const { data } = await axios.post("/update/user", {
      id: user?.id,
      profilePic: user?.profilePic,
      phoneNumber: value,
      name: profileDetails.name,
      company_name: profileDetails.company_name,
    });
    if (data.success) {
      setLoader(false);

      const createNotification = await axios.post("/add-Notification-sa", {
        id: user.id,
        message: "User updated profile details",
        pic: user?.profilePic,
      });
      if (createNotification.data.success) {
        toast.success(`${data.message}`);
        setupload("s");
        return;
      }
    }
  };

  return (
    <div className="w-full max-w-6xl p-8 mx-auto | card">
      <div className="">
        <p
          className={`text-[17px] ${
            mood === "light" ? "text-black" : "text-[#ddd]"
          }  font-bold text-gray-900`}
        >
          Profile
        </p>
        <p className="mt-1 text-sm font-medium text-[#52525B]">
          You can edit your profile here
        </p>
      </div>

      <div className="max-w-3xl mt-12">
        <div className="space-y-8">
          <div className="sm:grid sm:grid-cols-3 sm:gap-5 sm:items-start">
            <label
              className={`block text-[14px] font-bold ${
                mood === "light" ? "text-black" : "text-[#ddd]"
              } sm:mt-px sm:pt-2`}
            >
              Profile photo
            </label>
            <div className="mt-2 sm:mt-0 sm:col-span-2">
              <div className="flex items-center space-x-6">
                <img
                  className="flex-shrink-0 object-cover w-12 h-12 rounded-lg"
                  src={
                    user?.profilePic !== ""
                      ? user?.profilePic
                      : "https://upload.wikimedia.org/wikipedia/commons/thumb/2/2c/Default_pfp.svg/1200px-Default_pfp.svg.png"
                  }
                  alt=""
                />
                <button
                  onClick={() => setPopUp(true)}
                  type="button"
                  className={`text-sm font-semibold text-[#4F46E5] transition-all duration-200  rounded-md hover:text-indigo-700 
                  focus:outline-none ${
                    mood === "light" ? "text-blue" : "text-blue bg-transparent"
                  }`}
                >
                  Update
                </button>
                {user?.profilePic !== "" && (
                  <button
                    type="button"
                    className={`text-sm font-semibold text-black-600 transition-all duration-200  rounded-md hover:text-indigo-700
                       focus:outline-none ${
                         mood === "light"
                           ? "text-red"
                           : "bg-transparent text-red"
                       }`}
                    onClick={removeLogo}
                  >
                    {textChange ? "Removing..." : "Remove"}
                  </button>
                )}
              </div>
            </div>
          </div>

          <div className="sm:grid sm:grid-cols-3 sm:gap-5 sm:items-start">
            <label
              className={`block text-[14px] font-bold ${
                mood === "light" ? "text-black" : "text-[#ddd]"
              }  sm:mt-px sm:pt-2`}
            >
              Name
            </label>
            <div className="mt-2 sm:mt-0 sm:col-span-2">
              <input
                type="text"
                name="name"
                id=""
                placeholder="Name"
                onChange={handelChange}
                defaultValue={user?.name}
                className="!outline-none border block w-full px-4 py-3 placeholder-[#52525B] border-[#D4D4D8] rounded-lg focus:border-[#4F46E5] sm:text-sm caret-[#4F46E5]"
              />
            </div>
          </div>

          <div className="sm:grid sm:grid-cols-3 sm:gap-5 sm:items-start">
            <label
              className={`block text-[14px] font-bold ${
                mood === "light" ? "text-black" : "text-[#ddd]"
              } sm:mt-px sm:pt-2`}
            >
              Phone number
            </label>
            <div className="mt-2 sm:mt-0 sm:col-span-2">
              <PhoneInput
                placeholder="Phone number"
                enableSearch={true}
                value={user?.phone_no}
                className=" customInputSize block w-full bg-white  text-base text-black border border-[#D4D4D8] rounded-lg focus-within:border-[#4F46E5]"
                onChange={setInputValue}
              />
            </div>
          </div>

          <div className="sm:grid sm:grid-cols-3 sm:gap-5 sm:items-start">
            <label
              className={`block text-[14px] font-bold ${
                mood === "light" ? "text-black" : "text-[#ddd]"
              } sm:mt-px sm:pt-2`}
            >
              Company name
            </label>
            <div className="mt-2 sm:mt-0 sm:col-span-2">
              <input
                type="text"
                name="company_name"
                id=""
                placeholder="Enter company name"
                onChange={handelChange}
                defaultValue={user?.company_name}
                className="!outline-none border block w-full px-4 py-3 placeholder-[#52525B] border-[#D4D4D8] rounded-lg focus:ring-
                [#4F46E5] focus:border-[#4F46E5] sm:text-sm caret-[#4F46E5]"
              />
            </div>
          </div>

          <div className="sm:grid sm:grid-cols-3 sm:gap-5 sm:items-start">
            <label
              className={`block text-[14px] font-bold ${
                mood === "light" ? "text-black" : "text-[#ddd]"
              }  sm:mt-px sm:pt-2`}
            >
              Email
            </label>
            <div className="mt-2 sm:mt-0 sm:col-span-2">
              <input
                type="email"
                name="email"
                id=""
                placeholder="Enter email address"
                disabled
                className="!outline-none border block w-full px-4 py-3 placeholder-[#52525B] border-[#D4D4D8] rounded-lg focus:ring-
                [#4F46E5] focus:border-[#4F46E5] sm:text-sm caret-[#4F46E5]"
                defaultValue={user?.email}
              />
            </div>
          </div>
          {user?.auth !== "local" && (
            <div className="sm:grid sm:grid-cols-3 sm:gap-5 sm:items-start">
              <label
                className={`block text-[14px] font-bold ${
                  mood === "light" ? "text-black" : "text-[#ddd]"
                }  sm:mt-px sm:pt-2`}
              >
                Logged in
              </label>
              <div className="mt-2 sm:mt-0 sm:col-span-2">
                {user?.auth === "google" && (
                  <div className=" border border-[#D4D4D8] w-[55%] p-2 rounded-lg flex items-center">
                    <FcGoogle size={25} className=" ml-2" />
                    <span className="ml-3 font-semibold">
                      Connected with Google
                    </span>
                  </div>
                )}
                {user?.auth === "linkedin" && (
                  <div className="border border-[#D4D4D8] w-[55%] p-2 rounded-lg flex items-center">
                    <AiFillLinkedin
                      size={28}
                      className="ml-2"
                      color="#0a66c2"
                    />
                    <span className="ml-3 font-semibold">
                      Connected with Linkedin
                    </span>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>

        <div className="mt-6 sm:mt-12 flex justify-between">
          {!loader ? (
            <button
              type="submit"
              onClick={update}
              className=" primaryButton py-3 w-[100px]"
            >
              Save
            </button>
          ) : (
            <button
              type="submit"
              disabled
              className=" primaryButton py-3 w-[100px]"
            >
              <CustomDotLoader
                dotSize={50}
                isWhite
                className="mx-auto text-center"
              />
            </button>
          )}

          <button
            onClick={() => setDeleteAccountModal(true)}
            className=" text-red underline"
          >
            Delete Account
          </button>
        </div>
      </div>

      {deleteAccountModal && (
        <>
          <AccountDeleteModal
            setShowPopup={setShowConfirmationPopup}
            setShowModal={setDeleteAccountModal}
          />
        </>
      )}
      <EventName
        status="deleteAccount"
        show={showConfirmationPopup}
        hide={setShowConfirmationPopup}
      />

      {popUp && (
        <>
          <ProfileUpload
            close={setPopUp}
            pre={setPreview}
            uploads={setuploads}
            statusChange={statusChange}
            change={setstatusChange}
            setbase64String={setbase64String}
            load={setLoader}
            uploadStatus={uploads}
          />
        </>
      )}
      <Toaster position="top-right" reverseOrder={false} />
    </div>
  );
};

export default Profile;
