import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  id: null,
  buttonClick: null
};

const idSlice = createSlice({
  name: "id",
  initialState,
  reducers: {
    setId(state, action) {
      state.id = action.payload;
    },
    buttonClick(state, action) {
      state.buttonClick = action.payload;
    }
  },
});

export const { setId, buttonClick } = idSlice.actions;
export default idSlice.reducer;
