/* eslint-disable no-undef */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import EventName from "../../components/popUp/EventName";
import Navbar from "../../components/Navbar";
import AddQuestions from "./addQuestions";
import Header from "./Header";
import Preview from "./preview";
import CustomeStyle from "../../components/addImage";
import { toast, Toaster } from "react-hot-toast";
import { useNavigate, useSearchParams } from "react-router-dom";
import { logoDetails, bgImageDetails } from "../../redux/slices/questions";
import { setClear } from "../../redux/slices/bgColor";
import axios from "../../axios";
import { setUserValue } from "../../redux/slices/auth";
import SmallScreenOverlay from "../SmallScreenOverlay";

const index = () => {
  const [questionType, setQuestionType] = useState("");
  const [id, setId] = useState("");
  const [selectedTab, setSelectedTab] = useState("Question");
  const { mood } = useSelector((state) => state.mood);
  const [popUp, setPopUp] = useState(false);
  const [eventNames, setEventName] = useState("");
  const [searchParams] = useSearchParams();
  const ID = searchParams.get("id");
  const [viewStatus, setViewStatus] = useState("computer");
  const [errorView, setErrorView] = useState();
  const navigate = useNavigate();
  localStorage.setItem("clearQuestion", "clear");
  const { eventName } = useSelector((state) => state.eventName);
  const { questionValues } = useSelector((state) => state.questions);
  const { user } = useSelector((state) => state.userAuth);
  const { questions } = useSelector((state) => state);
  const logoInfo = questions.logoDetails;
  const bgInfo = questions.bgImageDetails;
  const [popupType, setPopupType] = useState("created");
  const [message, setMessage] = useState("");
  const [popupStatus, setPopupStatus] = useState(false);
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [preview, setPreview] = useState("");
  const [update, setupdate] = useState(false);
  const [status, setStatus] = useState(false);

  const updates = () => {
    if (ID !== null) {
      setPopupType("created");
    }
  };

  useEffect(() => {
    const Api = async () => {
      if (update) {
        let list_of_items = questionValues.map((i) => {
          if (i.uuid !== undefined) {
            return {
              id: i.id.toString(),
              question: i.question,
              type: i.type,
              answer: i.choice,
              uuid: i.uuid,
              subHeading: i.subHeading,
            };
          } else {
            return {
              id: i.id.toString(),
              question: i.question,
              type: i.type,
              answer: i.choice,
              subHeading: i.subHeading,
            };
          }
        });
        for (const obj of list_of_items) {
          if (obj.type === "welcome") {
            obj.answer = [];
          }
        }

        for (let i = 0; i < list_of_items.length; i++) {
          if (list_of_items[i].question.trim() === "") {
            setErrorView(list_of_items[i]);
            toast.error(`Please fill data`);
            setLoader(false);
            return;
          }
        }
        const filter = list_of_items.filter(
          (i) => i.type !== "welcome" && i.type !== "contact"
        );
        const answerFilter = filter.map((i) => ({
          answer: i.answer,
          id: i.id,
        }));

        for (let i = 0; i < answerFilter.length; i++) {
          for (let j = 0; j < answerFilter[i].answer.length; j++) {
            if (answerFilter[i].answer[j].value.trim() === "") {
              setErrorView(answerFilter[i]);
              toast.error(`Please fill data`);
              setLoader(false);
              return;
            }
          }
        }

        if (list_of_items.length === 0) {
          setPopupStatus(true);
          setPopupType("messing");
          setMessage("Question");
          setLoader(false);
          setUpdate(false);
          return 0;
        } else {
          setLoader(true);
          const data = await axios.post("/add/event", {
            logoId: logoInfo.length === 0 ? "" : logoInfo?.[0]?.id,
            logoStatus: logoInfo.length === 0 ? "" : logoInfo[0]?.status,
            bgId: bgInfo.length === 0 ? "" : bgInfo[0]?.id,
            bgStatus: bgInfo.length === 0 ? "" : bgInfo[0]?.status,
            feedback_count: 0,
            qrCode: "",
            share_link: "",
            event_owner: user?.id,
            eventName: eventName,
            no_of_question: questionValues.length,
            list_of_items: list_of_items,
            action: "yes",
            buttonColor:
              questions.buttonColor === null
                ? "#4F46E5"
                : questions.buttonColor,
            backgroundColor: questions.backgroundColor,
            textColor:
              questions.textColor === null ? "#000" : questions.textColor,
            welcometextColor:
              questions.welcomeScreenTextColor === null
                ? "#000"
                : questions.welcomeScreenTextColor,
            id: localStorage.getItem("uuid"),
          });

          if (data.status === 201 && data.data.success) {
            setPreview(`${window.location.origin}/${data.data.eventId}`);
            window.history.pushState(
              {},
              document.title,
              `${window.location.href}?id=${data.data.eventId}`
            );
            setLoader(false);
            setPopupStatus(true);
            setPopupType("created");
            dispatch(setClear());
            setErrorView();
            localStorage.removeItem("BGColor");
            localStorage.removeItem("TextColor");
            const datas = await axios.post("/getParticularUser", {
              id: user.id,
            });
            dispatch(setUserValue(datas.data[0]));
            localStorage.setItem("Oldquestion", JSON.stringify(list_of_items));
            updates();
            if (ID !== null) {
              const createNotification = await axios.post(
                "/add-Notification-sa",
                {
                  id: user.id,
                  message: "User Created New Surveys",
                  pic: user?.profilePic,
                }
              );
              if (createNotification.data.success) {
                console.log(createNotification.data);
              }
            }
            return;
          } else {
            setLoader(false);
            setPopupType("failed");
            setPopupStatus(true);
          }
          return 0;
        }
      }
    };
    Api();
  }, [update, status]);

  return (
    <>
      <div className="min-h-screen max-lg:hidden">
        <Navbar
          inputValue={eventNames}
          setNames={setEventName}
          popups={setPopUp}
          hide={true}
        />
        <Header
          setViewStatus={setViewStatus}
          viewStatus={viewStatus}
          select={setSelectedTab}
        />
        {!eventName && (
          <EventName
            show={popUp}
            status={"addEvent"}
            hide={setPopUp}
            setInput={setEventName}
            inputValue={eventName}
          />
        )}
        <div className="w-full min-h-full bg-gradient-to-r from-[#f4f4f4] from-30% to-[#fafafa] to-60%">
          {selectedTab === "Question" && (
            <div className=" w-[40%] absolute bottom-0 left-0 bg-white py-9" />
          )}
          <div className="w-full min-h-full flex flex-wrap max-w-[105rem] mx-auto">
            {selectedTab === "Question" && (
              <AddQuestions
                questionTypes={setQuestionType}
                getId={(e) => setId(e)}
                id={id}
                quType={questionType}
                popup={ID === null && popUp}
                loader={loader}
                loadDetails={setLoader}
                create={setupdate}
                Status={setStatus}
                ViewStatus={status}
                errorHighlight={errorView}
              />
            )}
            {selectedTab === "Themes" && <CustomeStyle />}

            <Preview
              viewStatus={viewStatus}
              typeOfPreview={questionType}
              id={id}
              colName={setQuestionType}
              changeId={setId}
            />
          </div>
        </div>

        <EventName
          show={popupStatus}
          status={popupType}
          hide={setPopupStatus}
          messsage={message}
          preview={preview}
        />
      </div>
      <SmallScreenOverlay />
    </>
  );
};

export default index;
