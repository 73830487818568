import React, { useCallback, useEffect, useRef, useState } from "react";
import ReactCanvasConfetti from "react-canvas-confetti";
import { useSelector } from "react-redux";
import axios from "../../axios";
import {
  useNavigate,
  useLocation,
  useParams,
  useSearchParams,
} from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import Star from "../../components/star/Star";
import Axios from "axios";

import { motion } from "framer-motion";

// ** lottie player
import Lottie from "react-lottie-player";

// ** lottie json
import planeAnimationJson from "../../assets/lottie/feedback-loader.json";

function randomInRange(min, max) {
  return Math.random() * (max - min) + min;
}

const canvasStyles = {
  position: "fixed",
  pointerEvents: "none",
  width: "100%",
  height: "100%",
  top: 0,
  left: 0,
};

function getAnimationSettings(originXA, originXB) {
  return {
    startVelocity: 30,
    spread: 360,
    ticks: 60,
    zIndex: 0,
    particleCount: 150,
    origin: {
      x: randomInRange(originXA, originXB),
      y: Math.random() - 0.2,
    },
  };
}

const Success = () => {
  const { id } = useParams();
  const location = useLocation();
  const path = location.pathname;

  const colors = path.split("/");
  const { feedbackValue } = useSelector((state) => state.feedbackValue);
  const refAnimationInstance = useRef(null);
  const [intervalId, setIntervalId] = useState();
  const [status, setStatus] = useState(false);
  const local = localStorage.getItem("eventResponse");
  const [searchParams] = useSearchParams();
  const language = searchParams.get("language");
  const [staticText, setstaticText] = useState("");
  const [staticText1, setstaticText1] = useState("");

  const getInstance = useCallback((instance) => {
    refAnimationInstance.current = instance;
  }, []);

  const nextTickAnimation = useCallback(() => {
    if (refAnimationInstance.current) {
      refAnimationInstance.current(getAnimationSettings(0.1, 0.3));
      refAnimationInstance.current(getAnimationSettings(0.7, 0.9));
    }
  }, []);

  const startAnimation = useCallback(() => {
    if (!intervalId) {
      setIntervalId(setInterval(nextTickAnimation, 400));
    }
  }, [intervalId, nextTickAnimation]);

  const stopAnimation = useCallback(() => {
    clearInterval(intervalId);
    setIntervalId(null);
    refAnimationInstance.current && refAnimationInstance.current.reset();
  }, [intervalId]);

  useEffect(() => {
    return () => {
      clearInterval(intervalId);
    };
  }, [intervalId]);

  if (!status) {
    setTimeout(() => {
      stopAnimation();
    }, 7000);
  }

  const fetchThankYouText = async () => {
    const trans = await Axios.post(
      "https://feedback-translator.up.railway.app/translate-to-target",
      {
        randomText: "Feedback submitted",
        targetLanguage: language || "en", // Simplify language selection
      }
    );
    if (trans.data.success) {
      setstaticText(trans.data.data.data);
      const trans1 = await Axios.post(
        "https://feedback-translator.up.railway.app/translate-to-target",
        {
          randomText: "Thanks for your valuable feedback",
          targetLanguage: language || "en",
        }
      );

      if (trans1.data.success) {
        setstaticText1(trans1.data.data.data);
        const datas = await axios.post("/getParticularEvent", {
          shortID: id,
        });

        console.log('datas', datas.data);
        if (datas.status === 201 && datas.data[0].continue_loop) {
          if (feedbackValue?.[0]?.eventId && feedbackValue?.[0]?.list_of_items) {
            const updatedFeedbackValue = await feedbackValue.map((feedback) => ({
              ...feedback,
              list_of_items: feedback.list_of_items.map((item) => {
                if (item.type === 'contact') {
                  const updatedArrayAns = item.arrayAns.map((answer) => {
                    if (answer.type === 'gender') {
                      switch (answer.valieID) {
                        case '1':
                          return { ...answer, value: 'male' };
                        case '2':
                          return { ...answer, value: 'female' };
                        case '3':
                          return { ...answer, value: 'other' };
                        default:
                          return { ...answer, value: "" };
                      }
                    }
                    return answer;
                  });
            
                  return { ...item, arrayAns: updatedArrayAns };
                }
                return item;
              }),
            }));
  
  
            const data = await axios.post("/add/userFeedback", {
              // Use destructuring to make the code cleaner
              ...updatedFeedbackValue[0],
            });
            setStatus(true);
          }
          setTimeout(() => {
            const orgin = window.location.origin;
            window.location.replace(
              `${orgin}/${localStorage.getItem("location")}`
            );
          }, 4000);
          return
        } else {
          if (feedbackValue?.[0]?.eventId && feedbackValue?.[0]?.list_of_items) {
            const updatedFeedbackValue = await feedbackValue.map((feedback) => ({
              ...feedback,
              list_of_items: feedback.list_of_items.map((item) => {
                if (item.type === 'contact') {
                  const updatedArrayAns = item.arrayAns.map((answer) => {
                    if (answer.type === 'gender') {
                      switch (answer.valieID) {
                        case '1':
                          return { ...answer, value: 'male' };
                        case '2':
                          return { ...answer, value: 'female' };
                        case '3':
                          return { ...answer, value: 'other' };
                        default:
                          return { ...answer, value: "" };
                      }
                    }
                    return answer;
                  });
            
                  return { ...item, arrayAns: updatedArrayAns };
                }
                return item;
              }),
            }));
  
  
            const data = await axios.post("/add/userFeedback", {
              // Use destructuring to make the code cleaner
              ...updatedFeedbackValue[0],
            });
            setStatus(true);

             
        setTimeout(() => {
          window.location.replace("https://www.feedbacksync.com/");
        }, 4000);
          }
        }
      }
      startAnimation();
      localStorage.setItem(
        "eventResponse",
        local === "undefined" || local === null
          ? "false"
          : local === "true"
          ? "false"
          : "true"
      );
    }
    return;
  };

  useEffect(() => {  
    fetchThankYouText();
  }, [feedbackValue]);

  const [bg, setBg] = useState("white");
  const [color, setColor] = useState("#612166");
  useEffect(() => {
    setBg(`#${colors[3]}`);
    setColor(`#${colors[2]}`);
  }, [colors]);

  const click = () => {
    const orgin = window.location.origin;
    window.location.replace(`${orgin}/${localStorage.getItem("location")}`);
  };

  useEffect(() => {
    axios
      .post("/get-logo", {
        eventId: colors[4],
      })
      .then((data) => {
        console.log(data);
      });
  }, []);

  if (!status) {
    return (
      <div className="w-full h-screen flex items-center justify-center">
        <Lottie
          loop
          animationData={planeAnimationJson}
          play
          className=" w-96 h-96"
        />
      </div>
    );
  }

  return (
    <div>
      <div>
        <div
          className={`flex items-center justify-center min-h-screen reltive`}
        >
          <div className="flex flex-col items-center w-full h-screen justify-center">
            <div className="flex flex-col items-center">
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
              >
                <Star color={color} bg={bg} />
              </motion.div>
              {staticText && (
                <motion.p
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.5 }}
                  className="mt-5 sm:mt-10 text-[#A6A6A6] text-sm"
                >
                  {staticText}
                </motion.p>
              )}
              {staticText1 && (
                <motion.p
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5 }}
                  onClick={click}
                  className="text-3xl sm:text-5xl mt-4 sm:mt-8 cursor-pointer text-center font-semibold text-[#2F2F2F]"
                >
                  {staticText1}
                </motion.p>
              )}
            </div>
          </div>
        </div>
        <div
          className={`absolute bottom-0 w-full h-[15px] bg-[${color}]`}
        ></div>
      </div>

      <ReactCanvasConfetti refConfetti={getInstance} style={canvasStyles} />

      <Toaster position="top-right" reverseOrder={false} />
    </div>
  );
};

export default Success;
