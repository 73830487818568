/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState } from "react"
import { Link, useNavigate, useSearchParams } from "react-router-dom"
import { AiFillEye, AiFillEyeInvisible, AiFillLinkedin } from "react-icons/ai"
import axios from "../../../axios"
import toast, { Toaster } from "react-hot-toast"
import logo from "../../../assets/logo1.svg"
import CustomDotLoader from './../../../components/CustomDotLoader/index'
import "react-phone-number-input/style.css"
import PhoneInput from "react-phone-input-2"
import "react-phone-input-2/lib/style.css"
import { useEffect } from "react"
import PasswordStrengthBar from "react-password-strength-bar"
// import Dropdown from "../../../components/Dropdown/Dropdown";
import config from "../../../constants"
import { FcGoogle } from "react-icons/fc"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { signUpSchema } from "../../../utils/validation"
import dayjs from "dayjs"

// ** import components
import Input from "../../../components/Input/Input"
import FadeInTransition from "../../../components/FadeInTransition"

const index = () => {
    const navigate = useNavigate()
    const [visible, setVisible] = useState(false)
    const [loader, setLoader] = useState(false)
    const [loader1, setLoader1] = useState(false)
    const [loader2, setLoader2] = useState(false)
    const [passwordStrength, setPasswordStrength] = useState("")
    const [phone, setPhone] = useState("")

    const {
        register,
        handleSubmit,
        watch,
        reset,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(signUpSchema)
    })

    const google = async () => {
        setLoader1(true)
        localStorage.removeItem("Token")
        // localStorage.setItem("SIGNIN", true);

        localStorage.setItem("Signin Method", "google")
        window.open(`${config.SERVER_URL}/google`, "_self")
    }

    const linkedin = async () => {
        setLoader2(true)
        localStorage.removeItem("Token")
        // localStorage.setItem("SIGNIN", true);

        localStorage.setItem("Signin Method", "linkedIn")
        window.open(`${config.SERVER_URL}/linkedIN`, "_self")
    }

    const onSubmit = async (res) => {
        setLoader(true)
        const { data } = await axios.post("/addUser", {
            name: res.Name,
            email: res.Email,
            password: res.Password,
            phone_no: phone,
            company_name: res.CompanyName,
            event: [],
            profilePic: "",
            paymentId: "",
            PaymentPaid: "0",
            free: "free",
            auth: "local",
            plan_activate_date: dayjs().toISOString()
        })
        if (data.success) {
            toast.success(`${data.message}`)
            setLoader(false)
            reset({
                Name: "",
                Email: "",
                CompanyName: "",
                Password: ""
            })
            setPhone("")
            navigate(`/verify?email=${res.Email}&verify=verify`)
            return
        } else {
            setLoader(false)
            return toast.error(`${data.message}`)
        }
    }

    useEffect(() => {
        setPasswordStrength(watch().Password)
    }, [watch().Password])

    return (
        <div className="max-h-[100%] overflow-hidden py-20">
            <div className="fixed bottom-0 left-0 w-full border-b-[10px] border-[#4F46E5] z-50"></div>
            <div
                style={{ boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.15)" }}
                className="py-5 w-full !z-50 absolute top-0"
            >
                <img
                    className="w-[175px] block mx-auto"
                    src={logo}
                    alt=""
                />
            </div>
            <FadeInTransition>
                <section className=" !mt-[80px] w-full">
                    <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
                        <div className="relative max-w-md mx-auto lg:max-w-lg">
                            <div className="absolute -inset-2">
                                {/* <div
                className="w-full h-full mx-auto rounded-3xl opacity-30 blur-lg filter"
                style={{"background: linear-gradient(90deg, #44ff9a -0.55%, #44b0ff 22.86%, #8b44ff 48.36%, #ff6644 73.33%, #ebff70 99.34%"}}
              ></div> */}
                            </div>

                            <div
                                style={{ boxShadow: "0px 0px 61.3082px rgba(0, 0, 0, 0.15)" }}
                                className="bg-[white] relative overflow-hidden rounded-[16px]"
                            >
                                <div className="px-4 py-6 sm:px-8">
                                    <h1 className="font-bold font-pj text-center text-[#18181B] text-[21px]">
                                        Welcome to Feedbacksync
                                    </h1>

                                    <p className="text-center text-[#52525B] text-[16px] mt-2">Create your account</p>

                                    <form
                                        onSubmit={handleSubmit(onSubmit)}
                                        action=""
                                    >
                                        <div className="space-y-5 mt-[30px]">
                                            <Input
                                                name="Name"
                                                type="text"
                                                id="Name"
                                                placeholder="Full name"
                                                register={register}
                                                error={errors?.Name?.message}
                                            />

                                            <Input
                                                name="Company Name"
                                                id="CompanyName"
                                                type="text"
                                                placeholder="Enter your company name"
                                                register={register}
                                                error={errors?.CompanyName?.message}
                                            />
                                            <div>
                                                <label
                                                    for=""
                                                    className="text-[14px] text-[#18181B] font-semibold text-gray-900 font-pj"
                                                >
                                                    {" "}
                                                    Phone No{" "}
                                                </label>
                                                <div className="mt-1">
                                                    <PhoneInput
                                                        placeholder="Phone number"
                                                        value={phone}
                                                        className=" customInputSize py-1 block w-full bg-white  text-base text-black border border-[#D4D4D8] rounded-lg focus-within:border-[#4F46E5]"
                                                        onChange={setPhone}
                                                        name="phone"
                                                        enableSearch={true}
                                                        country={"in"}
                                                        defaultCountry={"in"}
                                                    />
                                                </div>
                                            </div>

                                            <Input
                                                name="Email"
                                                type="email"
                                                id="Email"
                                                placeholder="Enter email address"
                                                register={register}
                                                error={errors?.Email?.message}
                                            />
                                            <div className="relative">
                                                <Input
                                                    name="Password"
                                                    id="Password"
                                                    type={visible === false ? "password" : "text"}
                                                    placeholder="Enter password"
                                                    register={register}
                                                    error={errors?.Password?.message}
                                                />
                                                <p className=" absolute z-50 right-3 top-11 ">
                                                    {visible === false ? (
                                                        <>
                                                            <AiFillEyeInvisible
                                                                size={26}
                                                                color="gray"
                                                                className="cursor-pointer"
                                                                onClick={() => setVisible(true)}
                                                            />
                                                        </>
                                                    ) : (
                                                        <>
                                                            <AiFillEye
                                                                size={26}
                                                                color="gray"
                                                                className="cursor-pointer"
                                                                onClick={() => setVisible(false)}
                                                            />
                                                        </>
                                                    )}
                                                </p>
                                            </div>
                                            <PasswordStrengthBar
                                                className="!mt-3"
                                                password={passwordStrength}
                                            />
                                            <div></div>

                                            {/* <div className="!mt-0">
                        <div className="mt-1">
                          <Dropdown
                            name="Your payment plan"
                            option={["Free", "Premium"]}
                            plan={Plan}
                            selected={setPlan}
                          />
                        </div>
                      </div>

                      <div className="!mt-4">
                        <div className="mt-2">
                          <Dropdown
                            name="Your subscription plan"
                            option={["Monthly", "Yearly"]}
                            plan={Subscription}
                            selected={setSubscription}
                          />
                        </div>
                      </div> */}

                                            {/* <div className="relative items-center mt-4 flex justify-between">
                        <div className="flex">
                          <div className="flex items-center h-5">
                            <input
                              required
                              type="checkbox"
                              name="terms"
                              id="terms"
                              className="w-5 h-5 text-gray-900 border-gray-300 rounded focus:ring-gray-900"
                            />
                          </div>

                          <div className="ml-3 text-base">
                            <label
                              for="terms"
                              className="text-[#18181B] font-[16px] font-pj ml-2"
                            >
                              I agree to
                              <a
                                target="_blank"
                                href="https://www.feedbacksync.com/privacy-policy"
                                title=""
                                className="font-bold rounded focus:outline-none focus:ring-1 focus:ring-gray-900 focus:ring-offset-2 hover:no-underline ml-2 mr-2 text-blue underline"
                              >
                                Privacy Policy
                              </a>
                              and
                              <a
                                target="_blank"
                                href="https://www.feedbacksync.com/terms-conditions"
                                title=""
                                className="font-bold rounded focus:outline-none focus:ring-1 focus:ring-gray-900 focus:ring-offset-2 hover:no-underline ml-2 text-blue underline"
                              >
                                Terms and Conditions
                              </a>
                            </label>
                          </div>
                        </div>
                      </div> */}
                                        </div>

                                        {!loader ? (
                                            <button
                                                type="submit"
                                                className="flex items-center justify-center w-full px-8 h-[50px] max-h-[50px] mt-5 text-base font-bold text-white transition-all duration-200 bg-[#4F46E5] border border-transparent hover:border-[#4F46E5] hover:!bg-white hover:text-[#4F46E5] rounded-xl focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray  hover:bg-grayDark"
                                            >
                                                Sign up
                                            </button>
                                        ) : (
                                            <button
                                                type="submit"
                                                className={`flex items-center justify-center overflow-hidden h-[50px] max-h-[50px] w-full px-8 mt-5 text-base font-bold text-white transition-all duration-200 bg-[#4F46E5] border border-transparent hover:border-[#4F46E5] hover:!bg-[#4e46e5e8] hover:text-[white] rounded-xl focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray`}
                                            >
                                                <CustomDotLoader
                                                dotSize={70}
                                                isWhite
                                              
                                            />
                                            </button>
                                        )}
                                    </form>

                                    <div className=" relative mt-4">
                                        <hr className=" absolute w-[30%] top-[12px] left-0 text-lightColor" />
                                        <p className="text-center font-medium text-base">Or continue with</p>
                                        <hr className=" absolute w-[30%] top-[12px] right-0 text-lightColor" />
                                    </div>

                                    {!loader1 ? (
                                        <button
                                            // disabled={!captcha}
                                            type="submit"
                                            className="flex items-center justify-center w-full px-8 h-[50px] max-h-[50px] mt-5 text-base font-bold text-black transition-all duration-200 border border-lightColor  rounded-xl "
                                            onClick={google}
                                        >
                                            <FcGoogle
                                                size={25}
                                                className="cursor-pointer mx-3"
                                            />
                                            <span>Sign up with Google</span>
                                        </button>
                                    ) : (
                                        <button
                                            type="submit"
                                            className={`flex items-center justify-center overflow-hidden h-[50px] max-h-[50px] w-full px-8 mt-5 text-base font-bold text-black transition-all duration-200  border border-lightColor rounded-xl`}
                                        >
                                            <CustomDotLoader
                                                    dotSize={70}
                                                    
                                                />
                                        </button>
                                    )}

                                    {/* OR */}

                                    {!loader2 ? (
                                        <button
                                            // disabled={!captcha}
                                            type="submit"
                                            className="flex items-center justify-center w-full px-8 h-[50px] max-h-[50px] mt-5 text-base font-bold text-black transition-all duration-200 border border-lightColor  rounded-xl "
                                            onClick={linkedin}
                                        >
                                            <AiFillLinkedin
                                                size={30}
                                                className="cursor-pointer mx-3 rounded-xl"
                                                color="#0a66c2"
                                            />
                                            <span>Sign up with LinkedIn</span>
                                        </button>
                                    ) : (
                                        <button
                                            type="submit"
                                            className={`flex items-center justify-center overflow-hidden h-[50px] max-h-[50px] w-full px-8 mt-5 text-base font-bold text-black transition-all duration-200  border border-lightColor rounded-xl`}
                                        >
                                              <CustomDotLoader
                                                dotSize={70}
                                                
                                            />
                                        </button>
                                    )}

                                    <p className="text-[#18181B] text-[15px] text-center pt-6">
                                        Already have an account?
                                        <Link
                                            to="/login"
                                            className="text-base font-medium text-[#4F46E5] rounded font-pj text-[15px] hover:underline focus:outline-none focus:ring-1 focus:ring-gray-900 focus:ring-offset-2 ml-2"
                                        >
                                            Log In
                                        </Link>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </FadeInTransition>

            <Toaster
                position="top-right"
                reverseOrder={false}
            />
        </div>
    )
}

export default index
