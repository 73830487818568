import React from "react"
import QRCode from "react-qr-code"
import { AiOutlineDownload } from "react-icons/ai"
import html2canvas from "html2canvas"
import { useSelector } from "react-redux"
import axios from "../../axios"
import { toast } from "react-hot-toast"

// assets
import brandLogo from "../../assets/logo/logo-brand-white.svg"

const Template = ({ link, webtext, title, qrCode, description, brand, brandColor, brandTextColor, qrID }) => {
    const { logo } = useSelector((state) => state.qrLogo)
    const { defaultLogo } = useSelector((state) => state.qrLogo)
    const { user } = useSelector((state) => state.userAuth)

    const download = async () => {
        if (qrID !== undefined && qrID !== null && qrID !== "") {
            const { data } = await axios.post("/qr-download", {
                id: qrID
            })
            if (data.success) {
                const createNotification = await axios.post("/add-Notification-sa", {
                    id: user.id,
                    message: `User Downalod Qr Template ${qrID}`,
                    pic: user?.profilePic
                })
                if (createNotification.data.success) {
                }
            }
        } else {
            return toast.error("Choose the Survey")
        }
    }

    const downloadPNG = () => {
        if (qrID !== undefined && qrID !== null && qrID !== "") {
            const screenshorttarget = document.getElementById("webpage")

            html2canvas(screenshorttarget).then((canvas) => {
                const base64Image = canvas.toDataURL("image/png")
                var anchor = document.createElement("a")
                anchor.setAttribute("href", base64Image)
                anchor.setAttribute("download", "Image.png")
                anchor.click()
            })
        }
    }

    const downloadSVG = () => {
        if (qrID !== undefined && qrID !== null && qrID !== "") {
            const screenshorttarget = document.getElementById("webpage")

            html2canvas(screenshorttarget).then((canvas) => {
                const svgImage = createSVGImage(canvas)
                const svgString = getSVGString(svgImage)

                var blob = new Blob([svgString], { type: "image/svg+xml" })
                var url = URL.createObjectURL(blob)

                var anchor = document.createElement("a")
                anchor.setAttribute("href", url)
                anchor.setAttribute("download", "Image.svg")
                anchor.click()
            })
        }
    }

    const createSVGImage = (canvas) => {
        const svgImage = new Image()
        const ctx = canvas.getContext("2d")
        const data = canvas.toDataURL("image/png")

        svgImage.onload = () => {
            ctx.clearRect(0, 0, canvas.width, canvas.height)
            ctx.drawImage(svgImage, 0, 0)
        }

        svgImage.src = data
        return svgImage
    }

    const getSVGString = (svgImage) => {
        const serializer = new XMLSerializer()
        const svgString = serializer.serializeToString(svgImage)
        return svgString
    }

    return (
        <div className=" w-full  l3:pl-10">
            <div
                id="webpage"
                style={{
                    boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.15)",
                }}
                className="template relative max-w-xl mx-auto"
            >
                <div
                    style={{
                        display: link === false ? "none" : "block"
                    }}
                    className="template_content_link"
                >
                    <p
                        style={{
                            background: brandColor,
                            color: brandTextColor
                        }}
                        className={`template_content1_text `}
                    >
                        {webtext}
                    </p>
                </div>

                <div className="template_content1 mt-20">
                    <div className="template_content_logo">
                        <img
                            src={logo !== null ? logo : defaultLogo}
                            alt="logo"
                        />
                    </div>
                </div>

                <div className="template_content1 my-5">
                    <div className="template_content_title">
                        {/* 40 character */}
                        <p>{title}</p>
                    </div>
                </div>

                <div className="template_content1 !mt-0">
                    <div className="QR_code">
                        {qrCode && qrCode.length > 0 && (
                            <QRCode
                                value={qrCode}
                                size={170}
                                style={{
                                    borderRadius: "10px"
                                }}
                                renderAs="svg"
                                includeMargin
                            />
                        )}
                        {/* <div className="template_content1  mt-5"> */}
                        <button
                            style={{ background: brandColor, color: brandTextColor }}
                            className=" button absolute bottom-[-30%] w-full "
                        >
                            <p>Scan me</p>
                        </button>
                        {/* </div> */}
                    </div>
                </div>

                <div className="template_content1 mt-5">
                    <div className="template_content_description">
                        {/* 120 character */}
                        <p>{description}</p>
                    </div>
                </div>

                <div
                    style={{
                        display: brand === false ? "none" : "block"
                    }}
                    className="template_content_brand_content "
                >
                    <div
                        style={{
                            background: brandColor
                        }}
                        className="template_content_brand"
                    >
                        <div
                            style={{
                                color: brandTextColor
                            }}
                            className="template_content_brand_left"
                        >
                            <p>Want feedback from your valuable customers?</p>
                        </div>
                        <div
                            style={{
                                color: brandTextColor
                            }}
                            className="template_content_brand_right"
                        >
                            {/* <p>Feedback Sync</p> */}
                            <img
                                src={brandLogo}
                                alt="logo"
                            />
                            <p>www.feedbacksync.com</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="template_content1 mt-5">
                <div className=" w-full flex gap-4">
                    <button
                        className="secondaryButton !gap-2 !py-4 flex-1 relative"
                        onClick={() => {
                            downloadSVG()
                            download()
                        }}
                    >
                        <AiOutlineDownload
                            size={25}
                            className=" "
                        />
                        <p>Download SVG</p>
                    </button>

                    <button
                        className="primaryButton !gap-2 !py-4 flex-1 relative"
                        onClick={() => {
                            downloadPNG()
                            download()
                        }}
                    >
                        <AiOutlineDownload
                            size={25}
                            className=" "
                        />
                        <p>Download PNG</p>
                    </button>
                </div>
            </div>
        </div>
    )
}

export default Template
