import React from "react"
import { X } from "react-feather"
import { AiOutlineClose } from "react-icons/ai"

const ModalCloseBtn = ({ onClick }) => {
    return (
        <button
            type="button"
            className="absolute -top-3 -right-3 text-black  bg-blue  rounded-full text-sm p-1.5 ml-auto inline-flex items-center transition-all duration-300"
            data-modal-toggle="popup-modal"
        >
            <AiOutlineClose
                size={18}
                onClick={onClick}
                className=" text-white"
            />
        </button>
    )
}

export default ModalCloseBtn
