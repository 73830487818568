import React, { useEffect, useState } from "react"
// import Switch from "react-input-switch"
import Switch from "react-switch"
import Button from "../../components/shared/Button"
import { Link, useNavigate } from "react-router-dom"
import axios from "../../axios"
import { toast } from "react-hot-toast"
import { IoMdArrowBack } from "react-icons/io"
import { useSelector } from "react-redux"
import bgImage from "../../assets/images/plans-bg.png"
import SmallScreenOverlay from "../SmallScreenOverlay"

const Payment = () => {
    const [isYearlyPlan, setIsYearlyPlan] = useState(true)
    const { user } = useSelector((state) => state.userAuth)

    const handelPayment = async (e) => {
        if (user) {
            const { data } = await axios.post("/phonepe", {
                mobileNumber: user.phone_no,
                id: user.id,
                plan: "OneTime"
            })
            if (data.success) {
                const url = data.data.instrumentResponse.redirectInfo.url
                if (url) {
                    window.location.href = url
                }
            }
        } else {
            return toast.error("Please login and pay")
        }
    }

    // useEffect(() => {
    //   if (user) {
    //     return setIsYearlyPlan(user?.payment_plan === "month" ? false : true);
    //   }
    // }, []);

    return (
        <>
            <div className="w-full min-h-full overflow-hidden sectionPadding pb-10 max-w-[105rem] pt-8 mx-auto relative max-lg:hidden">
                <Link
                    className="flex items-center cursor-pointer"
                    to="/"
                >
                    <IoMdArrowBack
                        size={20}
                        className=" mx-[2px] text-blue hover:transition-all hover:duration-300"
                    />

                    <p className=" text-blue ">Back</p>
                </Link>

                <div className="absolute pointer-events-none hidden px-20 pt-20 lg:block top-[20%] l3:top-[6%] z-10 left-0 w-[100%] mx-auto ">
                    <img
                        src={bgImage}
                        alt="background illustration"
                        className=" w-full h-full object-contain"
                    />
                </div>
                <h1 className=" text-2xl l2:text-4xl font-bold text-center">
                    Be the first to try <span className=" text-blue">Feedback Sync</span>
                </h1>
                <h5 className="text-[#616C87] mt-[8px] text-center">
                    Join the beta to experience the full power of Feedback Sync
                </h5>

                {/* <div className="ms:flex items-center mx-auto text-center w-full justify-center mt-8"> */}
                {/* <div className="flex justify-center ms:justify-start"> */}
                {/* switch */}
                {/* <Switch
                        checkedIcon={false}
                        uncheckedIcon={false}
                        className="react-switch ml-4"
                        onColor="#4F46E5"
                        onHandleColor="#fff"
                        handleDiameter={20}
                        // className="mx-4"
                        checked={isYearlyPlan}
                        // onChange={() => setIsYearlyPlan((prev) => !prev)}
                    /> */}
                {/* </div> */}

                {/* <div className="flex items-center justify-center ms:justify-start">
                        <h6
                            className={` text-2xl l2:text-4xl font-bold text-center text-blue ml-5 ${
                                !isYearlyPlan && " text-blue"
                            }`}
                        >
                            Life Time Plan
                        </h6>
                        <p
                            className="font-[600] font-[poppins] ml-3 !text-lg text-primary"
                            href=" "
                        >
                            {user?.payment_activate && !user?.free_trail ? "" : "(15 Days Free)"}
                        </p>
                    </div>
                </div> */}

                <div className="w-full mt-10 scale-95 l3:scale-100  flex flex-wrap justify-center mx-0 relative">
                    <div
                        style={{
                            boxShadow:
                                "0px 6px 14px -6px rgba(24, 39, 75, 0.12), 0px 10px 32px -4px rgba(24, 39, 75, 0.1)"
                        }}
                        className=" relative z-0  sm:mt-0 w-[90%] ms:w-[70%] sm:w-[45%] lg:w-[32%] l3:w-[31%] mx-auto lg:mx-0 bg-[#4f46e5] border-2 border-black rounded-lg "
                    >
                        {/* <span className="ribbon"></span> */}
                        <div className=" p-[25px] bg-[#4f46e5] border-b border-black overflow-hidden">
                            <h6 className="text-[#fff] text-lg font-semibold l3:text-xl">Onetime Purchase</h6>
                            <p className="text-[#fff] text-sm l3:text-base mt-2">
                                Analyze performance and do more with your data
                            </p>
                        </div>
                        <div className=" p-4 l3:p-6">
                            <div className="flex items-center">
                                <span className="font-[poppins] text-4xl l3:text-[48px] font-[600] text-[#fff]">
                                    Lifetime
                                </span>
                                <p className="ml-2 text-[14px] font-[poppins] text-[#fff]">/License</p>
                            </div>

                            <button
                                disabled={user?.payment_activate && !user?.free_trail}
                                onClick={() => handelPayment(!isYearlyPlan ? 3999 : 39999)}
                                className="secondaryButton hover:!text-blue hover:bg-white hover:ring-2 hover:!ring-white w-full my-6"
                            >
                                {user?.payment_activate && !user?.free_trail ? "Already Subscribed" : "Join Beta"}
                            </button>

                            <div>
                                <span className="text-[#fff] font-[600]">SPARK FEATURES</span>
                                <div className="mt-[10px] flex items-center">
                                    <div>
                                        <i class="fa-solid fa-check text-primary text-[9px] bg-white rounded-full p-[4px]"></i>
                                    </div>
                                    <p className="ml-2 mb-[-6px] font-[poppins] text-[16px] text-[#fff]">
                                        15 days free trial
                                    </p>
                                </div>
                                <div className="mt-[10px] flex items-center">
                                    <div>
                                        <i class="fa-solid fa-check text-primary text-[9px] bg-white rounded-full p-[4px]"></i>
                                    </div>
                                    <p className="ml-2 mb-[-6px] font-[poppins] text-[16px] text-[#fff]">
                                        Unlimited feedbacks
                                    </p>
                                </div>
                                <div className="mt-[10px] flex items-center">
                                    <div>
                                        <i class="fa-solid fa-check text-primary text-[9px] bg-white rounded-full p-[4px]"></i>
                                    </div>
                                    <p className="ml-2 mb-[-6px] font-[poppins] text-[16px] text-[#fff]">
                                        Unlimited Surveys
                                    </p>
                                </div>
                                <div className="mt-[10px] flex items-center">
                                    <div>
                                        <i class="fa-solid fa-check text-primary text-[9px] bg-white rounded-full p-[4px]"></i>
                                    </div>
                                    <p className="ml-2 mb-[-6px] font-[poppins] text-[16px] text-[#fff]">
                                        Unlimited questions
                                    </p>
                                </div>
                                {/* <div className="mt-[10px] flex items-center">
                                <div>
                                    <i class="fa-solid fa-check text-primary text-[9px] bg-white rounded-full p-[4px]"></i>
                                </div>
                                <p className="ml-2 mb-[-6px] font-[poppins] text-[16px] text-[#fff]">Unlimited sms</p>
                            </div> */}
                                <div className="mt-[10px] flex items-center">
                                    <div>
                                        <i class="fa-solid fa-check text-primary text-[9px] bg-white rounded-full p-[4px]"></i>
                                    </div>
                                    <p className="ml-2 mb-[-6px] font-[poppins] text-[16px] text-[#fff]">
                                        Unlimited email responses
                                    </p>
                                </div>
                                <div className="mt-[10px] flex items-center">
                                    <div>
                                        <i class="fa-solid fa-check text-primary text-[9px] bg-white rounded-full p-[4px]"></i>
                                    </div>
                                    <p className="ml-2 mb-[-6px] font-[poppins] text-[16px] text-[#fff]">
                                        No credit card required
                                    </p>
                                </div>
                                {/* <div className="mt-[10px] flex items-center">
                                <div>
                                    <i class="fa-solid fa-check text-primary text-[9px] bg-white rounded-full p-[4px]"></i>
                                </div>
                                <p className="ml-2 mb-[-6px] font-[poppins] text-[16px] text-[#fff]">Multiple Integration</p>
                            </div> */}
                                <div className="mt-[10px] flex items-center">
                                    <div>
                                        <i class="fa-solid fa-check text-primary text-[9px] bg-white rounded-full p-[4px]"></i>
                                    </div>
                                    <p className="ml-2 mb-[-6px] font-[poppins] text-[16px] text-[#fff]">
                                        Dashboards & reports
                                    </p>
                                </div>
                                <div className="mt-[10px] flex items-center">
                                    <div>
                                        <i class="fa-solid fa-check text-primary text-[9px] bg-white rounded-full p-[4px]"></i>
                                    </div>
                                    <p className="ml-2 mb-[-6px] font-[poppins] text-[16px] text-[#fff]">Support assistance</p>
                                </div>
                            </div>
                        </div>
                        {/* <div className="absolute top-0 left-[-380px] w-[400px] h-[630px] z-50">
                        <img
                            src={cardImage}
                            alt="man holding phone illustration"
                            className=" w-full h-full object-cover"
                        />
                    </div> */}
                    </div>
                </div>

                <div className="flex bg-blue justify-between p-6 mt-20 l3:mt-32 rounded-xl">
                    <div className=" text-white">
                        <h2 className=" text-xl font-bold">Enterprise</h2>
                        <sub className=" text-base font-normal">Unlimited users</sub>
                    </div>
                    <p className=" text-base font-normal text-white max-w-lg">
                        This option is best for companies that need custom work and integration with other systems.
                    </p>

                    <a
                        href="mailto:support@feedbacksync.com"
                        // onClick={() => navigate("/payment")}
                        className=" button bg-white text-blue focus:!ring-0 focus:ring-offset-0 mt-1"
                    >
                        Contact Us
                    </a>
                </div>
            </div>
            <SmallScreenOverlay />
        </>
    )
}

export default Payment
