import { BrowserRouter } from "react-router-dom";
import Router from "./Routes/Routes";
import { Provider, useSelector } from "react-redux";
import { store } from "./redux/store";
import AOS from 'aos'
import 'aos/dist/aos.css';
import { useEffect } from "react";

function App() {

  useEffect(() => {
    AOS.init({
      once: true,
    });
  }, [])

  return (
    <BrowserRouter>
      <Provider store={store}>
        <Router />
      </Provider>
    </BrowserRouter>
  );
}

export default App;
