/* eslint-disable react-hooks/exhaustive-deps */
import React from "react"
import { useEffect } from "react"
import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { setFeedbackValue } from "../../../redux/slices/feedbackValue"
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io"
import "./Nsp.css"
import α from "color-alpha"

const Nsp = ({ inputQuestion, id, questionType, answer, nextQuz, next, eventId }) => {
    const [count, setCount] = useState([])
    const [value, setValue] = useState("")
    const { questionValues } = useSelector((state) => state.questions)
    const [nextValues, setNextValues] = useState([])
    const { questions } = useSelector((state) => state)
    const logoInfo = questions.logoDetails
    const buttonColors = questions.buttonColor
    const textColors = questions.textColor
    const [preValue, setPreValue] = useState([])
    let prev = Number(id) - 1
    useEffect(() => {
        const filter = questionValues.filter((e) => Number(e.id) === Number(prev))
        setPreValue(filter)
    }, [questionType, id])
    // const { buttonColor } = useSelector((state) => state.globalDefine);
    // const { textColor } = useSelector((state) => state.globalDefine);
    const col = α(
        (buttonColors !== null) & (buttonColors !== undefined)
            ? // ? buttonColor :
              buttonColors
            : "#4F46E5",
        0.2
    )
    // const { companyLogo } = useSelector((state) => state.globalDefine);
    let nextId = Number(id) + 1

    // const dispatch = useDispatch();
    const { mood } = useSelector((state) => state.mood)
    const [selected, setSelected] = useState(0)

    // const getValue = (answer) => {
    //   if (
    //     inputQuestion?.length !== 0 &&
    //     answer?.length !== 0 &&
    //     id?.length !== 0 &&
    //     questionType?.length !== 0 &&
    //     answer?.length !== 0 &&
    //     eventId?.length !== 0
    //   ) {
    //     let list = [...questions[0].list_of_items];
    //     list[id - 1] = {
    //       ...list[id - 1],
    //       answer: answer.toString(),
    //     };
    //     let allItem = { ...questions[0], list_of_items: list };
    //     return dispatch(setFeedbackValue(allItem));
    //   }
    // };

    useEffect(() => {
        const filter = questionValues.filter((e) => Number(e.id) === Number(nextId))
        setNextValues(filter)
    }, [questionType, id])

    useEffect(() => {
        const value = []
        for (let i = 1; i <= 10; i++) {
            value.push(i)
        }
        return setCount(value)
    }, [])

    return (
        <div className=" h-fit max-h-[100%] w-full flex flex-col items-center  mt-[30px] overflow-y-auto overflow-x-hidden">
            {logoInfo.length !== 0 && (
                <img
                    className="mb-[30px] max-w-[125px]"
                    src={logoInfo[0]?.logoLocation}
                    alt=""
                />
            )}
            {/* {logoInfo.length === 0 && companyLogo !== null && (
        <img className="mb-[30px] max-w-[125px]" src={companyLogo} alt="" />
      )} */}

            <h3
                style={{
                    color: `${
                        (textColors !== null) & (textColors !== undefined) &&
                        // ? textColor :
                        textColors
                    }`
                }}
                className={`w-[80%]  z-20 text-center mx-10 text-[18px] font-[500] font-inter  ${
                    mood === "light" ? "text-black" : "text-white"
                } break-words`}
            >
                <span className="w-full">{inputQuestion}</span>
            </h3>

            <div className="flex items-center justify-center mx-6 flex-wrap">
                {count.map((i, index) => (
                    <p
                        key={i}
                        onClick={() => {
                            setSelected(i)
                            setValue(i)
                            // getValue(i);
                        }}
                        style={{
                            border: `3px solid ${
                                selected === i
                                    ? (buttonColors !== null) & (buttonColors !== undefined)
                                        ? // ? buttonColor :
                                          buttonColors
                                        : "#4F46E5"
                                    : "#F7F7F7"
                            }`,
                            color: `${selected === i ? "black" : "#494949"}`,
                            background: `${selected === i ? col : "#F7F7F7"}`
                        }}
                        className={`m-3 w-14 h-12 rounded cursor-pointer font-semibold text-base py-3 ${
                            value === i ? `bg-blue` : `bg-white`
                        }  flex items-center justify-center ${value === i ? `hover:bg-blue` : `hover:bg-grayLight`}
            `}
                    >
                        {i}
                    </p>
                ))}
            </div>
            <div className="flex items-center gap-x-3">
                {preValue.length !== 0 && (
                    <button
                        type="button"
                        style={{
                            background: `${
                                buttonColors === null || buttonColors === undefined
                                    ? "#4F46E5"
                                    : //  buttonColor
                                      buttonColors
                            }`
                        }}
                        className=" rounded-full p-3 my-3 transition-all duration-200 text-lg font-semibold text-center block "
                        onClick={() => {
                            nextQuz(preValue[0].type)
                            next(prev)
                        }}
                    >
                        <IoIosArrowBack
                            size={40}
                            color="white"
                        />
                    </button>
                )}
                {nextValues.length !== 0 && (
                    <button
                        style={{
                            background: `${
                                buttonColors === null || buttonColors === undefined
                                    ? "#4F46E5"
                                    : //  buttonColor
                                      buttonColors
                            }`
                        }}
                        className=" rounded-full p-3 my-3 transition-all duration-200 text-lg font-semibold text-center block "
                        onClick={() => {
                            nextQuz(nextValues[0].type)
                            next(nextId)
                        }}
                    >
                        <IoIosArrowForward
                            size={40}
                            color="white"
                        />
                    </button>
                )}
            </div>
        </div>
    )
}

export default Nsp
