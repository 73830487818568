import React, { useEffect, useState } from "react"
import Switch from "react-switch"
import axios from "../../axios"
import { useSelector } from "react-redux"
import { toast } from "react-hot-toast"

// ** icons
import { AiOutlineClose } from "react-icons/ai"
import { BiChevronDown, BiInfoCircle } from "react-icons/bi"
import Tooltip from './../Tooltip/index'


const GeneralSettings = () => {
    const { user } = useSelector((state) => state.userAuth)
    const [email, setEmail] = useState(false)
    const [sms, setSms] = useState(false)
    const [eventList, seteventList] = useState([])
    const [selectedEvents, setselectedEvents] = useState([])
    const [filterValue, setfilterValue] = useState([])
    const [dropDownList, setDropDownList] = useState(false)
    const [search, setsearch] = useState("")
    const [statusChange, setstatusChange] = useState(false)
    const [select, setselect] = useState(false)

    useEffect(() => {
        const Api = async () => {
            if (user) {
                const { data } = await axios.post("/user/event", {
                    id: user.id
                })

                if (data.success) {
                    seteventList(data.data)
                    const getloopStatus = data.data.filter((i) => i.continue_loop === true)
                    setselectedEvents([])
                    getloopStatus.map((i) =>
                        setselectedEvents((pre) => [
                            ...pre,
                            {
                                id: i.id,
                                name: i.eventName,
                                event_owner: i.event_owner
                            }
                        ])
                    )
                }
            }
        }

        Api()
    }, [user])

    useEffect(() => {
        const API = async () => {
            if (user !== null) {
                const { data } = await axios.post("/toggle-response", {
                    id: user.id
                })
                setEmail(data.data[0].email_response)
                setSms(data.data[0].sms_response)
            }
        }
        API()
    }, [user])

    // const handelChange = async (e) => {
    //     if (user !== null) {
    //         if (e === "Email") {
    //             const { data } = await axios.post("/Email-notification", {
    //                 id: user.id
    //             })
    //             if (data.success) {
    //                 const datas = await axios.post("/toggle-response", {
    //                     id: user.id
    //                 })
    //                 setEmail(datas.data.data[0].email_response)
    //                 setSms(datas.data.data[0].sms_response)
    //                 if (email) {
    //                     return toast.error(`Email ${data.data}`)
    //                 } else {
    //                     return toast.success(`Email ${data.data}`)
    //                 }
    //             }
    //         } else {
    //             const { data } = await axios.post("/SMS-notification", {
    //                 id: user.id
    //             })
    //             if (data.success) {
    //                 const datas = await axios.post("/toggle-response", {
    //                     id: user.id
    //                 })
    //                 setEmail(datas.data.data[0].email_response)
    //                 setSms(datas.data.data[0].sms_response)
    //                 if (sms) {
    //                     return toast.error(`SMS ${data.data}`)
    //                 } else {
    //                     return toast.success(`SMS ${data.data}`)
    //                 }
    //             }
    //         }
    //     }
    // }

    const handleSelect = async (id, name, event_owner) => {
        setselectedEvents((prev) => [
            ...prev,
            {
                id,
                name,
                event_owner
            }
        ])
    }

    const removeSelected = async (id) => {
        const filter = selectedEvents.filter((i) => i.id !== id)
        setselectedEvents(filter)

        return
    }

    useEffect(() => {
        const result =
            search.length === 0
                ? eventList
                : eventList.filter((item) => item.eventName.toLowerCase().includes(search.toLowerCase()))
        setfilterValue(result)
    }, [search, eventList])

    useEffect(() => {
        const Events = async () => {
            if (select) {
                if (user) {
                    const result = eventList.filter((item) => {
                        return !selectedEvents.some((i) => i.id === item.id)
                    })

                    setfilterValue(result)
                    const { data } = await axios.post("/loop-on", {
                        events: selectedEvents,
                        event_owner: user.id
                    })
                    if (data.success) {
                        setstatusChange(!statusChange)
                        setselect(false)
                    }
                }
            }
        }
        Events()
    }, [select])

    useEffect(() => {
        const API = async () => {
            if (statusChange) {
                if (user) {
                    const datas = await axios.post("/user/event", {
                        id: user.id
                    })
                    const getloopStatus = datas.data.data.filter((i) => i.continue_loop === true)
                    setselectedEvents([])
                    await getloopStatus.map((i) =>
                        setselectedEvents((pre) => [
                            ...pre,
                            {
                                id: i.id,
                                name: i.eventName,
                                event_owner: i.event_owner
                            }
                        ])
                    )

                    return
                }
            }
        }

        API()
    }, [statusChange])

    return (
        <div className="general_main">
            <div className="general_box">
                {/* <div className="general_tag">
                    <div className="general_tag_head flex items-center justify-between">
                        <div className="general_title">
                            <p>Email Notification</p>
                            <span>Every feedback send E-mail notification.</span>
                        </div>

                        <Switch
                            checked={email}
                            className="react-switch"
                            onColor="#4F46E5"
                            onHandleColor="#fff"
                            handleDiameter={20}
                            onChange={() => handelChange("Email")}
                            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                        />
                    </div>
                </div> */}
                <div className="general_tag">
                    <div className="general_tag_head flex items-center justify-between">
                        <div className="general_title">
                            <p className=" flex gap-1">
                                SMS Notification{" "}
                                <Tooltip tooltipTxt="The SMS notification feature is operational after receiving approvals from TRAI. Please feel free to reach out to Feedback Sync for assistance with integration.">
                                    <BiInfoCircle
                                        size={20}
                                        className=" text-blue"
                                    />
                                </Tooltip>
                            </p>
                            <span>Every feedback send SMS notification.</span>
                        </div>
                        <p>Coming Soon...</p>
                        {/* <Switch
                            checked={sms}
                            onColor="#4F46E5"
                            onHandleColor="#fff"
                            handleDiameter={20}
                            onChange={() => handelChange("SMS")}
                            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                        /> */}
                    </div>
                </div>
                <div className="general_tag">
                    <div className="general_tag_head flex items-center justify-between">
                        <div className="general_title">
                            <p className=" flex gap-1">
                                Continue Loop
                                <Tooltip tooltipTxt="Choosing a Survey from the dropdown lets users provide unrestricted feedback for each user for that specific Survey.">
                                    <BiInfoCircle
                                        size={20}
                                        className=" text-blue"
                                    />
                                </Tooltip>
                            </p>
                        </div>

                        {/* <Switch
              checked={selectedEvents.length > 0 ? true : false}
              className="react-switch"
              onColor="#4F46E5"
              onHandleColor="#fff"
              height={18}
              width={45}
              boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
            /> */}
                    </div>
                    <div className="loop_dropdown_container relative">
                        <div className="loop_selected">
                            <div className="loop_selectedItem">
                                {selectedEvents.map((i) => (
                                    <div className="event_selected">
                                        <p>{i.name}</p>
                                        <AiOutlineClose
                                            onClick={() => {
                                                removeSelected(i.id)
                                                setselect(true)
                                            }}
                                            size={16}
                                            className=" hover:text-red cursor-pointer"
                                        />
                                    </div>
                                ))}
                            </div>
                            <BiChevronDown
                                size={26}
                                className=""
                                onClick={() => setDropDownList(!dropDownList)}
                            />
                            {/* <div className="loop_selectedItem_icons"> */}
                            {/* <TbMinusVertical
                                    size={26}
                                    className=""
                                /> */}

                            {/* </div> */}
                        </div>
                        {dropDownList && (
                            <div className="loop_dropdownlist absolute top-[105%] bg-white">
                                <input
                                    placeholder="Search.."
                                    onChange={(e) => setsearch(e.target.value)}
                                    className=" w-[100%] py-2 border-2 border-gray outline-none mb-2 rounded-md px-4 "
                                />
                                <div className="loop_list_item">
                                    {filterValue.map((i) => (
                                        <p
                                            onClick={() => {
                                                handleSelect(i.id, i.eventName, i.event_owner)
                                                setDropDownList(!dropDownList)
                                                setselect(true)
                                            }}
                                        >
                                            {i.eventName}
                                        </p>
                                    ))}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GeneralSettings
