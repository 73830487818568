import React from "react"
// import { useSelector } from "react-redux";
import Box from "./Box"
import Chart from "./Chart"
import TopEvent from "./TopEvent"
import { useSelector } from "react-redux"

// ** import components
import BlueBannerStrip from "./BlueBannerStrip"
import FadeInTransition from "../FadeInTransition"
const DashboardComponents = () => {
    // const { bgColor } = useSelector((state) => state.bg);
    // const { mood } = useSelector((state) => state.mood);
    const { user } = useSelector((state) => state.userAuth)

    return (
        <div className="flex-1 overflow-x-hidden">
            <main>
                <div className="py-6">
                    {/* <FadeInTransition> */}
                        <div className=" mx-auto max-w-[105rem] sectionPadding">
                            {user?.free_trail && <BlueBannerStrip />}
                            <Box />
                            <div className="grid grid-cols-4 items-center gap-10 w-full">

                                <TopEvent />
                                <div className=" col-span-3">
                                    <Chart />
                                </div>
                            </div>
                        </div>
                    {/* </FadeInTransition> */}
                </div>
            </main>
        </div>
    )
}

export default DashboardComponents
