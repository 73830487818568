/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState } from "react"
import { RiArrowDropDownLine } from "react-icons/ri"

const dropDown = () => {
    const [show, setShow] = useState(false)
    return (
        <div className="h-full w-full mb-20 flex flex-col justify-center items-center  bg-no-repeat bg-cover bg-center bg-fixed">
            <button
                className=" w-96 flex items-center justify-between text-gray bg-gray hover:bg-gray  font-medium rounded-lg text-sm px-4 py-2.5 text-center outline-none border-none"
                type="button"
                onClick={() => setShow(!show)}
            >
                Dropdown divider <RiArrowDropDownLine size={26} />
            </button>

            <div
                className={
                    show
                        ? "z-10 w-96 bg-white rounded divide-y divide-gray shadow dark:bg-gray dark:divide-gray my-3"
                        : "hidden"
                }
            >
                <h3>hello</h3>
            </div>
        </div>
    )
}

export default dropDown
