/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect } from "react"
import { AiFillEyeInvisible, AiFillEye, AiFillLinkedin } from "react-icons/ai"
import { Link, useNavigate } from "react-router-dom"
import axios from "../../../axios"
import toast, { Toaster } from "react-hot-toast"
import logo from "../../../assets/logo1.svg"
import CustomDotLoader from './../../../components/CustomDotLoader/index'
import { Turnstile } from "@marsidev/react-turnstile"
import { FcGoogle } from "react-icons/fc"
import config from "../../../constants"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"

// ** import components
import Input from "../../../components/Input/Input"
import FadeInTransition from "./../../../components/FadeInTransition/index"

import { magicLinkSchema, signInSchema } from "../../../utils/validation"

const index = () => {
    const navigate = useNavigate()
    const [value, setValue] = useState(0)
    const [loader, setLoader] = useState(false)
    const [visible, setVisible] = useState(false)
    const [loader1, setLoader1] = useState(false)
    const [loader2, setLoader2] = useState(false)
    const [loader3, setLoader3] = useState(false)
    const [captcha, setCaptcha] = useState(false)
    const [errStatus, setErrStatus] = useState("")
    const [isChecked, setIsChecked] = useState(false)

    const {
        register,
        handleSubmit,
        watch,
        reset,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(value === 0 ? signInSchema : magicLinkSchema)
    })

    if (isChecked && value === 0)
        localStorage.setItem("remember", JSON.stringify({ Email: watch().Email, Password: watch().Password }))
    const Remember = JSON.parse(localStorage.getItem("remember"))

    const onChange = (value) => {
        localStorage.setItem("Captcha value:", value)
        setCaptcha(!captcha)
    }

    useEffect(() => {
        if (Remember !== null) {
            if (Remember?.Email?.length > 0) {
                reset({
                    Email: Remember?.Email?.length === 0 ? "" : Remember?.Email,
                    Password: Remember?.Password?.length === 0 ? "" : Remember?.Password
                })
            }
        }
    }, [])

    useEffect(() => {
        setErrStatus("")
    }, [value])

    const sendMagicLink = async (res) => {
        setLoader3(true)
        const { data } = await axios.post("/magiclink", {
            email: res.Email
        })

        if (data.success) {
            toast.success(`${data.message}`)
            reset({
                email: ""
            })
            setLoader3(false)

            return
        } else {
            setErrStatus(data.message)
            setLoader3(false)
            return
        }
    }

    const onSubmit = async (res) => {
        setLoader(true)
        localStorage.setItem("auth", "local")
        localStorage.setItem("Signin Method", "local")

        localStorage.removeItem("Token")
        const { data } = await axios.post("/login", {
            email: res.Email,
            password: res.Password,
            auth: "local"
        })

        if (data.success) {
            if (data?.token) localStorage.setItem("Token", data.token)
            setLoader(false)
            // localStorage.setItem("SIGNIN", true);
            navigate("/")
            setIsChecked(false)
        } else {
            setLoader(false)
            setErrStatus(data.message)
        }
    }

    // useEffect(() => {
    //   window.onloadTurnstileCallback = function () {
    //     turnstile.render('#myWidget', {
    //       sitekey: '0x4AAAAAAACJ6BZpsJtzoQu-',
    //       callback: function (token) {
    //         if (!token) {
    //           alert('Done');
    //         }
    //       },
    //     });
    //   };
    // }, []);

    const google = async () => {
        setLoader1(true)
        localStorage.removeItem("Token")
        // localStorage.setItem("SIGNIN", true);

        localStorage.setItem("Signin Method", "google")
        window.open(`${config.SERVER_URL}/google`, "_self")
    }

    const linkedin = async () => {
        setLoader2(true)
        localStorage.removeItem("Token")
        // localStorage.setItem("SIGNIN", true);

        localStorage.setItem("Signin Method", "linkedIn")
        window.open(`${config.SERVER_URL}/linkedIN`, "_self")
    }

    return (
        <div className="max-h-[100%] overflow-hidden py-20">
            <div className="fixed bottom-0 left-0 w-full border-b-[10px] border-[#4F46E5] z-50"></div>
            <div
                style={{ boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.15)" }}
                className="py-5 w-full !z-50 absolute top-0"
            >
                <a
                    href="https://www.feedbacksync.com"
                    alt="feedback"
                >
                    <img
                        className="w-[175px] block mx-auto"
                        src={logo}
                        alt=""
                    />
                </a>
            </div>

            <section className=" !mt-[80px] w-full">
                <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
                    <FadeInTransition>
                        <div className="relative max-w-md mx-auto lg:max-w-lg">
                            <div className="absolute -inset-2">
                                {/* <div
                className="w-full h-full mx-auto rounded-3xl opacity-30 blur-lg filter"
                style={{"background: linear-gradient(90deg, #44ff9a -0.55%, #44b0ff 22.86%, #8b44ff 48.36%, #ff6644 73.33%, #ebff70 99.34%"}}
              ></div> */}
                            </div>

                            <>
                                {value === 0 ? (
                                    <div
                                        style={{
                                            boxShadow: "0px 0px 61.3082px rgba(0, 0, 0, 0.15)"
                                        }}
                                        className="bg-[white] relative overflow-hidden rounded-[16px]"
                                    >
                                        <div className="px-4 py-6 sm:px-8">
                                            <h1
                                                className="font-bold font-pj text-center text-[#18181B] text-[21px] hover:cursor-pointer"
                                                // onClick={() => setMagic(true)}
                                            >
                                                Login to your account
                                            </h1>

                                            <form
                                                onSubmit={handleSubmit(onSubmit)}
                                                action=""
                                                className="space-y-5 mt-[30px]"
                                            >
                                                <Input
                                                    name="Email"
                                                    type="email"
                                                    placeholder="Enter email address"
                                                    register={register}
                                                    error={errors?.Email?.message}
                                                />
                                                <div className="mt-1 relative">
                                                    <Input
                                                        name="Password"
                                                        type={visible === false ? "password" : "text"}
                                                        placeholder="Enter password"
                                                        register={register}
                                                        error={errors?.Password?.message}
                                                    />

                                                    <p className=" absolute z-50 right-3 top-11">
                                                        {visible === false ? (
                                                            <>
                                                                <AiFillEyeInvisible
                                                                    size={26}
                                                                    color="gray"
                                                                    className="cursor-pointer"
                                                                    onClick={() => setVisible(true)}
                                                                />
                                                            </>
                                                        ) : (
                                                            <>
                                                                <AiFillEye
                                                                    size={26}
                                                                    color="gray"
                                                                    className="cursor-pointer"
                                                                    onClick={() => setVisible(false)}
                                                                />
                                                            </>
                                                        )}
                                                    </p>
                                                </div>

                                                <div className="relative items-center mt-4 flex justify-between">
                                                    <div className="flex">
                                                        <div className="flex items-center h-5">
                                                            <input
                                                                type="checkbox"
                                                                name="terms"
                                                                id="terms"
                                                                value={isChecked}
                                                                onChange={(e) => {
                                                                    setIsChecked(e.target.checked)
                                                                }}
                                                                className="w-5 h-5 text-gray-900 border-gray-300 rounded focus:ring-gray-900"
                                                            />
                                                        </div>

                                                        <div className="ml-3 text-base">
                                                            <label
                                                                for="terms"
                                                                className="text-[#18181B] font-[16px] font-pj"
                                                            >
                                                                Remember me
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <Link
                                                        to="/forgot-pass"
                                                        className="text-base font-medium text-[#4F46E5] rounded font-pj text-[16px] hover:underline focus:outline-none focus:ring-1 focus:ring-gray-900 focus:ring-offset-2"
                                                    >
                                                        {" "}
                                                        Forgot Password?{" "}
                                                    </Link>
                                                </div>

                                                {value == 0 && errStatus !== "" && (
                                                    <p className="text-red">{errStatus}</p>
                                                )}

                                                {/* <div className=" mt-3 rounded-lg">
                        <Turnstile
                          siteKey="0x4AAAAAAACJr9D3kh0_sCK8"
                          onSuccess={onChange}
                          onChange={onChange}
                          options={{
                            size: "normal",
                            theme: "auto",
                          }}
                        />
                      </div> */}

                                                {!loader ? (
                                                    <button
                                                        // disabled={!captcha}
                                                        type="submit"
                                                        className="flex items-center justify-center w-full px-8 h-[50px] max-h-[50px] mt-5 text-base font-bold text-white transition-all duration-200 bg-[#4F46E5] border border-transparent hover:border-[#4F46E5] hover:!bg-white hover:text-[#4F46E5] rounded-xl focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray  hover:bg-grayDark"
                                                    >
                                                        Log in
                                                    </button>
                                                ) : (
                                                    <button
                                                        type="submit"
                                                        className={`flex items-center justify-center overflow-hidden h-[50px] max-h-[50px] w-full px-8 mt-5 text-base font-bold text-white transition-all duration-200 bg-[#4F46E5] border border-transparent hover:border-[#4F46E5] hover:!bg-[#4e46e5e8] hover:text-[white] rounded-xl focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray`}
                                                    >
                                                        <CustomDotLoader
                                                            dotSize={70}
                                                            isWhite
                                                            className="mx-auto text-center"
                                                        />
                                                    </button>
                                                )}
                                            </form>

                                            <h1
                                                className="font-bold font-pj text-center text-blue text-[18px] my-6 hover:cursor-pointer"
                                                onClick={() => setValue(1)}
                                            >
                                                Login via Magic Link
                                            </h1>

                                            <div className=" relative">
                                                <hr className=" absolute w-[30%] top-[12px] left-0 text-lightColor" />
                                                <p className="text-center font-medium text-base">Or continue with</p>
                                                <hr className=" absolute w-[30%] top-[12px] right-0 text-lightColor" />
                                            </div>

                                            {/* OR */}

                                            {!loader1 ? (
                                                <button
                                                    // disabled={!captcha}
                                                    type="submit"
                                                    className="flex items-center justify-center w-full px-8 h-[50px] max-h-[50px] mt-5 text-base font-bold text-black transition-all duration-200 border border-lightColor  rounded-xl "
                                                    onClick={google}
                                                >
                                                    <FcGoogle
                                                        size={25}
                                                        className="cursor-pointer mx-3"
                                                    />
                                                    <span>Sign in with Google</span>
                                                </button>
                                            ) : (
                                                <button
                                                    type="submit"
                                                    className={`flex items-center justify-center overflow-hidden h-[50px] max-h-[50px] w-full px-8 mt-5 text-base font-bold text-black transition-all duration-200  border border-lightColor rounded-xl`}
                                                >
                                                     <CustomDotLoader
                                                        dotSize={70}
                                                        
                                                    />
                                                </button>
                                            )}

                                            {/* OR */}

                                            {!loader2 ? (
                                                <button
                                                    // disabled={!captcha}
                                                    type="submit"
                                                    className="flex items-center justify-center w-full px-8 h-[50px] max-h-[50px] mt-5 text-base font-bold text-black transition-all duration-200 border border-lightColor  rounded-xl "
                                                    onClick={linkedin}
                                                >
                                                    <AiFillLinkedin
                                                        size={30}
                                                        className="cursor-pointer mx-3 rounded-xl"
                                                        color="#0a66c2"
                                                    />
                                                    <span>Sign in with LinkedIn</span>
                                                </button>
                                            ) : (
                                                <button
                                                    type="submit"
                                                    className={`flex items-center justify-center overflow-hidden h-[50px] max-h-[50px] w-full px-8 mt-5 text-base font-bold text-black transition-all duration-200  border border-lightColor rounded-xl`}
                                                >
                                                   <CustomDotLoader
                                                        dotSize={70}
                                                        
                                                    />
                                                </button>
                                            )}

                                            <p className="text-[#18181B] text-[15px] text-center pt-6">
                                                Don’t have an account?
                                                <Link
                                                    to="/signUp"
                                                    className="text-base font-medium text-[#4F46E5] rounded font-pj text-[15px] hover:underline focus:outline-none focus:ring-1 focus:ring-gray-900 focus:ring-offset-2 ml-2"
                                                >
                                                    Sign Up
                                                </Link>
                                            </p>
                                            <a
                                                className="flex items-center justify-center mt-3 w-full text-[#4F46E5] hover:underline"
                                                href="https://www.feedbacksync.com"
                                                alt="feedback"
                                            >
                                                Go back
                                            </a>
                                        </div>
                                    </div>
                                ) : (
                                    <div
                                        style={{
                                            boxShadow: "0px 0px 61.3082px rgba(0, 0, 0, 0.15)"
                                        }}
                                        className="bg-[white] relative overflow-hidden rounded-[16px] px-10 py-7"
                                    >
                                        <h1 className="font-bold font-pj text-center text-[#18181B] text-[21px] hover:cursor-pointer mb-4">
                                            Login via Magic Link
                                        </h1>

                                        <div>
                                            <form
                                                onSubmit={handleSubmit(sendMagicLink)}
                                                action=""
                                            >
                                                <Input
                                                    name="Email"
                                                    type="email"
                                                    placeholder="Enter email address"
                                                    register={register}
                                                    error={errors?.Email?.message}
                                                />
                                                {value == 1 && errStatus !== "" && (
                                                    <p className="text-red">{errStatus}</p>
                                                )}

                                                <div className=" flex justify-end mt-6">
                                                    {!loader3 ? (
                                                        <button
                                                            type="submit"
                                                            className="flex items-center justify-center w-full px-8 h-[50px] max-h-[50px] text-base font-bold text-white transition-all duration-200 bg-[#4F46E5] border border-transparent hover:border-[#4F46E5] hover:!bg-white hover:text-[#4F46E5] rounded-xl focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray  hover:bg-grayDark"
                                                        >
                                                            Login via Magic Link
                                                        </button>
                                                    ) : (
                                                        <button
                                                            type="submit"
                                                            className={`flex items-center justify-center overflow-hidden h-[50px] max-h-[50px] w-full px-8 text-base font-bold text-white transition-all duration-200 bg-[#4F46E5] border border-transparent hover:border-[#4F46E5] hover:!bg-[#4e46e5e8] hover:text-[white] rounded-xl focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray`}
                                                        >
                                                          <CustomDotLoader
                                                                dotSize={70}
                                                                isWhite
                                                            />
                                                        </button>
                                                    )}
                                                </div>
                                            </form>

                                            <p className="text-[#18181B] text-[15px] text-center pt-6 mt-6">
                                                Don’t have an account?
                                                <Link
                                                    to="/signUp"
                                                    className="text-base font-medium text-[#4F46E5] rounded font-pj text-[15px] hover:underline focus:outline-none focus:ring-1 focus:ring-gray-900 focus:ring-offset-2 ml-2"
                                                >
                                                    Sign Up
                                                </Link>
                                            </p>
                                            <p
                                                className="flex items-center justify-center mt-3 cursor-pointer w-full text-[#4F46E5] hover:underline"
                                                onClick={() => setValue(0)}
                                            >
                                                Go back
                                            </p>
                                        </div>
                                    </div>
                                )}
                            </>
                        </div>
                    </FadeInTransition>
                </div>
            </section>

            <Toaster
                position="top-right"
                reverseOrder={false}
            />
        </div>
    )
}

export default index
