import React, { useState } from "react"

import bgImg from "../../assets/images/freetrial.jpg"
import PlansModal from "./PlansModal"

const TrialModal = () => {
    const [plansModalOpen, setPlansModalOpen] = useState(false)

    return (
        <div className=" fixed top-0 left-0 w-screen h-screen flex justify-center items-center z-[70]  bg-[#00000042]">
            {plansModalOpen ? (
                <PlansModal setPlansModalOpen={setPlansModalOpen} />
            ) : (
                <div className="bg-[#fafafa] relative flex flex-col w-[60%] mx-auto rounded-lg overflow-hidden">
                    {/* <button
                    type="button"
                    className="absolute top-2 right-2 w-8 h-8 text-white bg-[#4F46E5] rounded-full text-sm  ml-auto inline-flex items-center justify-center  transition-all duration-300 z-50"
                    data-modal-toggle="popup-modal"
                    onClick={() => {
                        setShowModal(false)
                    }}
                >
                    <X size={25} />
                </button> */}
                    <div className="w-full h-80">
                        <img
                            src={bgImg}
                            alt="15 days free trial"
                            className=" w-full h-full object-cover"
                        />
                    </div>
                    <div className="p-8 text-center">
                        <h1 className=" text-xl font-bold">Your 15-days trial has ended!</h1>
                        <p className=" text-base font-normal mt-4 max-w-2xl mx-auto">
                            You've likely grasped its value for your feedback processes. Join our platform for business
                            growth by subscribing now!
                        </p>
                        <button
                            onClick={() => {
                                setPlansModalOpen(true)
                            }}
                            className=" primaryButton mt-8"
                        >
                            Compare plans
                        </button>
                    </div>
                </div>
            )}
        </div>
    )
}

export default TrialModal
