/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useLayoutEffect, useRef } from "react";
import QRCode from "qrcode.react";
import { useSelector } from "react-redux";
import { BiArrowBack } from "react-icons/bi";

import { driver } from "driver.js";
import "driver.js/dist/driver.css";

const index = ({
  value,
  popUp,
  name,
  click,
  setdownload,
  innerContentOnly = false,
}) => {
  const { mood } = useSelector((state) => state.mood);
  const { user } = useSelector((state) => state.userAuth);
  const qr_code_download = localStorage.getItem("qr_code_download_png");

  useLayoutEffect(() => {
    const API = async () => {
      if (user?.initialLogin && qr_code_download === null) {
        const driverOptions = {
          showProgress: true,
          showButtons: ["next", "previous"],
          steps: [
            {
              element: "#qr_code_download_svg",
              popover: {
                title: "QR",
                description:
                  "This QR code can be downloaded and used to create stands or posters.",
                side: "right",
                align: "start",
              },
            },

            {
              element: "#qr_code_download_png",
              popover: {
                title: "QR",
                description:
                  "This QR code can be downloaded and used to create stands or posters.",
                side: "right",
                align: "start",
              },
            },
          ],
        };
        const driverObj = driver(driverOptions);

        driverObj?.drive();
        localStorage.setItem("qr_code_download_png", false);
      }
    };
    API();
  }, []);

  const downloadQrCode = () => {
    if (value !== null) {
      const canvas = document.getElementById("qr-gen");
      const svgUrl =
        "data:image/svg+xml;base64," +
        btoa(new XMLSerializer().serializeToString(canvas));
      let downloadlink = document.createElement("a");
      downloadlink.href = svgUrl;
      downloadlink.download = `Qr-code-${name}.svg`;
      document.body.appendChild(downloadlink);
      downloadlink.click();
      document.body.removeChild(downloadlink);

      if (!innerContentOnly) {
        popUp(false);
      }
    }
  };

  const downloadQrCode1 = () => {
    if (value !== null) {
      const canvas = document.getElementById("qr-gen1");
      const pngUrl = canvas
        .toDataURL("image/png")
        .replace("image/png", "image/octet-stream");
      let downloadlink = document.createElement("a");
      downloadlink.href = pngUrl;
      downloadlink.download = `Qr-code-${name}.png`;
      document.body.appendChild(downloadlink);
      downloadlink.click();
      document.body.removeChild(downloadlink);
    }
  };

  useEffect(() => {
    if (click === "download") {
      const canvas = document.getElementById("qr-gen1");
      const pngUrl = canvas
        .toDataURL("image/png")
        .replace("image/png", "image/octet-stream");
      let downloadlink = document.createElement("a");
      downloadlink.href = pngUrl;
      downloadlink.download = `Qr-code-${name}.png`;
      document.body.appendChild(downloadlink);
      downloadlink.click();
      document.body.removeChild(downloadlink);
      setdownload(false);
    }
  }, [click]);

  return (
      <div className={`${innerContentOnly ? "w-full" : "w-[400px]"}`}>
          {innerContentOnly ? (
              <div className="flex items-center gap-4 w-full mb-8">
                  <button onClick={() => popUp(false)}>
                      <BiArrowBack
                          size={30}
                          className="text-blue cursor-pointer"
                      />
                  </button>
                  <p className="font-bold text-xl text-center -ml-8 flex-1 pointer-events-none">{name}</p>
              </div>
          ) : null}

          <QRCode
              value={value}
              size={300}
              style={{
                  borderRadius: "10px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%"
              }}
              id="qr-gen"
              renderAs="svg"
              includeMargin
          />

          <QRCode
              value={value}
              size={300}
              style={{
                  borderRadius: "10px",
                  display: "none"
              }}
              id="qr-gen1"
              includeMargin
          />

          <div className={`flex items-center ${innerContentOnly ? "mt-14" : "mt-6"} gap-4`}>
              <button
                  id="qr_code_download_svg"
                  onClick={downloadQrCode}
                  type="button"
                  className=" secondaryButton flex-1  py-3"
              >
                  Download SVG
              </button>

              <button
                  id="qr_code_download_png"
                  data-tut="third-step"
                  onClick={downloadQrCode1}
                  type="button"
                  className=" primaryButton flex-1  py-3"
              >
                  Download PNG
              </button>
          </div>
      </div>
  )
};

export default index;
