/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io"

const Welcome = ({ inputQuestion, id, questionType, answer, nextQuz, next, eventId,subHeading }) => {
    // const [rating, setRating] = useState(0);
    const { questionValues } = useSelector((state) => state.questions)
    const [nextValues, setNextValues] = useState([])
    let nextId = Number(id) + 1
    const { mood } = useSelector((state) => state.mood)
    const { questions } = useSelector((state) => state)
    const logoInfo = questions.logoDetails
    const buttonColors = questions.buttonColor
    const welcomeScreenTextColor = questions.welcomeScreenTextColor
    const [preValue, setPreValue] = useState([])
    let prev = Number(id) - 1
    useEffect(() => {
        const filter = questionValues.filter((e) => Number(e.id) === Number(prev))
        setPreValue(filter)
    }, [questionType, id])

    // const { welcomeTextColor } = useSelector((state) => state.globalDefine);
    // const { buttonColor } = useSelector((state) => state.globalDefine);
    // const { companyLogo } = useSelector((state) => state.globalDefine);

    useEffect(() => {
        const filter = questionValues.filter((e) => Number(e.id) === Number(nextId))
        setNextValues(filter)
    }, [questionType, id])

    return (
        <div className="!z-30 h-[100%] w-full flex flex-col items-center justify-center mt-[30px] overflow-y-auto overflow-x-hidden">
            {logoInfo.length !== 0 && (
                <img
                    className="mb-[30px] max-w-[125px]"
                    src={logoInfo[0]?.logoLocation}
                    alt=""
                />
            )}
            {/* {logoInfo.length === 0 && companyLogo !== null && (
        <img className="mb-[30px] max-w-[125px]" src={companyLogo} alt="" />
      )} */}

            <h3
                style={{
                    color: `${welcomeScreenTextColor === null ? "#000" : welcomeScreenTextColor}`
                }}
                className={`w-[80%] max-h-full z-20 text-center mx-10 sm: ms:xs: font-inter  ${
                    mood === "light" ? "text-[#616060]" : "text-white"
                } break-words`}
            >
                <span className="w-full text-[18px] font-[600]">{inputQuestion}</span>
            </h3>

            <h6
                style={{
                    color: `${welcomeScreenTextColor === null ? "#000" : welcomeScreenTextColor}`
                }}
                className={`w-[80%] max-h-full z-20 text-center mx-10 font-inter  ${
                    mood === "light" ? "text-[#616060]" : "text-white"
                } break-words`}
            >
                <span className="w-full text-[16px] md:text-base font-normal">{subHeading}</span>
            </h6>
            <div className="flex items-center gap-x-3">
                {preValue?.length !== 0 && (
                    <button
                        type="button"
                        style={{
                            background: `${
                                buttonColors === null || buttonColors === undefined
                                    ? "#4F46E5"
                                    : //  buttonColor
                                      buttonColors
                            }`
                        }}
                        className=" rounded-full p-3 my-3 transition-all duration-200 text-lg font-semibold text-center block "
                        onClick={() => {
                            nextQuz(preValue[0].type)
                            next(prev)
                        }}
                    >
                        <IoIosArrowBack
                            size={40}
                            color="white"
                        />
                    </button>
                )}
                {nextValues.length !== 0 && (
                    <button
                        style={{
                            background: `${
                                buttonColors === null || buttonColors === undefined
                                    ? "#4F46E5"
                                    : //  buttonColor
                                      buttonColors
                            }`
                        }}
                        className=" rounded-full p-3 my-3 transition-all duration-200 text-lg font-semibold text-center block "
                        onClick={() => {
                            nextQuz(nextValues[0].type)
                            next(nextId)
                        }}
                    >
                        <IoIosArrowForward
                            size={40}
                            color="white"
                        />
                    </button>
                )}
            </div>
        </div>
    )
}

export default Welcome
