import React from "react";

const Input = ({ name, type, placeholder, register, error, id, disable }) => {
  return (
    <div>
      <label
        htmlFor={name}
        className="text-[14px] text-[#18181B] font-semibold text-gray-900 font-pj"
      >
        {name}
      </label>
      <div className="mt-1">
        <input
          type={type}
          name="name"
          placeholder={placeholder}
          disabled={disable !== undefined ? true : false}
          className="block w-full px-4 py-4 rounded-[10px] focus:border-blue focus:outline-none border border-gray font-medium placeholder:text-gray text-black "
          {...register(
            id === undefined
              ? name === "Confirm Password"
                ? placeholder
                : name
              : id
          )}
        />
        {error && (
          <p className="text-red text-xs mt-1">
            {" "}
            <span className=" text-base">*</span> {error}
          </p>
        )}
      </div>
    </div>
  );
};

export default Input;
