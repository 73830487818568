/* eslint-disable react-hooks/exhaustive-deps */
import axios from "../../axios";
import React, { useEffect } from "react";
import { useState } from "react";
import { toast, Toaster } from "react-hot-toast";
import { AiOutlineClose, AiOutlineDesktop } from "react-icons/ai";
import { BiChevronDown, BiMobileAlt } from "react-icons/bi";
import { useSelector } from "react-redux";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import EventName from "../../components/popUp/EventName";
import { useDispatch } from "react-redux";
import {
  bgImageDetails,
  logoDetails,
  questionEmpty,
} from "../../redux/slices/questions";
import { setEventValue } from "../../redux/slices/eventName";

const Header = ({ select, setViewStatus, viewStatus }) => {
  const [selected, setSelected] = useState("Question");
  const { mood } = useSelector((state) => state.mood);
  const { eventName } = useSelector((state) => state.eventName);
  const [eventStatus, setEventStatus] = useState(false);
  const [renameValue, setEventName] = useState("");
  const [renameStatus, setRenameStatus] = useState(false);
  const [searchParams] = useSearchParams();
  const Id = searchParams.get("id");
  const [closePop, setClosePop] = useState(false);
  const [clearPop, setClearPop] = useState(false);
  const [ID, setId] = useState(Id);
  const [popupStatus, setPopupStatus] = useState(false);
  const [link, setOpenLink] = useState("");
  const getOldquestion = JSON.parse(localStorage.getItem("Oldquestion"));
  const { questionValues } = useSelector((state) => state.questions);
  const getPublished = localStorage.getItem("Published");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const areArraysMatching = () => {
    if (questionValues.length !== getOldquestion.length) {
      setClosePop(true);
      return;
    }

    for (let i = 0; i < questionValues.length; i++) {
      const obj1 = questionValues[i];
      const obj2 = getOldquestion[i];

      if (
        obj1.id !== obj2.id ||
        obj1.question !== obj2.question ||
        obj1.type !== obj2.type || 
        obj1.subHeading !== obj2.subHeading
      ) {
        setClosePop(true);
        return;
      }

      if (obj1.type === "radioButton" || obj1.type === "starRating") {
        if (obj1.choice.length !== obj2.answer.length) {
          setClosePop(true);
          return;
        }

        for (let j = 0; j < obj1.choice.length; j++) {
          if (
            obj1.choice[j].id !== obj2.answer[j].id ||
            obj1.choice[j].value !== obj2.answer[j].value
          ) {
            setClosePop(true);
            return;
          }
        }
      }
    }

    dispatch(questionEmpty());
    dispatch(setEventValue("New Event"));
    dispatch(logoDetails([]));
    dispatch(bgImageDetails([]));
    localStorage.removeItem("reRender");
    localStorage.removeItem("brandRender");
    navigate("/events");
  };

  useEffect(() => {
    const Api = async () => {
      if (ID !== null) {
        const { data } = await axios.post("/getParticularEvent", {
          shortID: Id,
        });
        setOpenLink(data?.[0]?.shortID);
      }
    };

    Api();
  }, [ID]);

  return (
    <div className={`${mood === "light" ? `bg-white` : "bg-[#161A25]"}`}>
      <EventName
        show={renameStatus}
        status={"rename"}
        hide={setRenameStatus}
        setInput={setEventName}
        inputValue={renameValue}
      />
      <div
        className={`flex flex-wrap w-[100%]  max-w-[105rem] mx-auto ${
          mood === "light" ? `!bg-white` : "!bg-[#161A25]"
        }`}
      >
        <div className="flex lg:w-[40%] px-8 items-center justify-between gap-x-2 sm:ml-0 md:ml-0 md:flex-wrap py-2 lg:border-r border-[#E4E4E7]">
          <div className="flex gap-4">
            <div className="flex items-center cursor-pointer !my-0 !py-0">
              <h5
                className={`${
                  selected === "Question"
                    ? `text-lg text-center font-normal bg-bgLightBlue text-[#4F46E5] `
                    : `text-lg text-black blueHover `
                } !my-0 font-inter py-1 px-4 rounded-md`}
                onClick={() => {
                  setSelected("Question");
                  select("Question");
                }}
              >
                Question
              </h5>
            </div>
            <div className=" w-[2px] min-h-full bg-gray"></div>
            <div className="flex items-center cursor-pointer">
              <p
                className={`${
                  selected === "Themes"
                    ? `text-lg text-center font-normal bg-bgLightBlue text-[#4F46E5] `
                    : `text-lg text-black blueHover `
                } !my-0 font-inter py-1 px-4 rounded-md`}
                onClick={() => {
                  setSelected("Themes");
                  select("Themes");
                }}
              >
                Branding
              </p>
            </div>
          </div>

          <div>
            <div className="flex items-center mx-2">
              {ID !== null && (
                <Link
                  to={`/${link}?preview=preview`}
                  // onClick={() => {
                  //   setPopUp(true);
                  // }}

                  className={`${
                    mood === "light"
                      ? "border-[#6B53FD] bg-[#6B53FD] text-white hover:bg-white hover:text-[#6B53FD]"
                      : "bg-[#303544] border-[#303544] text-white hover:bg-[#232734]"
                  }  font-inter flex w-[150px]  border transition-all duration-300  justify-center items-center py-2 rounded-md mr-2`}
                >
                  Preview
                </Link>
              )}
              {/* <AiOutlineClose
                                size={22}
                                className={`text-red-600 ml-1 font-extrabold text-lg cursor-pointer ${
                                    mood === "light" ? "text-red" : "text-white"
                                }`}
                                onClick={() => setClosePop(true)}
                            /> */}
            </div>
          </div>
        </div>
        <div className=" lg:w-[60%] px-8 flex flex-1 justify-between items-center py-2 pl-6 ">
          <div className="hidden lg:block">
            <BiMobileAlt
              size={26}
              className={`text-amber-200 ml-5 cursor-pointer ${
                viewStatus === "mobile"
                  ? `${mood === "light" ? "text-[#111827]" : "text-white"}`
                  : "text-gray"
              }`}
              onClick={() => setViewStatus("mobile")}
            />
            <AiOutlineDesktop
              size={26}
              className={`text-amber-200 ml-6 cursor-pointer  ${
                viewStatus === "computer"
                  ? `${mood === "light" ? "text-[#111827]" : "text-white"}`
                  : "text-gray"
              }`}
              onClick={() => setViewStatus("computer")}
            />
          </div>
          <div
            onClick={() => setEventStatus(!eventStatus)}
            className="relative flex items-center justify-center cursor-pointer mt-1 "
          >
            <p
              className={`${
                mood === "light" ? "text-[#111827] font-[600]" : "text-white"
              } font-inter`}
            >
              {eventName && eventName}
            </p>
            <BiChevronDown
              size={26}
              className={`${
                mood === "light" ? "text-[#111827] font-[600]" : "text-white"
              }`}
            />
            {eventStatus && eventName && (
              <div
                className={`w-36 rounded absolute z-[999] py-2 top-[30px] border border-grayLight transition-all duration-500 font-inter shadow-md bg-white text-black`}
              >
                <p
                  onClick={() => setRenameStatus(!renameStatus)}
                  className=" p-[2px] font-semibold capitalize cursor-pointer px-4  text-center blueHover"
                >
                  Rename
                </p>
              </div>
            )}
          </div>
          <div
            onClick={areArraysMatching}
            className="bg-blue w-[32px] h-[32px] rounded-md cursor-pointer flex items-center justify-center"
          >
            <AiOutlineClose size={25} className=" text-white" />
          </div>
        </div>

        <Toaster
          position="top-right"
          reverseOrder={false}
          hide={setPopupStatus}
        />
      </div>

      {closePop && (
        <>
          <EventName
            status="clear"
            show={closePop}
            hide={setClosePop}
            selectName="/events"
          />
        </>
      )}

      {clearPop && (
        <>
          <EventName status="close" show={clearPop} hide={setClearPop} />
        </>
      )}
    </div>
  );
};

export default Header;
