/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { setFeedbackValue } from "../../../redux/slices/feedbackValue";
import style from "./MultiSelect.module.css";
import α from "color-alpha";
import { Check } from "react-feather";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import axios from "../../../axios";

const MultiSelect = ({
  inputQuestion,
  id,
  questionType,
  answer,
  eventId,
  bg,
  color,
  textColor,
  setType,
  setMulti,
  statictext,
}) => {
  const dispatch = useDispatch();
  const [selected, setSelected] = useState({ value: [] });
  const col = α(color, 0.2);
  const datas = useSelector((state) => state.feedbackValue.feedbackValue);
  const [temp, setTemp] = useState([]);

  const getValue = (answer) => {
    if (
      inputQuestion.length !== 0 &&
      answer.length !== 0 &&
      id.length !== 0 &&
      questionType.length !== 0 &&
      answer.length !== 0 &&
      eventId.length !== 0
    ) {
      //setting value in a state

      if (temp === undefined || temp?.length === 0) {
        return setTemp([answer]);
      } else {
        const check = temp?.some((item) => answer === item);

        if (check === undefined || !check) {
          return setTemp([...temp, answer]);
        } else {
          const allData = temp?.filter((data) => data !== answer);
          return setTemp(allData);
        }
      }
    }
  };

  useEffect(() => {
    setType("multiSelect");
    if (datas?.[0]?.list_of_items?.[id - 1]?.answer[0]?.id) {
      setTemp([]);
      setMulti("");
    } else {
      setMulti(datas?.[0]?.list_of_items?.[id - 1]?.multiAns);
      setTemp(datas?.[0]?.list_of_items?.[id - 1]?.multiAns);
    }
  }, [datas?.[0]?.list_of_items[id - 1]?.answer]);

  useEffect(() => {
    if (temp?.length !== 0) {
      let list = [...datas?.[0]?.list_of_items];
      list[id - 1] = {
        ...list[id - 1],
        answer: `{${temp}}`,
        multiAns: temp,
      };

      let allItem = { ...datas?.[0], list_of_items: list };
      dispatch(setFeedbackValue(allItem));
    }
  }, [temp]);

  // const [answers, setAnswers] = useState([]);

  const handelChange = (event, id) => {
    if (event.target.checked === true) {
      setSelected({ value: [...selected.value, event.target.value] });
    } else {
      setSelected({
        value: selected.value.filter((e) => e !== event.target.value),
      });
    }
  };

  return (
    <div className=" mb-6 h-full w-full flex flex-col justify-center items-center  bg-no-repeat bg-cover bg-center bg-fixed">
      <h3
        style={{ color: `${textColor}`, wordBreak: "break-word" }}
        className="mb-8 text-center  flex items-center justify-center w-full max-w-3xl  break-words mx-10  text-xl sm:text-3xl font-semibold"
      >
        {inputQuestion}
      </h3>
      <p className="mb-3">{statictext}</p>
      {answer.map((i, index) => {
        const check =
          datas?.[0]?.list_of_items[id - 1]?.multiAns &&
          datas?.[0]?.list_of_items[id - 1]?.multiAns.some(
            (item) => i.id === item
          );
        return (
          <label
            style={{
              border: `3px solid ${check ? color : "#F7F7F7"}`,
              background: `${check ? col : "#F7F7F7"}`,
              wordBreak:'break-word'
            }}
            className={`${style.formCheck} w-full relative !rounded !min-w-[25%] md:max-w-[25%]`}
            for={i.value}
            key={index}
          >
            <input
              type="checkbox"
              id={i.id}
              value={i.id}
              style={{ opacity: "0" }}
              onClick={(e) => {
                getValue(i.id);
                setMulti(i.id);
                handelChange(e, index + 1);
              }}
            />
            <label
              style={{ color: `${check ? "black" : "#494949"}`,wordBreak: "break-word" }}
              for={i.id}
              className="w-full  lg:w-[90%] break-words  text-start"
            >
              {i.value}
            </label>
            {check && (
              <Check
                style={{ color: `${color}` }}
                className="absolute right-3 top-4"
                size={25}
              />
            )}
          </label>
        );
      })}
    </div>
  );
};

export default MultiSelect;
