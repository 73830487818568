import React, { useLayoutEffect, useState } from "react";

// components
import ViewSamplePopup from "../QrcodeTemplate/ViewSamplePopup";
import CopyToClipboard from "../shared/CopyToClipboard";
import ModalCloseBtn from "../ModalCloseBtn";
import Mailsend from "./Mailsend";
import QrCode from "../qr-code";

// Import react-router
import { Link } from "react-router-dom";

// Import icons
import { MdQrCodeScanner } from "react-icons/md";
import { FiMail } from "react-icons/fi";

// Import assets
import { a1Img, a2Img, gym1, gym2, res1, res2, mall1, mall2, tour1,tour2, re1, re2, fsc1, fsc2,  ed1, ed2} from "../../assets/images/qr-templates"

// Import third-party
import Emoji from "react-emojis";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
// import required swiper modules
import { Autoplay, Pagination } from "swiper/modules";
import { driver } from "driver.js";
import "driver.js/dist/driver.css";
import { useSelector } from "react-redux";
/**
 * Represents a popup component for displaying campaign-related information.
 *
 * @component
 * @param {Object} props - The component's props.
 * @param {string} props.eventName - The name of the campaign event.
 * @param {string} props.linkToCopy - The link to copy.
 * @param {string} props.id - The link to copy.
 * @param {string} props.onGoBackClicked - The path to redirect to, when clicked go back button.
 * @param {function} props.onPreviewClicked - A function to handle the preview button click event.
 * @param {function} props.onCloseClicked - A function to handle the close button click event.
 *
 * @returns {JSX.Element} The rendered CampaignCreatedPopup component.
 */

const carouselImages = [a1Img, a2Img, gym1, gym2, res1, res2, mall1, mall2,  tour1,tour2,  re1, re2,  fsc1, fsc2, ed1, ed2];

const CampaignCreatedPopup = ({
  eventName,
  linkToCopy,
  onPreviewClicked,
  onGoBackClicked,
  onCloseClicked,
  id,
  clear
}) => {
  const [samplePopupOpen, setSamplePopupOpen] = useState(false);
  const [emailPopupOpen, setEmailPopupOpen] = useState(false);
  const [qrPopupOpen, setQrPopupOpen] = useState(false);
  const Campaing_publish = localStorage.getItem("Campaing_publish");
  const { user } = useSelector((state) => state.userAuth);

  useLayoutEffect(() => {
    const API = async () => {
      if (user?.initialLogin && Campaing_publish === null) {
        const driverOptions = {
          showProgress: true,
          showButtons: ["next", "previous"],
          steps: [
            {
              element: "#campaign_link",
              popover: {
                title: "Survey Link!",
                description:
                  "The link can be copied and shared with anyone to receive feedback on the creation.",
                side: "right",
                align: "start",
              },
            },
            {
              element: "#campaign_qr",
              popover: {
                title: "QR",
                description:
                  "This QR code can be downloaded and used to create stands or posters.",
                side: "right",
                align: "start",
              },
            },
            {
              element: "#campaign_email",
              popover: {
                title: "Send Email",
                description: "You can send you the Survey link via email.",
                side: "right",
                align: "start",
              },
            },
          ],
        };
        const driverObj = driver(driverOptions);

        driverObj?.drive();
        localStorage.setItem("Campaing_publish", false);
      }

      // Mark the tour as completed
      //    return localStorage.setItem("campaignCreated_tourCompleted", "true")
      // }
      // }
    };
    API();
  }, []);

  const buttons = [
    {
      title: "QR Code",
      icon: <MdQrCodeScanner size={30} className="cursor-pointer text-blue" />,
      id: "campaign_qr",
      onClick: () => setQrPopupOpen(true),
    },
    {
      title: "Email Link",
      icon: <FiMail size={30} className="cursor-pointer text-blue" />,
      id: "campaign_email",
      onClick: () => setEmailPopupOpen(true),
    },
  ];

  const RenderLeftContent = () => (
    <>
      <div className=" text-center">
        <Emoji size={60} emoji="grinning-face-with-smiling-eyes" />
        <p className=" font-bold text-xl font-inter mt-4">Congratulations!</p>
        <h4 className=" text-base font-semibold text-blue mt-6 ">
          “{eventName}”
        </h4>
        <p className=" text-base font-normal">
        Survey has been Successfully Created.
        </p>
      </div>
      <div className=" text-center">
        <h2 className=" text-xl font-semibold text-blue">
          Share your Survey
        </h2>
        <div className="grid grid-cols-3 gap-4 w-full mt-6">
          <div id="campaign_link">
            <CopyToClipboard linkToCopy={linkToCopy} />
          </div>
          {buttons.map((obj, idx) => (
            <div
              id={obj.id}
              key={obj.title}
              onClick={obj.onClick}
              className="flex flex-col justify-center items-center gap-2 p-4 rounded-lg border border-blue cursor-pointer hover:bg-blue/10"
            >
              {obj.icon}
              <p className=" text-base font-medium">{obj.title}</p>
            </div>
          ))}
        </div>
        <div className="mt-8 flex gap-4 justify-between w-full mx-auto">
          <Link
            onClick={clear}
            to={onGoBackClicked}
            className=" secondaryButton flex-1"
          >
           All Surveys 
          </Link>
          <a
            href={linkToCopy}
            target="_blank"
            className=" primaryButton flex-1"
            onClick={onPreviewClicked}
          >
            Live Preview
          </a>
        </div>
      </div>
    </>
  );

  const RenderRightContent = () => (
    <>
      <div className=" max-w-full flex-1 relative block">
        <Swiper
          pagination={true}
          modules={[Pagination, Autoplay]}
          autoplay
          loop
          className=" max-w-full h-full rounded-lg"
        >
          {carouselImages.map((img, idx) => (
            <SwiperSlide key={idx} className=" w-fit">
              <img
                src={img}
                className=" w-full h-full object-cover"
                alt={`Qr template ${idx}`}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div className="flex items-center gap-10">
        <p className=" text-sm font-medium text-grayDark">
          Good designs get you good feedback. If you've got a different idea,
          tell us. We can get it done for you!
        </p>
        <button
          onClick={() => setSamplePopupOpen(true)}
          className=" primaryButton whitespace-nowrap"
        >
          View Samples
        </button>
      </div>
    </>
  );

  const MainContent = () => (
    <div className="bg-[#fafafa] flex gap-10 w-[80%] h-[85%] max-w-5xl max-h-[540px] mx-auto p-8 rounded-lg relative">
      <ModalCloseBtn onClick={onCloseClicked} />
      <div className="w-1/2 flex flex-col justify-between p-1">
        {emailPopupOpen ? (
          <Mailsend innerFormOnly={true} hide={setEmailPopupOpen} id={id} eventName={eventName} />
        ) : qrPopupOpen ? (
          <QrCode
            name={eventName}
            popUp={setQrPopupOpen}
            value={linkToCopy}
            innerContentOnly={true}
          />
        ) : (
          <RenderLeftContent />
        )}
      </div>
      <div className=" w-1/2 flex flex-col gap-6 relative">
        <RenderRightContent />
      </div>
    </div>
  );

  return (
    <div className=" fixed top-0 left-0 w-screen h-screen flex justify-center items-center z-[70]  bg-[#00000042]">
      {samplePopupOpen ? (
        <ViewSamplePopup setModalOpen={setSamplePopupOpen} />
      ) : (
        <MainContent />
      )}
    </div>
  );
};

export default CampaignCreatedPopup;
