import React, { useState, useEffect } from "react"
import { X } from "react-feather"

import { a1Img, a2Img, a3Img, a4Img, gym1, gym2, gym3, gym4, res1, res2, res3, res4, mall1, mall2, mall3, mall4, tour1,tour2, tour3,tour4, re1, re2, re3, re4, fsc1, fsc2, fsc3, fsc4, ed1, ed2, ed3, ed4} from "../../assets/images/qr-templates"
import ModalCloseBtn from "../ModalCloseBtn"
import { HiOutlineMail } from "react-icons/hi"
import { BiPhone } from "react-icons/bi"

const categories = [
    "Hospital",
    "GYM",
    "Restaurant",
    "Mall",
    "Tourism",
    "Real Estate",
    "Fashion Clothing",
    "Education Center"
]

const templateImages = [
    {
        key: "Hospital",
        category: "A",
        images: [a1Img, a2Img, a3Img, a4Img]
    },
    {
        key: "GYM",
        category: "B",
        images: [gym1, gym2, gym3, gym4]
    },
    {
        key: "Restaurant",
        category: "C",
        images: [res1, res2, res3, res4]
    },
    {
        key: "Mall",
        category: "D",
        images: [mall1, mall2, mall3, mall4]
    },
    {
        key: "Tourism",
        category: "E",
        images: [tour1,tour2, tour3,tour4]
    },
    {
        key: "Real Estate",
        category: "F",
        images: [re1, re2, re3, re4]
    },
    {
        key: "Fashion Clothing",
        category: "G",
        images: [fsc1, fsc2, fsc3, fsc4]
    },
    {
        key: "Education Center",
        category: "H",
        images: [ed1, ed2, ed3, ed4]
    }
]

const ViewSamplePopup = ({ setModalOpen }) => {
    const [selectedCategory, setSelectedCategory] = useState(categories[0])

    const renderImages = (obj) => {
        if (obj.key === selectedCategory) {
            if (!obj.images?.length) {
                return (
                    <h1
                        key={`${obj.category}_${obj.key}`}
                        className=" col-span-2 grid h-full place-items-center text-3xl"
                    >
                        Comming soon...
                    </h1>
                )
            }
            return obj.images.map((img, idx) => (
                <div
                    key={obj.key}
                    className="relative w-full aspect-[3/2] group overflow-hidden rounded-lg"
                >
                    <span className=" absolute hidden group-hover:block top-0 left-0 z-10 py-2 px-4 rounded-br-lg bg-blue text-white">
                        {obj.category}
                        {idx + 1}
                    </span>
                    <img
                        src={img}
                        alt={` ${obj.key} template`}
                        key={`${obj.key}_${idx}`}
                        className=" w-full h-full object-cover"
                    />
                </div>
            ))
        }
    }

    return (
        <div className=" fixed top-0 left-0 w-screen h-screen flex justify-center items-center z-[70]  bg-[#00000042]">
            <div className="bg-[#fafafa] flex flex-col w-[80%] h-[85%] max-w-6xl max-h-[600px] l2:max-h-[900px] mx-auto p-8 rounded-lg">
                <div className="flex justify-between w-full items-center border-b border-grayLight pb-4">
                    <h2 className=" text-2xl font-bold">QR Code Template</h2>
                    <div className="flex gap-4 items-center">
                        <a
                            href="mailto:Info@feedbacksync.com"
                            target="_blank"
                            className=" relative h-10 w-10 cursor-pointer bg-white outline outline-2 outline-blue/80 rounded grid place-items-center group"
                        >
                            <span className=" absolute w-fit whitespace-nowrap opacity-0 group-hover:opacity-100 group-hover:translate-y-[-120%] translate-y-[-100%] top-0 left-[-40%] bg-blue/40 px-4 py-2 rounded-md text-black text-xs transition-all duration-300">
                                Mail us
                            </span>
                            <HiOutlineMail
                                size={22}
                                className=" text-blue"
                            />
                        </a>
                        <a
                            href="tel:9994172111"
                            target="_blank"
                            className="relative h-10 w-10 cursor-pointer bg-white outline outline-2 outline-blue/80 rounded grid place-items-center group"
                        >
                            <span className=" absolute w-fit whitespace-nowrap opacity-0 group-hover:opacity-100 group-hover:translate-y-[-120%] translate-y-[-100%] top-0 left-[-60%] bg-blue/40 px-4 py-2 rounded-md text-black text-xs transition-all duration-300">
                                Contact us
                            </span>
                            <BiPhone
                                size={22}
                                className=" text-blue"
                            />
                        </a>
                        <button
                            type="button"
                            className="p-2 text-white bg-blue transition-colors duration-300 blueHover rounded-md"
                            data-modal-toggle="popup-modal"
                            onClick={() => setModalOpen(false)}
                        >
                            <X size={25} />
                        </button>
                    </div>
                </div>

                <ul className="flex w-full mt-4">
                    {categories.map((cat, idx) => (
                        <li
                            onClick={() => setSelectedCategory(cat)}
                            className={` border-b-4 ${
                                selectedCategory === cat ? "border-blue text-blue" : "border-transparent text-black"
                            } p-3 cursor-pointer text-base font-medium`}
                            key={`${cat}_${idx}`}
                        >
                            {cat}
                        </li>
                    ))}
                </ul>

                <div className="mt-8 flex-1  w-full overflow-y-auto p-2">
                    <div className="grid grid-cols-2 gap-8 min-h-full">
                        {templateImages.map((obj) => renderImages(obj))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ViewSamplePopup
