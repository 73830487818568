import React from 'react'

// import animation JSON
import dotLoaderJson from "../../assets/lottie/feedback-dot-loader.json"
import whiteDotLoaderJson from "../../assets/lottie/feedback-dot-loader-white.json"

// import lottie player
import Lottie from 'react-lottie-player'

/**
 * Represents a custom dot loader animation component.
 *
 * @component
 * @param {Object} props - The component's props.
 * @param {number} [props.dotSize=100] - The size of the dot loader animation.
 * @param {string} [props.className=""] - Any additional styles you want to apply.
 * @param {boolean} [props.isWhite=false] - Set this to true for white color dots.
 *
 * @returns {JSX.Element} The rendered CustomDotLoader component.
 */

const CustomDotLoader = ({dotSize = 100, className = "", isWhite = false}) => {
  return (
      <Lottie
          loop
          animationData={ isWhite ? whiteDotLoaderJson : dotLoaderJson}
          play
          style={{
            width: dotSize,
            height: dotSize / 2,
          }}
          className={className}
      />
  )
}

export default CustomDotLoader