import React from "react";

const TextArea = ({ name, type, placeholder, register, error, id }) => {
  return (
    <div>
      <label
        htmlFor={name}
        className="text-[14px] text-[#18181B] font-semibold text-gray-900 font-pj"
      >
        {name}
      </label>
      <div className="mt-1">
        <textarea
          type={type}
          name={name}
          placeholder={placeholder}
          className="w-full mt-2  h-[120px] p-3 rounded-[10px] focus:border-blue focus:outline-none border border-gray font-medium placeholder:text-gray text-black resize-none"
          cols="30"
          rows="10"
          {...register(id === undefined ? placeholder : id)}
        />
        {error && (
          <p className="text-red text-xs mt-1">
            {" "}
            <span className=" text-base">*</span> {error}
          </p>
        )}
      </div>
    </div>
  );
};

export default TextArea;
