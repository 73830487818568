/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setFeedbackValue } from "../../../redux/slices/feedbackValue";
import style from "./MultiSelect.module.css";
import α from "color-alpha";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";

const MultiSelect = ({
  inputQuestion,
  id,
  questionType,
  answer,
  nextQuz,
  next,
  eventId,
  viewStatus,
}) => {
  const { questionValues } = useSelector((state) => state.questions);
  const [nextValues, setNextValues] = useState([]);
  const [reduxValue, setReduxValue] = useState([]);
  const [preValue, setPreValue] = useState([]);
  const { questions } = useSelector((state) => state);
  const logoInfo = questions.logoDetails;
  const buttonColors = questions.buttonColor;
  const textColors = questions.textColor;
  // const { buttonColor} = useSelector((state) => state.globalDefine);
  // const { textColor } = useSelector((state) => state.globalDefine);
  const col = α(
    (buttonColors !== null) & (buttonColors !== undefined)
      ? buttonColors
      : "#4F46E5",
    0.2
  );
  let nextId = Number(id) + 1;
  let prev = Number(id) - 1;
  const dispatch = useDispatch();
  const { mood } = useSelector((state) => state.mood);
  const { companyLogo } = useSelector((state) => state.globalDefine);

  const getValue = (answer) => {
    if (
      inputQuestion?.length !== 0 &&
      answer?.length !== 0 &&
      id?.length !== 0 &&
      questionType?.length !== 0 &&
      answer?.length !== 0 &&
      eventId?.length !== 0
    ) {
      return dispatch(
        setFeedbackValue({
          eventId: eventId,
          list_of_items: [
            {
              question: inputQuestion,
              answer: answer,
              answerId: id,
              type: questionType,
            },
          ],
        })
      );
    }
  };

  useEffect(() => {
    const filter = questionValues.filter((e) => Number(e.id) === Number(prev));
    setPreValue(filter);
  }, [questionType, id]);

  useEffect(() => {
    const filter = questionValues.filter(
      (e) => Number(e.id) === Number(nextId)
    );
    setNextValues(filter);
  }, [questionType, id]);

  useEffect(() => {
    const current = Number(id);
    const filter = questionValues.filter(
      (e) => Number(e.id) === Number(current)
    );
    setReduxValue(filter);
  }, [id]);

  const [selected, setSelected] = useState({ value: [] });

  const handelChange = (event) => {
    if (event.target.checked === true) {
      setSelected({ value: [...selected.value, event.target.value] });
    } else {
      setSelected({
        value: selected.value.filter((e) => e !== event.target.value),
      });
    }
  };

  return (
    <div className="w-full flex flex-col items-center mt-[30px] overflow-y-auto overflow-x-hidden">
      {logoInfo.length !== 0 && (
        <img
          className="mb-[30px] max-w-[125px]"
          src={logoInfo[0]?.logoLocation}
          alt=""
        />
      )}
      {logoInfo.length === 0 && companyLogo !== null && (
        <img className="mb-[30px] max-w-[125px]" src={companyLogo} alt="" />
      )}

      <h3
        style={{
          color: `${
            (textColors !== null) & (textColors !== undefined) && textColors
            // textColor : textColors
          }`,
        }}
        className={`w-[80%] max-h-full z-20 text-center mx-10 text-[18px] font-[500] font-inter ${
          mood === "light" ? "text-black" : "text-white"
        } break-words`}
      >
        <span className="w-full">{inputQuestion}</span>
      </h3>
      {console.log("col", col)}

      {answer !== false &&
        answer?.map((i, index) => (
          <label
            style={{
              border: `3px solid  ${
                (buttonColors !== null) & (buttonColors !== undefined)
                  ? buttonColors
                  : "#4F46E5"
                //  buttonColor : buttonColors
              }`,
              background: `${col}`,
            }}
            className={`${
              viewStatus === "computer" ? "w-[50%]" : "w-[74%]"
            } break-words flex items-center  pr-2 propacity-80 rounded-[5px] py-3 border ${
              mood === "light"
                ? "border-[#E4E4E7] bg-[#f1f1f1]"
                : "bg-[#232734] border-[#303544]"
            } mt-4`}
            for={i.value}
            key={index}
          >
            <input
              type="checkbox"
              name={i.value}
              id={i.value}
              value={i.value}
              onClick={(e) => {
                handelChange(e);
                getValue(i.value);
              }}
              style={{
                accentColor: `${
                  (buttonColors !== null) & (buttonColors !== undefined)
                    ? buttonColors
                    : "#4F46E5"
                  //  buttonColor : buttonColors
                }`,
              }}
              className="mx-2 w-4 h-4"
            />
            <label
              className={` ${
                viewStatus === "computer" ? "w-[90%]" : "w-[88%]"
              }  px-0 ml-2 mr-2 text-base font-medium ${
                mood === "light" ? "text-black" : "text-gray"
              } cursor-pointer break-words`}
              for={i.value}
            >
              {i.value}
            </label>
          </label>
        ))}

      <div className="flex items-center gap-x-3">
        {preValue.length !== 0 && (
          <button
            type="button"
            style={{
              background: `${
                buttonColors === null || buttonColors === undefined
                  ? "#4F46E5"
                  : //  buttonColor
                    buttonColors
              }`,
            }}
            className=" rounded-full p-3 my-3 transition-all duration-200 text-lg font-semibold text-center block "
            onClick={() => {
              nextQuz(preValue[0].type);
              next(prev);
            }}
          >
            <IoIosArrowBack size={40} color="white" />
          </button>
        )}
        {nextValues.length !== 0 && (
          <button
            style={{
              background: `${
                buttonColors === null || buttonColors === undefined
                  ? "#4F46E5"
                  : //  buttonColor
                    buttonColors
              }`,
            }}
            className=" rounded-full p-3 my-3 transition-all duration-200 text-lg font-semibold text-center block "
            onClick={() => {
              nextQuz(nextValues[0].type);
              next(nextId);
            }}
          >
            <IoIosArrowForward size={40} color="white" />
          </button>
        )}
      </div>
    </div>
  );
};

export default MultiSelect;
