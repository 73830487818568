/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from "react"
import axios from "../../../axios"
import toast, { Toaster } from "react-hot-toast"
import { useNavigate, useSearchParams } from "react-router-dom"
import logo from "../../../assets/logo1.svg"
import CustomDotLoader from "../../../components/CustomDotLoader"

import OtpInput from "react-otp-input"

// ** import components
import FadeInTransition from "../../../components/FadeInTransition"

const index = () => {
    const [otp, setOtp] = useState("")
    const [loader, setLoader] = useState(false)
    const navigation = useNavigate()
    const [searchParams] = useSearchParams()
    const Email = searchParams.get("email")
    const [seconds, setSeconds] = useState(180)
    const Verify = searchParams.get("verify")

    const handelSubmit = async () => {
        // localStorage.setItem("SIGNIN", true);

        if (Email !== null) {
            if (otp.length !== 0) {
                setLoader(true)
                const { data } = await axios.post("/verify/otp", {
                    action: Verify,
                    otp
                })

                if (data.success) {
                    const { data } = await axios.post("/verify", {
                        otp,
                        status: Verify
                    })
                    if (data.success) {
                        toast.success(`${data.message}`)
                        localStorage.setItem("Token", data.token)
                        navigation("/")
                        return
                    } else if (data.success === false) {
                        return toast.error(`${data.message}`)
                    }
                } else {
                    toast.error(`${data.message}`)
                    setLoader(false)
                    return
                }
            } else {
                toast.error("Input the OTP")
                return
            }
        }
    }

    const reSend = async () => {
        if (seconds === 0) {
            if (Email !== null) {
                const { data } = await axios.post("/resend-otp", {
                    email: Email,
                    status: "verify",
                    auth: "local"
                })
                if (data.success) {
                    return toast.success(`${data.message}`)
                }
            }
        } else {
            return toast.error("OTP Expire time is running")
        }
    }

    useEffect(() => {
        if (seconds > 0) {
            const countdown = setInterval(() => {
                setSeconds((prevSeconds) => prevSeconds - 1)
            }, 1000)

            return async () => clearInterval(countdown)
        } else {
            const API = async () => {
                const { data } = await axios.post("/remove-otp", {
                    email: Email,
                    status: "verify"
                })
            }
            API()
        }
    }, [seconds])

    const formatTime = (time) => {
        const minutes = Math.floor(time / 60)
        const seconds = time % 60
        return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`
    }

    return (
        <div className="bg-white w-full flex justify-center items-center h-screen">
            <div className="fixed bottom-0 left-0 w-full border-b-[10px] border-[#4F46E5] z-50"></div>
            <div
                style={{ boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.15)" }}
                className="py-5 w-full !z-50 absolute top-0"
            >
                <img
                    className="w-[175px] block mx-auto"
                    src={logo}
                    alt=""
                />
            </div>
            <div className="px-4 mx-auto w-[500px] sm:px-6 lg:px-8">
                <div className="relative mx-auto">
                    <FadeInTransition>
                        <div
                            style={{ boxShadow: "0px 0px 61.3082px rgba(0, 0, 0, 0.15)" }}
                            className="bg-[white] relative overflow-hidden rounded-[16px]"
                        >
                            <div className="px-4 py-6 sm:px-8">
                                <p className="font-bold font-pj text-center text-[#18181B] text-[21px]">
                                    Verify your email
                                </p>
                                <p className="text-center text-[15px] text-[#52525B] mt-3 font-pj">
                                    Please enter the 4 digit code {Email !== null ? Email : ""}
                                </p>

                                <div className="space-y-5 mt-[30px]">
                                    <div className="flex flex-col items-center justify-center">
                                        <div className="mt-1 flex items-center justify-center relative">
                                            <OtpInput
                                                value={otp}
                                                onChange={setOtp}
                                                numInputs={4}
                                                inputStyle={"otpInput"}
                                            />
                                        </div>
                                    </div>
                                </div>
                                {Email !== null && (
                                    <div className=" text-center mt-1">
                                        <p
                                            className="text-[14px] text-[#4F46E5] text-center mt-4 cursor-pointer underline"
                                            onClick={reSend}
                                        >
                                            Resend code ({formatTime(seconds)})
                                        </p>
                                    </div>
                                )}

                                {!loader ? (
                                    <button
                                        onClick={handelSubmit}
                                        type="submit"
                                        className="flex items-center justify-center w-full px-8 h-[50px] max-h-[50px] mt-5 text-base font-bold text-white transition-all duration-200 bg-[#4F46E5] border border-transparent hover:border-[#4F46E5] hover:!bg-white hover:text-[#4F46E5] rounded-xl focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray  hover:bg-grayDark"
                                    >
                                        Verify
                                    </button>
                                ) : (
                                    <button
                                        type="submit"
                                        className={`flex items-center justify-center overflow-hidden h-[50px] max-h-[50px] w-full px-8 mt-5 text-base font-bold text-white transition-all duration-200 bg-[#4F46E5] border border-transparent hover:border-[#4F46E5] hover:!bg-[#4e46e5e8] hover:text-[white] rounded-xl focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray`}
                                    >
                                        <CustomDotLoader
                                            dotSize={60}
                                            isWhite
                                        />
                                    </button>
                                )}
                            </div>
                        </div>
                    </FadeInTransition>
                </div>
            </div>

            <Toaster
                position="top-right"
                reverseOrder={false}
            />
        </div>
    )
}

export default index
