/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import α from "color-alpha"
import Select from "react-select"
import "../../index.css"
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io"

const Connect = ({ inputQuestion, id, questionType, answer, nextQuz, next, eventId, viewStatus }) => {
    const { questionValues } = useSelector((state) => state.questions)
    const [nextValues, setNextValues] = useState([])
    const { mood } = useSelector((state) => state.mood)
    const { questions } = useSelector((state) => state)
    const buttonColors = questions.buttonColor
    const textColors = questions.textColor
    const logoInfo = questions.logoDetails
    const [preValue, setPreValue] = useState([])
    let prev = Number(id) - 1
    useEffect(() => {
        const filter = questionValues.filter((e) => Number(e.id) === Number(prev))
        setPreValue(filter)
    }, [questionType, id])
    const col = α(
        (buttonColors !== null) & (buttonColors !== undefined) &&
            // buttonColor :
            buttonColors,
        0.2
    )
    // const { buttonColor} = useSelector((state) => state.globalDefine);
    // const { textColor } = useSelector((state) => state.globalDefine);
    // const { companyLogo } = useSelector((state) => state.globalDefine);

    let nextId = Number(id) + 1

    useEffect(() => {
        const filter = questionValues.filter((e) => Number(e.id) === Number(nextId))
        setNextValues(filter)
    }, [questionType, id])

    //custom styles
    const customStyles = {
        control: (styles) => ({
            ...styles,
            backgroundColor: `${mood === "light" ? "#FAFAFA" : "#303544"}`,
            border: `2px solid  ${
                (buttonColors !== null) & (buttonColors !== undefined) ? buttonColors : "#4F46E5"
                //  buttonColor : buttonColors
            }`,
            paddingBlock: 3
        }),
        option: (provided, state) => ({
            ...provided,
            color: state.isSelected ? "white" : "black",
            background: state.isSelected ? "#8a8af0" : "white",
            padding: 14,
            cursor: "pointer",
            borderRadius: "4px",
            zIndex: 999999
        }),

        singleValue: (provided) => ({
            ...provided,
            color: "black",
            fontWeight: "500"
        }),
        placeholder: (provided) => ({
            ...provided,
            color: "#BEBEBE",
            fontWeight: "500"
        }),
        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
        menuList: (base) => ({
            ...base,
            // kill the white space on first and last option
            border: `2px solid #3e4e4e4`,
            boxShadow: "0 10px 15px rgba(0,0,0,0.10)",
            borderRadius: "8px",
            padding: 10,
            zIndex: 9999
        })
    }
    // eslint-disable-next-line react-hooks/rules-of-hooks

    //bg-[url('https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg')]
    return (
        <div className="w-full h-full gap-4  flex flex-col items-center mt-[30px] overflow-y-auto overflow-x-hidden bg">
            {logoInfo.length !== 0 && (
                <img
                    className="mb-[30px] max-w-[125px]"
                    src={logoInfo[0]?.logoLocation}
                    alt=""
                />
            )}
            {/* {
        logoInfo.length === 0 && companyLogo !== null && 
        <img
        className="mb-[30px] max-w-[125px]"
        src={companyLogo}
        alt=""
      />
      } */}

            <h3
                style={{
                    color: `${
                        (textColors !== null) & (textColors !== undefined) && textColors
                        //  textColor : textColors
                    }`
                }}
                className={`w-[80%] max-h-full z-20 text-center mx-10 text-[18px] font-[500] font-inter  ${
                    mood === "light" ? "text-black" : "text-white"
                } break-words`}
            >
                <span className="w-full">{inputQuestion}</span>
            </h3>
            {answer?.map((i) => (
                <div
                    key={i.id}
                    className={`${
                        viewStatus === "computer" ? "w-[50%]" : "w-[74%]"
                    } break-words flex items-center rounded-[5px] border-0 mt-2 contact pre`}
                >
                    {i.status === "textarea" ? (
                        <textarea
                            style={{
                                border: `2px solid  ${
                                    (buttonColors !== null) & (buttonColors !== undefined) ? buttonColors : "#4F46E5"
                                    //  buttonColor : buttonColors
                                }`,
                                color: "black"
                            }}
                            placeholder={`${i.placeholder}`}
                            className={`inputStyle w-full  h-[100px] py-3 rounded px-3 outline-0 font-medium mx-2 placeholder:text-gray resize-none`}
                            name=""
                            id=""
                            cols="30"
                            rows="10"
                        ></textarea>
                    ) : i.status === "select" && i?.options?.length !== 0 ? (
                        <Select
                            /*onChange={(e) => update(item.id, e.value, item?.type, item?.placeholder, item?.status, item?.options)}*/ 
                            styles={
                                customStyles
                            }
                            className={`!text-gray w-full mx-2`}
                            options={i.options}
                            placeholder={i.placeholder}
                        />
                    ) : (
                        <input
                            style={{
                                border: `2px solid  ${
                                    (buttonColors !== null) & (buttonColors !== undefined) ? buttonColors : "#4F46E5"
                                    //  buttonColor : buttonColors
                                }`,
                                color: "black"
                            }}
                            type={i.type}
                            placeholder={`${i.placeholder}`}
                            className={` inputStyle w-full py-3  rounded px-3 outline-0 font-medium mx-2 placeholder:text-gray placeholder:font-normal `}
                        />
                    )}
                </div>
            ))}

            <div className="flex items-center gap-x-3">
                {preValue.length !== 0 && (
                    <button
                        type="button"
                        style={{
                            background: `${
                                buttonColors === null || buttonColors === undefined
                                    ? "#4F46E5"
                                    : //  buttonColor
                                      buttonColors
                            }`
                        }}
                        className=" rounded-full p-2 my-3 transition-all duration-200 text-lg font-semibold text-center block "
                        onClick={() => {
                            nextQuz(preValue[0].type)
                            next(prev)
                        }}
                    >
                        <IoIosArrowBack
                            size={35}
                            color="white"
                        />
                    </button>
                )}
                {nextValues.length !== 0 && (
                    <button
                        style={{
                            background: `${
                                buttonColors === null || buttonColors === undefined
                                    ? "#4F46E5"
                                    : //  buttonColor
                                      buttonColors
                            }`
                        }}
                        className=" rounded-full p-2 my-3 transition-all duration-200 text-lg font-semibold text-center block "
                        onClick={() => {
                            nextQuz(nextValues[0].type)
                            next(nextId)
                        }}
                    >
                        <IoIosArrowForward
                            size={35}
                            color="white"
                        />
                    </button>
                )}
            </div>
        </div>
    )
}

export default Connect
