/* eslint-disable default-case */
import React, { useEffect, useState } from "react"
import axios from "../../axios"
import { useSelector } from "react-redux"
import ReactApexChart from "react-apexcharts"
import { BsChevronDown } from "react-icons/bs"
import DateFilterMenu from "./../DateFilterMenu"
import { highlightComponent } from "../../utils/HighlightComponents"

const Chart = () => {
    const { user } = useSelector((state) => state.userAuth)
    const [eventList, setEventList] = useState([])
    const [dateRange, setDateRange] = useState(null)
    const [label, setlabel] = useState("today");
    const [dateType, setDateType] = useState("today");
    const [categories, setcategories] = useState([])
    const [eventName, setEventName] = useState("AllEvents")
    const [chartValue, setChartValue] = useState([])
    const [filterOpen, setFilterOpen] = useState(false)
    const [filterOpen2, setFilterOpen2] = useState(false)
    const [getData, setgetData] = useState(false)
    const [length, setlength] = useState(0);


    useEffect(() => {
        switch (label) {
            case "month":
                setcategories(Array.from({ length: 31 }, (_, i) => (i + 1).toString()));
                break;

            case "date":
                setcategories(
                    Array.from({ length: length }, (_, i) => (i + 1).toString())
                );
                break;

            case "week":
                setcategories([
                    "Sunday",
                    "Monday",
                    "Tuesday",
                    "Wednesday",
                    "Thursday",
                    "Friday",
                    "Saturday",
                ]);
                break;
            case "year":
                setcategories([
                    "Jan",
                    "Feb",
                    "Mar",
                    "Apr",
                    "May",
                    "Jun",
                    "Jul",
                    "Aug",
                    "Sep",
                    "Oct",
                    "Nov",
                    "Dec",
                ]);
                break;
            case "today":
                setcategories([
                    "12AM",
                    "1AM",
                    "2AM",
                    "3AM",
                    "4AM",
                    "5AM",
                    "6AM",
                    "7AM",
                    "8AM",
                    "9AM",
                    "10AM",
                    "11AM",
                    "12PM",
                    "1PM",
                    "2PM",
                    "3PM",
                    "4PM",
                    "5PM",
                    "6PM",
                    "7PM",
                    "8PM",
                    "9PM",
                    "10PM",
                    "11PM",
                ]);
                break;
        }
    }, [label]);

    useEffect(() => {
        const fetchEventList = async () => {
            if (user) {
                const { data } = await axios.post("/feedbackChart/eventList", {
                    id: user.id
                })
                setEventList(data)
            }
        }
        fetchEventList()
    }, [user])

    useEffect(() => {
        const fetchChartData = async () => {
            if (user) {
                if (dateType === 'dateRange') {
                    const { data } = await axios.post("/feedbackChart", {
                        label: dateType,
                        fromDate: dateRange[0],
                        toDate: dateRange[1],
                        eventID: user.id,
                        eventName: eventName,
                    })
                    setlabel(
                        (dateType === "dateRange" && "date") ||
                        (dateType === "today" && "today") ||
                        (dateType === "yesterday" && "today") ||
                        (dateType === "thisMonth" && "month") ||
                        (dateType === "lastMonth" && "month") ||
                        (dateType === "thisWeek" && "week") ||
                        (dateType === "lastWeek" && "week") ||
                        (dateType === "thisYear" && "year") ||
                        (dateType === "lastYear" && "year")
                    );
                    setChartValue(data)
                    setgetData(false);
                    setDateRange(null);
                    const startDate = new Date(dateRange[0]);
                    const endDate = new Date(dateRange[1]);
                    const dateRanges = Math.round(
                        (endDate - startDate) / (1000 * 60 * 60 * 24)
                    );
                    setlength(dateRanges);

                } else {

                    const { data } = await axios.post("/feedbackChart", {
                        label: dateType,
                        fromDate: "",
                        toDate: "",
                        eventID: user.id,
                        eventName: eventName,

                    })
                    setlabel(
                        (dateType === "today" && "today") ||
                        (dateType === "yesterday" && "today") ||
                        (dateType === "thisMonth" && "month") ||
                        (dateType === "lastMonth" && "month") ||
                        (dateType === "thisWeek" && "week") ||
                        (dateType === "lastWeek" && "week") ||
                        (dateType === "thisYear" && "year") ||
                        (dateType === "lastYear" && "year")
                    );
                    setChartValue(data)
                    setgetData(false);


                }
            }
        }
        fetchChartData()
    }, [eventName, user, dateType, dateRange])

    const options = {
        chart: {
            type: "line",
            zoom: {
                enabled: false,
            },
            dropShadow: {
                enabled: true,
                top: 18,
                left: 2,
                blur: 5,
                opacity: 0.1,
            },
        },
        markers: {
            colors: "#4F46E5",
            strokeColors: "#4F46E5",
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            curve: "smooth",
            colors: "#4F46E5",
        },
        title: {
            text: `${dateType === 'dateRange' ? "" : dateType.toUpperCase()}`,
            align: "left",
            offsetX: -10,
            style: {
                fontSize: "20px",
                fontWeight: "500",
                color: "#737373",
            },
        },
        grid: {
            row: {
                colors: ["transparent"], // takes an array which will be repeated on columns
                opacity: 0.5,
            },
        },
        xaxis: {
            categories: categories,
        },
        noData: {
            text: "Loading...",
        },
    };

    // ** Chart Series
    const series = [
        {
            name: "Feedbacks",
            data: chartValue,
        },
    ];
    return (
        <div>
            <div
                style={{ boxShadow: "0px 2.37037px 8px rgba(0, 0, 0, 0.06)" }}
                className="border border-[#E4E4E7] max-h-[550px] h-[550px] overflow-hidden bg-white rounded-xl"
            >
                <div className="px-4 pt-5 sm:px-6">
                    <div className="flex items-center justify-between">
                        <div>
                            <p className="text-[20px] font-semibold text-[#737373] lg:order-1">Feedbacks</p>
                        </div>
                        <div className="flex items-center space-x-2">
                            <div className="mt-4 sm:mt-0">
                                <div
                                    id="chart_eventFilter"
                                    className="relative min-w-[200px]"
                                >
                                    <button
                                        onClick={() => {
                                            setFilterOpen((prev) => !prev)
                                            setFilterOpen2(false)
                                            // highlightComponent("#chart_eventFilter")
                                        }}
                                        type="button"
                                        className="relative w-full cursor-pointer rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-grayDark shadow-sm ring-1 ring-inset ring-grayLight focus:outline-none  sm:text-sm sm:leading-6"
                                    >
                                        <span className="flex items-center">
                                            <span className="ml-3 block truncate">
                                                {eventName === "AllEvents" ? "All" : eventName}
                                            </span>
                                        </span>
                                        <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                                            <BsChevronDown size={16} />
                                        </span>
                                    </button>
                                    <ul
                                        className={` ${
                                            filterOpen ? "visible" : "hidden"
                                        } absolute mt-1 max-h-56 w-full z-20 overflow-auto rounded-md bg-white  text-base  ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm dropdownBackground p-4`}
                                        tabIndex="-1"
                                        role="listbox"
                                    >
                                        <li
                                            onClick={() => {
                                                setFilterOpen((prev) => !prev)
                                                setEventName("AllEvents")
                                            }}
                                            className="text-gray-900 blueHover relative select-none py-2 pl-3 pr-9 cursor-pointer rounded"
                                            role="option"
                                        >
                                            <div className="flex items-center">
                                                <span className="font-normal ml-3 block truncate">All</span>
                                            </div>
                                        </li>
                                        {eventList?.map((el, idx) => (
                                            <li
                                                key={idx}
                                                onClick={() => {
                                                    setFilterOpen((prev) => !prev)
                                                    setEventName(el)
                                                }}
                                                className="text-gray-900 blueHover relative select-none py-2 pl-3 pr-9 cursor-pointer rounded"
                                                role="option"
                                            >
                                                <div className="flex items-center">
                                                    <span className="font-normal ml-3 block truncate">{el}</span>
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>

                            <div className="mt-4 sm:mt-0">
                                {/* TODO: date filter old UI */}
                                {/* <div className="relative min-w-[100px]">
                                    <button
                                        onClick={() => {
                                            setFilterOpen(false)
                                            setFilterOpen2((prev) => !prev)
                                        }}
                                        type="button"
                                        className="relative w-full cursor-pointer rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-grayLight focus:outline-none  sm:text-sm sm:leading-6"
                                    >
                                        <span className="flex items-center">
                                            <span className="ml-3 block truncate">{dateRange}</span>
                                        </span>
                                        <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                                            <BsChevronDown size={16} />
                                        </span>
                                    </button>
                                    <ul
                                        className={` ${
                                            filterOpen2 ? "visible" : "hidden"
                                        } absolute mt-1 max-h-56 w-full z-20 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm`}
                                        tabIndex="-1"
                                        role="listbox"
                                    >
                                        <li
                                            onClick={() => {
                                                setFilterOpen2(false)
                                                setDateRange("365")
                                            }}
                                            className="text-gray-900 blueHover relative select-none py-2 pl-3 pr-9 cursor-pointer"
                                            role="option"
                                        >
                                            <div className="flex items-center">
                                                <span className="font-normal ml-3 block truncate">365</span>
                                            </div>
                                        </li>
                                        <li
                                            onClick={() => {
                                                setFilterOpen2(false)
                                                setDateRange("30")
                                            }}
                                            className="text-gray-900 blueHover relative select-none py-2 pl-3 pr-9 cursor-pointer"
                                            role="option"
                                        >
                                            <div className="flex items-center">
                                                <span className="font-normal ml-3 block truncate">30</span>
                                            </div>
                                        </li>
                                        <li
                                            onClick={() => {
                                                setFilterOpen2(false)
                                                setDateRange("7")
                                            }}
                                            className="text-gray-900 blueHover relative select-none py-2 pl-3 pr-9 cursor-pointer"
                                            role="option"
                                        >
                                            <div className="flex items-center">
                                                <span className="font-normal ml-3 block truncate">7</span>
                                            </div>
                                        </li>
                                    </ul>
                                </div> */}
                                {/* TODO: date filter new UI */}
                                <div className="relative inline-block text-left">
                                    <div>
                                        <button
                                            onClick={() => {
                                                setFilterOpen(false)
                                                setFilterOpen2((prev) => !prev)
                                            }}
                                            type="button"
                                            className="inline-flex w-full justify-center items-center gap-x-10 rounded-md bg-white py-2 px-4 text-left text-grayDark text-sm font-normal shadow-sm ring-1 ring-inset ring-grayLight hover:bg-gray-50"
                                        >
                                            {dateType}
                                            <BsChevronDown size={16} />
                                        </button>
                                    </div>

                                    <DateFilterMenu
                                        filterOpen={filterOpen2}
                                        close={setFilterOpen2}
                                        setDateTypes={setDateType}
                                        dateRanges={setDateRange}
                                        getData={setgetData}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="my-4">
                        <ReactApexChart
                            options={options}
                            series={series}
                            height={450}
                        />
                    </div>
                </div>
            </div>
            
        </div>
    )
}

export default Chart
