/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef } from "react";
import { Calendar, Eye, FilePlus, FileText, Link, Search } from "react-feather";
import {
  BsCheckCircle,
  BsEmojiSmile,
  BsOctagon,
  BsRecordCircle,
  BsSpeedometer2,
  BsStar,
} from "react-icons/bs";
import img1 from "../../assets/ratting/1.png";
import img2 from "../../assets/ratting/2.png";
import img3 from "../../assets/ratting/4.png";
import img4 from "../../assets/ratting/3.png";
import img5 from "../../assets/ratting/5.png";
import icon from "../../assets/ratting/Icon.svg";
import star from "../../assets/ratting/Active.svg";
import axios from "../../axios";
import "react-datepicker/dist/react-datepicker.css";
import { useSelector, useDispatch } from "react-redux";
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import logoDark from "../../assets/svg/logo.svg";
import { AiOutlineUser } from "react-icons/ai";
import { svg1, svg2, svg3, svg4, svg5 } from "../../assets/ratting/1";

const ReportPateForExport = ({
  id,
  buttonClick,
  DateType,
  GetData,
  DateRange,
}) => {
  const { mood } = useSelector((state) => state.mood);
  const [eventDetails, setEventDetails] = useState([]);
  const [listOfQuestion, setListOfQuestion] = useState([]);
  const [multiSelectValue, setMultiSelectValue] = useState([]);
  const [reports, setReports] = useState([]);
  const [dateType, setDateType] = useState(DateType);
  const [getData, setgetData] = useState(GetData);
  const [dateRange, setDateRange] = useState(DateRange);

  console.log(dateType);

  const handelExport = (event) => {
    pdfExportComponent.current.save();
  };

  useEffect(() => {
    if (buttonClick != null) {
      handelExport();
    }
  }, [buttonClick]);

  const pdfExportComponent = useRef();

  const radioButtonValues = reports.filter((obj) => obj.type === "radioButton");
  const smileyScaleValues = reports.filter((obj) => obj.type === "smileyScale");
  const starRatingValues = reports.filter((obj) => obj.type === "starRating");
  const Nps = reports.filter((obj) => obj.type === "Nsp");

  useEffect(() => {
    const Api = async () => {
      if ((dateRange !== "") & (dateRange !== undefined)) {
        const { data } = await axios.post("/report", {
          label: dateType,
          id: id,
          fromDate: dateRange[0],
          toDate: dateRange[1],
        });

        if (data.success) {
          setReports(data.feedbackCount);
          setMultiSelectValue(data.multiSelect);
          setgetData(false);
        }
      } else {
        const { data } = await axios.post("/report", {
          label: dateType,
          id: id,
        });

        if (data.success) {
          setReports(data.feedbackCount);
          setMultiSelectValue(data.multiSelect);
          setgetData(false);
          setDateRange("");
        }
      }
    };
    Api();
  }, [id, getData, dateType, dateRange]);

  useEffect(() => {
    const Api = async () => {
      const { data } = await axios.post("/getParticularEvent", {
        shortID: id,
      });

      setListOfQuestion(data?.[0]?.list_of_items);
      return setEventDetails(data);
    };
    Api();
  }, [id]);

  const RadioButton = (con, id) => {
    const totalCount = radioButtonValues?.reduce((accumulator, item) => {
      if (item?.uuid === con && item?.answer === id) {
        accumulator += item?.count;
      }
      return accumulator;
    }, 0);

    return totalCount;
  };

  const StarRating = (con, id) => {
    const totalCount = starRatingValues?.reduce((accumulator, item) => {
      if (item?.uuid === con && item?.answer === id) {
        accumulator += item?.count;
      }
      return accumulator;
    }, 0);

    return totalCount;
  };

  const MultiPle = (con, id) => {
    const totalCount = multiSelectValue?.reduce((accumulator, item) => {
      if (item?.uuid === con && item?.answer == id.toString()) {
        accumulator += item?.count;
      }
      return accumulator;
    }, 0);

    return totalCount;
  };

  const Nsp = (con, id) => {
    const totalCount = Nps?.reduce((accumulator, item) => {
      if (item?.uuid === con && item?.answer === id) {
        accumulator += item?.count;
      }
      return accumulator;
    }, 0);

    return totalCount;
  };

  const SmilyScale = (con, id) => {
    const totalCount = smileyScaleValues?.reduce((accumulator, item) => {
      if (item.uuid === con && item.answer === id) {
        accumulator += item?.count;
      }
      return accumulator;
    }, 0);

    return totalCount;
  };
  return (
    <div>
      <div
        className={`w-full ${
          mood === "light" ? `bg-[#FAFAFA]` : "bg-[#161A25]"
        } `}
      >
        <PDFExport ref={pdfExportComponent} paperSize="A2">
          <div
            className={`logoBar py-3 border-b ${
              mood === "light"
                ? `bg-black border-[#E4E4E7]`
                : "bg-[#161A25] border-gray"
            } `}
          >
            <div className={`px-4 mx-auto w-[95%] 3xl:max-w-7xl sm:px-2 py-1`}>
              <div className="flex items-center w-full justify-center xs:flex-col sm:flex-col ms:flex-col lg:flex-row xl:flex-row">
                <div className="my-2">
                  <>
                    <img
                      className="block w-auto h-8 lg:hidden"
                      src={logoDark}
                      alt=""
                    />
                    <img
                      className="hidden w-auto h-8 lg:block"
                      src={logoDark}
                      alt=""
                    />
                  </>
                </div>
              </div>
            </div>
          </div>
          <div className="py-10">
            <div
              className="w-[90%] flex items-center justify-between mx-auto bg-[white] border-2 border-[#E4E4E7] rounded-xl
                     py-7 px-5"
            >
              <div className="flex">
                <div className="text-start">
                  <p className="uppercase text-[#71717A] text-[14px]  ">
                    Event Name
                  </p>
                  <p className="capitalize mt-1   font-[600] text-[14px] text-[#71717A]">
                    {eventDetails?.[0]?.eventName}
                  </p>
                </div>
                <div className="ml-12 text-center">
                  <p className="uppercase text-[#71717A] text-[14px]">
                    Question
                  </p>
                  <p className="capitalize mt-1   font-[600] text-[14px] text-[#71717A]">
                    {eventDetails?.[0]?.no_of_question}
                  </p>
                </div>
                <div className="ml-12 text-center">
                  <p className="uppercase text-[#71717A] text-[14px]">
                    Feedback Count
                  </p>
                  <p className="capitalize mt-1   font-[600] text-[14px] text-[#71717A]">
                    {eventDetails?.[0]?.feedback_count}
                  </p>
                </div>
              </div>
            </div>
          </div>

          {listOfQuestion?.map((i, index) => (
            <>
              {i?.type === "smileyScale" && (
                <div className="py-10">
                  <div className="w-[90%] flex flex-col mx-auto bg-[white] border-2 border-[#E4E4E7] rounded-xl py-7 px-7">
                    <div className="flex items-center">
                      {/* <p>{index?.length > 9 ? index : "0" + index}</p> */}
                      <BsEmojiSmile
                        size={18}
                        color="#4F46E5"
                        className="ml-2"
                      />
                      <p className="font-inter text-[#1F1F1F] ml-2">
                        Smiley scale
                      </p>
                    </div>
                    <p className="font-inter text-[#71717A] my-2">
                      {i?.question}
                    </p>
                    <div className="flex mt-5 gap-x-8">
                      <div className="flex flex-col items-center">
                        <span>{svg1({ size: "80px", color: "#4F46E5" })}</span>

                        <button className="flex items-center bg-[#4F46E5] py-1 px-3 rounded-md text-white font-inter mt-2 gap-2">
                          <AiOutlineUser size={18} color="white" />
                          {SmilyScale(i?.uuid, "1")}
                        </button>
                      </div>
                      <div className="flex flex-col items-center">
                        <span>{svg2({ size: "80px", color: "#4F46E5" })}</span>

                        <button className="flex items-center bg-[#4F46E5] py-1 px-3 rounded-md text-white font-inter mt-2 gap-2">
                          <AiOutlineUser size={18} color="white"/>
                          {SmilyScale(i?.uuid, "2")}
                        </button>
                      </div>
                      <div className="flex flex-col items-center">
                        <span>{svg3({ size: "80px", color: "#4F46E5" })}</span>
                        <button className="flex items-center bg-[#4F46E5] py-1 px-3 rounded-md text-white font-inter mt-2 gap-2">
                          <AiOutlineUser size={18} color="white"/>
                          {SmilyScale(i?.uuid, "3")}
                        </button>
                      </div>
                      <div className="flex flex-col items-center">
                        <span>{svg4({ size: "80px", color: "#4F46E5" })}</span>

                        <button className="flex items-center bg-[#4F46E5] py-1 px-3 rounded-md text-white font-inter mt-2 gap-2">
                          <AiOutlineUser size={18} color="white"/>
                          {SmilyScale(i?.uuid, "4")}
                        </button>
                      </div>
                      <div className="flex flex-col items-center">
                        <span>{svg5({ size: "80px", color: "#4F46E5" })}</span>

                        <button className="flex items-center bg-[#4F46E5] py-1 px-3 rounded-md text-white font-inter mt-2 gap-2">
                          <AiOutlineUser size={18} color="white"/>
                          {SmilyScale(i?.uuid, "5")}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {i?.type === "starRating" && (
                <div className="py-10">
                  <div className="w-[90%] flex flex-col mx-auto bg-[white] border-2 border-[#E4E4E7] rounded-xl py-7 px-7">
                    <div className="flex items-center">
                      {/* <p>{index?.length > 9 ? index : "0" + index}</p> */}

                      <BsStar size={18} color="#4F46E5" className="ml-2" />
                      <p className="font-inter text-[#1F1F1F] ml-2">
                        Rating scale
                      </p>
                    </div>
                    <p className="font-inter text-[#71717A] my-2">
                      {i?.question}
                    </p>
                    <div className="flex mt-5 gap-x-8">
                      {i?.answer?.map((ans) => (
                        <div
                          className="flex flex-col items-center"
                          key={ans?.id}
                        >
                          <img src={star} alt="" />
                          <p className="font-inter text-[#71717A] my-1">
                            {ans?.value}
                          </p>
                          <button className="icon_alignment">
                            <AiOutlineUser size={18} color="white" />
                            {StarRating(i?.uuid, ans?.id)}
                          </button>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}

              {i.type === "radioButton" && (
                <div className="py-10">
                  <div className="w-[90%] flex flex-col mx-auto bg-[white] border-2 border-[#E4E4E7] rounded-xl py-7 px-7">
                    <div className="flex items-center">
                      {/* <p>{index?.length > 9 ? index : "0" + index}</p> */}

                      <BsRecordCircle
                        size={18}
                        color="#4F46E5"
                        className="ml-2"
                      />
                      <p className="font-inter text-[#1F1F1F] ml-2">
                        Single answer selection
                      </p>
                    </div>
                    <p className="font-inter text-[#71717A] my-2">
                      {i?.question}
                    </p>
                    {i?.answer?.map((ans) => (
                      <div
                        className="flex flex-col gap-y-3 mt-5 gap-x-8"
                        key={ans?.id}
                      >
                        <div className="flex items-center">
                          <button
                            className="text-[#1F1F1F] font-inter py-3 px-3 border border-[#E4E4E7] rounded-md bg-[#FAFAFA] w-[400px] 
                text-start break-words"
                          >
                            {ans?.value}
                          </button>
                          <button className="icon_alignment">
                            <AiOutlineUser size={18} color="white" />
                            {RadioButton(i?.uuid, ans?.id)}
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
              {i?.type === "multiSelect" && (
                <div className="py-10">
                  <div className="w-[90%] flex flex-col mx-auto bg-[white] border-2 border-[#E4E4E7] rounded-xl py-7 px-7">
                    <div className="flex items-center">
                      {/* <p>{index?.length > 9 ? index : "0" + index}</p> */}

                      <BsCheckCircle
                        size={18}
                        color="#4F46E5"
                        className="ml-2"
                      />
                      <p className="font-inter text-[#1F1F1F] ml-2">
                        Multiple answer selection
                      </p>
                    </div>

                    <p className="font-inter text-[#71717A] my-2">
                      {i?.question}
                    </p>
                    {i?.answer?.map((ans, idx) => (
                      <div className="flex flex-col gap-y-3 mt-5 gap-x-8">
                        <div className="flex items-center">
                          <button
                            className="text-[#1F1F1F] font-inter py-3 px-3 border border-[#E4E4E7] rounded-md bg-[#FAFAFA] w-[400px] 
                  text-start break-words"
                          >
                            {ans?.value}
                          </button>
                          <button className="icon_alignment">
                            <AiOutlineUser size={18} color="white" />
                            {MultiPle(i?.uuid, idx + 1)}
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
              {i?.type === "Nsp" && (
                <div className="py-10">
                  <div className="w-[90%] flex flex-col mx-auto bg-[white] border-2 border-[#E4E4E7] rounded-xl py-7 px-7">
                    <div className="flex items-center">
                      {/* <p>{index?.length > 9 ? index : "0" + index}</p> */}

                      <BsSpeedometer2
                        size={18}
                        color="#4F46E5"
                        className="ml-2"
                      />
                      <p className="font-inter text-[#1F1F1F] ml-2">NSP</p>
                    </div>
                    <p className="font-inter text-[#71717A] my-2">
                      {i?.question}
                    </p>
                    <div className="flex mt-5 gap-x-8">
                      <div className="flex flex-col items-center">
                        <p className="font-inter text-[#3d3d52] my-1 p-2 px-4 mb-2 rounded-md bg-[#F7F7F7]">
                          1
                        </p>
                        <button className="icon_alignment">
                          <AiOutlineUser size={18} color="white" />
                          {Nsp(i?.uuid, "1")}
                        </button>
                      </div>
                      <div className="flex flex-col items-center">
                        <p className="font-inter text-[#71717A] my-1 p-2 px-4 mb-2 rounded-md bg-[#F7F7F7]">
                          2
                        </p>
                        <button className="icon_alignment">
                          <AiOutlineUser size={18} color="white" />
                          {Nsp(i?.uuid, "2")}
                        </button>
                      </div>
                      <div className="flex flex-col items-center">
                        <p className="font-inter text-[#71717A] my-1 p-2 px-4 mb-2 rounded-md bg-[#F7F7F7]">
                          3
                        </p>
                        <button className="icon_alignment">
                          <AiOutlineUser size={18} color="white" />
                          {Nsp(i?.uuid, "3")}
                        </button>
                      </div>
                      <div className="flex flex-col items-center">
                        <p className="font-inter text-[#71717A] my-1 p-2 px-4 mb-2 rounded-md bg-[#F7F7F7]">
                          4
                        </p>
                        <button className="icon_alignment">
                          <AiOutlineUser size={18} color="white" />
                          {Nsp(i?.uuid, "4")}
                        </button>
                      </div>
                      <div className="flex flex-col items-center">
                        <p className="font-inter text-[#71717A] my-1 p-2 px-4 mb-2 rounded-md bg-[#F7F7F7]">
                          5
                        </p>
                        <button className="icon_alignment">
                          <AiOutlineUser size={18} color="white" />
                          {Nsp(i?.uuid, "5")}
                        </button>
                      </div>
                    </div>
                    <div className="flex mt-5 gap-x-8">
                      <div className="flex flex-col items-center">
                        <p className="font-inter text-[#71717A] my-1 p-2 px-4 mb-2 rounded-md bg-[#F7F7F7]">
                          6
                        </p>
                        <button className="icon_alignment">
                          <AiOutlineUser size={18} color="white" />
                          {Nsp(i?.uuid, "6")}
                        </button>
                      </div>
                      <div className="flex flex-col items-center">
                        <p className="font-inter text-[#71717A] my-1 p-2 px-4 mb-2 rounded-md bg-[#F7F7F7]">
                          7
                        </p>
                        <button className="icon_alignment">
                          <AiOutlineUser size={18} color="white" />
                          {Nsp(i?.uuid, "7")}
                        </button>
                      </div>
                      <div className="flex flex-col items-center">
                        <p className="font-inter text-[#71717A] my-1 p-2 px-4 mb-2 rounded-md bg-[#F7F7F7]">
                          8
                        </p>
                        <button className="icon_alignment">
                          <AiOutlineUser size={18} color="white" />
                          {Nsp(i?.uuid, "8")}
                        </button>
                      </div>
                      <div className="flex flex-col items-center">
                        <p className="font-inter text-[#71717A] my-1 p-2 px-4 mb-2 rounded-md bg-[#F7F7F7]">
                          9
                        </p>
                        <button className="icon_alignment">
                          <AiOutlineUser size={18} color="white" />
                          {Nsp(i?.uuid, "9")}
                        </button>
                      </div>
                      <div className="flex flex-col items-center">
                        <p className="font-inter text-[#71717A] my-1 p-2 px-4 mb-2 rounded-md bg-[#F7F7F7]">
                          10
                        </p>
                        <button className="icon_alignment">
                          <AiOutlineUser size={18} color="white" />
                          {Nsp(i?.uuid, "10")}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          ))}
        </PDFExport>
      </div>
    </div>
  );
};

export default ReportPateForExport;
