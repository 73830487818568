import React, { useEffect, useState } from "react"

import { Line } from "rc-progress"
import axios from "../../axios"
import ReactCount from "react-countup"
import { useSelector } from "react-redux"
import { Toaster, toast } from "react-hot-toast"
import CustomDotLoader from "../CustomDotLoader"


const TopEvent = () => {
    const { user } = useSelector((state) => state.userAuth)

    const [topEventList, settopEventList] = useState([])
    const [value, setValue] = useState(false)

    useEffect(() => {
        const Api = async () => {
            if (user) {

                const { data } = await axios.post("/topEvent", {
                    id: user.id
                })

                const percentages = data.map((item) => {
                    return {
                        name: item.name,
                        count: item.count,
                        percentage: Math.round((item.count / data.reduce((acc, curr) => acc + curr.count, 0)) * 100)
                    }
                })

                settopEventList(percentages)
                setValue(true)

                if (user?.PaymentPaid === "0" && percentages?.[0]?.count > 999) {
                    await axios.post("/edit/event", {
                        id: localStorage.getItem("EVENTID")
                    })
                    return toast.error(
                        "Upgrade your account for free You have reached the limit and must now delete your Surveys or upgrade your account."
                    )
                }
            }
        }

        Api()
    }, [user])

    return (
        <div className=" col-span-1">
            <div
                style={{ boxShadow: "0px 2.37037px 8px rgba(0, 0, 0, 0.06)" }}
                className="border border-[#E4E4E7] overflow-hidden bg-white rounded-xl  max-h-[550px] h-[550px]"
            >
                <div className="px-4 py-5 sm:p-6">
                    <div className="sm:flex sm:items-center sm:justify-between">
                        <p className="text-[20px] font-semibold text-[#737373] lg:order-1">Top 5 Surveys</p>
                    </div>

                    <div className="mt-8 space-y-4 relative">
                        {value === false && (
                            <>
                                <div className="w-full h-full flex items-center justify-center absolute top-10">
                                <CustomDotLoader />
                                </div>
                            </>
                        )}
                        {topEventList.length === 0 && value === true && (
                            <>
                                <p className=" capitalize text-center text-gray"> Do not have any event 🤨 </p>
                            </>
                        )}
                        {topEventList.map((i, index) => (
                            <>
                                <div
                                    key={index}
                                    className="flex items-center justify-between"
                                >
                                    <p className="text-sm font-medium text-[#436073]">{i.name}</p>
                                    <ReactCount
                                        duration={2}
                                        end={i.count}
                                        className="text-sm font-medium text-[#436073]"
                                    />
                                </div>
                                <div className="">
                                    <Line
                                        percent={i.percentage}
                                        strokeWidth={2}
                                        strokeColor="#4F46E5"
                                    />
                                </div>
                            </>
                        ))}
                    </div>
                </div>
            </div>
            <Toaster
                position="top-right"
                reverseOrder={false}
            />
        </div>
    )
}

export default TopEvent
