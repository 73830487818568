import React, { useState } from "react"
import ManageSubscription from "./ManageSubscription"
import MyPlan from "./MyPlan"

const Purchase = () => {
    const [isManageSub, setIsManageSub] = useState(false)
    const [planDetails, setplanDetails] = useState()

    return !isManageSub ? (
        <MyPlan
            setManageComponentActive={setIsManageSub}
            setplanDetails={setplanDetails}
        />
    ) : (
        <ManageSubscription
            isComponentActive={setIsManageSub}
            planDetails={planDetails}
        />
    )
}

export default Purchase
