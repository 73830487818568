import React, { useState } from "react";
import Switch from "react-switch";
import axios from "../../axios";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";

const PlansModal = () => {
  const [isYearlyPlan, setIsYearlyPlan] = useState(true);
  const { user } = useSelector((state) => state.userAuth);

  const handelPayment = async () => {
    if (user) {
      const { data } = await axios.post("/phonepe", {
        mobileNumber: user.phone_no,
        id: user.id,
        plan: "OneTime",
      });
      if (data.success) {
        const url = data.data.instrumentResponse.redirectInfo.url;
        if (url) {
          window.location.href = url;
        }
      }
    } else {
      return toast.error("Log in and make your payment, please!");
    }
  };

  const features = [
    "Unlimited responses",
    "Unlimited Surveys",
    "Unlimited questions",
    "Unlimited email",
    "15 days free trial",
    "No credit card required",
    "Dashboards & reports",
    "Support assistance",
  ];

  return (
    <div className=" fixed top-0 left-0 w-screen h-screen flex justify-center items-center z-[70]  bg-[#00000042]">
      <div className="bg-[#fafafa] relative flex flex-col h-[95%] max-w-[40rem] max-h-[600px] w-[50%] mx-auto rounded-lg p-8">
        {/* <ModalCloseBtn /> */}

        <h1 className=" text-3xl font-bold text-center">
          All features for one special price.
        </h1>

        <div className="ms:flex items-center scale-75 mx-auto text-center w-full justify-center mt-4">
          <div className="flex justify-center ms:justify-start">
            {/* <h6 className={`!text-base ${isYearlyPlan && "text-[gray]"}`}>
              Monthly Plan
            </h6> */}
            {/* switch */}
            {/* <Switch
              // styles={{
              //     track: {
              //         width: "50px",
              //         height: "30px",
              //         borderRadius: "30px"
              //     },
              //     trackChecked: {
              //         backgroundColor: "#5046E5"
              //     },
              //     button: {
              //         width: "25px",
              //         height: "25px",
              //         borderRadius: "100%"
              //     },
              //     buttonChecked: {
              //         left: "22px"
              //     }
              // }}
              checkedIcon={false}
              uncheckedIcon={false}
              className="react-switch ml-4"
              onColor="#4F46E5"
              onHandleColor="#fff"
              handleDiameter={20}
              // className="mx-4"
              checked={isYearlyPlan}
              // onChange={() => setIsYearlyPlan((prev) => !prev)}
            /> */}
          </div>

          <div className="flex justify-center ms:justify-start">
            <h6 className={`text-2xl l2:text-4xl font-bold text-center text-blue ml-5 ${!isYearlyPlan && "text-[gray]"}`}>
            Life Time Plan
            </h6>
            <a
              className="font-[600] font-[poppins] ml-3 !text-base text-primary"
              href=" "
            >
            </a>
          </div>
        </div>

        <div className="w-full mt-6 flex flex-wrap justify-center mx-0 relative">
          <div
            style={{
              boxShadow:
                "0px 6px 14px -6px rgba(24, 39, 75, 0.12), 0px 10px 32px -4px rgba(24, 39, 75, 0.1)",
            }}
            className=" relative z-0  w-[95%]  mx-auto lg:mx-0 bg-[#4f46e5] border-2 border-black rounded-lg "
          >
            {/* <span className="ribbon"></span> */}
            <div className=" p-4 bg-[#4f46e5] border-b border-black overflow-hidden">
              <h6 className="text-[#fff] text-lg font-semibold l3:text-xl">
                Spark
              </h6>
              <p className="text-[#fff] text-sm l3:text-base mt-1">
                One plan for all the your business needs.
              </p>
            </div>
            <div className=" p-4 ">
              <div className="flex items-center">
                <span className="font-[poppins] text-4xl l3:text-[48px] font-[600] text-[#fff]">
                  ₹ {!isYearlyPlan ? "3,999" : "39,999"}
                </span>
                <p className="ml-2 text-[14px] font-[poppins] text-[#fff]">
                  /{isYearlyPlan ? "oneTime" : "month"}
                </p>
              </div>

              <button
                onClick={() => handelPayment(!isYearlyPlan ? 3999 : 39999)}
                className="secondaryButton hover:!text-blue hover:bg-white hover:ring-2 hover:!ring-white w-full my-6"
              >
                Subscribe
              </button>
              <div>
                <span className="text-[#fff] font-[600]">SPARK FEATURES</span>
                <div className="grid grid-cols-2 gap-3 mt-2">
                  {features.map((itm) => (
                    <div key={itm} className="flex items-center">
                      <div>
                        <i class="fa-solid fa-check text-primary text-[9px] bg-white rounded-full p-[4px]"></i>
                      </div>
                      <p className="ml-2 mb-[-6px] font-[poppins] text-[16px] text-[#fff]">
                        {itm}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            {/* <div className="absolute top-0 left-[-380px] w-[400px] h-[630px] z-50">
                        <img
                            src={cardImage}
                            alt="man holding phone illustration"
                            className=" w-full h-full object-cover"
                        />
                    </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlansModal;
