/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import axios from "../../../axios";
import toast, { Toaster } from "react-hot-toast";
import logo from "../../../assets/logo1.svg";
import CustomDotLoader from "./../../../components/CustomDotLoader/index";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import {
  addButtonColor,
  addCompanyLogo,
  addTextColor,
  addWelcomeBg,
  addWelcomeTextColor,
} from "../../../redux/slices/globalDefine";
import { setUserValue } from "../../../redux/slices/auth";
import config from "../../../constants";
import { useDispatch } from "react-redux";

// ** import components
import FadeInTransition from "../../../components/FadeInTransition";

const SocialLoginStep = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isChecked, setIsChecked] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [loader, setloader] = useState(false);
  const [searchParams] = useSearchParams();
  const ID = searchParams.get("id");

  localStorage.setItem("initialLogin", true);
  const handelClick = async () => {
    if (ID !== null) {
      if (isChecked === true) {
        setloader(true);
        const { data } = await axios.post("/firstSocialLogin", {
          id: ID,
          phoneNumber: phoneNumber,
          company_name: companyName,
        });
        if (data.success) {
          const response = await fetch(`${config.SERVER_URL}/success`, {
            credentials: "include",
          });
          const data = await response.json();

          if (data.user.verify) {
            localStorage.setItem("UserID", data.user.id);

            dispatch(setUserValue(data.user));

            // dispatch(
            //   addCompanyLogo(
            //     data?.[0]?.company_logo !== undefined &&
            //       data[0].company_logo.length !== 0
            //       ? data[0].company_logo
            //       : null
            //   )
            // );
            // dispatch(
            //   addWelcomeBg(
            //     data[0].welcome_bg_logo !== undefined &&
            //       data[0].welcome_bg_logo.length !== 0
            //       ? data[0].welcome_bg_logo
            //       : null
            //   )
            // );
            // dispatch(
            //   addButtonColor(
            //     data[0].btn_color !== undefined &&
            //       data[0].btn_color.length !== 0
            //       ? data[0].btn_color
            //       : "#4F46E5"
            //   )
            // );
            // dispatch(
            //   addTextColor(
            //     data[0].text_color !== undefined &&
            //       data[0].text_color.length !== 0
            //       ? data[0].text_color
            //       : "#D9D9D9"
            //   )
            // );
            // dispatch(
            //   addWelcomeTextColor(
            //     data[0].welcome_screen_color !== undefined &&
            //       data[0].welcome_screen_color.length !== 0
            //       ? data[0].welcome_screen_color
            //       : "#000000"
            //   )
            // );

            setloader(false);
            setIsChecked(false);

            navigate("/");

            return;
          }
        }
      } else {
        toast.error("Agree to terms and conditions");
        setloader(false);

        return;
      }
    } else {
      toast.error("ID not found");
      setloader(false);

      return;
    }
  };

  return (
    <div className="max-h-[100%] overflow-hidden py-20">
      <div className="fixed bottom-0 left-0 w-full border-b-[10px] border-[#4F46E5] z-50"></div>
      <div
        style={{ boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.15)" }}
        className="py-5 w-full !z-50 absolute top-0"
      >
        <a href="https://www.feedbacksync.com" alt="feedback">
          <img className="w-[175px] block mx-auto" src={logo} alt="" />
        </a>
      </div>
      <FadeInTransition>
        <section className="bg-[#FAFAFA] !mt-[80px] w-full">
          <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
            <div className="relative max-w-md mx-auto lg:max-w-lg">
              <div className="absolute -inset-2">
                {/* <div
                className="w-full h-full mx-auto rounded-3xl opacity-30 blur-lg filter"
                style={{"background: linear-gradient(90deg, #44ff9a -0.55%, #44b0ff 22.86%, #8b44ff 48.36%, #ff6644 73.33%, #ebff70 99.34%"}}
              ></div> */}
              </div>

              <>
                <div
                  style={{
                    boxShadow: "0px 0px 61.3082px rgba(0, 0, 0, 0.15)",
                  }}
                  className="bg-[white] min-h-[400px] relative overflow-hidden rounded-[16px]"
                >
                  <div className="px-4 py-6 sm:px-8">
                    <h1
                      className="font-bold font-pj text-center text-[#18181B] text-[21px] hover:cursor-pointer"
                      // onClick={() => setMagic(true)}
                    >
                      Fill the details
                    </h1>

                    <div className="space-y-5 mt-[30px]">
                      <div>
                        <label
                          for=""
                          className="text-[14px] text-[#18181B] font-semibold text-gray-900 font-pj"
                        >
                          Company name
                        </label>
                        <div className="mt-1">
                          <input
                            required
                            type="Text"
                            name="company"
                            placeholder=""
                            className="block w-full px-4 py-4 text-gray-900 placeholder-gray-600 bg-white border border-[#A1A1AA] rounded-[10px] focus:border-gray-900 focus:ring-gray-900 caret-gray-900"
                            onChange={(e) => setCompanyName(e.target.value)}
                          />
                        </div>
                      </div>

                      <div>
                        <label
                          for=""
                          className="text-[14px] text-[#18181B] font-semibold text-gray-900 font-pj"
                        >
                          {" "}
                          Phone no{" "}
                        </label>
                        <div className="mt-1">
                          <PhoneInput
                            placeholder="Phone number"
                            value={phoneNumber}
                            className=" customInputSize py-1 block w-full bg-white  text-base text-black border border-[#D4D4D8] rounded-lg focus-within:border-[#4F46E5]"
                            onChange={setPhoneNumber}
                            name="phone"
                            enableSearch={true}
                            country={"in"}
                            defaultCountry={"in"}
                          />
                        </div>
                      </div>

                      <div className="relative items-center mt-4 flex justify-between">
                        <div className="flex">
                          <div className="flex items-center h-5">
                            <input
                              required
                              type="checkbox"
                              name="terms"
                              id="terms"
                              value={isChecked}
                              onChange={(e) => {
                                setIsChecked(e.target.checked);
                              }}
                              className="w-5 h-5 text-gray-900 border-gray-300 rounded focus:ring-gray-900"
                            />
                          </div>

                          <div className="ml-3 text-base">
                            <label
                              for="terms"
                              className="text-[#18181B] font-[16px] font-pj"
                            >
                              I agree to{" "}
                              <a
                                href="https://www.feedbacksync.com/privacy-policy"
                                target="_blank"
                                rel="noreferrer"
                                className="text-blue underline"
                              >
                                Privacy Policy
                              </a>{" "}
                              &{" "}
                              <a
                                href="https://www.feedbacksync.com/terms-conditions"
                                target="_blank"
                                rel="noreferrer"
                                className="text-blue underline"
                              >
                                Terms and Conditions
                              </a>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className=" mt-6">
                      {!loader && (
                        <button
                          onClick={handelClick}
                          type="submit"
                          className="flex items-center justify-center w-full px-8 h-[50px] max-h-[50px] mt-5 text-base font-bold text-white transition-all duration-200 bg-[#4F46E5] border border-transparent hover:border-[#4F46E5] hover:!bg-white hover:text-[#4F46E5] rounded-xl focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray  hover:bg-grayDark"
                        >
                          Continue
                        </button>
                      )}
                      {loader && (
                        <button
                          type="submit"
                          className="flex items-center justify-center w-full px-8 h-[50px] max-h-[50px] mt-5 text-base font-bold text-white transition-all duration-200 bg-[#4F46E5] border border-transparent hover:border-[#4F46E5] hover:!bg-white hover:text-[#4F46E5] rounded-xl focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray  hover:bg-grayDark"
                        >
                          <CustomDotLoader dotSize={70} isWhite />
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </>
            </div>
          </div>
        </section>
      </FadeInTransition>

      <Toaster position="top-right" reverseOrder={false} />
    </div>
  );
};

export default SocialLoginStep;
