import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  mood: 'light',
};

const moodSlice = createSlice({
  name: "mood",
  initialState,
  reducers: {
    setMoodValue(state, action) {
      state.mood = action.payload;
    },
  },
});

export const { setMoodValue } = moodSlice.actions;
export default moodSlice.reducer;
