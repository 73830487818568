/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useRef, useLayoutEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setEventValue } from "../../redux/slices/eventName";
import axios from "../../axios";
import toast, { Toaster } from "react-hot-toast";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import failed from "../../assets/failed.png";
import {
  bgImageDetails,
  logoDetails,
  questionEmpty,
} from "../../redux/slices/questions";
import { AiOutlineClose } from "react-icons/ai";
import { MdContentCopy, MdQrCodeScanner } from "react-icons/md";
import { BsEmojiSmile, BsEmojiSmileFill, BsLink45Deg } from "react-icons/bs";
import { FiDownload } from "react-icons/fi";
import QRcode from "../qr-code";
import Emoji from "react-emojis";
import { logOut, setUserValue } from "../../redux/slices/auth";
import { remove } from "../../redux/slices/feedbackValue";
import { removeLogoValue } from "../../redux/slices/qrCodeLogo";
import CampaignCreatedPopup from "./CampaignCreatedPopup";
import { setClear } from "../../redux/slices/bgColor";
import CustomDotLoader from "./../CustomDotLoader/index";
import { setEmailTemplateValue } from "../../redux/slices/emailTemplate";
// website tour

const { v4: uuidv4 } = require("uuid");

const EventName = ({
  show,
  hide,
  status,
  setInput,
  inputValue,
  id,
  eventNames,
  changeId,
  messsage,
  selectName,
  list,
  preview,
  setActionStatus,
  toogle,
  condition,
  trigger,
  payment,
  onPressMenu,
  move,
  clearStatement,
  setrerender,
  qrTemplate,
}) => {
  const navigate = useNavigate();
  const { eventName } = useSelector((state) => state.eventName);
  const { logo } = useSelector((state) => state.qrLogo);
  const { mood } = useSelector((state) => state.mood);
  const { user } = useSelector((state) => state.userAuth);
  const userToken = localStorage.getItem("Token");
  const { questionValues } = useSelector((state) => state.questions);
  const { emailTemplate } = useSelector((state) => state.emailTemplate);
  const { eventId } = useParams();
  const dispatch = useDispatch();
  const close = () => {
    return hide(false);
  };
  const [name, setName] = useState("");
  const [download, setdownload] = useState(false);
  const [loader, setLoader] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [qrTemp, setQrTemp] = useState(qrTemplate);
  const { questions } = useSelector((state) => state);
  const logoInfo = questions.logoDetails;
  const bgInfo = questions.bgImageDetails;
  const [searchParams] = useSearchParams();
  const ID = searchParams.get("id");
  const [loaderSave, setloaderSave] = useState(false);

  const Save = async () => {
    setloaderSave(true);

    let list_of_items = questionValues.map((i) => {
      if (i.uuid !== undefined) {
        return {
          id: i.id.toString(),
          question: i.question,
          type: i.type,
          answer: i.choice,
          uuid: i.uuid,
          subHeading: i.subHeading,
        };
      } else {
        return {
          id: i.id.toString(),
          question: i.question,
          type: i.type,
          answer: i.choice,
          subHeading: i.subHeading,
        };
      }
    });
    for (const obj of list_of_items) {
      if (obj.type === "welcome") {
        obj.answer = [];
      }
    }

    for (let i = 0; i < list_of_items.length; i++) {
      if (list_of_items[i].question.trim() === "") {
        toast.error(`Please fill data`);
        setloaderSave(false);
        return;
      }
    }
    const filter = list_of_items.filter(
      (i) => i.type !== "welcome" && i.type !== "contact"
    );
    const answerFilter = filter.map((i) => ({ answer: i.answer, id: i.id }));

    for (let i = 0; i < answerFilter.length; i++) {
      for (let j = 0; j < answerFilter[i].answer.length; j++) {
        if (answerFilter[i].answer[j].value.trim() === "") {
          toast.error(`Please fill data`);
          setloaderSave(false);
          return;
        }
      }
    }

    if (list_of_items.length === 0) {
      status = "messing";
      show = true;
      return 0;
    } else {
      const data = await axios.post("/add/event", {
        logoId: logoInfo.length === 0 ? "" : logoInfo?.[0]?.id,
        logoStatus: logoInfo.length === 0 ? "" : logoInfo[0]?.status,
        bgId: bgInfo.length === 0 ? "" : bgInfo[0]?.id,
        bgStatus: bgInfo.length === 0 ? "" : bgInfo[0]?.status,
        feedback_count: 0,
        qrCode: "",
        share_link: "",
        event_owner: user?.id,
        eventName: eventName,
        no_of_question: questionValues.length,
        list_of_items: list_of_items,
        action: "yes",
        buttonColor:
          questions.buttonColor === null ? "#4F46E5" : questions.buttonColor,
        backgroundColor: questions.backgroundColor,
        textColor: questions.textColor === null ? "#000" : questions.textColor,
        welcomeColor:
          questions.welcomeScreenTextColor === null
            ? "#000"
            : questions.welcomeScreenTextColor,
        id: localStorage.getItem("uuid"),
      });

      if (data.status === 201 && data.data.success) {
        setloaderSave(false);
        dispatch(setClear());
        localStorage.removeItem("BGColor");
        localStorage.removeItem("TextColor");
        const datas = await axios.post("/getParticularUser", {
          id: user.id,
        });
        dispatch(setUserValue(datas.data[0]));
        localStorage.setItem("Oldquestion", JSON.stringify(list_of_items));
        if (ID !== null) {
          const createNotification = await axios.post("/add-Notification-sa", {
            id: user.id,
            message: "User Created New Survey",
            pic: user?.profilePic,
          });
          if (createNotification.data.success) {
            console.log(createNotification.data);
          }
        }
        localStorage.removeItem("reRender");
        localStorage.removeItem("brandRender");
        navigate(`${selectName}`);
        return;
      } else {
        setloaderSave(false);
        status = "failed";
        show = true;
      }
      return 0;
    }
  };

  const handelDelete = async () => {
    if (user) {
      const { data } = await axios.delete(`/delete-account?id=${user.id}`);
      if (data.success) {
        toast.success(`${data.message}`);
        setTimeout(() => {
          navigate("/login");
          hide(false);
        }, 2000);
        return;
      }
    } else {
      return toast.error("User not found");
    }
  };

  const Submit = () => {
    dispatch(setEventValue(name));
  };
  const addEvent = () => navigate("/addQuestions");

  useEffect(() => {}, [eventName]);

  const active = async () => {
    const RandomNumber = `${Math.floor(1000 + Math.random() * 9000)}`;
    const { data } = await axios.post("/edit/event", {
      id: id,
    });
    if (data.success) {
      toast.success(`${data.message}`);
      changeId(id);
      setActionStatus(!toogle);
      trigger(RandomNumber);
      return;
    } else {
      setActionStatus(!toogle);
      trigger(RandomNumber);
      toast.error(`${data.message}`);
      return;
    }
  };

  const deleteEvent = async (i) => {
    setDeleteLoader(true);
    const { data } = await axios.post("/delete/event", {
      id: i,
    });
    if (data.success) {
      toast.error("Survey deleted");
      setDeleteLoader(false);
      trigger(i);
      close();
      return;
    } else {
      return setDeleteLoader(false);
    }
  };
  const clear = () => {
    dispatch(logoDetails([]));
    dispatch(bgImageDetails([]));
    dispatch(questionEmpty());
    dispatch(setEventValue("New Event"));
    localStorage.removeItem("reRender");
    localStorage.removeItem("brandRender");
  };

  const clearAll = async () => {
    dispatch(logOut());
    dispatch(remove());
    dispatch(questionEmpty());
    localStorage.setItem("SIGNIN", false);
    localStorage.removeItem("Signin Method");
    localStorage.removeItem("Token");
    localStorage.removeItem("UserID");
    localStorage.removeItem("uuid");

    close();
    navigate("/login");
  };
  const redirect = async (value) => {
    if (list) {
      if (userToken !== null) {
        const { data } = await axios.get("/userDetails", {
          headers: {
            Authorization: userToken,
          },
        });
        const datas = await axios.post("/user/event", {
          id: data[0].id,
        });
        const filter = datas.data.data.filter((i) => i.shortID === eventId);
        if (filter.length > 0) {
          return navigate("/events");
        } else {
          return window.location.replace("https://www.feedbacksync.com/");
        }
      } else {
        return window.location.replace("https://www.feedbacksync.com/");
      }
    }
    if (status === "clear" && selectName === undefined) {
      dispatch(questionEmpty());
      dispatch(setEventValue("New Event"));
      dispatch(logoDetails([]));
      dispatch(bgImageDetails([]));
      localStorage.removeItem("reRender");
      localStorage.removeItem("brandRender");
      return navigate("/events");
    }

    if (status === "clear") {
      dispatch(questionEmpty());
      dispatch(setEventValue("New Event"));
      dispatch(logoDetails([]));
      dispatch(bgImageDetails([]));
      localStorage.removeItem("reRender");
      localStorage.removeItem("brandRender");
      return navigate(selectName);
    }

    if (status === "close" && selectName === undefined) {
      dispatch(questionEmpty());
      dispatch(setEventValue("New Event"));
      // return navigate(
      //     payment === "account"
      //         ? "/account"
      //         : payment === "dashboard"
      //         ? "/"
      //         : payment === "events"
      //         ? "/events"
      //         : undefined
      // )
      dispatch(logoDetails([]));
      dispatch(bgImageDetails([]));
      localStorage.removeItem("reRender");
      localStorage.removeItem("brandRender");
      return navigate("/events");
    }
    if (value === "dashboard") {
      dispatch(questionEmpty());

      localStorage.removeItem("clearQuestion");
      dispatch(setEventValue("New Event"));
      return navigate("/");
    }
    if (value === "events") {
      dispatch(questionEmpty());
      localStorage.removeItem("clearQuestion");
      dispatch(setEventValue("New Event"));
      return navigate("/events");
    }
  };

  // const link = `${window.location.origin}/${preview}`;

  const removeQRLogo = async () => {
    const getTemplate = localStorage.getItem("qrcodeTemplate");

    if (logo !== null) {
      if (getTemplate !== null) {
        let pre = qrTemp;
        pre.logo = null;

        if (getTemplate !== null) {
          localStorage.setItem("qrcodeTemplate", JSON.stringify(pre));
        }
        dispatch(removeLogoValue());
        hide(false);
        setLoader(false);
        toast.success(`Successfully removed`);
      } else {
        dispatch(removeLogoValue());
        hide(false);
        setLoader(false);
        toast.success(`Successfully removed`);
      }
    } else {
      hide(false);
      clearStatement();
    }
  };

  useEffect(() => {
    setQrTemp((pre) => ({
      ...pre,
      logo: logo,
    }));
  }, [qrTemplate]);

  const saveQrTemplate = async () => {
    setLoader(true);
    localStorage.setItem("qrcodeTemplate", JSON.stringify(qrTemp));
    if (user !== null) {
      const { data } = await axios.post("/add-email-content", {
        id: user.id,
        subject: emailTemplate?.[0]?.subject,
        message: emailTemplate?.[0]?.message,
        bottomColor: qrTemp !== null ? qrTemp.brandColor : "#4F46E5",
      });
      if (data.success) {
        const datas = await axios.post("/get-particular-template", {
          id: user.id,
        });
        if (datas.data.success) {
          dispatch(setEmailTemplateValue(datas.data.data));
          setrerender(false);
          move(onPressMenu);
          close(false);
          toast.success("Uploaded Successfully");
          setLoader(false);
          return;
        }
      }
    }
    move(onPressMenu);
    close(false);
  };

  return (
    <div>
      {show && (
        <>
          <div
            id="popup-modal"
            tabIndex="-1"
            style={{
              backgroundColor: `${mood === "light" && "rgba(0, 0, 0, 0.3)"}`,
            }}
            className={`${
              mood !== "light" && "!bg-[#7f838f67]"
            } fixed flex items-center top-0 left-0 justify-center z-[99999]  right-auto w-[100vw] h-[100vh]`}
            aria-hidden="true"
          >
            <div className="relative p-4 w-full flex items-center justify-center">
              <div
                className={`${
                  mood === "light" ? "bg-white" : "bg-[#4b5368]"
                } relative rounded-lg shadow-2xl w-[400px]`}
              >
                {list && (
                  <>
                    <div className="p-6 text-center justify-center flex items-center flex-col">
                      <button
                        type="button"
                        className="absolute -top-2 -right-2 w-6 h-6 text-white bg-red rounded-full text-sm p-1 ml-auto inline-flex items-center justify-center  transition-all duration-300"
                        data-modal-toggle="popup-modal"
                        onClick={() => condition === null && redirect()}
                      >
                        {condition === "open" ? (
                          <a
                            href={
                              condition === "open" &&
                              "https://www.feedbacksync.com/"
                            }
                          >
                            <AiOutlineClose />
                          </a>
                        ) : (
                          <AiOutlineClose />
                        )}
                      </button>
                      <div className="px-3 text-center flex items-center flex-col">
                        {/* <img src={failed} className="w-[70px]" alt="" /> */}
                        <Emoji emoji="face-with-open-mouth" size={60} />
                        <div className="my-3 px-3 mt-3">
                          <p className="text-sm text-[#18181B] py-4">
                            Event Disabled, Please contact Administrator
                          </p>
                          <button
                            className={` focus:ring-4 focus:outline-none border !mx-auto px-8 block justify-center items-centermax-w-[45%] border-[lightBlue] text-white hover:!text-blue bg-lightBlue hover:bg-lightBlue font-medium rounded text-sm py-2 text-center hover:bg-transparent transition-all duration-300 dark:hover:border-blue`}
                            onClick={() => condition === null && redirect()}
                          >
                            {condition === "open" ? (
                              <a
                                href={
                                  condition === "open" &&
                                  "https://www.feedbacksync.com/"
                                }
                              >
                                Close
                              </a>
                            ) : (
                              "Close"
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {status === "addEvent" && (
                  <>
                    <div
                      className="p-6 text-center flex items-center flex-col w-full"
                      data-tut="second-step"
                    >
                      {/* <img src={smaily} className="w-[70px] pb-6" alt="" /> */}
                      <Emoji size={60} emoji="slightly-smiling-face" />

                      <p
                        className={`font-[600] mt-3 ${
                          mood === "light" ? "text-black" : "!text-white"
                        } font-inter`}
                      >
                        Create Surveys
                      </p>
                      <div className="my-3 w-full">
                        <input
                          type="text"
                          placeholder="Enter Survey name"
                          className="p-3 !py-2.5 w-full rounded outline-none bg-[#FAFAFA] border-gray border"
                          onChange={(e) => {
                            setName(e.target.value);
                          }}
                          value={name}
                        />
                      </div>
                      <div className="mt-2 flex gap-4 justify-between mx-auto w-full">
                        <button
                          onClick={close}
                          data-modal-toggle="popup-modal"
                          type="button"
                          // className={`hover:bg-transparent focus:ring-4 focus:outline-none rounded border ${
                          //     mood === "light"
                          //         ? "border-gray  text-[#8a8989]"
                          //         : "bg-[#303544] border-[#303544] text-white hover:bg-[#232734]"
                          // } text-sm font-medium py-2.5 bg-transparent transition-all duration-300 flex justify-center items-center w-[45%]`}
                          className=" secondaryButton w-1/2"
                        >
                          Cancel
                        </button>
                        <button
                          data-modal-toggle="popup-modal"
                          type="button"
                          // className={`focus:ring-4 focus:outline-none border flex justify-center items-center w-[45%] border-[#4F46E5] ${
                          //     mood === "light"
                          //         ? "text-white bg-blue "
                          //         : "bg-transperent border-[#303544] text-[#303544] hover:bg-[#232734] hover:!border-[#232734]"
                          // } font-medium rounded text-sm inline-flex items-center py-2.5 text-center  dark:hover:border-blue border`}
                          className=" primaryButton w-1/2 py-3"
                          onClick={() => {
                            if (name !== "") {
                              localStorage.setItem("uuid", uuidv4());
                              localStorage.setItem(
                                "Oldquestion",
                                JSON.stringify([])
                              );
                              addEvent();
                              dispatch(setEventValue(name));
                              setInput(name);
                              close();
                            } else {
                              toast.error("Enter the name");
                            }
                          }}
                        >
                          Create
                        </button>
                      </div>
                    </div>
                  </>
                )}
                {status === "rename" && (
                  <>
                    <>
                      <div className="p-6 text-center flex items-center flex-col w-full">
                        {/* <img src={winking} className="w-[70px] pb-6" alt="" /> */}
                        <Emoji size={60} emoji="face-with-raised-eyebrow" />
                        <p
                          className={`font-[600] mt-3 ${
                            mood === "light" ? "text-black" : "!text-white"
                          } font-inter`}
                        >
                          Rename Survey
                        </p>
                        <div className="my-4 w-full">
                          <input
                            type="text"
                            placeholder="Event name"
                            className="p-3 !py-235 w-full rounded outline-none bg-[#FAFAFA] border-gray border"
                            onChange={(e) => {
                              setInput(e.target.value);
                              setName(e.target.value);
                            }}
                            value={inputValue}
                          />
                        </div>
                        <div className="mt-1 flex gap-4 justify-between w-full mx-auto">
                          <button
                            onClick={close}
                            data-modal-toggle="popup-modal"
                            type="button"
                            className=" secondaryButton flex-1"
                          >
                            cancel
                          </button>
                          <button
                            data-modal-toggle="popup-modal"
                            type="button"
                            className=" primaryButton flex-1"
                            onClick={() => {
                              Submit();
                              close();
                            }}
                          >
                            Rename
                          </button>
                        </div>
                      </div>
                    </>
                  </>
                )}

                {status === "created" && (
                  // <>
                  //   <>
                  //     <div className="p-6 text-center flex items-center flex-col w-full relative">
                  //       <button
                  //         type="button"
                  //         className="absolute -top-3 -right-2 text-black  bg-red  rounded-full text-sm p-1 ml-auto inline-flex items-center transition-all duration-300"
                  //         data-modal-toggle="popup-modal"
                  //       >
                  //         <AiOutlineClose
                  //           size={20}
                  //           onClick={() => close()}
                  //           className=" text-white"
                  //         />
                  //       </button>
                  //       {/* <img src={success} className="w-[70px] pb-3" alt="" /> */}
                  //       <Emoji
                  //         size={60}
                  //         emoji="grinning-face-with-smiling-eyes"
                  //       />
                  //       <p className="font-[600] mt-3 font-inter">
                  //         Congratulations
                  //       </p>
                  //       <div className="my-3">
                  //         <p className="text-sm text-[#18181B] py-4 !pt-0">
                  //           <span className="text-[#4F46E5]">
                  //             “{eventName}”
                  //           </span>{" "}
                  //           Campaign <br />
                  //           has been Created Successfully.
                  //         </p>
                  //       </div>
                  //       <div className="my-3 w-full">
                  //         <div className="inpuStyle">
                  //           <div className="px-2">
                  //             <BsLink45Deg
                  //               size={25}
                  //               className="cursor-pointer text-blue"
                  //             />
                  //           </div>
                  //           <div className="flex-1 ">
                  //             <input
                  //               type="text"
                  //               placeholder="Event name"
                  //               className="inputStyle_input"
                  //               value={link}
                  //               defaultValue={link}
                  //             />
                  //           </div>
                  //           <div className="inputStyle_right ">
                  //             <MdContentCopy
                  //               size={25}
                  //               className="inpuStyle_right_icon"
                  //               onClick={click}
                  //             />
                  //           </div>
                  //         </div>
                  //       </div>

                  //       <div className="my-3 w-full">
                  //         <div className="inpuStyle">
                  //           <div className="px-2">
                  //             <MdQrCodeScanner
                  //               size={25}
                  //               className="cursor-pointer text-blue"
                  //             />
                  //           </div>
                  //           <div className="flex-1 ">
                  //             <p className="text-start">Download QR code</p>
                  //           </div>
                  //           <div className="inputStyle_right ">
                  //             <FiDownload
                  //               size={25}
                  //               className="inpuStyle_right_icon"
                  //               onClick={() => setdownload(true)}
                  //             />
                  //           </div>
                  //         </div>
                  //       </div>
                  //       <div className="mt-3 flex gap-4 justify-between w-full mx-auto">
                  //         <Link
                  //           to="/addQuestions"
                  //           className=" secondaryButton flex-1"
                  //           onClick={() => {
                  //             close();
                  //           }}
                  //         >
                  //           Continue
                  //         </Link>
                  //         <a
                  //           href={preview}
                  //           target="_blank"
                  //           className=" primaryButton flex-1"
                  //           onClick={() => {
                  //             close();
                  //             localStorage.removeItem("reRender");
                  //             localStorage.removeItem("brandRender");
                  //           }}
                  //         >
                  //           Preview
                  //         </a>
                  //       </div>
                  //       <Link
                  //         to="/events"
                  //         className={`text-black mt-4 back`}
                  //         onClick={() => {
                  //           clear();
                  //           redirect(selectName);
                  //           localStorage.removeItem("uuid");
                  //           localStorage.removeItem("reRender");
                  //           localStorage.removeItem("brandRender");
                  //           close();
                  //         }}
                  //       >
                  //         Go Home
                  //       </Link>
                  //     </div>
                  //   </>
                  // </>
                  <CampaignCreatedPopup
                    eventName={eventName}
                    id={preview}
                    linkToCopy={preview}
                    onCloseClicked={() => close()}
                    onQrClicked={() => setdownload(true)}
                    onPreviewClicked={() => {
                      close();
                      localStorage.removeItem("reRender");
                      localStorage.removeItem("brandRender");
                    }}
                    clear={clear}
                    onGoBackClicked="/events"
                  />
                )}

                {status === "failed" && (
                  <>
                    <>
                      <div className="p-6 text-center flex items-center flex-col">
                        {/* <img src={failed} className="w-[70px] pb-3" alt="" /> */}
                        <Emoji size={60} emoji="face-with-open-mouth" />
                        <p className="font-[600] font-inter">Failed!</p>
                        <div className="my-3">
                          <p className="text-sm text-[#18181B] py-4 !pt-0">
                            Oops! something went wrong <br />
                            please try again
                          </p>
                        </div>
                        <div className="mt-1 flex justify-between w-full mx-auto">
                          <Link
                            to="/events"
                            className={`hover:bg-transparent focus:ring-4 focus:outline-none rounded border flex justify-center items-center w-full max-w-[45%] ${
                              mood === "light"
                                ? "border-gray  text-[#8a8989]"
                                : "bg-[#303544] border-[#303544] text-white hover:bg-[#232734]"
                            } text-sm font-medium px-10 py-2 bg-transparent transition-all duration-300`}
                            onClick={() => {
                              redirect();
                              close();
                            }}
                          >
                            Go, Back
                          </Link>
                          <Link
                            to="/addQuestions"
                            className={`focus:ring-4 focus:outline-none border flex justify-center items-center w-[45%] border-[#4F46E5] ${
                              mood === "light"
                                ? "text-white hover:!text-blue bg-[#4F46E5] hover:bg-[#4F46E5]"
                                : "bg-transperent border-[#303544] text-[#303544] hover:bg-[#232734] hover:!border-[#232734] hover:!text-blue"
                            } font-medium rounded text-sm inline-flex items-center py-2.5 text-center hover:bg-transparent transition-all duration-300 hover:text-blue dark:hover:border-blue border`}
                            onClick={() => {
                              redirect(selectName);
                              close();
                            }}
                          >
                            Try again
                          </Link>
                        </div>
                      </div>
                    </>
                  </>
                )}

                {status === "clear" && (
                  <>
                    <>
                      <div className="p-6 text-center flex items-center flex-col">
                        {/* <img src={failed} className="w-[70px] pb-3" alt="" /> */}
                        <Emoji emoji="face-with-open-mouth" size={60} />

                        <p className="font-[600] font-inter mt-3">
                          Confirmation!
                        </p>
                        <p className="text-sm text-[#18181B] pt-2 text-center">
                          Are you sure you want to go back?
                        </p>
                        <p className="text-sm text-[#18181B] text-center">
                          Your edited data will loss
                        </p>
                        <div className=" mt-6 w-full flex justify-between  mx-auto gap-4 ">
                          <button
                            type="button"
                            className=" secondaryButton flex-1"
                            onClick={() => {
                              redirect(selectName);
                              close();
                            }}
                          >
                            Close
                          </button>
                          {!loaderSave ? (
                            <button
                              type="button"
                              className=" primaryButton flex-1"
                              onClick={Save}
                            >
                              Save
                            </button>
                          ) : (
                            <button
                              type="button"
                              className=" primaryButton flex-1"
                              disabled
                            >
                              <CustomDotLoader
                                dotSize={50}
                                isWhite
                                className="mx-auto text-center"
                              />
                            </button>
                          )}
                        </div>
                        <p
                          onClick={() => {
                            close();
                          }}
                          className="text-blue underline mt-6 cursor-pointer"
                        >
                          Go back
                        </p>
                      </div>
                    </>
                  </>
                )}

                {status === "close" && (
                  <>
                    <>
                      <div className="p-6 text-center flex items-center flex-col">
                        {/* <img src={failed} className="w-[70px] pb-3" alt="" /> */}
                        <Emoji emoji="face-with-open-mouth" size={60} />

                        <p className="font-[600] font-inter mt-3">
                          Confirmation!
                        </p>
                        <p className="text-sm text-[#18181B] pt-2 text-center">
                          Are you sure do you want to close?
                        </p>
                        <div className=" mt-6 w-full flex justify-between  mx-auto gap-4 ">
                          <button
                            type="button"
                            className=" secondaryButton flex-1"
                            onClick={() => {
                              close();
                            }}
                          >
                            No
                          </button>
                          <button
                            type="button"
                            className=" primaryButton flex-1"
                            onClick={() => {
                              redirect(selectName);
                              close();
                            }}
                          >
                            Yes
                          </button>
                        </div>
                      </div>
                    </>
                  </>
                )}

                {status === "messing" && (
                  <>
                    <>
                      <div className="p-6 text-center flex items-center flex-col">
                        {/* <img src={failed} className="w-[70px] pb-3" alt="" /> */}
                        <Emoji emoji="face-with-open-mouth" size={60} />

                        <p className="font-[600] font-inter mt-3">
                          {messsage} Required!
                        </p>
                        <div className="my-3">
                          <p className="text-sm text-[#18181B] py-4 !pt-0">
                            Oops! something went wrong
                          </p>
                        </div>
                        <div className="mt-1 flex justify-between w-[90%] mx-auto pl-2">
                          {/* <Link
                            to="/events"
                            className={`hover:bg-transparent focus:ring-4 focus:outline-none rounded border flex justify-center items-center w-full max-w-[45%] ${mood === "light"
                              ? "border-gray hover:!bg-gray hover:text-white text-[#8a8989]"
                              : "bg-[#303544] border-[#303544] text-white hover:bg-[#232734]"
                              } text-sm font-medium px-10 py-2 bg-transparent transition-all duration-300`}
                            onClick={() => {
                              redirect();
                              close();
                            }}
                          >
                            Go, Back
                          </Link> */}
                          <button
                            type="button"
                            className="primaryButton flex-1"
                            onClick={() => {
                              close();
                            }}
                          >
                            Continue
                          </button>
                        </div>
                      </div>
                    </>
                  </>
                )}

                {status === "active" && (
                  <>
                    <>
                      <div className="p-10 text-center flex items-center flex-col">
                        {/* <img src={failed} className="w-[70px] pb-3" alt="" /> */}
                        <Emoji emoji="face-with-open-mouth" size={60} />

                        <p className="font-[600] font-inter mt-3">
                          Enable Survey
                        </p>
                        <p className="text-sm text-[#18181B] py-4 !pt-0">
                          Are you sure you want to enable this
                          <span className="text-blue">
                            {" "}
                            “{eventNames}”
                          </span>{" "}
                          Survey?
                        </p>

                        <div className="mt-4 flex w-full gap-4 mx-auto">
                          <button
                            onClick={close}
                            data-modal-toggle="popup-modal"
                            type="button"
                            // className={` focus:ring-4 focus:outline-none rounded border flex justify-center items-center w-full max-w-[45%] ${
                            //     mood === "light"
                            //         ? "border-gray text-[#8a8989]"
                            //         : "bg-[#303544] border-[#303544] text-white hover:bg-[#232734]"
                            // } text-sm font-medium px-10 py-2`}
                            className=" secondaryButton py-3 w-full"
                          >
                            No
                          </button>
                          <button
                            data-modal-toggle="popup-modal"
                            type="button"
                            className="primaryButton py-3 w-full"
                            // className={`focus:ring-4 focus:outline-none flex justify-center items-center max-w-[45%] w-full border border-[lightBlue] ${
                            //     mood === "light"
                            //         ? "text-white hover:!text-blue bg-lightBlue hover:bg-lightBlue"
                            //         : "bg-transperent border-[#303544] text-[#303544] hover:bg-[#232734] hover:!border-[#232734] hover:!text-blue"
                            // } font-medium rounded text-sm inline-flex items-center px-7 py-2 text-center mr-2 hover:bg-transparent transition-all duration-300 hover:text-blue dark:hover:border-blue border`}
                            onClick={() => {
                              active();
                              close();
                            }}
                          >
                            Yes
                          </button>
                        </div>
                      </div>
                    </>
                  </>
                )}

                {status === "deactive" && (
                  <>
                    <>
                      <div className="p-10 text-center flex items-center flex-col">
                        {/* <img src={failed} className="w-[70px] pb-3" alt="" /> */}
                        <Emoji emoji="astonished-face" size={60} />

                        <p className="font-[600] font-inter mt-3">
                          {" "}
                          Disable Survey
                        </p>
                        <p className="text-sm text-[#18181B] py-4 !pt-0">
                          Are you sure you want to disable <br />
                          <span className="text-blue">
                            {" "}
                            “{eventNames}”
                          </span>{" "}
                          Survey?
                        </p>

                        <div className="mt-4 flex gap-4 w-full mx-auto">
                          <button
                            onClick={close}
                            data-modal-toggle="popup-modal"
                            type="button"
                            className=" secondaryButton py-3 w-full"

                            // className={` focus:ring-4 focus:outline-none rounded border flex justify-center items-center w-full max-w-[45%] ${
                            //     mood === "light"
                            //         ? "border-gray  text-[#8a8989]"
                            //         : "bg-[#303544] border-[#303544] text-white hover:bg-[#232734]"
                            // } text-sm font-medium px-10 py-2 `}
                          >
                            No
                          </button>
                          <button
                            data-modal-toggle="popup-modal"
                            type="button"
                            className="primaryButton py-3 w-full"
                            // className={`focus:ring-4 focus:outline-none flex justify-center items-center max-w-[45%] w-full border border-[lightBlue] ${
                            //     mood === "light"
                            //         ? "text-white hover:!text-blue bg-lightBlue hover:bg-lightBlue"
                            //         : "bg-transperent border-[#303544] text-[#303544] hover:bg-[#232734] hover:!border-[#232734] hover:!text-blue"
                            // } font-medium rounded text-sm inline-flex items-center px-7 py-2 text-center mr-2 hover:bg-transparent transition-all duration-300 hover:text-blue dark:hover:border-blue border`}
                            onClick={() => {
                              active();
                              close();
                            }}
                          >
                            Yes
                          </button>
                        </div>
                      </div>
                    </>
                  </>
                )}

                {status === "delete" && (
                  <>
                    <>
                      <div className="p-6 text-center flex items-center flex-col">
                        {/* <img src={failed} className="w-[70px] pb-3" alt="" /> */}
                        <Emoji emoji="astonished-face" size={60} />

                        <p className="font-[600] font-inter mt-3">
                          {" "}
                          Delete Survey
                        </p>
                        <p className="text-sm text-[#18181B] py-4 !pt-0">
                          Are you sure you want to delete this <br />
                          <span className="text-blue">
                            {" "}
                            “{eventNames}”
                          </span>{" "}
                          Survey?
                        </p>

                        <div className="mt-1 flex justify-between gap-4 w-full mx-auto">
                          <button
                            onClick={close}
                            data-modal-toggle="popup-modal"
                            type="button"
                            className=" secondaryButton flex-1"
                          >
                            No, Go back
                          </button>
                          {!deleteLoader ? (
                            <button
                              data-modal-toggle="popup-modal"
                              type="button"
                              className=" primaryButton flex-1"
                              onClick={() => {
                                deleteEvent(id);
                              }}
                            >
                              Yes Delete
                            </button>
                          ) : (
                            <button
                              data-modal-toggle="popup-modal"
                              type="button"
                              className=" primaryButton flex-1"
                              disabled
                            >
                              <CustomDotLoader
                                dotSize={50}
                                isWhite
                                className="mx-auto text-center"
                              />
                            </button>
                          )}
                        </div>
                      </div>
                    </>
                  </>
                )}

                {status === "Deleted" && (
                  <>
                    <>
                      <div className="p-4 text-center flex items-center flex-col">
                        {/* <img src={failed} className="w-[70px] pb-3" alt="" /> */}
                        <Emoji emoji="astonished-face" size={60} />

                        <p className="font-[600] font-inter mt-3">
                          {" "}
                          Survey not found
                        </p>

                        <div className="mt-4 flex justify-center w-full mx-auto">
                          <button
                            onClick={() => {
                              close();
                              window.location.replace(
                                "https://www.feedbacksync.com/"
                              );
                            }}
                            data-modal-toggle="popup-modal"
                            type="button"
                            className={`hover:bg-transparent focus:ring-4 focus:outline-none rounded border flex justify-center items-center w-full max-w-[45%] ${
                              mood === "light"
                                ? "border-red  text-white"
                                : "bg-[#303544] border-[#303544] text-white hover:bg-[#232734]"
                            } text-sm font-medium px-7 py-2 bg-red transition-all duration-300`}
                          >
                            Close
                          </button>
                        </div>
                      </div>
                    </>
                  </>
                )}

                {status === "logout" && (
                  <>
                    <>
                      <div className="p-6 text-center flex items-center flex-col">
                        {/* <img src={failed} className="w-[70px] pb-3" alt="" /> */}
                        <Emoji emoji="face-with-raised-eyebrow" size={60} />

                        <p className="font-[600] font-inter mt-3">
                          {" "}
                          Confirmation
                        </p>
                        <p className="text-sm text-[#18181B] py-4 !pt-0">
                          Are you sure you want to logout <br />
                          from the feedback sync?
                        </p>

                        <div className="mt-1 flex justify-between gap-4 w-full mx-auto">
                          <button
                            onClick={close}
                            data-modal-toggle="popup-modal"
                            type="button"
                            className=" secondaryButton flex-1"
                          >
                            No, Go back
                          </button>
                          <button
                            data-modal-toggle="popup-modal"
                            type="button"
                            className=" primaryButton flex-1"
                            onClick={clearAll}
                          >
                            Yes, Logout
                          </button>
                        </div>
                      </div>
                    </>
                  </>
                )}

                {status === "deleteQRLogo" && (
                  <>
                    <>
                      <div className="p-8 text-center flex items-center flex-col">
                        {/* <img src={failed} className="w-[70px] pb-3" alt="" /> */}
                        <Emoji emoji="face-with-raised-eyebrow" size={60} />

                        <p className="font-[600] font-inter mt-3">
                          {" "}
                          Confirmation
                        </p>
                        <p className="text-sm text-[#18181B] py-4 !pt-0">
                          Are you sure you want to delete <br />
                          QR Code Logo
                        </p>

                        <div className="mt-1 flex gap-4 justify-between w-full mx-auto">
                          <button
                            onClick={close}
                            data-modal-toggle="popup-modal"
                            type="button"
                            className=" secondaryButton flex-1"
                          >
                            No, Go back
                          </button>
                          {!loader ? (
                            <button
                              data-modal-toggle="popup-modal"
                              type="button"
                              className=" primaryButton flex-1"
                              onClick={() => {
                                setLoader(true);
                                removeQRLogo();
                              }}
                            >
                              Yes, Delete
                            </button>
                          ) : (
                            <button
                              data-modal-toggle="popup-modal"
                              type="button"
                              className=" primaryButton flex-1"
                            >
                              <CustomDotLoader
                                dotSize={50}
                                isWhite
                                className="mx-auto text-center"
                              />
                            </button>
                          )}
                        </div>
                      </div>
                    </>
                  </>
                )}
                {status === "deleteAccount" && (
                  <>
                    <>
                      <div className="p-8 text-center flex items-center flex-col">
                        {/* <img src={failed} className="w-[70px] pb-3" alt="" /> */}
                        <Emoji emoji="face-with-raised-eyebrow" size={60} />

                        <p className="font-[600] font-inter mt-3">
                          {" "}
                          Confirmation
                        </p>
                        <p className="text-sm text-[#18181B] py-4 !pt-0">
                          Are you sure you want to delete <br />
                          Your Account
                        </p>

                        <div className="mt-1 flex gap-4 justify-between w-full mx-auto">
                          <button
                            onClick={close}
                            data-modal-toggle="popup-modal"
                            type="button"
                            className=" secondaryButton flex-1"
                          >
                            No, Go back
                          </button>
                          {!loader ? (
                            <button
                              data-modal-toggle="popup-modal"
                              type="button"
                              className=" primaryButton flex-1"
                              onClick={() => {
                                setLoader(true);
                                handelDelete();
                              }}
                            >
                              Yes, Delete
                            </button>
                          ) : (
                            <button
                              data-modal-toggle="popup-modal"
                              type="button"
                              className=" primaryButton flex-1"
                            >
                              <CustomDotLoader
                                dotSize={50}
                                isWhite
                                className="mx-auto text-center"
                              />
                            </button>
                          )}
                        </div>
                      </div>
                    </>
                  </>
                )}

                {status === "clearQrcodeTemplate" && (
                  <>
                    <>
                      <div className="p-8 text-center flex items-center flex-col">
                        {/* <img src={failed} className="w-[70px] pb-3" alt="" /> */}
                        <Emoji emoji="face-with-raised-eyebrow" size={60} />

                        <p className="font-[600] font-inter mt-3">
                          {" "}
                          Confirmation
                        </p>
                        <p className="text-sm text-[#18181B] py-4 !pt-0">
                          Are you sure you want to go back?
                          <br />
                          Your edited data will loss
                        </p>

                        <div className="mt-1 flex justify-between gap-4 w-full mx-auto">
                          <button
                            onClick={close}
                            data-modal-toggle="popup-modal"
                            type="button"
                            className=" secondaryButton flex-1"
                          >
                            No, Go back
                          </button>
                          {!loader ? (
                            <button
                              data-modal-toggle="popup-modal"
                              type="button"
                              // className={`focus:ring-4 focus:outline-none flex justify-center items-center max-w-[45%] w-full border border-[lightBlue] ${
                              //     mood === "light"
                              //         ? "text-white bg-blue "
                              //         : "bg-transperent border-[#303544] text-[#303544] hover:bg-[#232734] hover:!border-[#232734] "
                              // } font-medium rounded text-sm inline-flex items-center px-7 py-2 text-center mr-2 hover:bg-transparent transition-all duration-300  border`}
                              className=" primaryButton flex-1"
                              onClick={() => {
                                saveQrTemplate();
                              }}
                            >
                              Save
                            </button>
                          ) : (
                            <button
                              data-modal-toggle="popup-modal"
                              type="button"
                              className=" primaryButton flex-1"
                            >
                              <CustomDotLoader
                                dotSize={50}
                                isWhite
                                className="mx-auto text-center"
                              />
                            </button>
                          )}
                        </div>
                      </div>
                    </>
                  </>
                )}

                {status === "clearEmailTemplate" && (
                  <>
                    <>
                      <div className="p-8 text-center flex items-center flex-col">
                        {/* <img src={failed} className="w-[70px] pb-3" alt="" /> */}
                        <Emoji emoji="face-with-raised-eyebrow" size={60} />

                        <p className="font-[600] font-inter mt-3">
                          {" "}
                          Confirmation
                        </p>
                        <p className="text-sm text-[#18181B] py-4 !pt-0">
                          Are you sure you want to go back?
                          <br />
                        </p>

                        <div className="mt-1 flex justify-between gap-4 w-full mx-auto">
                          <button
                            onClick={close}
                            data-modal-toggle="popup-modal"
                            type="button"
                            className=" secondaryButton flex-1"
                          >
                            No, Go back
                          </button>
                          {!loader ? (
                            <button
                              data-modal-toggle="popup-modal"
                              type="button"
                              // className={`focus:ring-4 focus:outline-none flex justify-center items-center max-w-[45%] w-full border border-[lightBlue] ${
                              //     mood === "light"
                              //         ? "text-white bg-blue "
                              //         : "bg-transperent border-[#303544] text-[#303544] hover:bg-[#232734] hover:!border-[#232734] "
                              // } font-medium rounded text-sm inline-flex items-center px-7 py-2 text-center mr-2 hover:bg-transparent transition-all duration-300  border`}
                              className=" primaryButton flex-1"
                              onClick={() => {
                                setLoader(true);
                                setrerender(true);
                              }}
                            >
                              Save
                            </button>
                          ) : (
                            <button
                              data-modal-toggle="popup-modal"
                              type="button"
                              className=" primaryButton flex-1"
                            >
                              <CustomDotLoader
                                dotSize={50}
                                isWhite
                                className="mx-auto text-center"
                              />
                            </button>
                          )}
                        </div>
                      </div>
                    </>
                  </>
                )}
              </div>
            </div>
          </div>
        </>
      )}

      {download && (
        <div className="hidden">
          <QRcode
            value={preview}
            click={"download"}
            setdownload={setdownload}
          />
        </div>
      )}
    </div>
  );
};

export default EventName;
