import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  emailTemplate: [],
};

const emailTemplateSlice = createSlice({
  name: "emailtemplate",
  initialState,
  reducers: {
    setEmailTemplateValue(state, action) {
      state.emailTemplate = action.payload;
    },
    setUpdateEmailTemplateValue(state, action) {
      state.emailTemplate = state.emailTemplate.map((i) => {
        return {
          ...i,
          subject: action.payload.subject,
          message: action.payload.message,
        };
      });
    },
  },
});

export const { setEmailTemplateValue, setUpdateEmailTemplateValue} = emailTemplateSlice.actions;
export default emailTemplateSlice.reducer;
