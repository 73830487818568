import dayjs from "dayjs";
import React from "react";
import { BsCreditCard } from "react-icons/bs";

const ManageSubscription = ({ isComponentActive, planDetails }) => {
  return (
    <div className="w-full max-w-6xl p-8 mt-8 mx-auto | card">
      <div className="flex justify-between w-full pb-4 border-b border-grayLight items-center">
        <h1 className=" text-xl font-semibold text-grayDark">Current Plan</h1>

        <button
          onClick={() => isComponentActive(false)}
          className="  button py-2 hover:underline text-red"
        >
          Close
        </button>
      </div>
      <div className="my-4">
        <h4 className="text-base font-medium text-grayDark">Pro access</h4>
        <h1 className=" text-xl font-bold text-black my-2">
          ₹
          {new Intl.NumberFormat("en-IN", {
            currency: "INR",
          }).format(planDetails?.[0]?.amount)}{" "}
          per {planDetails?.[0]?.payment_plan}
        </h1>
        <p>
          Your plan lifeTime
          {/* renews on{" "}
          {dayjs(planDetails?.[0]?.plan_activate_date)
            .add(planDetails?.[0]?.payment_plan === "month" ? 30 : 365, "day")
            .format("MMMM DD YYYY")} */}
        </p>
      </div>
      <div className="pt-8 pb-4 border-b border-grayLight">
        <h1 className=" text-xl font-semibold text-grayDark">Payment Method</h1>
      </div>
      <div className="flex mt-4 w-1/3 justify-between">
        <div className="flex gap-4 items-center">
          <BsCreditCard size={24} className="text-blue" />
          <p>{planDetails?.[0]?.payment_mode}</p>
        </div>
        {/* <p>Expires 05/2023</p> */}
      </div>
      <div className="pt-8 pb-4 border-b border-grayLight">
        <h1 className=" text-xl font-semibold text-grayDark">
          Invoice History
        </h1>
      </div>
      <div className="flex mt-4 w-1/2 justify-between">
        <p>{dayjs(planDetails?.[0]?.createdAt).format("DD MMM YYYY")}</p>
        <p>
          ₹
          {new Intl.NumberFormat("en-IN", {
            currency: "INR",
          }).format(planDetails?.[0]?.amount)}
        </p>
        <p className="text-sm px-3 py-1 bg-yellow/40 rounded-full ">
          {planDetails?.[0]?.payment_status === "SUCCESS"?  "Paid" : planDetails?.[0]?.payment_status}
        </p>
        <p className="text-sm px-3 py-1 bg-blue/40 rounded-full ">
          {planDetails && "Pro access"}
        </p>
      </div>
    </div>
  );
};

export default ManageSubscription;
