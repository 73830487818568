import React, { useRef } from "react"

// ** import react-transition
import { Transition } from "react-transition-group"

/**
 * A component that provides smooth transitions for its children.
 *
 * @param {Object} props - The component's props.
 * @param {number} [props.duration=750] - The duration of the transition in milliseconds.
 * @param {number} [props.opacity] - The target opacity value (0 to 1).
 * @param {string} [props.transform] - The transform property (e.g., "translateX(10px)", "translateY(-20px)").
 * @param {ReactNode} props.children - The child elements to be transitioned.
 *
 * @returns {JSX.Element} - The rendered TransitionComponent.
 */

const FadeInTransition = ({ duration = 750, opacity, transform, children }) => {
    const nodeRef = useRef(null)

    const defaultStyle = {
        transition: `opacity ${duration}ms ease-in-out, transform ${duration}ms ease-in-out`,
        opacity: opacity !== undefined ? opacity : 0,
        transform: transform || "translateY(1rem)"
    }

    const transitionStyles = {
        entering: {
            opacity: opacity !== undefined ? opacity : 1,
            transform: transform || "translateY(0rem)"
        },
        entered: {
            opacity: opacity !== undefined ? opacity : 1,
            transform: transform || "translateY(0rem)"
        },
        exiting: {
            opacity: opacity !== undefined ? opacity : 0,
            transform: transform || "translateY(1rem)"
        },
        exited: {
            opacity: opacity !== undefined ? opacity : 0,
            transform: transform || "translateY(1rem)"
        }
    }

    return (
        <Transition
            nodeRef={nodeRef}
            appear={true}
            in={true}
            mountOnEnter
            timeout={duration}
        >
            {(state) => (
                <div
                    ref={nodeRef}
                    style={{
                        ...defaultStyle,
                        ...transitionStyles[state]
                    }}
                >
                    {children}
                </div>
            )}
        </Transition>
    )
}

export default FadeInTransition
