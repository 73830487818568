import React from "react"
import { useEffect } from "react"
import { useState } from "react"
import { useDispatch } from "react-redux"
import { setFeedbackValue } from "../../../redux/slices/feedbackValue"
import "./Nsp.css"
import α from "color-alpha"
import { useSelector } from "react-redux"

const Nsp = ({ inputQuestion, id, questionType, eventId, bg, color, textColor, setType, setNsp }) => {
    const [count, setCount] = useState([])
    const [value, setValue] = useState("")
    const [selected, setSelected] = useState(0)
    const col = α(color, 0.2)
    const datas = useSelector((state) => state.feedbackValue.feedbackValue)

    const dispatch = useDispatch()

    const getValue = (answer) => {
        if (
            inputQuestion?.length !== 0 &&
            answer?.length !== 0 &&
            id?.length !== 0 &&
            questionType?.length !== 0 &&
            answer?.length !== 0 &&
            eventId?.length !== 0
        ) {
            let list = [...datas?.[0].list_of_items]
            list[id - 1] = {
                ...list[id - 1],
                answer: answer.toString()
            }
            let allItem = { ...datas?.[0], list_of_items: list }
            return dispatch(setFeedbackValue(allItem))
        }
    }

    useEffect(() => {
        if (datas?.[0]?.list_of_items[id - 1]?.answer[0]?.id) {
            setSelected(null)
        } else {
            setSelected(parseInt(datas?.[0]?.list_of_items?.[id - 1]?.answer) - 1)
            setNsp(parseInt(datas?.[0]?.list_of_items?.[id - 1]?.answer))
        }
        setType("Nsp")
    }, [])

    useEffect(() => {
        const value = []
        for (let i = 1; i <= 10; i++) {
            value.push(i)
        }
        return setCount(value)
    }, [])

    return (
        <div className="mt-12 mb-6 h-full w-full flex flex-col justify-center items-center  bg-no-repeat bg-cover bg-center bg-fixed">
            <h3
                style={{ color: `${textColor}` , wordBreak: "break-word"}}
                className="mb-8  w-full max-w-3xl  flex items-center justify-center text-xl sm:text-3xl font-semibold text-center mx-auto"

            >
                {inputQuestion}
            </h3>

            <div className="flex items-center justify-center mx-6 flex-wrap w-full">
                {count.map((i, index) => (
                    <p
                        key={i}
                        onClick={() => {
                            setValue(index)
                            getValue(i)
                            setNsp(i)
                            setSelected(index)
                        }}
                        style={{
                            border: `3px solid ${selected === index ? color : "#F7F7F7"}`,
                            color: `${selected === index ? "black" : "#494949"}`,
                            background: `${selected === index ? col : "#F7F7F7"}`
                        }}
                        className={`m-3 w-14 h-12 rounded cursor-pointer font-semibold text-base py-3 ${
                            value === i ? `bg-blue` : `bg-grayDark`
                        }  flex items-center justify-center ${value === i ? `hover:bg-blue` : `hover:bg-grayLight`}
            `}
                    >
                        {i}
                    </p>
                ))}
            </div>
        </div>
    )
}

export default Nsp
