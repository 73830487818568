import React from "react";
import ReactDOM from "react-dom";
import { TourProvider } from "@reactour/tour";
import App from "./App";
import "./index.css";
import * as Sentry from "@sentry/react";

const steps = [
  {
    selector: '[data-tut="first-step"]',
    content: "Create a Survey",
  },
  {
    selector: '[data-tut="second-step"]',
    content: "Give the name and create Survey",
  },
  {
    selector: '[data-tut="third-step"]',
    content: "Click the add question button",
  },
  {
    selector: '[data-tut="four-step"]',
    content: "Click the question",
  },
  {
    selector: '[data-tut="five-step"]',
    content: "Type...",
  },
  {
    selector: '[data-tut="six-step"]',
    content: "Close",
  },
];


Sentry.init({
  dsn: "https://899889ba8c5e9709bd254ee241d255a0@o4505754337017856.ingest.sentry.io/4505826457288704",
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", "http://app.feedbacksync.com"],
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});


const root = document.getElementById("root");
ReactDOM.render(
  <TourProvider steps={steps} scrollSmooth disableDotsNavigation>
    <App />
  </TourProvider>,
  root
);
