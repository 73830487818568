import React, { useState } from "react"
import Flatpickr from "react-flatpickr"
import "flatpickr/dist/themes/material_blue.css"

const DateFilterMenu = ({ filterOpen, close, dateRanges, getData, setDateTypes, all }) => {
    const [dateType, setDateType] = useState("")
    const [dateRange, setDateRange] = useState(null)

    const handelClick = async () => {
        if (dateType.length > 0) {
            if (dateType === "dateRange"){
                setDateTypes(dateType)
                dateRanges(dateRange)
                getData(true)
                close(false)
                return
            } else {
                setDateTypes(dateType)
                getData(true)
                close(false)
                return
            }
        }
    }

    return (
        <div
            className={` ${
                filterOpen ? "visible" : "hidden"
            } absolute p-4 right-0 z-20 mt-2 w-[400px] origin-top-right rounded-md bg-white ring-1 ring-black ring-opacity-5 focus:outline-none | dropdownBackground`}
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="menu-button"
            tabIndex="-1"
        >
            <div className="mt-1 relative">
                <h3>Time Period</h3>
                <Flatpickr
                    onClick={() =>
                        setDateType((prev) => {
                            return prev === "dateRange" ? "" : "dateRange"
                        })
                    }
                    value={dateRange} // Use the state variable here to set the selected range
                    id="range-picker"
                    className="w-full border border-gray rounded-md py-2 px-2"
                    onChange={(selectedDates) => {
                        if (selectedDates.length === 2) {
                            const [startDate, endDate] = selectedDates
                            setDateRange([
                                startDate.toISOString(), // Convert the dates to ISO string format
                                endDate.toISOString()
                            ])
                        }
                    }}
                    options={{
                        mode: "range"
                    }}
                />
            </div>
            <div className="days">
                <div
                    onClick={() => {
                        setDateType((prev) => {
                            return prev === "today" ? "" : "today"
                        })
                        setDateRange(null)
                    }}
                    className={`${dateType === "today" ? "active" : ""}`}
                >
                    Today
                </div>
                <div
                    onClick={() => {
                        setDateType((prev) => {
                            return prev === "yesterday" ? "" : "yesterday"
                        })
                        setDateRange(null)
                    }}
                    className={`${dateType === "yesterday" ? "active" : ""}`}
                >
                    Yesterday
                </div>
                <div
                    onClick={() => {
                        setDateType((prev) => {
                            return prev === "lastWeek" ? "" : "lastWeek"
                        })
                        setDateRange(null)
                    }}
                    className={`${dateType === "lastWeek" ? "active" : ""}`}
                >
                    Last Week
                </div>
                <div
                    onClick={() => {
                        setDateType((prev) => {
                            return prev === "thisWeek" ? "" : "thisWeek"
                        })
                        setDateRange(null)
                    }}
                    className={`${dateType === "thisWeek" ? "active" : ""}`}
                >
                    This Week
                </div>
                <div
                    onClick={() => {
                        setDateType((prev) => {
                            return prev === "lastMonth" ? "" : "lastMonth"
                        })
                        setDateRange(null)
                    }}
                    className={`${dateType === "lastMonth" ? "active" : ""}`}
                >
                    Last Month
                </div>
                <div
                    onClick={() => {
                        setDateType((prev) => {
                            return prev === "thisMonth" ? "" : "thisMonth"
                        })
                        setDateRange(null)
                    }}
                    className={`${dateType === "thisMonth" ? "active" : ""}`}
                >
                    This Month
                </div>
                <div
                    onClick={() => {
                        setDateType((prev) => {
                            return prev === "thisYear" ? "" : "thisYear"
                        })
                        setDateRange(null)
                    }}
                    className={`${dateType === "thisYear" ? "active" : ""}`}
                >
                    This Year
                </div>
                <div
                    onClick={() => {
                        setDateType((prev) => {
                            return prev === "lastYear" ? "" : "lastYear"
                        })
                        setDateRange(null)
                    }}
                    className={`${dateType === "lastYear" ? "active" : ""}`}
                >
                    Last Year
                </div>

                {
                    all !== undefined && <div
                    onClick={() => {
                        setDateType((prev) => {
                            return prev === "All" ? "" : "All"
                        })
                        setDateRange(null)
                    }}
                    className={`${dateType === "All" ? "active" : ""}`}
                >
                    ALL
                </div>
                }
            </div>
            <div className="flex gap-4 mt-4">
                <button
                    onClick={() => {
                        setDateType("")
                    }}
                    className=" secondaryButton flex-1"
                >
                    <span>Clear</span>
                </button>
                <button
                    onClick={handelClick}
                    className=" primaryButton flex-1"
                >
                    <span>Apply</span>
                </button>
            </div>
        </div>
    )
}

export default DateFilterMenu
