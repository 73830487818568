/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState } from "react"
import { RatingComponent } from "react-rating-emoji"
import "react-rating-emoji/dist/index.css"
import { useDispatch } from "react-redux"
import { setFeedbackValue } from "../../../redux/slices/feedbackValue"
import "./Emoji.module.css"
import { svg1, svg2, svg3, svg4, svg5 } from "../../../assets/ratting/1"

import { useSelector } from "react-redux"
import { useEffect } from "react"

const Emoji = ({ inputQuestion, id, questionType, eventId, textColor, setType, setEmoji, color }) => {
    const [rating, setRating] = useState(0)
    const dispatch = useDispatch()
    const datas = useSelector((state) => state.feedbackValue.feedbackValue)

    const getValue = (answer) => {
        if (
            inputQuestion?.length !== 0 &&
            answer?.length !== 0 &&
            id?.length !== 0 &&
            questionType?.length !== 0 &&
            answer?.length !== 0 &&
            eventId?.length !== 0
        ) {
            let list = [...datas?.[0]?.list_of_items]
            list[id - 1] = {
                ...list[id - 1],
                answer: answer.toString()
            }
            let allItem = { ...datas?.[0], list_of_items: list }
            return dispatch(setFeedbackValue(allItem))
        }
    }

    const handleRating = (newRating) => {
        getValue(newRating)
        setRating(newRating)
    }
    const [selected, setSelected] = useState(10)
    useEffect(() => {
        if (datas?.[0]?.list_of_items[id - 1]?.answer[0]?.id) {
            setSelected(20)
            setEmoji(0)
        } else {
            setEmoji(parseInt(datas?.[0]?.list_of_items[id - 1]?.answer))
            setSelected(parseInt(datas?.[0]?.list_of_items[id - 1]?.answer) - 1)
        }
        setType("smileyScale")
    }, [datas?.[0]?.list_of_items[id - 1]])
    const [state, setState] = useState([
        {
            img: svg1({ size: "60px", color }),
            text: "Terrible"
        },
        {
            img: svg2({ size: "60px", color }),
            text: "Do better"
        },
        {
            img: svg3({ size: "60px", color }),
            text: "Decent"
        },
        {
            img: svg4({ size: "60px", color }),
            text: "I enjoyed"
        },
        {
            img: svg5({ size: "60px", color }),
            text: "Love it"
        }
    ])

    return (
        <div className=" mb-6 h-full w-full flex flex-1 flex-col justify-center items-center  bg-no-repeat bg-cover bg-center bg-fixed">
            <h3
                style={{ color: `${textColor}`, wordBreak: "break-word" }}
                className="mb-8 text-center  w-full max-w-3xl  break-words  flex items-center justify-center mx-10  text-xl sm:text-3xl font-semibold"
            >
                {inputQuestion}
            </h3>
            <div className="my-5 py-5 flex gap-4 sm:gap-2 flex-wrap sm:flex-nowrap w-full max-w-[90%] overflow-hidden justify-center">
                {/* <RatingComponent rating={rating} onClick={handleRating} /> */}
                {/* <img src={} alt="" /> */}
                {state.map((data, index) => {
                    return (
                        <div
                            key={index}
                            className={`flex flex-col max-w-[33%] justify-center items-center
              }`}
                        >
                            <div
                                style={{
                                    filter: selected === index ? "saturate(1)" : "saturate(0)",
                                    WebkitFilter: selected === index ? "saturate(1)" : "saturate(0)",
                                    ":hover": {
                                        WebkitFilter: "saturate(1)"
                                    },
                                    transform: selected === index ? "scale(1.1)" : "scale(1)"
                                }}
                                className={` ${selected === index && "px-1"} cursor-pointer mx-3 xs:mx-1  transition duration-300`}
                                onClick={() => {
                                    setSelected(index)
                                    handleRating(index + 1)
                                    setEmoji(index + 1)
                                }}
                            >
                                <span>{data.img}</span>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default Emoji
