import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  eventName: "New Event",
};

const eventSlice = createSlice({
  name: "eventName",
  initialState,
  reducers: {
    setEventValue(state, action) {
      state.eventName = action.payload;
    },
  },
});

export const { setEventValue } = eventSlice.actions;
export default eventSlice.reducer;
