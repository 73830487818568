import React, { useEffect, useState } from "react"
import Languages from "./Languages"
import { AiOutlineCheck, AiOutlineClose } from "react-icons/ai"

const LanguagePopup = ({ close, clicked, select, color }) => {
    const [language, setlanguage] = useState(Languages)
    const [filterLanguages, setFilterLanguages] = useState(language)

    const [search, setSearch] = useState("")

    const handelClick = async (content, id, name, language) => {
        select({
            content,
            id,
            name,
            language
        })
        return
    }

    useEffect(() => {
        const searchTerm = search.toLowerCase() // convert search term to lowercase for case-insensitive search

        const filteredSuggestions = language.suggest.filter(
            (lang) => lang.subtitle.toLowerCase().includes(searchTerm) || lang.name.toLowerCase().includes(searchTerm)
        )

        const filteredAllLanguages = language.allLanguage.filter(
            (lang) => lang.subtitle.toLowerCase().includes(searchTerm) || lang.name.toLowerCase().includes(searchTerm)
        )

        setFilterLanguages({
            suggest: filteredSuggestions,
            allLanguage: filteredAllLanguages
        })
    }, [search])
    return (
        <div
            style={{
                backgroundColor: `rgba(0, 0, 0, 0.3)`
            }}
            className=" w-[100%] h-[100%] absolute top-0 flex items-center justify-center "
        >
            <div className=" w-[80%] h-[600px] max-w-7xl bg-white rounded-lg p-4 md:p-10  overflow-hidden pb-20 relative z-[999]">
                <button
                    type="button"
                    className={` hover:text-red group absolute top-2 right-2 border-2 rounded-md p-1 text-gray  text-3xl inline-flex items-center justify-center  transition-all duration-300`}
                    data-modal-toggle="popup-modal"
                    onClick={() => close(false)}
                >
                    <AiOutlineClose
                        size={22}
                        className="group-hover:text-red text-gray transition-color duration-300"
                    />
                </button>
                <div className="flex items-center justify-between lg:flex-row flex-wrap pb-3 xs:flex-col sm:flex-col sm:items-start xs:items-start mt-6">
                    <div className=" flex-1 mt-3">
                        <h1 className=" lg:text-2xl xs:text-base font-semibold ">Select your Language</h1>
                    </div>
                    <input
                        type="search"
                        className="py-2 px-3 outline-none max-sm:mt-2 w-full lg:w-[300px] border-gray border-2 rounded-md"
                        placeholder="Search..."
                        onChange={(e) => setSearch(e.target.value)}
                        value={search}
                    />
                </div>

                <div className="w-full h-[90%]  overflow-scroll">
                    {/* suggest */}
                    <div className="">
                        <h1 className=" text-lg font-semibold mt-5 xs:text-base">Suggested for you</h1>
                        <div className="grid xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-2 my-6 lg:mx-6 gap-y-4 md:gap-y-8 ">
                            {filterLanguages?.suggest?.map((i) => (
                                <div
                                    key={i.id}
                                    className={`flex items-center rounded-md cursor-pointer ${
                                        clicked?.content === "suggest" && i.id === clicked?.id
                                            ? "bg-[#E6FAFF]"
                                            : "bg-transparent"
                                    }  py-2 px-2`}
                                    onClick={() => {
                                        handelClick("suggest", i.id, i.name, i.language)
                                        setSearch("")
                                        close(false)
                                    }}
                                >
                                    <div className="flex items-center  gap-x-4 flex-1">
                                        <img
                                            src={i.flag}
                                            alt="flag"
                                            className=" w-[40px] h-[40px] rounded-full object-cover "
                                        />
                                        <h1
                                            style={{
                                                color: "#000000"

                                                // color: color !== null || undefined ? color : "#4F46E5",
                                            }}
                                            className="lg:text-lg md:text-base sm:text-xs "
                                        >
                                            {i.name}
                                        </h1>
                                    </div>
                                    {clicked?.content === "suggest" && i.id === clicked?.id && (
                                        <div className="mx-4">
                                            <AiOutlineCheck
                                                size={22}
                                                style={{
                                                    color: "#4F46E5"
                                                }}
                                            />
                                        </div>
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                    {/* All Language */}
                    <div className="">
                        <h1 className=" text-lg font-semibold  mt-5">All Languages</h1>

                        <div className="grid xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-2 my-6 lg:mx-6 gap-y-4 md:gap-y-8 ">
                            {filterLanguages?.allLanguage?.map((i) => (
                                <div
                                    key={i.id}
                                    className={`flex items-center rounded-sm cursor-pointer ${
                                        clicked?.content === "allLanguages" && i.id === clicked?.id
                                            ? "bg-[#E6FAFF]"
                                            : "bg-transparent"
                                    }  py-3 pl-2`}
                                    onClick={() => {
                                        handelClick("allLanguages", i.id, i.name, i.language)
                                        setSearch("")
                                        close(false)
                                    }}
                                >
                                    <div className="flex items-center  gap-x-4 flex-1">
                                        <img
                                            src={i.flag}
                                            alt="flag"
                                            className=" w-[40px] h-[40px] rounded-full object-cover "
                                        />
                                        <h1
                                            style={{
                                                color: "#000000"
                                            }}
                                            className="lg:text-lg md:text-base sm:text-xs"
                                        >
                                            {i.name}
                                        </h1>
                                    </div>
                                    {clicked?.content === "allLanguages" && i.id === clicked?.id && (
                                        <div className="mx-4">
                                            <AiOutlineCheck
                                                size={22}
                                                style={{
                                                    color: "#4F46E5"
                                                }}
                                            />
                                        </div>
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                    <div></div>
                </div>
            </div>
        </div>
    )
}

export default LanguagePopup
