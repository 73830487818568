/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from "react";
import { MdAdd } from "react-icons/md";
import { AiOutlineMinusCircle } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { newArrayAnswer, setArrayValue } from "../../redux/slices/questions";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { VscGripper } from "react-icons/vsc";

const answerBox = ({ boxId, type, addAnswer }) => {
  const { questionValues } = useSelector((state) => state.questions);
  const [answerChoice, setAnswerChoice] = useState([]);
  const dispatch = useDispatch();
  // const { bgColor } = useSelector((state) => state.bg);
  const { mood } = useSelector((state) => state.mood);

  useEffect(() => {
    const newChanges = () => {
      const answerIndex = questionValues.filter((item) => item.id == boxId);
      setAnswerChoice(answerIndex[0]?.choice);
    };
    newChanges();
  }, [questionValues, boxId]);

  const addAnswerChoice = () => {
    const arayValueFilter = questionValues.filter((item) => item.id == boxId);
    const choiceOption = arayValueFilter[0].choice;
    let lastIndex = arayValueFilter[0].choice.length;
    let addValues = () => {
      const value = Number(lastIndex) + 1;
      return value.toString();
    };
    let newValue = addValues();
    let addArray = [...choiceOption, { id: newValue, value: "" }];

    dispatch(setArrayValue({ id: boxId, choice: addArray, type: type }));
  };

  const remove = (i) => {
    const arrayValue = answerChoice.filter((e) => e.id !== i);
    dispatch(setArrayValue({ id: boxId, choice: arrayValue, type: type }));
  };

  const update = (i, value) => {
    const arayValueFilter = questionValues.filter((item) => item.id == boxId);
    const choiceOption = arayValueFilter[0].choice.filter(
      (items) => items.id !== i
    );
    const addValues = [...choiceOption, { id: i, value: value }];
    dispatch(setArrayValue({ id: boxId, choice: addValues, type: type }));
  };

  const handelChange = (e) => {
    if (!e.destination) return;
    const items = Array.from(answerChoice);
    const [reorderedItem] = items.splice(e.source.index, 1);
    items.splice(e.destination.index, 0, reorderedItem);
    dispatch(newArrayAnswer({ choice: items, id: boxId }));
    setAnswerChoice(items);
  };

  useEffect(() => {
    if (addAnswer !== null && addAnswer !== true && addAnswer !== false) {
      addAnswerChoice();
    }
  }, [addAnswer, boxId]);

  return (
    <div>
      <div>
        <DragDropContext onDragEnd={handelChange}>
          <Droppable droppableId="answerChoice">
            {(provided) => (
              <div
                className="answerChoice"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {answerChoice !== undefined &&
                  answerChoice.map((item, index) => (
                    <div>
                      <Draggable
                        key={item.id}
                        draggableId={item.id}
                        index={index}
                      >
                        {(provided) => (
                          <div
                            className="flex items-center"
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            ref={provided.innerRef}
                          >
                            <VscGripper
                              size={26}
                              className="text-gray hover:text-black"
                            />
                            <div 
                            className={` flex items-center w-[78%] py-3 my-3  px-3 outline-0 font-medium mx-2 bg-white  text-base text-[#000000] border border-[#D4D4D8] rounded-lg focus-within:border-[#4F46E5] `}>

                            <input
                              type="text"
                              placeholder={`Answer ${item.id}`}
                              className="border-0 outline-none w-[96%] px-2"
                              onChange={(e) => update(item.id, e.target.value)}
                              defaultValue={item.value}
                              maxLength={200}
                              // style={{
                              //     boxShadow: `${
                              //         mood === "light"
                              //             ? "0px 2px 8px rgba(0, 0, 0, 0.20)"
                              //             : "0px 2px 8px #2f3b5c"
                              //     }`
                              // }}
                            />
                            <p className=" text-gray text-xs">{item.value.length}/200</p>
                            </div>


                            {item.id > 2 ? (
                              <AiOutlineMinusCircle
                                size={26}
                                className={`cursor-pointer text-gray w-[10%] `}
                                onClick={() => remove(item.id)}
                              />
                            ) : null}
                          </div>
                        )}
                      </Draggable>
                    </div>
                  ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
      {type === "multiSelect" && (
        <p
          className={`font-[500] font-inter capitalize text-[16px] cursor-pointer text-[#5F58B7] absolute top-0 right-0`}
          onClick={addAnswerChoice}
        >
          <MdAdd size={26} className="text-amber-300 font-bold text-lg" />
          {/* Add */}
        </p>
      )}
      {type === "radioButton" && (
        <p
          className={`inline mt-3 font-[500] font-inter capitalize text-[16px] cursor-pointer text-[#5F58B7] absolute top-[-10px] right-0`}
          onClick={addAnswerChoice}
        >
          <MdAdd size={26} className="text-amber-300 font-bold text-lg" />
          {/* Add */}
        </p>
      )}
    </div>
  );
};

export default answerBox;
