/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState } from "react"
import { useEffect } from "react"
import { useSelector } from "react-redux"
import { useDispatch } from "react-redux"
import { Rating } from "react-simple-star-rating"
import { setFeedbackValue } from "../../../redux/slices/feedbackValue"
import "./StartRating.module.css"

const StarRating = ({
    inputQuestion,
    id,
    questionType,
    eventId,
    bg,
    color,
    textColor,
    setType,
    setStarRating,
    answer,
}) => {
    const [review, setReview] = useState(null)
    const [rating, setRating] = useState(0)

    const dispatch = useDispatch()
    const datas = useSelector((state) => state.feedbackValue.feedbackValue)

    useEffect(() => {
        if (datas?.[0]?.list_of_items[id - 1]?.answer?.[0]?.id) {
            setRating(0)
            setStarRating(0)
        } else {
            setStarRating(parseInt(datas?.[0]?.list_of_items?.[id - 1]?.answer))
            setRating(parseInt(datas?.[0]?.list_of_items?.[id - 1]?.answer))
        }
        setType("starRating")
    }, [datas?.[0]?.list_of_items[id - 1]])

    const getValue = (answer) => {
        if (
            inputQuestion?.length !== 0 &&
            answer?.length !== 0 &&
            id?.length !== 0 &&
            questionType?.length !== 0 &&
            eventId?.length !== 0
        ) {
            let list = [...datas?.[0]?.list_of_items]
            list[id - 1] = {
                ...list[id - 1],
                answer: answer.toString()
            }
            let allItem = { ...datas?.[0], list_of_items: list }
            return dispatch(setFeedbackValue(allItem))
        }
    }

    // Catch Rating value
    const handleRating = (rate) => {
        getValue(rate)
        setRating(rate)
        setStarRating(rate)
        // other logic
    }
    // Optinal callback functions

    const onPointerMove = (value) => {
        if (value === 1) {
            return setReview("Terrible")
        }
        if (value === 2) {
            return setReview("Bad")
        }
        if (value === 3) {
            return setReview("Average")
        }
        if (value === 4) {
            return setReview("Great")
        }
        if (value === 5) {
            return setReview("Awesome")
        }
    }

    return (
        <div className=" mb-6 h-full w-full flex flex-col justify-center items-center  bg-no-repeat bg-cover bg-center bg-fixed">
            <h3
                style={{ color: `${textColor}`, wordBreak: "break-word" }}
                className="mb-8  w-full max-w-3xl  break-words text-center  flex items-center justify-center mx-10  text-xl sm:text-3xl font-semibold"
            >
                {inputQuestion}
            </h3>

            <div className="flex justify-center items-center my-4">
                <Rating
                    onClick={handleRating}
                    initialValue={rating}
                    className=""
                    onPointerMove={onPointerMove}
                    size={60}
                    fillColor={color}
                />
            </div>
            {/* <div className="flex justify-center items-center">
        {review !== null && (
          <p style={{background:`${color}`,color:`${bg}`}} className={`text-[${bg}]  focus:ring-4 focus:outline-none  font-medium rounded text-sm px-5 py-2.5 text-center inline-flex items-center 5 mr-2 mb-2 mt-4 ml-6`} >
            {review}
          </p>
        )}
      </div> */}
        </div>
    )
}

export default StarRating
