/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { useState } from "react"
import axios from "../../axios"
import toast, { Toaster } from "react-hot-toast"
import { useDispatch } from "react-redux"
import { FileUploader } from "react-drag-drop-files"
import Cropper from "react-cropper"
import "cropperjs/dist/cropper.css"
import { useRef } from "react"
import { X } from "react-feather"
import CustomDotLoader from "../CustomDotLoader"
import { useSelector } from "react-redux"
import { addCompanyLogo, addWelcomeBg } from "../../redux/slices/globalDefine"
import { setUserValue } from "../../redux/slices/auth"

const GlobalBrandingUpload = ({ close, reload, check, content }) => {
    const dispatch = useDispatch()
    const { user } = useSelector((state) => state.userAuth)
    const [imageCode, setImageCode] = useState(null)
    const [preview, setPreview] = useState(null)
    const [mainPreview, setMainPreview] = useState(null)
    const [btn, setBtn] = useState(true)
    const [url, setImageUrl] = useState("")
    const [isEdit, setEditStatus] = useState(false)
    const [loader, setLoader] = useState(false)
    const userToken = localStorage.getItem("Token")

    const onClose = () => {
        setPreview(null)
    }

    // Welcome_bg

    const upload = async () => {
        try {
            setLoader(true)
            if (content === "Company_logo") {
                const { data } = await axios.post("/add-companylogo", {
                    id: user.id,
                    image: preview
                })
                if (data.success) {
                    toast.success(`${data.message}`)
                    if (userToken !== null) {
                        const { data } = await axios.get("/userDetails", {
                            headers: {
                                Authorization: userToken
                            }
                        })
                        dispatch(setUserValue(data[0]))
                        dispatch(addCompanyLogo(data[0].company_logo))
                        setBtn(false)
                        reload(check === true ? false : true)
                        setLoader(false)
                        onClose()
                        close(false)
                    }
                }
            } else if (content === "Welcome_bg") {
                const { data } = await axios.post("/add-welcomebg", {
                    id: user.id,
                    image: preview
                })
                if (data.success) {
                    toast.success(`${data.message}`)
                    if (userToken !== null) {
                        const { data } = await axios.get("/userDetails", {
                            headers: {
                                Authorization: userToken
                            }
                        })
                        dispatch(setUserValue(data[0]))
                        dispatch(addWelcomeBg(data[0].welcome_bg_logo))
                        setBtn(false)
                        reload(check === true ? false : true)
                        setLoader(false)
                        onClose()
                        close(false)
                    }
                }
            }
        } catch (error) {
            if (error.message === "Network Error") {
                setLoader(false)
                toast.error("File size too large")
            } else {
                setLoader(false)
                toast.error(error.message)
            }
        }
    }

    const getBase64 = (file) => {
        return new Promise((resolve) => {
            let fileInfo
            let baseURL = ""
            let reader = new FileReader()

            reader.readAsDataURL(file)

            reader.onload = () => {
                baseURL = reader.result
                resolve(baseURL)
            }
        })
    }

    const handleChange = (file) => {
        const array = file.name.split(".")
        if (array[array.length - 1] == "png" || array[array.length - 1] == "jpeg" || array[array.length - 1] == "jpg") {
            setImageUrl(URL.createObjectURL(file))
            getBase64(file).then((result) => {
                setMainPreview(result)
                setPreview(result)
            })
        } else {
            toast.error("Support image format only")
        }
    }

    const cropperRef = useRef(null)
    const onCrop = () => {
        const imageElement = cropperRef?.current
        const cropper = imageElement?.cropper
        setPreview(cropper.getCroppedCanvas().toDataURL())
    }

    return (
        <div className=" fixed top-0 left-0 w-screen h-screen flex justify-center items-center z-[70]  bg-[#00000042]">
            <div className="bg-[#fafafa] min-w-[90%] mx-auto sm:min-w-[500px] min-h-[300px] py-2 sm:px-[10px] rounded-lg">
                <div className="relative">
                    <button
                        type="button"
                        className="absolute -top-4 -right-4 w-6 h-6 text-white bg-[#4F46E5] rounded-full text-sm p-1 ml-auto inline-flex items-center justify-center  transition-all duration-300 z-50"
                        data-modal-toggle="popup-modal"
                        onClick={() => {
                            setImageUrl("")
                            setPreview(null)
                            close(false)
                        }}
                    >
                        <X size={25} />
                    </button>
                </div>
                <div className="overflow-hidden upload min-w-full min-h-full flex flex-col items-center relative">
                    <div
                        className={`flex flex-col items-center absolute top-[50px] ${
                            preview === null ? "block" : "hidden"
                        }`}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="mb-2 w-10 h-10 mx-auto text-[#4F46E5]"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5m-13.5-9L12 3m0 0l4.5 4.5M12 3v13.5"
                            />
                        </svg>
                        <p className="text-[16px] text-[#6d6d6d] font-semibold">Drag and Drop file to upload</p>
                        <p className="text-[16px] text-[#666666] font-semibold mt-3">Or</p>
                    </div>

                    {url && (
                        <div className={`${preview === null ? "hidden" : "block"} min-h-[300px] relative`}>
                            {isEdit && (
                                <div
                                    onClick={() => {
                                        setPreview(mainPreview)
                                        setEditStatus(false)
                                    }}
                                    className="absolute top-3 cursor-pointer flex"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth={1.5}
                                        stroke="currentColor"
                                        className="w-6 h-6"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M15.75 19.5L8.25 12l7.5-7.5"
                                        />
                                    </svg>
                                </div>
                            )}

                            <p className="text-[20px] py-2 pb-5 text-center font-semibold">
                                {isEdit ? "Crop" : "Upload"} Image
                            </p>
                            {!isEdit && (
                                <div className="min-w-[400px] mx-auto h-[200px] !border-[3px] !border-[#E4E4E7] rounded-xl flex justify-center items-center relative">
                                    <img
                                        src={url}
                                        className="max-w-full max-h-[90%]"
                                        alt=""
                                    />
                                    <svg
                                        onClick={() => setEditStatus(true)}
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth={1.5}
                                        stroke="currentColor"
                                        className="w-6 h-6 absolute top-2 text-[#4F46E5] cursor-pointer right-3"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                                        />
                                    </svg>
                                </div>
                            )}
                            {isEdit && (
                                <div className="max-w-[400px] mx-auto h-[220px] !border-[3px] !border-[#E4E4E7] rounded-xl flex justify-center items-center relative overflow-hidden">
                                    <Cropper
                                        src={url}
                                        className="max-w-full h-[90%]"
                                        initialAspectRatio={16 / 9}
                                        crop={onCrop}
                                        ref={cropperRef}
                                    />
                                </div>
                            )}

                            <div className="mt-10 mb-5 flex justify-between min-w-[400px]">
                                {!isEdit ? (
                                    <button
                                        onClick={() => {
                                            setImageUrl("")
                                            setPreview(null)
                                            close(false)
                                        }}
                                        data-modal-toggle="popup-modal"
                                        type="button"
                                        className={`focus:ring-4 focus:outline-none justify-center px-14 border border-[#56565c] text-[#56565c] bg-transparent font-medium rounded text-sm inline-flex items-center py-3 text-center hover:bg-transparent transition-all duration-300`}
                                    >
                                        Cancel
                                    </button>
                                ) : (
                                    <button
                                        onClick={() => {
                                            setPreview(mainPreview)
                                            cropperRef.current.cropper.reset()
                                        }}
                                        data-modal-toggle="popup-modal"
                                        type="button"
                                        className={`focus:ring-4 focus:outline-none justify-center px-14 border border-[#56565c] text-[#56565c] bg-transparent font-medium rounded text-sm inline-flex items-center py-3 text-center hover:bg-transparent transition-all duration-300`}
                                    >
                                        Reset
                                    </button>
                                )}
                                {!loader ? (
                                    <button
                                        data-modal-toggle="popup-modal"
                                        type="button"
                                        className={`focus:ring-4 max-w-[160px] w-[160px] h-[50px] focus:outline-none justify-center px-16  border-[#4F46E5] text-white bg-[#4F46E5] hover:bg-lightBlue font-medium rounded text-sm inline-flex items-center py-3 text-center hover:bg-transparent transition-all duration-300 hover:text-blue dark:hover:border-blue border`}
                                        onClick={upload}
                                    >
                                        Save
                                    </button>
                                ) : (
                                    <button
                                        data-modal-toggle="popup-modal"
                                        type="button"
                                        className={`focus:ring-4 max-w-[160px] overflow-hidden w-[160px] h-[50px] focus:outline-none justify-center px-16  border-[#4F46E5] text-white bg-[#4F46E5] hover:bg-lightBlue font-medium rounded text-sm inline-flex items-center py-3 text-center hover:bg-transparent transition-all duration-300 hover:text-blue dark:hover:border-blue border`}
                                    >
                                          <CustomDotLoader
                                            dotSize={50}
                                            isWhite
                                            className="mx-auto text-center mr-10"
                                        />
                                    </button>
                                )}
                            </div>
                        </div>
                    )}

                    <div className={`!z-50 mt-10 relative min-w-full ${preview === null ? "block" : "hidden"}`}>
                        <FileUploader
                            onDrop={handleChange}
                            handleChange={handleChange}
                            name="file"
                            hoverTitle={"Drag here"}
                            label="Browse"
                        />
                        <p className="absolute bottom-16 min-w-full text-center text-[#8a8a8d]">
                            Supported file: JPEG, PNG Up to 4MB.
                        </p>
                    </div>
                </div>
            </div>
            <Toaster
                position="top-right"
                reverseOrder={false}
            />
        </div>
    )
}

export default GlobalBrandingUpload
