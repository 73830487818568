import React from "react"
import { MdContentCopy } from "react-icons/md"
import toast, { Toaster } from "react-hot-toast"
import { X } from "react-feather"
import ModalCloseBtn from "../ModalCloseBtn"

const Popup = ({ show, hide, id }) => {
    const close = () => {
        return hide(false)
    }

    // const link = `http://localhost:3000/${id}?open=open`;
    const link = `${window.location.origin}/${id}?open=open`

    const click = () => {
        navigator.clipboard.writeText(link)
        return toast.success("Link Copied")
    }

    return (
        <div>
            {show && (
                <>
                    <div
                        id="popup-modal"
                        tabIndex="-1"
                        className="fixed top-0 left-0 w-full h-full z-[70] flex justify-center items-center bg-black/40"
                        aria-hidden="true"
                    >
                        <div className="relative p-4 w-full max-w-md">
                            <div
                                style={{ boxShadow: "2px 2px 10px #000" }}
                                className="relative bg-white rounded-lg"
                            >
                                <div className=" absolute top-1 right-1 ">
                                    <ModalCloseBtn
                                        onClick={close}
                                        size="sm"
                                    />
                                </div>
                                <div className="p-6 pt-10 text-center">
                                    <div className="my-3 flex items-center  bg-grayLight rounded px-3">
                                        <input
                                            type="text"
                                            placeholder="Event name"
                                            className="p-3 w-80  bg-grayLight outline-none border-none"
                                            value={link}
                                            defaultValue={link}
                                        />
                                        <MdContentCopy
                                            size={25}
                                            className="cursor-pointer hover:text-gray transition-all duration-300"
                                            onClick={click}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
            <Toaster
                position="top-right"
                reverseOrder={false}
            />
        </div>
    )
}

export default Popup
