import dayjs from "dayjs";
import axios from "../../../axios";
import React, { useEffect, useState } from "react";
import { BsCalendar2DateFill } from "react-icons/bs";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const MyPlan = ({ setManageComponentActive, setplanDetails }) => {
  const { user } = useSelector((state) => state.userAuth);
  const [purchaseContent, setPurchaseContent] = useState([]);
  useEffect(() => {
    const API = async () => {
      const { data } = await axios.get(
        `/getuser-payment-details?id=${user.id}`
      );
      if (data.success) {
        setPurchaseContent(data.data);
      }
    };

    API();
  }, [user]);

  const handelEvent = async (id) => {
    const filter = purchaseContent.filter((i) => i.id === id);
    setplanDetails(filter);
    setManageComponentActive(true);
  };

  return (
    <div className="w-full max-w-6xl p-8 mt-8 mx-auto | card">
      <h1 className=" text-xl font-bold">My Plan</h1>
      <div className="flex justify-between items-center">
        <sub className=" text-base font-normal text-grayDark">
          One plan designed for everyone
        </sub>
        {!purchaseContent?.length && (
          <Link className=" primaryButton" to="/payment">
            View Plans
          </Link>
        )}
      </div>
      {purchaseContent.map((i, idx) => (
        <div key={idx} className="flex gap-4 w-full items-center mt-8">
          <BsCalendar2DateFill size={40} className=" text-blue" />
          <div className=" flex-1">
            <h2 className=" text-lg font-semibold">
              Pro access - <span className="capitalize">{i.payment_plan}</span>
            </h2>
            <p className=" text-sm font-normal">
              You have a activated subscription for{" "}
              <span className="text-md font-medium">LifeTime</span>
            </p>
          </div>
          <button
            onClick={() => handelEvent(i.id)}
            className=" secondaryButton"
          >
            Manage subscription
          </button>
        </div>
      ))}
    </div>
  );
};

export default MyPlan;
