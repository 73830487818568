// ** import driver.js for highlighting
import { driver } from "driver.js"
import "driver.js/dist/driver.css"

const driverObj = driver({
    overlayOpacity: 0.1,
    stagePadding: 0,
    popoverOffset: 0,
    overlayColor: '#000000'
})

export const highlightComponent = (elementId) => {
    driverObj.highlight({
        element: elementId
    })
}

export const unHighlightComponent = () => {
    driverObj.destroy()
}
