import React, { useCallback, useEffect, useLayoutEffect } from "react";
import { useState } from "react";
import UploadImage from "../../../components/UploadImage/UploadImage";
import toast from "react-hot-toast";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Input from "../../../components/Input/Input";
import { reportSchema } from "../../../utils/validation";
import TextArea from "../../../components/Input/TextArea";
import { useSelector } from "react-redux";
import axios from "../../../axios";
import CustomDotLoader from "./../../../components/CustomDotLoader/index";

const ReportIssue = () => {
  const [isAddFileModalOpen, setIsAddFileModalOpen] = useState(false);
  const [statusChange, setstatusChange] = useState(false);
  const [preview, setPreview] = useState(null);
  const [upload, setupload] = useState(false);
  const [base64Image, setBase64Image] = useState(null);
  const { user } = useSelector((state) => state.userAuth);
  const [loader, setLoader] = useState(false);

  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(reportSchema),
  });

  useEffect(() => {
    reset({
      Email: user.email !== undefined && user.email !== null ? user.email : "",
    });
  }, [user]);

  const deleteImage = () => {
    return setBase64Image(null);
  };

  const onSubmit = async (res) => {
    if (base64Image === null) return toast.error("Upload attachment");
    setLoader(true);
    const fd = new FormData();
    fd.append("email", res.Email);
    fd.append("describe", res.Describe);
    fd.append("url", res.URL);
    fd.append("attachment", preview);

    try {
      const { data } = await axios.post("/issue-report", fd);
      if (data.success) {
        setBase64Image(null);
        reset({
          Describe: "",
          URL: "",
        });
        return toast.success(`${data.message}`);
      } else {
        return toast.error(`${data.message}`);
      }
    } catch (error) {
      console.error("Error submitting form: ", error);
      return toast.error("An error occurred while submitting the form.");
    } finally {
      setLoader(false);
    }
  };

  return (
    <div className="w-fit min-w-[800px] max-w-6xl p-8 mt-8 mx-auto | card">
      <div className="max-w-xl  md:px-0 pb-10">
        <h2 className={`text-[16px] font-bold text-black font-pj`}>
          Report an issue
        </h2>
        <p className="mt-2 text-sm font-normal text-[#52525B] font-pj">
          Our team will respond within 24 hours
        </p>
      </div>

      <div className=" max-w-3xl ">
        <form onSubmit={handleSubmit(onSubmit)} action="" className="space-y-5">
          <Input
            name="URL"
            type="url"
            placeholder="e.g: https://app.feedbacksync.com/global-settings"
            register={register}
            error={errors?.URL?.message}
          />

          <Input
            name="Email"
            type="email"
            placeholder="Enter email address"
            register={register}
            disable="disable"
            error={errors?.Email?.message}
          />

          <TextArea
            name="Describe the issue in detail"
            type="text"
            placeholder="Describe"
            register={register}
            error={errors?.Describe?.message}
          />

          {/* email  */}

          <div className="flex flex-col mt-4">
            <div className=" flex-[0.2] text-base text-black">
              <p>Attach file</p>
            </div>
            <div className="email_qr_logo_upload flex-[0.8]">
              <div className="email_qr_logo_img !border">
                {base64Image !== null && (
                  <img src={base64Image} alt="issue_image" />
                )}
              </div>
              <div className="email_qr_logo_btn">
                <p
                  className="email_qr_logo_text"
                  onClick={() => {
                    setPreview(null);
                    setIsAddFileModalOpen(true);
                  }}
                >
                  Upload
                </p>
                {base64Image !== null && (
                  <p onClick={deleteImage} className="email_qr_logo_remove">
                    Remove
                  </p>
                )}
              </div>
            </div>
          </div>
          <div className="flex items-end justify-end">
            {!loader ? (
              <button type="submit" className=" primaryButton w-[150px] mt-8 ">
                Send Issue
              </button>
            ) : (
              <button className=" primaryButton w-[150px] mt-8 ">
                <CustomDotLoader dotSize={50} isWhite />
              </button>
            )}
          </div>
        </form>
      </div>

      {isAddFileModalOpen && (
        <UploadImage
          show={isAddFileModalOpen}
          close={() => setIsAddFileModalOpen(false)}
          pre={setPreview}
          uploads={setupload}
          statusChange={statusChange}
          change={setstatusChange}
          setbase64String={setBase64Image}
        />
      )}
    </div>
  );
};

export default ReportIssue;
