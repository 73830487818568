import React, { useCallback, useEffect, useState } from "react"
import Navbar from "../../components/Navbar"
import { RiArrowDropDownLine } from "react-icons/ri"
import { AiOutlineSearch } from "react-icons/ai"
import Table from "./Table"
import { useSelector } from "react-redux"
import axios from "../../axios"
import CustomDotLoader from "./../../components/CustomDotLoader/index"
import NoEvent from "../../assets/svg/eventnotfound.svg"
import SmallScreenOverlay from "../SmallScreenOverlay"

const Reports = () => {
    const [filterPop, setfilterPop] = useState(false)
    const [selectedFilter, setselectedFilter] = useState("Newest")
    const { user } = useSelector((state) => state.userAuth)
    const [info, setInfo] = useState([])
    const [infoStatus, setInfoStatus] = useState(null)
    const [search, setSearch] = useState("")
    const [filterData, setFilterData] = useState([])

    const handelSelectFilter = (e) => {
        setselectedFilter(e)
        setfilterPop(false)
    }

    useEffect(() => {
        // Filter the data based on the search text
        const result =
            search === "" ? info : info.filter((item) => item?.eventName.toLowerCase().includes(search.toLowerCase()))
        setFilterData(result) // Update the filtered data state
    }, [search, info])

    useEffect(() => {
        // Fetch user events when the user changes
        if (user) {
            const Api = async () => {
                if (user) {
                    const { data } = await axios.post("/getParticularUserEvent", {
                        id: user.id
                    })
                    if (data.length === 0) {
                        setInfoStatus(false)
                    } else {
                        setInfoStatus(true)
                        setInfo(data)
                    }
                }
            }
            Api()
        }
    }, [user])

    useEffect(() => {
        // Sort the data based on the selected filter
        if (selectedFilter === "Newest") {
            const filter = info.slice().sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
            setFilterData(filter)
        } else if (selectedFilter === "Old") {
            const filter = info.slice().sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
            setFilterData(filter)
        } else if (selectedFilter === "Popular") {
            const filter = info.slice().sort((a, b) => b.feedback_count - a.feedback_count)
            setFilterData(filter)
        }
    }, [selectedFilter, info])

    return (
        <>
            <div className=" max-lg:hidden">
                <Navbar />

                {infoStatus === null && (
                    <>
                        <div className={`w-full h-[80%] absolute flex items-center justify-center bg-[#fafaf]`}>
                            <CustomDotLoader dotSize={120} />
                        </div>
                    </>
                )}
                {infoStatus === false && (
                    <div className="no-event">
                        <img
                            src={NoEvent}
                            alt="noevent"
                        />
                        <p className="no-event-content">No event found!</p>
                        <p className="no-event-content">Please add your event first</p>
                    </div>
                )}
                {infoStatus && (
                    <div className="mt-10 mx-auto max-w-[105rem] sectionPadding pb-4">
                        <div className="flex justify-between items-end mb-8">
                            <div>
                                <h1 className="reportTitle">Reports</h1>
                                <p className={`mt-1 text-sm font-medium  text-[#71717A]`}>
                                    Actionable insights at your fingertips
                                </p>
                            </div>
                            <div className="report_subcontent">
                                <div className="report_input">
                                    <AiOutlineSearch
                                        size={20}
                                        className="searchbox_icon"
                                    />
                                    <input
                                        className="searchbox"
                                        type="search"
                                        placeholder="Search..."
                                        onChange={(e) => setSearch(e.target.value)}
                                    />
                                </div>
                                <div
                                    className="report_dropdown relative select-none"
                                    onClick={() => setfilterPop(!filterPop)}
                                >
                                    <div className="report_selected_element">
                                        <p>{selectedFilter}</p>
                                        <RiArrowDropDownLine
                                            size={28}
                                            className=" absolute right-0"
                                        />
                                    </div>
                                    {filterPop && (
                                        <div className="dropdown_content shadow-lg">
                                            <p
                                                onClick={() => handelSelectFilter("Popular")}
                                                className="dropdown_content_text"
                                            >
                                                Popular
                                            </p>
                                            <p
                                                onClick={() => handelSelectFilter("Old")}
                                                className="dropdown_content_text"
                                            >
                                                Old
                                            </p>
                                            <p
                                                onClick={() => handelSelectFilter("Newest")}
                                                className="dropdown_content_text"
                                            >
                                                Newest
                                            </p>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>

                        <Table items={filterData} />
                    </div>
                )}
            </div>
            <SmallScreenOverlay />
        </>
    )
}

export default Reports
