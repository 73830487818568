import axios from "../../axios";
import React from "react";
import { useState } from "react";
import { Eye, EyeOff } from "react-feather";
import toast, { Toaster } from "react-hot-toast";
import { useSelector } from "react-redux";
import CustomDotLoader from './../../components/CustomDotLoader/index'


const Password = () => {
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");

  const [show, setShow] = useState("password");
  const [show1, setShow1] = useState("password");
  const [show2, setShow2] = useState("password");
  const { user } = useSelector((state) => state.userAuth);
  const { mood } = useSelector((state) => state.mood);
  const [loader, setLoader] = useState(false);

  const changePassword = async () => {
    if (password !== newPassword) return toast.error("Password doesn't match");

    if (newPassword.length === 0) return toast.error("Input the password");
    setLoader(true);
    if (user) {
      const { data } = await axios.post("/changePassword", {
        id: user?.id,
        password: newPassword,
        currentPassword: currentPassword,
      });
      if (data.success) {
        setLoader(false);

        const createNotification = await axios.post("/add-Notification-sa", {
          id: user.id,
          message: "User Changed Password",
          pic: user?.profilePic,
        });
        if (createNotification.data.success) {
          toast.success(data.message);
          setCurrentPassword("");
          setNewPassword("");
          setPassword("");
          return;
        }
      } else {
        setLoader(false);
        toast.error(data.message);
        return;
      }
    } else {
      setLoader(false);
      return;
    }
  };

  return (
    <div className="w-full max-w-6xl p-8 mt-8 mx-auto | card">
      <div className="max-w-xl  md:px-0 pb-10">
        <h2
          className={`text-[16px] font-bold ${
            mood === "light" ? "text-black" : "text-[#ddd]"
          } font-pj`}
        >
          Change Password
        </h2>
        <p className="mt-2 text-sm font-normal text-[#52525B] font-pj">
          Fill the details below to change your password
        </p>
      </div>
      {user?.auth === "local" && (
        <div className="w-full ms:w-[auto] flex justify-start items-start ms:px-8 md:px-0 mt-5 flex-col sm:flex-row gap-4 ">
          <h2
            className={`w-[auto] sm:w-[20%] block text-[14px] font-bold ${
              mood === "light" ? "text-black" : "text-[#ddd]"
            } sm:mt-px sm:pt-2`}
          >
            Current password
          </h2>

          <div className="">
            <div className="relative">
              <div className="absolute inset-y-0 right-0 flex items-center pr-4 cursor-pointer">
                {show === "password" && <Eye onClick={() => setShow("text")} />}
                {show === "text" && (
                  <EyeOff onClick={() => setShow("password")} />
                )}
              </div>

              <input
                type={show}
                name=""
                id=""
                required
                value={currentPassword}
                placeholder="Current password"
                onChange={(e) => setCurrentPassword(e.target.value)}
                className="md:min-w-[400px] !outline-none border block w-full px-4 py-3 placeholder-[#9CA3AF] border-[#D4D4D8] rounded-lg focus:ring-[#4F46E5] focus:border-[#4F46E5] sm:text-sm caret-[#4F46E5]"
              />
            </div>
          </div>
        </div>
      )}

      <div className="w-full ms:w-[auto] flex justify-start items-start ms:px-8 md:px-0 mt-5 flex-col sm:flex-row gap-4 ">
        <h2
          className={`w-[auto] sm:w-[20%] block text-[14px] font-bold ${
            mood === "light" ? "text-black" : "text-[#ddd]"
          } sm:mt-px sm:pt-2`}
        >
          Enter new password
        </h2>

        <div className="">
          <div className="relative">
            <div className="absolute inset-y-0 right-0 flex items-center pr-4 cursor-pointer">
              {show1 == "password" && <Eye onClick={() => setShow1("text")} />}
              {show1 == "text" && (
                <EyeOff onClick={() => setShow1("password")} />
              )}
            </div>

            <input
              type={show1}
              name=""
              id=""
              required
              value={password}
              placeholder="Set a new password"
              onChange={(e) => setPassword(e.target.value)}
              className="md:min-w-[400px] !outline-none border block w-full px-4 py-3 placeholder-[#9CA3AF] border-[#D4D4D8] rounded-lg focus:ring-[#4F46E5] focus:border-[#4F46E5] sm:text-sm caret-[#4F46E5]"
            />
          </div>
        </div>
      </div>

      <div className="w-full ms:w-[auto] flex justify-start items-center ms:px-8 md:px-0 mt-5 flex-col sm:flex-row gap-4 ">
        <h2
          className={`w-[auto] sm:w-[20%] block text-[14px] font-bold ${
            mood === "light" ? "text-black" : "text-[#ddd]"
          } sm:mt-px sm:pt-2`}
        >
          Repeat new password
        </h2>

        <div className="">
          <div className="relative">
            <div className="absolute inset-y-0 right-0 flex items-center pr-4 cursor-pointer">
              {show2 === "password" && <Eye onClick={() => setShow2("text")} />}
              {show2 === "text" && (
                <EyeOff onClick={() => setShow2("password")} />
              )}
            </div>

            <input
              type={show2}
              name=""
              id=""
              required
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              placeholder="Re-enter the new password"
              className="md:min-w-[400px] !outline-none border block w-full px-4 py-3 placeholder-[#9CA3AF] border-[#D4D4D8] rounded-lg
               focus:ring-[#4F46E5] focus:border-[#4F46E5] sm:text-sm caret-[#4F46E5]"
            />
          </div>
        </div>
      </div>

      <div className="w-full flex mt-8">
        {!loader ? (
          <button
            type="submit"
            onClick={changePassword}
            className=" primaryButton py-3 w-[100px]"
          >
            Save
          </button>
        ) : (
          <button
            type="submit"
            disabled
            className=" primaryButton py-3 w-[100px]"
          >
           <CustomDotLoader
                          dotSize={50}
                          isWhite
                          className="mx-auto text-center"
                      />
          </button>
        )}
      </div>
      <Toaster position="top-right" reverseOrder={false} />
    </div>
  );
};

export default Password;
