import { Suspense, useEffect, useState } from "react";
import { Outlet, useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setUserValue } from "../redux/slices/auth";
import axios from "../axios";
import {
  addButtonColor,
  addCompanyLogo,
  addTextColor,
  addWelcomeBg,
  addWelcomeTextColor,
} from "../redux/slices/globalDefine";

const PrivateRoute = ({ children }) => {
  const navigate = useNavigate();
  const userToken = localStorage.getItem("Token");
  const dispatch = useDispatch();
  // const getInfo = localStorage.getItem("SIGNIN");
  const getMethod = localStorage.getItem("Signin Method");
  const getUserID = localStorage.getItem("UserID");
  const [searchParams] = useSearchParams();
  const Admin = searchParams.get("admin");
  const Token = searchParams.get("token");

  if (Admin !== null) {
    localStorage.setItem("Token", Token);
  }

  const getUser = async () => {
    try {
      if (Admin !== null) {
        const { data } = await axios.get("/userDetails", {
          headers: {
            Authorization: Token,
          },
        });
        dispatch(setUserValue(null));

        if (data.length === 0) {
          return navigate("/login");
        } else {
          return dispatch(setUserValue(data[0]));
        }
      }
      // if (getInfo !== null) {
      if (userToken !== null) {
        const { data } = await axios.get("/userDetails", {
          headers: {
            Authorization: userToken,
          },
        });
        dispatch(setUserValue(null));

        if (data.length === 0) {
          navigate("/login");
        } else {
          dispatch(setUserValue(data[0]));
          if (!data[0].payment_activate) {
            navigate("/");
          }
          // dispatch(
          //   addCompanyLogo(
          //     data?.[0]?.company_logo !== undefined &&
          //       data?.[0]?.company_logo?.length !== 0
          //       ? data[0].company_logo
          //       : null
          //   )
          // );
          // dispatch(
          //   addWelcomeBg(
          //     data[0].welcome_bg_logo !== undefined &&
          //       data[0].welcome_bg_logo.length !== 0
          //       ? data[0].welcome_bg_logo
          //       : null
          //   )
          // );
          // dispatch(
          //   addButtonColor(
          //     data[0].btn_color !== undefined &&
          //       data[0].btn_color.length !== 0
          //       ? data[0].btn_color
          //       : "#4F46E5"
          //   )
          // );
          // dispatch(
          //   addTextColor(
          //     data[0].text_color !== undefined &&
          //       data[0].text_color.length !== 0
          //       ? data[0].text_color
          //       : "#D9D9D9"
          //   )
          // );
          // dispatch(
          //   addWelcomeTextColor(
          //     data[0].welcome_screen_color !== undefined &&
          //       data[0].welcome_screen_color.length !== 0
          //       ? data[0].welcome_screen_color
          //       : "#000000"
          //   )
          // );
        }
      } else if (getMethod === "google" || getMethod === "linkedIn") {
        if (getUserID !== null) {
          const { data } = await axios.post("/getParticularUser", {
            id: getUserID,
          });
          dispatch(setUserValue(null));

          if (data.length === 0) {
            navigate("/login");
          } else {
            dispatch(setUserValue(data[0]));
            if (!data[0].payment_activate) {
              navigate("/");
            }
            // dispatch(
            //   addCompanyLogo(
            //     data?.[0]?.company_logo !== undefined &&
            //       data[0].company_logo.length !== 0
            //       ? data[0].company_logo
            //       : null
            //   )
            // );
            // dispatch(
            //   addWelcomeBg(
            //     data[0].welcome_bg_logo !== undefined &&
            //       data[0].welcome_bg_logo.length !== 0
            //       ? data[0].welcome_bg_logo
            //       : null
            //   )
            // );
            // dispatch(
            //   addButtonColor(
            //     data[0].btn_color !== undefined &&
            //       data[0].btn_color.length !== 0
            //       ? data[0].btn_color
            //       : "#4F46E5"
            //   )
            // );
            // dispatch(
            //   addTextColor(
            //     data[0].text_color !== undefined &&
            //       data[0].text_color.length !== 0
            //       ? data[0].text_color
            //       : "#D9D9D9"
            //   )
            // );
            // dispatch(
            //   addWelcomeTextColor(
            //     data[0].welcome_screen_color !== undefined &&
            //       data[0].welcome_screen_color.length !== 0
            //       ? data[0].welcome_screen_color
            //       : "#000000"
            //   )
            // );
          }
        }
      } else if (Token !== null) {
        const { data } = await axios.get("/userDetails", {
          headers: {
            Authorization: Token,
          },
        });
        dispatch(setUserValue(null));


        if (data.length === 0) {
          return navigate("/login");
        } else {
          dispatch(setUserValue(data[0]));
          if (!data[0].payment_activate) {
            navigate("/");
          }
          return;
        }
      } else {
        navigate("/login");
      }
      // }
      // else {
      //   navigate("/login");
      // }
    } catch (error) {
      navigate("/login");
    }
  };

  useEffect(() => {
    getUser();
  }, [Admin, Token]);

  return (
    <Suspense fallback={null}>
      <Outlet />
    </Suspense>
  );
};

export default PrivateRoute;
