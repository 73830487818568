import React, { useEffect, useRef } from "react"
import { PDFExport, savePDF } from "@progress/kendo-react-pdf"
import logoDark from "../../assets/svg/logo.svg"
import { useSelector } from "react-redux"

const ContactTableExport = ({ item, buttonClick }) => {
    const { mood } = useSelector((state) => state.mood)

    const handelExport = (event) => {
        pdfExportComponent.current.save()
    }

    useEffect(() => {
        if (buttonClick != null) {
            handelExport()
        }
    }, [buttonClick])

    const pdfExportComponent = useRef()

    function Items({ currentItems }) {
        return (
            <>
                {currentItems &&
                    currentItems.map((i) => (
                        <tr className="bg-grayLigth text-center">
                            <td className={`px-4 py-4 text-sm font-bold align-top lg:align-middle whitespace-nowrap`}>
                                <div
                                    className={`flex items-center justify-start font-inter text-[13px] text-[#71717A]`}
                                >
                                    {new Date(i[0].date).toDateString()}
                                </div>
                            </td>
                            <td className="hidden px-4 py-4 text-sm font-medium text-grayDark lg:table-cell whitespace-nowrap">
                                <div
                                    className={`flex items-center justify-start font-inter text-[13px] text-[#71717A]`}
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth={1.5}
                                        stroke="currentColor"
                                        className="w-4 h-4 mr-2"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 
                                  2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5"
                                        />
                                    </svg>
                                    {i.map((subItem) => subItem.type === "text" && subItem.value)}
                                </div>
                            </td>

                            <td className="hidden px-4 py-4 text-center text-sm font-medium text-grayDark lg:table-cell whitespace-nowrap">
                                <div
                                    className={`flex items-center justify-start font-inter text-[13px] text-[#71717A]`}
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth={1.5}
                                        stroke="currentColor"
                                        className="w-4 h-4 mr-2"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25
                                   2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5"
                                        />
                                    </svg>
                                    {i.map((subItem) => subItem.type === "email" && subItem.value)}
                                </div>
                            </td>

                            <td className="hidden px-4 py-4 text-sm font-medium text-grayDark lg:table-cell whitespace-nowrap">
                                <div
                                    className={`flex items-center justify-start  font-inter text-[13px] text-[#71717A]`}
                                >
                                    {i.map((subItem) => subItem.type === "number" && subItem.value)}
                                </div>
                            </td>

                            <td className="px-4 py-4 text-sm font-medium text-grayDark xl:table-cell whitespace-nowrap">
                                <div className={`flex items-start justify-start font-inter text-[13px] text-[#71717A]`}>
                                    {i.map((subItem) => subItem.type === "age" && subItem.value)}
                                </div>
                            </td>

                            <td className={`px-4 py-4 text-sm flex justify-center text-[#71717A] align-top`}>
                                {i.map((subItem) => subItem.placeholder === "Select Gender" && subItem.value)}
                            </td>
                            <td className={`text-[#71717A] relative min-w-[70px] max-w-[300px] break-words `}>
                                {i.map(
                                    (subItem) =>
                                        subItem.type === "feedback" && (
                                            <>
                                                <p className="">{subItem.value}</p>
                                            </>
                                        )
                                )}
                            </td>
                        </tr>
                    ))}
            </>
        )
    }

    return (
        <div>
            <PDFExport
                ref={pdfExportComponent}
                paperSize="A2"
            >
                <div
                    className={`logoBar py-3 border-b ${
                        mood === "light" ? `bg-black border-[#E4E4E7]` : "bg-[#161A25] border-gray"
                    } `}
                >
                    <div className={`px-4 mx-auto w-[95%] 3xl:max-w-7xl sm:px-2 py-1`}>
                        <div className="flex items-center w-full justify-center xs:flex-col sm:flex-col ms:flex-col lg:flex-row xl:flex-row">
                            <div className="my-2">
                                <>
                                    <img
                                        className="block w-auto h-8 lg:hidden"
                                        src={logoDark}
                                        alt=""
                                    />
                                    <img
                                        className="hidden w-auto h-8 lg:block"
                                        src={logoDark}
                                        alt=""
                                    />
                                </>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="py-10">
                    <table className={`min-w-full lg:divide-gray lg:divide-y bg-white !rounded-lg mb-[50px] mt-[20px]`}>
                        <thead className="hidden lg:table-header-group">
                            <tr>
                                <th
                                    className={`py-3.5 px-4 text-start text-[12px] font-[600] text-[#71717A] uppercase`}
                                >
                                    Date & Time
                                </th>

                                <th
                                    className={`py-3.5 px-4 text-start text-[12px] font-[600] text-[#71717A] uppercase`}
                                >
                                    Name
                                </th>

                                <th
                                    className={`py-3.5 px-4 text-start text-[12px] font-[600] text-[#71717A] uppercase`}
                                >
                                    Email
                                </th>

                                <th
                                    className={`py-3.5 px-4 text-start text-[12px] font-[600] text-[#71717A] uppercase`}
                                >
                                    Mobile Number
                                </th>

                                <th
                                    className={`py-3.5 px-4 text-start text-[12px] font-[600] text-[#71717A] uppercase`}
                                >
                                    Age
                                </th>

                                <th
                                    className={`py-3.5 px-4 text-center text-[12px] font-[600] text-[#71717A] uppercase`}
                                >
                                    Gender
                                </th>

                                <th
                                    className={`py-3.5 px-4 text-center text-[12px] font-[600] text-[#71717A] uppercase`}
                                >
                                    Description
                                </th>
                            </tr>
                        </thead>

                        <tbody className="text-center divide-gray divide-y">
                            <Items currentItems={item} />
                        </tbody>
                    </table>
                </div>
            </PDFExport>
        </div>
    )
}

export default ContactTableExport
