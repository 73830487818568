/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react"
import { RatingComponent } from "react-rating-emoji"
import "react-rating-emoji/dist/index.css"
import { useDispatch, useSelector } from "react-redux"
import { setFeedbackValue } from "../../../redux/slices/feedbackValue"
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io"
import "./Emoji.module.css"

import { svg1, svg2, svg3, svg4, svg5 } from "../../../assets/ratting/1"

const Emoji = ({
    inputQuestion,
    id,
    questionType,
    answer,
    nextQuz,
    next,
    eventId,
    viewStatus,
    color,
    emojiSize = "120px"
}) => {
    console.log(emojiSize)
    const [rating, setRating] = useState(0)
    const { questionValues } = useSelector((state) => state.questions)
    const [nextValues, setNextValues] = useState([])
    const { mood } = useSelector((state) => state.mood)
    const { questions } = useSelector((state) => state)
    const logoInfo = questions.logoDetails
    const buttonColors = questions.buttonColor
    const textColors = questions.textColor
    // const { buttonColor} = useSelector((state) => state.globalDefine);
    // const { textColor } = useSelector((state) => state.globalDefine);
    const [selected, setSelected] = useState(10)
    const [state, setState] = useState([
        {
            img: svg1({ size: emojiSize, color: color === null ? "#4F46E5" : color }),
            text: "Terrible"
        },
        {
            img: svg2({ size: emojiSize, color: color === null ? "#4F46E5" : color }),
            text: "Do better"
        },
        {
            img: svg3({ size: emojiSize, color: color === null ? "#4F46E5" : color }),
            text: "Decent"
        },
        {
            img: svg4({ size: emojiSize, color: color === null ? "#4F46E5" : color }),
            text: "I enjoyed"
        },
        {
            img: svg5({ size: emojiSize, color: color === null ? "#4F46E5" : color }),
            text: "Love it"
        }
    ])

    // const { companyLogo } = useSelector((state) => state.globalDefine);
    const [preValue, setPreValue] = useState([])
    let prev = Number(id) - 1
    useEffect(() => {
        const filter = questionValues.filter((e) => Number(e.id) === Number(prev))
        setPreValue(filter)
    }, [questionType, id])

    let nextId = Number(id) + 1

    const dispatch = useDispatch()

    const getValue = (answer) => {
        if (
            inputQuestion.length !== 0 &&
            answer.length !== 0 &&
            id.length !== 0 &&
            questionType.length !== 0 &&
            answer.length !== 0 &&
            eventId.length !== 0
        ) {
            return dispatch(
                setFeedbackValue({
                    eventId: eventId,

                    list_of_items: [
                        {
                            question: inputQuestion,
                            answer: answer.toString(),
                            answerId: id,
                            type: questionType
                        }
                    ]
                })
            )
        }
    }

    useEffect(() => {
        const filter = questionValues.filter((e) => Number(e.id) === Number(nextId))
        setNextValues(filter)
    }, [questionType, id])

    const handleRating = (newRating) => {
        getValue(newRating)
        setRating(newRating)
    }
    return (
        <div className=" w-full flex flex-col items-center  mt-[30px] overflow-y-auto overflow-x-hidden">
            {logoInfo.length !== 0 && (
                <img
                    className="mb-[30px] max-w-[125px]"
                    src={logoInfo[0]?.logoLocation}
                    alt=""
                />
            )}
            {/* {
        logoInfo.length === 0 && companyLogo !== null && 
        <img
        className="mb-[30px] max-w-[125px]"
        src={companyLogo}
        alt=""
      />
      } */}

            <h3
                style={{
                    color: `${
                        (textColors === null) & (textColors === undefined) && textColors
                        // ? textColor : textColors
                    }`
                }}
                className={`w-[80%] max-h-full z-20 text-center mx-10 text-[18px] font-[500] font-inter  ${
                    mood === "light" ? "text-black" : "text-white"
                } break-words`}
            >
                <span className="w-full">{inputQuestion}</span>
            </h3>
            <div className="my-5 flex gap-4 ">
                {state.map((data, index) => {
                    return (
                        <div
                            className={`${
                                viewStatus !== "computer" && "w-[40px]"
                            } flex flex-col justify-center items-center ${
                                selected === index ? "opacity-100" : "opacity-50"
                            }`}
                        >
                            {" "}
                            <div
                                style={{
                                    filter: selected === index ? "saturate(1)" : "saturate(0)",
                                    WebkitFilter: selected === index ? "saturate(1)" : "saturate(0)",
                                    ":hover": {
                                        WebkitFilter: "saturate(1)"
                                    },
                                    transform: selected === index ? "scale(1.3)" : "scale(1)"
                                }}
                                className={` ${selected === index && "px-3"} cursor-pointer mx-3 xs:mx-1  transition duration-300`}
                                onClick={() => {
                                    setSelected(index)
                                    handleRating(index + 1)
                                }}
                            >
                                <span>{data.img}</span>
                            </div>
                            {/* <p className={`mt-2 ${viewStatus !== "computer" && "hidden"}`}>
                {data.text}
              </p> */}
                        </div>
                    )
                })}
            </div>
            <div className="flex items-center gap-x-3">
                {preValue.length !== 0 && (
                    <button
                        type="button"
                        style={{
                            background: `${
                                buttonColors === null || buttonColors === undefined
                                    ? "#4F46E5"
                                    : //  buttonColor
                                      buttonColors
                            }`
                        }}
                        className=" rounded-full p-3 my-3 transition-all duration-200 text-lg font-semibold text-center block "
                        onClick={() => {
                            nextQuz(preValue[0].type)
                            next(prev)
                        }}
                    >
                        <IoIosArrowBack
                            size={40}
                            color="white"
                        />
                    </button>
                )}
                {nextValues.length !== 0 && (
                    <button
                        style={{
                            background: `${
                                buttonColors === null || buttonColors === undefined
                                    ? "#4F46E5"
                                    : //  buttonColor
                                      buttonColors
                            }`
                        }}
                        className=" rounded-full p-3 my-3 transition-all duration-200 text-lg font-semibold text-center block "
                        onClick={() => {
                            nextQuz(nextValues[0].type)
                            next(nextId)
                        }}
                    >
                        <IoIosArrowForward
                            size={40}
                            color="white"
                        />
                    </button>
                )}
            </div>
        </div>
    )
}

export default Emoji
